import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import isEqual from 'lodash/isEqual';

import { getProducts } from '../actions';
import { makeItemsKey, itemsKeysDiffers } from '../reducers/products/itemsKey';

import LoadingIndicator from '../components/spinners';
import ProductArchive from '../components/ProductArchive';
import { defaultOrdering } from '../components/ProductArchiveOrdering';


class ProductArchiveFilters extends React.Component {
  constructor(props) {
    super(props);

    const {
      productsOffset = 0,
      productsLimit
    } = this.props;

    this.state = {
      productsOffset,
      productsLimit,
      isFetching: false
    }

    this.getNextPageProducts = this.getNextPageProducts.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { products: nextProducts } = nextProps;
    const { products: currentProducts } = this.props;

    const hasChanged = !isEqual(currentProducts, nextProducts);
    return hasChanged || nextState.isFetching;
  }

  componentDidMount() {
    const { needsInitialUpdate } = this.props;
    if (needsInitialUpdate) {
      this.getProducts(true);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { filters: nextFilters, ordering: nextOrdering } = nextProps;
    const { filters, ordering } = this.props;

    const hasChanged = !isEqual(nextFilters, filters) || !isEqual(nextOrdering, ordering);

    if (hasChanged) {
      this.getProducts(true);
    }
    else {
      this.setState({ isFetching: false });
    }
  }

  getProducts(resetOffset = false) {
    const { productsOffset, productsLimit } = this.state;

    this.setState({
      productsOffset: resetOffset ? 0 : (productsOffset + productsLimit),
      isFetching: true,
    }, () => {
      const productParams = this.constructProductParams();
      this.props.getProducts(productParams);
    });
  }

  constructProductParams() {
    const { filters, ordering, params } = this.props;
    const { productsOffset, productsLimit } = this.state;

    let productParams = {
      ...filters,
      limit: productsLimit,
      offset: productsOffset,
    };

    let _ordering = [];

    if (filters.activitySlugs && filters.activitySlugs.length) {
      _ordering.push('ACTIVITIES_FIRST')
    }

    _ordering.push(ordering !== undefined ? ordering : defaultOrdering);

    productParams.orderBy = _ordering;

    productParams.imageVariants = {
      archive: {
        h: 190
      },
    };

    return productParams;
  }

  getNextPageProducts() {
    this.getProducts(false);
  }

  hasReachedLastPage() {
    const { products, totalNumOfProducts } = this.props;
    return (products || []).length == totalNumOfProducts;
  }

  render() {
    const { products, totalNumOfProducts, t, blockConditions, listType } = this.props;
    const reachedLastPage = this.hasReachedLastPage();

    return (
      <div>
        <div>
          {
            products !== null ?
              (
                <div>
                  <ProductArchive
                    products={products}
                    totalNumOfProducts={totalNumOfProducts}
                    blockConditions={blockConditions}
                    listType={listType}
                  />

                  {
                    !reachedLastPage &&
                    <div className="mt-3 text-center">
                      <button className={`btn btn-primary btn-lg`} disabled={this.state.isFetching} onClick={this.getNextPageProducts}>
                        {
                          this.state.isFetching ?
                            <span>
                              <i className="fa fa-circle-o-notch fa-spin fa-fw" /> {t('buttons.nextPage.fetching.label')}
                            </span> :
                            <span>{t('buttons.nextPage.idle.label')}</span>
                        }
                      </button>
                    </div>
                  }
                </div>
              ) :
              (
                <LoadingIndicator />
              )
          }
        </div>
      </div>
    );
  }
}

function mapStateToProductArchiveFiltersProps(state) {
  const {
    products: {
      items: products,
      totalNumOfItems: totalNumOfProducts,
      filters: previousProductParams,
    },
  } = state;

  const historyWasPopped = false;
  const needsInitialUpdate = products === null || !historyWasPopped;

  const productsLimit = 36;
  const productsOffset = !needsInitialUpdate && previousProductParams && previousProductParams.offset ? previousProductParams.offset : 0;

  return {
    products,
    totalNumOfProducts,
    needsInitialUpdate,
    productsLimit,
    productsOffset,
  }
}


export default connect(mapStateToProductArchiveFiltersProps, {
  getProducts,
})(translate('productArchive')(ProductArchiveFilters));
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import find from 'lodash/find'

import config from '../../config'

import {
  setDeliveryMethod,
  setPaymentMethod,
  setDeliveryAddress,
  setInvoiceAddress,
} from 'actions'

import {
  orderApi
} from 'api'

import { DarkButton } from 'components/buttons'
import useBoolState from 'hooks/useBoolState'
import ModalWithClose from 'components/modals/Modal'
import ContentHeader from 'components/ContentHeader'
import Table from 'components/Table'
import CreateCustomerFormWrapper from 'components/forms/CreateCustomerFormWrapper'
import { Translate } from 'containers/translates'
import DeliveryMethodPriceColumn from './DeliveryMethodPriceColumn'
import DeliveryMethodMainColumn from './DeliveryMethodMainColumn'
import EditCustomerFormWrapper from 'components/forms/EditCustomerFormWrapper';

function OrderDeliveryMethodSelectionForOtherThanIndividual({
  deliveryMethods,
  selectedDeliveryMethodId,
  customer,
  order,
  setDeliveryMethod,
  setPaymentMethod,
  setDeliveryAddress,
  setInvoiceAddress,
}) {

  const [createDeliveryAddressFormDialogIsOpen, openCreateDeliveryAddressFormDialog, closeCreateDeliveryAddressFormDialog] = useBoolState(false)
  const [editDeliveryAddressFormDialogIsOpen, openEditDeliveryAddressFormDialog, closeEditDeliveryAddressFormDialog] = useBoolState(false)

  const [lastClickedDeliveryMethodId, setLastClickedDeliveryMethodId] = React.useState(selectedDeliveryMethodId)

  const temporarySelectedDeliveryMethodId = createDeliveryAddressFormDialogIsOpen ? lastClickedDeliveryMethodId : null
  const preOrJustSelectedDeliveryMethodId = selectedDeliveryMethodId || temporarySelectedDeliveryMethodId

  const selectedDeliveryAddressId = order.customerIdForDelivery
  const [deliveryAddress, setDeliveryAddressData] = React.useState(null)

  const refreshDeliveryAddress = React.useMemo(() => () => {
    orderApi.getCustomer(selectedDeliveryAddressId)
      .then(({ item: deliveryAddressData }) => {
        setDeliveryAddressData(deliveryAddressData)
      })
  }, [selectedDeliveryAddressId])

  React.useEffect(() => {
    if (selectedDeliveryAddressId) {
      refreshDeliveryAddress()
    }
    else {
      setDeliveryAddressData(null)
    }
  }, [selectedDeliveryAddressId])

  const setDeliveryAndInvoiceAddress = (addressId) => {
    setDeliveryAddress(addressId)
    setInvoiceAddress(addressId)
  }

  const handleSelectDeliveryMethod = (dataItem) => {
    if (deliveryAddress) {
      setDeliveryMethod(dataItem.id)
      setDeliveryAndInvoiceAddress(deliveryAddress.id)
    }
    else {
      setLastClickedDeliveryMethodId(dataItem.id)
      openCreateDeliveryAddressFormDialog()
    }
  }

  const handleOnCreatedDeliveryAddress = (createdDeliveryAddressId) => {
    setDeliveryMethod(lastClickedDeliveryMethodId)
    setDeliveryAndInvoiceAddress(createdDeliveryAddressId)
    closeCreateDeliveryAddressFormDialog()
  }

  const handleOnEditedDeliveryAddress = () => {
    refreshDeliveryAddress()
    closeEditDeliveryAddressFormDialog()
  }

  const lastClickedDeliveryMethod = React.useMemo(() => (
    find(deliveryMethods, (deliveryMethod) => deliveryMethod.id === lastClickedDeliveryMethodId)
  ), [deliveryMethods, lastClickedDeliveryMethodId])

  const deliveryMethodColumns = React.useMemo(() => [
    {
      key: 'main',
      render(dataItem) {
        return (
          <DeliveryMethodMainColumn
            deliveryMethod={dataItem}
            isSelected={dataItem.isSelected}
            showDeliveryAddress
            deliveryAddress={deliveryAddress ? deliveryAddress : undefined}
            onChangeDeliveryAddress={openEditDeliveryAddressFormDialog}
          />
        )
      }
    },
    {
      key: 'price',
      className: "text-right",
      render(dataItem) {
        return (
          <DeliveryMethodPriceColumn
            deliveryMethod={dataItem}
            isSelected={dataItem.isSelected}
          />
        )
      }
    },
  ], [deliveryAddress])

  const deliveryMethodData = React.useMemo(() => deliveryMethods.map((deliveryMethod) => ({
    ...deliveryMethod,
    isSelected: deliveryMethod.id === preOrJustSelectedDeliveryMethodId,
    key: deliveryMethod.id,
  })))

  const handleReset = () => {
    setDeliveryMethod(null)
    setPaymentMethod(null)
    setDeliveryAndInvoiceAddress(null)
  }

  return (
    <React.Fragment>
      <Table
        columns={deliveryMethodColumns}
        data={deliveryMethodData}
        showSelectable
        selectedKey={preOrJustSelectedDeliveryMethodId}
        onSelect={handleSelectDeliveryMethod}
      />
      <ModalWithClose
        isOpen={createDeliveryAddressFormDialogIsOpen}
        onClose={closeCreateDeliveryAddressFormDialog}
      >
        <Translate ns="deliveryAddressForm">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader />
              {lastClickedDeliveryMethod && (
                <CreateCustomerFormWrapper
                  fields={['name', 'mobile', 'email', 'address']}
                  onSubmit={handleOnCreatedDeliveryAddress}
                  initialValues={{
                    infoType: 'address',
                    usage: 'delivery',
                    customerId: customer.id,
                  }}
                  t={t}
                />
              )}
            </React.Fragment>
          )}
        </Translate>
      </ModalWithClose>
      <ModalWithClose
        isOpen={editDeliveryAddressFormDialogIsOpen}
        onClose={closeEditDeliveryAddressFormDialog}
      >
        <Translate ns="deliveryAddressForm">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader />
              {lastClickedDeliveryMethod && (
                <EditCustomerFormWrapper
                  fields={['name', 'mobile', 'email', 'address']}
                  onSubmit={handleOnEditedDeliveryAddress}
                  customer={deliveryAddress}
                  t={t}
                />
              )}
            </React.Fragment>
          )}
        </Translate>
      </ModalWithClose>
      {config.DEBUG && <div className="mt-4">
        <DarkButton onClick={handleReset}>{"Reset"}</DarkButton>
      </div>}
    </React.Fragment>
  )
}

OrderDeliveryMethodSelectionForOtherThanIndividual.propTypes = {
  // Redux props
  deliveryMethods: PropTypes.array.isRequired,
  selectedDeliveryMethodId: PropTypes.number,
  customer: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  setDeliveryMethod: PropTypes.func.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  setDeliveryAddress: PropTypes.func.isRequired,
  setInvoiceAddress: PropTypes.func.isRequired,
}

export default connect(state => ({
  deliveryMethods: state.order.availableDeliveryMethods,
  selectedDeliveryMethodId: state.order.deliveryMethodId,
  customer: state.customer,
  order: state.order,
}), {
    setDeliveryMethod,
    setPaymentMethod,
    setDeliveryAddress,
    setInvoiceAddress,
  }
)(OrderDeliveryMethodSelectionForOtherThanIndividual)
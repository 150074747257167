import { takeLatest, put, call, fork, select, all, delay } from 'redux-saga/effects';

import { cleanArray } from '../utils/array';

import * as actions from '../actions';
import {
  orderApi,
  addNewsletterSubscriber
} from '../api';
import { setCustomer } from '../actions';

export function* fetchCustomer({ id }) {
  yield put(actions.requestCustomer());
  try {
    const { item } = yield call(orderApi.getCustomer, id);
    yield delay(200);
    yield put(actions.receiveCustomer(item));
  } catch (e) {
    yield put(actions.failedCustomer(e));
  }
}

function* createAndFetchCustomer({ data }) {
  const { createdIds: [customerId] } = yield call(orderApi.createCustomer, data);

  // if (data.subscribeToNewsletter) {
  //   const emails = cleanArray([
  //     data.email,
  //     ...(data.contacts || []).map(c => c.email),
  //     ...(data.addresses || []).map(a => a.email)
  //   ]);

  //   if (emails.length > 0) {
  //     try {
  //       const newsletterResult = yield call(addNewsletterSubscriber, emails[0]);
  //     }
  //     catch (e) {
  //       const newsletterResult = e.response.body;
  //       console.warn("No subscriber was added to newsletter", newsletterResult);
  //     }
  //   }
  //   else {
  //     console.warn("No subscriber was added to newsletter", { reason: "No email" });
  //   }
  // }

  yield fetchCustomer({ id: customerId })
  yield put(actions.setCustomer(customerId));
}

function* editAndFetchCustomer({ id, data }) {
  yield call(orderApi.editCustomer, id, data);
  yield fetchCustomer({ id })
}


// function* resetCustomerWhenSettingCustomerType() {
//   yield put(actions.resetCustomer());
// }

function* refreshCustomer() {
  const customerId = yield select((state) => state.customer.id);

  if (customerId) {
    yield fetchCustomer({ id: customerId })
  }
}

function* setAndFetchCustomer({ id }) {
  yield fetchCustomer({ id })
  yield put(actions.setCustomer(id));
}

export default function* customerSaga() {
  yield all([
    takeLatest(actions.CUSTOMER.CREATE_AND_FETCH, createAndFetchCustomer),
    takeLatest(actions.CUSTOMER.EDIT_AND_FETCH, editAndFetchCustomer),
    takeLatest(actions.CUSTOMER.SET_AND_FETCH, setAndFetchCustomer),
    // takeLatest(actions.CUSTOMER.SET_TYPE, resetCustomerWhenSettingCustomerType),
    takeLatest(actions.CUSTOMER.REFRESH, refreshCustomer),
  ]);
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Grid from './Grid'

import {
  LinkGridCell,
  ContentGridCell,
  ProductGridCell,
  ArticleGridCell,
  LINK, CONTENT, PRODUCT, ARTICLE
} from './GridCell'


const gridCellTypeToComponentMap = {
  [LINK]: LinkGridCell,
  [CONTENT]: ContentGridCell,
  [PRODUCT]: ProductGridCell,
  [ARTICLE]: ArticleGridCell
}

export default class GridFromData extends Component {
  static propTypes = {
    layout: PropTypes.string,
    cells: PropTypes.array,
  }

  render() {
    return (
      <Grid layout={this.props.layout}>
        {this.props.cells.map((cell) => {

          try {
            const GridCellComponent = gridCellTypeToComponentMap[cell.type]

            return (
              <GridCellComponent
                key={cell.id}
                {...cell}
              />
            )
          } catch (error) {
            console.warn("Grid cell", error)
            return null
          }
        }

        )}
      </Grid>
    )
  }
}
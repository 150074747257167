import React from 'react';
import { connect } from 'react-redux';

import {
  setCustomerType,
  setAndFetchCustomer,
  createAndFetchCustomer,
  setUseDate,
  setComment
} from 'actions';

import { caseSelector } from 'utils/helpers';
import { patterns } from 'utils/values';

import SelectCustomerType from 'components/forms/SelectCustomerType';

import { CUSTOMER_TYPES, SORTED_CUSTOMER_TYPES } from 'selectors/customer';
import stepStateComposer, { STATES } from 'containers/hoc/stepStateComposer';

import { FormGroup } from 'reactstrap';
import { DarkButton } from 'components/buttons';

import Form, { FormInput, LabeledFormInput } from 'components/forms/Form';
import Select from 'components/forms/Select';

import { CheckIcon } from 'components/Icon';

import SigninOrRegister from 'containers/steps/SigninOrRegister';
import UnionNodeSearchForm from 'components/forms/UnionNodeSearchForm';


const STEPS = {
  CUSTOMER: {
    TYPE: 'STEPS.CUSTOMER.TYPE',
    UNION: {
      SELECT: 'STEPS.CUSTOMER.UNION.SELECT',
      CREATE: 'STEPS.CUSTOMER.UNION.CREATE',
    }
  },
  EVENT: {
    NAME: 'STEPS.EVENT.NAME',
    DATE: 'STEPS.EVENT.DATE',
    DETAILS: 'STEPS.EVENT.DETAILS',
  },
  REGISTER_USER: 'STEPS.REGISTER_USER',
  DONE: 'STEPS.DONE',
}

const GetStarted = connect(({ activities }) => ({
  activities
}), {
    setAndFetchCustomer,
    createAndFetchCustomer,
    setCustomerType,
    setComment,
    setUseDate
  })(stepStateComposer(class _GetStarted extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        customerType: null,
        customer: null,
        eventName: null,
        eventDate: null
      }

      this.selectedCustomerType = this.selectedCustomerType.bind(this);
      this.selectedCustomer = this.selectedCustomer.bind(this);
      this.setEventDetails = this.setEventDetails.bind(this);
      this.setEventDate = this.setEventDate.bind(this);

      this.submitSelectedCustomer = this.submitSelectedCustomer.bind(this);

      this.submitNewCustomer = this.submitNewCustomer.bind(this);

      this.submit = this.submit.bind(this);
    }

    selectedCustomerType(customerType) {
      this.setState(
        () => ({ customerType }),
        () => this.props.setStep(
          caseSelector({
            [CUSTOMER_TYPES.UNION]: STEPS.CUSTOMER.UNION.SELECT
          }, STEPS.EVENT.DETAILS)(customerType)
        )
      )
    }

    selectedCustomer(customer) {
      this.setState(() => ({ customer }))
    }

    submitSelectedCustomer() {
      if (this.state.customer.id === 'new') {
        this.props.setStep(STEPS.CUSTOMER.UNION.CREATE);
      }
      else {
        this.props.setStep(STEPS.EVENT.DETAILS);
      }
    }

    submitNewCustomer({ name, activities = [] }) {
      this.setState(
        () => ({
          customer: {
            id: 'new',
            name,
            activityIds: activities.map(a => a.id)
          }
        }),
        () => this.props.setStep(STEPS.EVENT.DETAILS)
      )
    }

    setEventDetails(inputValues) {
      this.setState(
        () => ({
          ...inputValues
        }),
        () => {
          this.props.setStep(STEPS.REGISTER_USER);
        }
      )
    }

    setEventDate(eventDate) {
      this.setState(
        () => ({ eventDate }),
        () => this.props.setStep(STEPS.DONE)
      )
    }

    submit(data) {
      const { customerType, customer, eventDate, eventName } = this.state;

      this.props.setUseDate(eventDate);
      this.props.setComment(`Evenemang: ${eventName}`);

      if (customer) {
        if (customer.id === 'new') {
          this.props.createAndFetchCustomer({
            name: customer.name,
            type: customerType,
            activityIds: customer.activityIds
          })
        }
        else {
          this.props.setAndFetchCustomer(customer.id);
        }
      }
      else {
        this.props.setCustomerType(customerType);
      }

      this.props.onDone();
    }

    componentDidMount() {
      this.props.setStep(STEPS.CUSTOMER.TYPE, null);
    }

    render() {
      const { t, step, stepState } = this.props;
      const { customer } = this.state;

      return (
        <React.Fragment>
          {caseSelector({
            [STEPS.CUSTOMER.TYPE]: () => (
              <React.Fragment>
                <h3>Välj kundtyp</h3>
                <hr />
                <div className="text-center">
                  {[
                    CUSTOMER_TYPES.UNION,
                    CUSTOMER_TYPES.COMPANY,
                    CUSTOMER_TYPES.INDIVIDUAL
                  ].map(customerType => (
                    <DarkButton outline size="lg" className="mx-2" key={customerType} onClick={this.selectedCustomerType.bind(this, customerType)}>
                      {t(`customerTypes.${customerType}`)}
                    </DarkButton>
                  ))}
                </div>
                <hr />
              </React.Fragment>
            ),
            [STEPS.CUSTOMER.UNION.SELECT]: () => (
              <React.Fragment>
                <h3>Välj förening</h3>
                <UnionNodeSearchForm
                  onSelect={this.selectedCustomer}
                  t={t}
                />
                {
                  customer !== null && (
                    <React.Fragment>
                      <hr />
                      <div>
                        {
                          customer.id === 'new' ? (
                            <DarkButton disabled={customer === null} onClick={this.submitSelectedCustomer}>Create</DarkButton>
                          ) :
                            (
                              <DarkButton disabled={customer === null} onClick={this.submitSelectedCustomer}>Select</DarkButton>
                            )
                        }
                      </div>
                    </React.Fragment>
                  )
                }
              </React.Fragment>
            ),
            [STEPS.CUSTOMER.UNION.CREATE]: () => (
              <React.Fragment>
                <h3>Create Union</h3>
                <Form
                  t={t}
                  onSubmit={this.submitNewCustomer}
                  initialInputValues={{
                    name: this.state.customer.name
                  }}
                  valueRules={[
                    { key: 'name', required: true, ...patterns.name }
                  ]}
                >
                  {() => (
                    <React.Fragment>
                      <FormGroup>
                        <LabeledFormInput
                          inputName="name"
                          inputType="text"
                          t={t}
                        />
                      </FormGroup>
                      <FormGroup>
                        <LabeledFormInput
                          inputName="activities"
                          inputType="select"
                          options={this.props.activities.map(a => ({
                            id: a.id,
                            value: a.id,
                            label: a.name,
                          }))}
                          multi
                          t={t}
                        />
                      </FormGroup>
                      <hr />
                      <FormGroup>
                        <DarkButton>
                          Proceed
                      </DarkButton>
                      </FormGroup>
                    </React.Fragment>
                  )}
                </Form>
              </React.Fragment>
            ),
            [STEPS.EVENT.DETAILS]: () => (
              <React.Fragment>
                <h3>{t('event.details', { context: 'header' })}</h3>
                <Form t={t} onSubmit={this.setEventDetails}>
                  {() => (
                    <React.Fragment>
                      <FormGroup>
                        <LabeledFormInput
                          inputName="eventName"
                          inputType="text"
                          t={t}
                        />
                      </FormGroup>
                      <FormGroup>
                        <LabeledFormInput
                          inputName="eventDate"
                          inputType="datepicker"
                          t={t}
                        />
                      </FormGroup>
                      <hr />
                      <FormGroup>
                        <DarkButton>
                          Proceed
                      </DarkButton>
                      </FormGroup>
                    </React.Fragment>
                  )}
                </Form>
              </React.Fragment>
            ),
            [STEPS.REGISTER_USER]: () => (
              <React.Fragment>
                <h3 className="text-primary">Bli medlem</h3>
                <hr />
                <div className="py-4">
                  <SigninOrRegister
                    customerIds={customer !== null ? [customer.id] : []}
                    onDone={this.submit}
                    t={t}
                  />
                </div>
                <hr />
                <div>
                  <DarkButton outline size="sm" onClick={this.submit}>
                    Fortsätt utan medlemskap
                </DarkButton>
                </div>
              </React.Fragment>
            )
          }, () => (
            <p>Not implemented: {step}(.{stepState})</p>
          ))(step)()}
        </React.Fragment>
      )
    }
  }));

export default GetStarted;
export const MODALS = {
  ADD: 'MODALS.ADD',
  REMOVE: 'MODALS.REMOVE'
}

export const addModal = (modalComponentId, modalComponentProps) => ({
  type: MODALS.ADD,
  modalComponentId, modalComponentProps
});

export const removeModal = (modalComponentId) => ({
  type: MODALS.REMOVE,
  modalComponentId
});
import React from 'react'
import PropTypes from 'prop-types'

import TemplateVariableWidget, { variablePropTypes } from './TemplateVariableWidget'

export default class TemplateVariablesWidget extends React.Component {

  static propTypes = {
    variables: PropTypes.arrayOf(
      PropTypes.shape(variablePropTypes)
    ),
    variableInputs: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  }

  handleChange = (variableName, variantValues) => {
    this.props.onChange({
      [variableName]: variantValues
    })
  }

  render() {
    const { variables, variableInputs } = this.props

    return (
      <React.Fragment>
        {variables.map((variable, variableIndex) => (
          <TemplateVariableWidget
            key={variableIndex}
            {...variable}
            variantValues={variableInputs[variable.name]}
            numOfVariants={variableInputs[variable.name].length}
            onChange={(variantValues) => this.handleChange(variable.name, variantValues)}
          />
        ))}
      </React.Fragment>
    )
  }
}
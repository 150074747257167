import React from 'react'
import PropTypes from 'prop-types'

import useEventState from './useEventState'

const EventStateContext = React.createContext({
  event: {},
  set: (eventPayload) => null,
  sync: (customerId) => Promise.resolve(null),
  isSyncing: false
})

export default EventStateContext


export function GlobalEventStateProvider({ children, initialEvent }) {
  const eventState = useEventState(initialEvent)
  return (
    <EventStateContext.Provider value={eventState}>
      {children}
    </EventStateContext.Provider>
  )
}

GlobalEventStateProvider.propTypes = {
  initialEvent: PropTypes.object,
  children: PropTypes.node
}
import React from 'react';
import { connect } from 'react-redux';
import {getQueryParamsFromLocation} from '../../utils/url';


import routes from '../../routes';

import { cleanObject } from '../../utils/object';

class BaseArchivePage extends React.Component {

  constructor(props) {
    super(props);

    this.composeSetFilter = this.composeSetFilter.bind(this);
    this.setOrdering = this.setOrdering.bind(this);
  }

  getQueryParams(props) {
    const {location} = (props || this.props);
    // return qs.parse(location.search.substr(1));
    return getQueryParamsFromLocation(location);
  }
  
  getQueryParam(props, key) {
    return this.getQueryParams(props).key || undefined;
  }

  composeProductFiltersFromParams() {
    const {priceRange} = this.getQueryParams(this.props);

    return {
      priceRange: (priceRange ? priceRange.split(",") : null),
      excludeItemsWithoutCategory: (this.props.isSignedInAndStaff ? false : true)
    };
  }

  composeSetFilter(filterKey) {
    return ((filterValue) => {
      this.changeUrlQuery({[filterKey]: filterValue});
    }).bind(this)
  }

  getUrlWithQuery(newQuery) {
    // Abstract
  }

  changeUrlQuery(queryChanges, method = "push") {
    this.props.history[method](
      this.getUrlWithQuery(
        cleanObject({
          ...this.getQueryParams(),
          ...queryChanges,
        })
      )
    );
  }

  setOrdering(ordering) {
    this.changeUrlQuery({ordering});
  }
};

export default BaseArchivePage;
import React from 'react';
import { connect } from 'react-redux';

import { resetCustomer } from 'actions';
import {
  customerExist as customerExistSelector,
  customerType as customerTypeSelector,
  customerName as customerNameSelector,
  customerId as customerIdSelector
} from 'selectors/customer';

import DocumentMeta from 'components/documentMeta';
import { OneColumnLayout } from 'components/layouts';
import { Sheet } from 'components/layouts';

import { CustomerTranslate } from 'containers/translates';
import BlockArea from 'containers/BlockArea';
import { DarkButton } from '../../components/buttons';
import { PageHeading } from 'components/content';

const CustomerPage = connect((state) => ({
  customerExist: customerExistSelector(state),
  customerType: customerTypeSelector(state),
  customerName: customerNameSelector(state),
  customerId: customerIdSelector(state),
}), {
    resetCustomer
  })(function CustomerPage({ customerExist, customerType, customerId, customerName, resetCustomer }) {

    const pageName = 'notFound';
    const blockConditions = [`page:${pageName}`];

    return (
      <CustomerTranslate>
        {({ t }) => (
          <React.Fragment>
            <DocumentMeta
              title={t('title', { context: pageName })}
            />
            <OneColumnLayout>
              <Sheet className="p-4">
                {/* {
                  customerExist && (
                    <React.Fragment>
                      <PageHeading>{customerName}</PageHeading>
                      <div>
                        <DarkButton onClick={resetCustomer}>
                          Reset Customer
                        </DarkButton>
                      </div>
                    </React.Fragment>
                  )
                } */}
                {/* <BlockArea
                  areaSlug="main"
                  conditions={blockConditions}
                  defaultContent={(
                    <div>
                      <h2>{t('customer.title')}</h2>
                      <p>{t('customer.description')}</p>
                    </div>
                  )}
                /> */}
              </Sheet>
            </OneColumnLayout>
          </React.Fragment>
        )}
      </CustomerTranslate>
    );
  })

export default CustomerPage;
import { PRODUCTS } from '../../actions';

const initState = 0;

export default function(state = initState, action) {
  
  switch(action.type) {
    case PRODUCTS.FETCH.RECEIVE:
      return action.totalNumOfItems;
    case PRODUCTS.INVALIDATE:
      return initState;
    default: 
      return state;
  }
};
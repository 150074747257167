import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
} from 'actions'

import { OneColumnLayout } from 'components/layouts';
import { Translate } from 'containers/translates';
import DeliverySection from 'components/Order/DeliverySection';

function TestPage2() {
  return (
    <OneColumnLayout>
      <h1>{"Test page 2"}</h1>
      <div>
        <Translate ns="order.sections">
          {() => (
            <DeliverySection
              sectionState="active"
            />
          )}
        </Translate>
      </div>
    </OneColumnLayout>
  )
}

TestPage2.propTypes = {
  customer: PropTypes.object.isRequired,
}

export default connect(({ customer }) => ({ customer }), {
})(TestPage2)
export const commonInputs = {
  email: "E-post",
  password: "Lösenord",
  retypePassword: "Repetera lösenord",
  mobile: "Mobil",
  street: "Adress",
  zip: "Postnummer",
  city: "Ort",
}

export const continueButton = {
  label: "Gå vidare"
}

export const backButton = {
  label: "Tillbaka"
}

export const saveButton = {
  label: "Spara"
}

export const addButton = {
  label: "Lägg till"
}

export const cancelButton = {
  label: "Hoppa över"
}

export default {
  select: {
    noResultsText: "Inga resultat",
  },

  validationErrors: {
    required: "Obligatoriskt",
    patternMismatch: "Felaktig inmatning",
    tooShort: "För få tecken (minsta antal {{ minLength }})",
    tooLong: "För många tecken (maximalt antal {{ maxLength }})",
    noMatch: "Värdet matchar inte"
  },
}
import React from 'react'
import PropTypes from 'prop-types'

import { DarkButton } from 'components/buttons'
import { Translate } from 'containers/translates'
import Address from 'components/Address'
import PaymentMethodNameAndDescription from './PaymentMethodNameAndDescription'
import { callbackWithoutPropagationComposer } from 'utils/event'


function PaymentMethodMainColumn({ paymentMethod, isSelected, showInvoiceAddress, showInvoiceAddressIsOtherThanDeliveryAddress, invoiceAddressIsOtherThanDeliveryAddress, onChangeInvoiceAddressIsOtherThanDeliveryAddress, invoiceAddress, onChangeInvoiceAddress }) {

  const renderedNameAndDescription = React.useMemo(() => (
    <PaymentMethodNameAndDescription
      {...{
        paymentMethod: paymentMethod,
        isSelected: isSelected
      }}
    />
  ), [paymentMethod, isSelected])


  if (showInvoiceAddress) {
    return (
      <Translate>
        {({ t }) => (
          <React.Fragment>
            {renderedNameAndDescription}
            {isSelected && (

              <React.Fragment>
                {/* {showInvoiceAddressIsOtherThanDeliveryAddress && (
                  <div className="mt-2">
                    <label>
                      <input
                        type="checkbox"
                        onChange={onChangeInvoiceAddressIsOtherThanDeliveryAddress}
                        checked={invoiceAddressIsOtherThanDeliveryAddress}
                        {...{}}
                      /> {t('invoiceAddressIsOtherThanDeliveryAddressLabel')}
                    </label>
                  </div>
                )} */}
                {
                  invoiceAddress !== undefined && (
                    <div className="mt-2">
                      <h4 className={showInvoiceAddressIsOtherThanDeliveryAddress && !invoiceAddressIsOtherThanDeliveryAddress ? "font-italic text-muted" : ""}>{invoiceAddress.name}</h4>
                      <Address
                        ghost={showInvoiceAddressIsOtherThanDeliveryAddress && !invoiceAddressIsOtherThanDeliveryAddress}
                        showName={false}
                        showContactInformation
                        {...invoiceAddress}
                      />
                      {invoiceAddressIsOtherThanDeliveryAddress && (
                        <DarkButton
                          outline
                          size="sm"
                          onClick={callbackWithoutPropagationComposer(onChangeInvoiceAddress)}
                        >
                          {t('changeButtonLabel')}
                        </DarkButton>
                      )}
                    </div>
                  )
                }
                {showInvoiceAddressIsOtherThanDeliveryAddress && !invoiceAddressIsOtherThanDeliveryAddress && (
                  <div className="mt-2">
                    <DarkButton
                      size="sm"
                      outline
                      onClick={onChangeInvoiceAddressIsOtherThanDeliveryAddress}
                    >
                      {t('changeToInvoiceIsOtherThanDeliveryAddressButtonLabel')}
                    </DarkButton>
                  </div>
                )}
              </React.Fragment>


            )}
          </React.Fragment>
        )}
      </Translate>
    )
  }
  else {
    return renderedNameAndDescription
  }
}

PaymentMethodMainColumn.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showInvoiceAddress: PropTypes.bool.isRequired,
  showInvoiceAddressIsOtherThanDeliveryAddress: PropTypes.bool,
  invoiceAddressIsOtherThanDeliveryAddress: PropTypes.bool,
  onChangeInvoiceAddressIsOtherThanDeliveryAddress: PropTypes.func,
  onChangeInvoiceAddress: PropTypes.func,
  invoiceAddress: PropTypes.object,
}

export default PaymentMethodMainColumn
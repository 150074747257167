import { fieldInput } from "./index";

export const PRODUCT_FIELDS = {
  FETCH: {
    REQUEST: 'PRODUCT_FIELDS.FETCH.REQUEST',
    RECEIVE: 'PRODUCT_FIELDS.FETCH.RECEIVE',
    FAILURE: 'PRODUCT_FIELDS.FETCH.FAILURE'
  },
  GET: 'PRODUCT_FIELDS.GET'
};

export const requestProductFields = () => {
  return {
    type: PRODUCT_FIELDS.FETCH.REQUEST,
  }
}

export const receiveProductFields = (items) => {
  return {
    type: PRODUCT_FIELDS.FETCH.RECEIVE,
    items
  }
}

export const getProductFields = () => {
  return {
    type: PRODUCT_FIELDS.GET
  }
}

export const failedProductFields = (error) => {
  return {
    type: PRODUCT_FIELDS.FETCH.FAILURE,
    error
  }
}

export const FIELD_SELECTIONS = {
  FETCH: {
    REQUEST: 'FIELD_SELECTIONS.FETCH.REQUEST',
    RECEIVE: 'FIELD_SELECTIONS.FETCH.RECEIVE',
    FAILURE: 'FIELD_SELECTIONS.FETCH.FAILURE'
  },
  GET: 'FIELD_SELECTIONS.GET',
  REFRESH: 'FIELD_SELECTIONS.REFRESH',
  DELETE: 'FIELD_SELECTIONS.DELETE',
};

export const requestFieldSelections = () => {
  return {
    type: FIELD_SELECTIONS.FETCH.REQUEST,
  }
}

export const receiveFieldSelections = (items) => {
  return {
    type: FIELD_SELECTIONS.FETCH.RECEIVE,
    items
  }
}

export const getFieldSelections = () => {
  return {
    type: FIELD_SELECTIONS.GET
  }
}

export const failedFieldSelections = (error) => {
  return {
    type: FIELD_SELECTIONS.FETCH.FAILURE,
    error
  }
}

export const refreshFieldSelections = () => ({
  type: FIELD_SELECTIONS.REFRESH
})

export const deleteFieldSelections = (id) => ({
  type: FIELD_SELECTIONS.DELETE,
  id
})
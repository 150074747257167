import React from 'react';
import {connect} from 'react-redux';

import { FormGroup } from 'reactstrap';

import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import { formatPrice } from '../../utils/content';

import Select from '../forms/Select';


function getAttributeValueArticleValue(articles, mapper = a => a) {
  return (attributeId, attributeValueId) => {
    return mapper(find(articles, a => (
      a.attributeValueIds[attributeId] &&
      a.attributeValueIds[attributeId] === attributeValueId
    )));
  }
}

const ProductDetailSingleAttribute = connect(state => {
  return {
    // showArticleNumber: state.auth.isSignedIn
    showArticleNumber: true
  }
})(({id: attributeId, name, values, selectedValueId, getAttributeValuePrice, getAttributeValueArticleNumber, showArticleNumber = false, onChange}) => {

  return (
    <FormGroup key={`product-attrbute-${attributeId}`}>
      <Select
        options={values.map(({id: attributeValueId, name}) => ({
          label: `${name}, ${formatPrice(getAttributeValuePrice(attributeId, attributeValueId), 'kr')} ${showArticleNumber ? `(${getAttributeValueArticleNumber(attributeId, attributeValueId)})` : ''}`,
          value: attributeValueId
        }))}
        value={selectedValueId}
        placeholder={name}
        onChange={(option) => onChange(attributeId, option.value)}
        searchable={false}
        clearable={false}
        selectedValueLabel={name}
      />
    </FormGroup>
  )
})


const ProductDetailAttribute = ({id, name, values, selectedValueId, articles, onChange}) => {

  return (
    <FormGroup key={`product-attrbute-${id}`}>
      <Select
        options={values.map(({id, name}) => ({
          label: name,
          value: id
        }))}
        value={selectedValueId}
        placeholder={name}
        onChange={(option) => onChange(id, option.value)}
        searchable={false}
        clearable={false}
        selectedValueLabel={name}
      />
    </FormGroup>
  )
}

const ProductDetailAttributeColor = ({id: attributeId, name, values, selectedValueId, onChange}) => {

  const valueIdIsSelected = (valueId) => valueId === selectedValueId;

  const colorStyle = ({color}, isSelected) => ({
    display: "inline-block",
    verticalAlign: "middle",
    width: "30px",
    height: "30px",
    lineHeight: "27px",
    backgroundColor: color,
    color: color,
    borderRadius: "50%",
    marginRight: ".25em",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: (isSelected ? "rgba(0, 0, 0, .8)" : "transparent"),
    textAlign: "center",
  });

  const hiddenInputStyle = {
    visibility: "hidden",
    position: "absolute",
  }

  return (
    <FormGroup key={`product-attrbute-${attributeId}`}>
      <div>
        {values.map(({id: valueId, name, data}) => (
          <label key={valueId} style={{marginRight: "1em"}}>
            <input type="radio" checked={valueIdIsSelected(valueId)} onChange={onChange.bind(null, attributeId, valueId)} style={hiddenInputStyle}/>
            <span style={colorStyle(data, valueIdIsSelected(valueId))}>
              {
                valueIdIsSelected(valueId) &&
                <i className="fa fa-check" style={{color: "rgba(0, 0, 0, .8)"}}/>
              }
            </span>
            <span>{name}</span>
          </label>
        ))}
      </div>
    </FormGroup>
  ) 

}


const ProductDetailAttributeFactory = (props) => {

  const {valueType, numOfAttributes} = props;

  switch(valueType) {
    // case 'color':
    //   return <ProductDetailAttributeColor {...props}/>;
    default:
      return (
        numOfAttributes === 1 ? 
          <ProductDetailSingleAttribute {...props}/> : 
          <ProductDetailAttribute {...props}/>
      )
  }
}

class ProductDetailAttributes extends React.PureComponent {

  render() {
    const {attributes, article, articles, onChange} = this.props;

    return (
      <div>
        {attributes.filter(a => a.values.length > 1).map((attribute) => (
          <ProductDetailAttributeFactory 
            key={attribute.id}
            {...attribute}
            getAttributeValuePrice={getAttributeValueArticleValue(articles, article => article.price.default)}
            getAttributeValueArticleNumber={getAttributeValueArticleValue(articles, article => article.articleNumber)}
            numOfAttributes={attributes.filter(a => a.values.length > 1).length}
            selectedValueId={article.attributeValueIds[attribute.id]}
            onChange={onChange}
          />
        ))}
      </div>
    )
  }
}


export default ProductDetailAttributes;
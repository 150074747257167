import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import find from 'lodash/find'
import isObject from 'lodash/isObject'

import memoize from 'lodash/memoize'

import { FormGroup } from "reactstrap"
import Form, { LabeledFormInput, FormRow } from 'components/forms/Form'

import { Event } from './props'

const activityToOption = (activity) => ({
  id: activity.id,
  value: activity.id,
  label: activity.name,
})

const activitiesToOptions = memoize((activities) => activities.map(activityToOption))

export const EventDetailsFormContent = connect((state) => ({
  activities: state.activities,
}))(function EventDetailsFormContent({ t, activities }) {
  return (
    <React.Fragment>
      <FormRow>
        <FormGroup className="col-12">
          <LabeledFormInput
            t={t}
            inputName="name"
            inputType="text"
            focusOnMount
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup className="col-12">
          <LabeledFormInput
            inputName="activity"
            inputType="select"
            options={activitiesToOptions(activities)}
            t={t}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup className="col-12">
          <LabeledFormInput
            t={t}
            inputName="date"
            inputType="datepicker"
          />
        </FormGroup>
      </FormRow>
    </React.Fragment>
  )
})

EventDetailsFormContent.propTypes = {
  t: PropTypes.func
}


class EventDetailsForm extends React.Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    event: PropTypes.shape(Event.propTypes).isRequired,
    children: PropTypes.func.isRequired,
    activities: PropTypes.array.isRequired,
    activitySlugInUserSettings: PropTypes.string,
  }

  handleSubmit = ({
    name,
    date,
    activity
  }) => {
    this.props.onSubmit({
      name, date,
      activityId: isObject(activity) ? activity.id : activity // Not so very nice ..
    })
  }

  getInitialFormValues = memoize((activitySlugInUserSettings) => {
    return {
      activity: (
        activitySlugInUserSettings ?
          activityToOption(find(this.props.activities, (a) => a.slug === activitySlugInUserSettings)).id :
          undefined
      )
    }
  })

  getPrefilledInputValues = memoize((event) => {
    const initialInputValues = this.getInitialFormValues(this.props.activitySlugInUserSettings)
    return {
      ...event,
      activity: event.activityId !== null ? event.activityId : initialInputValues.activity
    }
  })

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        valueRules={Event.valueRules}
        initialInputValues={this.getInitialFormValues(this.props.activitySlugInUserSettings)}
        prefilledInputValues={this.getPrefilledInputValues(this.props.event)}
      >{this.props.children}</Form>
    )
  }
}

export default connect((state) => ({
  activities: state.activities,
  activitySlugInUserSettings: state.userSettings.activitySlug
}))(EventDetailsForm)

import React from 'react';
import { withTranslation as translate } from 'react-i18next';

import Select from './forms/Select';

const _BaseFilterAuto = ({options, selectedValue = "", placeholder, selectedValueLabel = null, onChange}) => (
  <div>
    <Select
      options={options}
      placeholder={placeholder}
      value={selectedValue}
      selectedValueLabel={selectedValueLabel}
      onChange={((option) => onChange(option ? option.value : ""))}
    />
  </div>
)


const _BaseFilterBasic = ({options, selectedValue = "", noSelectedName, onChange}) => (
  <select className="form-control" onChange={(({target: {value}}) => onChange(value))} value={selectedValue}>
    {noSelectedName && <option value={""}>{noSelectedName}</option>}
    {options.map(({id, value, label}) => (
      <option key={id} value={value}>{label}</option>
    ))}
  </select>
)

export const CategoryFilter = translate("productArchive")(({productCategories, selectedCategorySlug = "", onChange, t}) => {
  const _recOptions = (accum, {id, slug, name, children = [], parents = []}) => {

    const childrenCopy = children.map(childCategory => ({
        id: childCategory.id, 
        slug: childCategory.slug,
        name: childCategory.name,
        children: childCategory.children,
        parents: [...parents, {name}]
      })
    );

    return [
      ...accum, 
      {
        id,
        label: [...parents.map(({name}) => name), name].join(" - "),
        value: slug
      },
      ...childrenCopy.reduce(_recOptions, [])
    ]
  }

  const options = productCategories.reduce(_recOptions, []);

  return (
    <_BaseFilterAuto 
      options={options}
      selectedValue={selectedCategorySlug}
      onChange={onChange}
      placeholder={t('filters.productCategory.placeholder')}
    />
  );
});


export const ActivityFilter = translate("productArchive")(({activities, selectedActivitySlug = "", onChange, t}) => (
  <_BaseFilterAuto 
    options={activities.map(({id, slug, name}) => ({id, label: name, value: slug}))}
    selectedValue={selectedActivitySlug}
    onChange={onChange}
    placeholder={t('filters.activity.placeholder')}
    selectedValueLabel={t('filters.activity.label')}
  />
));

const _noPriceRange = "";
const defaultPriceRanges = [
  {name: "up to 49kr", value: "0,49"},
  {name: "50kr - 99kr", value: "50,99"},
  {name: "100kr - 199kr", value: "100,199"},
  {name: "from 200kr", value: "200,99999999"},
];

export const PriceRangeFilter = ({priceRanges = defaultPriceRanges, selectedPriceRange = _noPriceRange, onChange}) => (
  <_BaseFilterBasic 
    options={priceRanges.map(({name, value}) => ({id: value, label: name, value}))}
    selectedValue={selectedPriceRange}
    onChange={onChange}
    noSelectedName="All prices"
  />
)
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  setPaymentMethod,
  setInvoiceAddress,
} from 'actions'

import {
  orderApi
} from 'api'

import config from '../../config'


import { DarkButton } from 'components/buttons'
import useBoolState from 'hooks/useBoolState'
import ModalWithClose from 'components/modals/Modal'
import ContentHeader from 'components/ContentHeader'
import Table from 'components/Table'
import CreateCustomerFormWrapper from 'components/forms/CreateCustomerFormWrapper'
import { Translate } from 'containers/translates'
import PaymentMethodPriceColumn from './PaymentMethodPriceColumn'
import PaymentMethodMainColumn from './PaymentMethodMainColumn'
import EditCustomerFormWrapper from 'components/forms/EditCustomerFormWrapper';

function OrderPaymentMethodSelectionForOtherThanIndividual({
  paymentMethods,
  selectedPaymentMethodId,
  customer,
  order,
  setPaymentMethod,
  setInvoiceAddress,
}) {

  const [createInvoiceAddressFormDialogIsOpen, openCreateInvoiceAddressFormDialog, closeCreateInvoiceAddressFormDialog] = useBoolState(false)
  const [editInvoiceAddressFormDialogIsOpen, openEditInvoiceAddressFormDialog, closeEditInvoiceAddressFormDialog] = useBoolState(false)

  const [lastClickedPaymentMethodId, setLastClickedPaymentMethodId] = React.useState(selectedPaymentMethodId)

  const temporarySelectedPaymentMethodId = createInvoiceAddressFormDialogIsOpen ? lastClickedPaymentMethodId : null
  const preOrJustSelectedPaymentMethodId = selectedPaymentMethodId || temporarySelectedPaymentMethodId



  const selectedDeliveryAddressId = order.customerIdForDelivery
  const selectedInvoiceAddressId = order.customerIdForInvoice

  const invoiceAddressIsOtherThanDeliveryAddress = selectedInvoiceAddressId !== selectedDeliveryAddressId

  const [otherInvoiceAddressId, setOtherInvoiceAddressId] = React.useState(null)

  React.useEffect(() => {
    if (invoiceAddressIsOtherThanDeliveryAddress) {
      setOtherInvoiceAddressId(selectedInvoiceAddressId)
    }
  }, [selectedInvoiceAddressId])

  const handleChangeInvoiceAddressIsOtherThanDeliveryAddress = React.useCallback(() => {
    if (invoiceAddressIsOtherThanDeliveryAddress) {
      setInvoiceAddress(selectedDeliveryAddressId)
    }
    else {
      if (otherInvoiceAddressId) {
        setInvoiceAddress(otherInvoiceAddressId)
      }
      else {
        setInvoiceAddress(null)
        setLastClickedPaymentMethodId(preOrJustSelectedPaymentMethodId)
        openCreateInvoiceAddressFormDialog()
      }

    }
  })


  const [invoiceAddress, setInvoiceAddressData] = React.useState(null)


  const refreshInvoiceAddress = React.useMemo(() => () => {
    orderApi.getCustomer(selectedInvoiceAddressId)
      .then(({ item: invoiceAddressData }) => {
        setInvoiceAddressData(invoiceAddressData)
      })
  }, [selectedInvoiceAddressId])

  React.useEffect(() => {
    if (selectedInvoiceAddressId) {
      refreshInvoiceAddress()
    }
    else {
      setInvoiceAddressData(null)
    }
  }, [selectedInvoiceAddressId])

  const handleSelectPaymentMethod = (dataItem) => {
    if (invoiceAddress) {
      setPaymentMethod(dataItem.id)
      setInvoiceAddress(invoiceAddress.id)
    }
    else {
      setLastClickedPaymentMethodId(dataItem.id)
      openCreateInvoiceAddressFormDialog()
    }
  }

  const handleOnCreatedInvoiceAddress = (createdInvoiceAddressId) => {
    setPaymentMethod(lastClickedPaymentMethodId)
    setInvoiceAddress(createdInvoiceAddressId)
    closeCreateInvoiceAddressFormDialog()
  }

  const handleOnEditedInvoiceAddress = () => {
    refreshInvoiceAddress()
    closeEditInvoiceAddressFormDialog()
  }

  const paymentMethodColumns = React.useMemo(() => [
    {
      key: 'main',
      render(dataItem) {
        return (
          <PaymentMethodMainColumn
            paymentMethod={dataItem}
            isSelected={dataItem.isSelected}
            showInvoiceAddress
            showInvoiceAddressIsOtherThanDeliveryAddress
            invoiceAddressIsOtherThanDeliveryAddress={invoiceAddressIsOtherThanDeliveryAddress}
            onChangeInvoiceAddressIsOtherThanDeliveryAddress={handleChangeInvoiceAddressIsOtherThanDeliveryAddress}
            invoiceAddress={invoiceAddress ? invoiceAddress : undefined}
            onChangeInvoiceAddress={openEditInvoiceAddressFormDialog}
          />
        )
      }
    },
    {
      key: 'price',
      className: "text-right",
      render(dataItem) {
        return (
          <PaymentMethodPriceColumn
            paymentMethod={dataItem}
            isSelected={dataItem.isSelected}
          />
        )
      }
    },
  ], [invoiceAddress, invoiceAddressIsOtherThanDeliveryAddress, handleChangeInvoiceAddressIsOtherThanDeliveryAddress, openEditInvoiceAddressFormDialog])

  const paymentMethodData = React.useMemo(() => paymentMethods.map((paymentMethod) => ({
    ...paymentMethod,
    isSelected: paymentMethod.id === preOrJustSelectedPaymentMethodId,
    key: paymentMethod.id,
  })))

  const handleReset = () => {
    setPaymentMethod(null)
    setInvoiceAddress(selectedDeliveryAddressId)
    setOtherInvoiceAddressId(null)
  }

  const handleCloseCreateInvoiceAddressformDialog = () => {
    closeCreateInvoiceAddressFormDialog()
    setInvoiceAddress(selectedDeliveryAddressId)
  }

  return (
    <React.Fragment>
      <Table
        columns={paymentMethodColumns}
        data={paymentMethodData}
        showSelectable
        selectedKey={preOrJustSelectedPaymentMethodId}
        onSelect={handleSelectPaymentMethod}
      />
      <ModalWithClose
        isOpen={createInvoiceAddressFormDialogIsOpen}
        onClose={handleCloseCreateInvoiceAddressformDialog}
      >
        <Translate ns="invoiceAddressForm">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader />
              {lastClickedPaymentMethodId && (
                <CreateCustomerFormWrapper
                  fields={['name', 'mobile', 'email', 'address']}
                  onSubmit={handleOnCreatedInvoiceAddress}
                  initialValues={{
                    infoType: 'address',
                    usage: 'invoice',
                    customerId: customer.id,
                  }}
                  t={t}
                />
              )}
            </React.Fragment>
          )}
        </Translate>
      </ModalWithClose>
      <ModalWithClose
        isOpen={editInvoiceAddressFormDialogIsOpen}
        onClose={closeEditInvoiceAddressFormDialog}
      >
        <Translate ns="invoiceAddressForm">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader />
              {invoiceAddress && (
                <EditCustomerFormWrapper
                  fields={['name', 'mobile', 'email', 'address']}
                  onSubmit={handleOnEditedInvoiceAddress}
                  customer={invoiceAddress}
                  t={t}
                />
              )}
            </React.Fragment>
          )}
        </Translate>
      </ModalWithClose>
      {config.DEBUG && <div className="mt-4">
        <DarkButton onClick={handleReset}>{"Reset"}</DarkButton>
      </div>}
    </React.Fragment>
  )
}

OrderPaymentMethodSelectionForOtherThanIndividual.propTypes = {
  // Redux props
  paymentMethods: PropTypes.array.isRequired,
  selectedPaymentMethodId: PropTypes.string,
  customer: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  setInvoiceAddress: PropTypes.func.isRequired,
}

export default connect(state => ({
  paymentMethods: state.order.availablePaymentMethods,
  selectedPaymentMethodId: state.order.paymentMethodId,
  customer: state.customer,
  order: state.order,
}), {
    setPaymentMethod,
    setInvoiceAddress,
  }
)(OrderPaymentMethodSelectionForOtherThanIndividual)
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import find from 'lodash/find';
import routes from '../routes';

import { Link } from 'react-router-dom';
import './Breadcrumb.css';

export const Breadcrumb = function({children}) {
  return (
    <div className="Breadcrumb text-center text-md-left">{children}</div>
  );
};

export const BreadcrumbItem = ({to, children}) => (
  <Link to={to} className="Breadcrumb_Item">{children}</Link>
);

export const ActivityBreadcrumbs = connect(({activities}, {activityId}) => ({
  ...find(activities, a => a.id === activityId)
}))(translate()(function _ActivityBreadcrumbs({id, slug, name, t}) {
  return (
    <Breadcrumb>
      <BreadcrumbItem to={routes.HOME.get()}>{t('home:name')}</BreadcrumbItem>
      <BreadcrumbItem to={routes.ACTIVITY_ARCHIVE.get()}>{t('activities:activities')}</BreadcrumbItem>
      <BreadcrumbItem to={routes.ACTIVITY.get(slug)}>{name}</BreadcrumbItem>
    </Breadcrumb>
  )
}
));
import React from 'react';
import BlockArea from '../containers/BlockArea';

import './SideColumn.scss';

const SideColumn = ({blockConditions}) => (
  <div>
    <BlockArea areaSlug="sidecolumn" conditions={blockConditions}/>
  </div>
);

export default SideColumn;
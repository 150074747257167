import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import times from 'lodash/times'
import get from 'lodash/get'
import castArray from 'lodash/castArray'
import entries from 'lodash/entries'
import isEqual from 'lodash/isEqual'
import templateCompiler from 'lodash/template'

import { Translate } from 'containers/translates'

import TemplateVariablesWidget from './TemplateVariablesWidget'

const TemplateTextWidget = connect(
  (state) => ({
    storedVariableInputs: state.userSettings.fieldVariableInputs
  })
)(class _TemplateTextWidget extends React.Component {
  state = {
    variableInputs: null
  }

  handleVariableChange = (data) => {
    this.setState({
      variableInputs: {
        ...this.state.variableInputs,
        ...data
      }
    }, this.handleOuterChange)
  }

  getVariableInputsForIndex = (variantIndex) => {
    return entries(this.state.variableInputs).reduce((variableInputs, [variableName, variantValues]) => ({
      ...variableInputs,
      [variableName]: (variantValues[variantIndex] || variantValues[0])
    }), {})
  }

  renderTemplate = (variantIndex) => {
    return templateCompiler(this.props.template)(
      this.getVariableInputsForIndex(variantIndex)
    )
  }

  handleOuterChange = () => {
    this.props.onChange({
      value: this.renderTemplate(0),
      meta: {
        variableInputs: this.state.variableInputs,
        templateId: this.props.id,
      },
      variants: times(this.props.numOfVariants, (variantIndex) => ({
        value: this.renderTemplate(variantIndex),
        meta: {
          variableInputs: this.getVariableInputsForIndex(variantIndex),
          templateId: this.props.id,
        }
      })),
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const {
      variables,
      bulkableVariables,
      variableInputs: previousVariableInputs,
      storedVariableInputs,
      numOfVariants
    } = nextProps

    if (prevState.variableInputs === null) {
      return {
        variableInputs: {
          ...variables.reduce((variableInputs, variable) => ({
            ...variableInputs,
            [variable.name]: (() => {

              const previousValue = get(previousVariableInputs, variable.name)
              const havePreviousValues = !!previousValue

              const storedValues = get(storedVariableInputs, variable.name)
              const haveStoredValues = !!storedValues

              const bulkOptions = get(bulkableVariables, variable.name)
              const inBulkMode = bulkOptions !== undefined

              const variableValue = (() => {
                if (inBulkMode) {
                  return times(numOfVariants, (i) => (
                    get(bulkOptions, ['defaultValues', i]) ||
                    ''
                  ))
                }
                else {
                  if (havePreviousValues) {
                    return previousValue
                  }
                  if (haveStoredValues) {
                    return storedValues[storedValues.length - 1]
                  }
                  return ''
                }
              })()

              return castArray(variableValue)

            })(),
          }), {})
        }
      }
    }

    return null
  }

  maybeTriggerChange(prevState = {}) {
    const hasChanged = !isEqual(prevState, this.state)

    if (hasChanged) {
      this.handleVariableChange()
    }
  }

  componentDidMount() {
    this.maybeTriggerChange()
  }

  componentDidUpdate(prevProps, prevState) {
    this.maybeTriggerChange(prevState)
  }

  render() {
    const { variables } = this.props
    const { variableInputs } = this.state

    return (
      <Translate ns="template">
        {({ t }) => (
          <React.Fragment>
            <TemplateVariablesWidget
              variables={variables}
              variableInputs={variableInputs}
              onChange={this.handleVariableChange}
            />
          </React.Fragment>
        )}
      </Translate>
    )
  }
})

TemplateTextWidget.propTypes = {
  variables: PropTypes.array.isRequired,
  variableInputs: PropTypes.object,
  bulkableVariables: PropTypes.object,
  numOfVariants: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
}

export default TemplateTextWidget
import React from 'react'
import PropTypes from 'prop-types'

import { formatPrice } from '../../utils/content'

function PaymentMethodPriceColumn({ paymentMethod }) {
  const hasPrice = paymentMethod.price > 0
  const hasDefaultPrice = paymentMethod.priceDefault > 0
  const hasNoPriceButDefaultPrice = hasDefaultPrice && !hasPrice

  return (
    <React.Fragment>
      <div>
        <span className={hasNoPriceButDefaultPrice ? "text-success h5" : ""}>
          {formatPrice(paymentMethod.price, 'kr', true)}
        </span>
      </div>
      {
        hasNoPriceButDefaultPrice &&
        <div style={{ textDecoration: "line-through" }}>
          {formatPrice(paymentMethod.priceDefault, 'kr', true)}
        </div>
      }
    </React.Fragment>
  )
}

PaymentMethodPriceColumn.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default PaymentMethodPriceColumn

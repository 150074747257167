import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Translate } from 'containers/translates'
import OrderSectionSheet, { OrderSectionSheetHeader, OrderSectionSheetBody } from './OrderSectionSheet'
import { SectionState } from './propTypes';

import OrderPaymentMethodSelectionForIndividual from './OrderPaymentMethodSelectionForIndividual'
import OrderPaymentMethodSelectionForOtherThanIndividual from './OrderPaymentMethodSelectionForOtherThanIndividual'
import OrderPaymentMethodSelectionForOtherThanIndividualAndSignedInUser from './OrderPaymentMethodSelectionForOtherThanIndividualAndSignedInUser'

function InnerPaymentSection({
  isSignedIn,
  customer,
}) {
  if (customer.customerType === 'individual') {
    return <OrderPaymentMethodSelectionForIndividual />
  }
  else if (isSignedIn) {
    return <OrderPaymentMethodSelectionForOtherThanIndividualAndSignedInUser />
  }
  else {
    return <OrderPaymentMethodSelectionForOtherThanIndividual />
  }
}

InnerPaymentSection.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
}

function PaymentSection({
  paymentMethods,
  isSignedIn,
  customer,
  sectionState,
}) {
  return (
    <Translate ns="payment">
      {({ t }) => (
        <OrderSectionSheet state={sectionState}>
          <OrderSectionSheetHeader no={4}>
            {t('heading')}
          </OrderSectionSheetHeader>
          <OrderSectionSheetBody>
            {sectionState !== 'disabled' && paymentMethods.length > 0 && (
              <InnerPaymentSection
                isSignedIn={isSignedIn}
                customer={customer}
                {...{}}
              />
            )}
          </OrderSectionSheetBody>
        </OrderSectionSheet>
      )}
    </Translate>
  )
}

PaymentSection.propTypes = {
  // Own props
  sectionState: SectionState,

  // Redux props
  paymentMethods: PropTypes.array.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
}


export default connect(state => ({
  paymentMethods: state.order.availablePaymentMethods,
  isSignedIn: state.auth.isSignedIn,
  customer: state.customer,
}))(PaymentSection)
export default {
    products: "produkter",
    categories: "kategorier",
    activities: "sporter",
    order: "kundvagn",
    search: "sok",
    pages: "sidor",
    customer: "kund",
    user: {
        root: "anvandare",
        customer: "kund",
        customerOrders: "bestallningar",
        customerEvents: "evenemang",
        customerImages: "bilder",
        addresses: "adresser",
        account: "konto",
        staff: "personal",
        contact: "kontakt",
    },
    resetPassword: "aterstall-losenord",

    // -- Pages --
    'user-conditions': "anvandar-villkor",
}
import React from 'react'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import ProductField from './ProductField'

export default function ProductFields({
  productFields,
  productFieldInputs,
  productFieldInputPrices,
  productFieldInputPriceState,
  numOfVariants,
  onChange,
  onReset
}) {
  return (
    <div>
      {productFields.map((productField, productFieldIndex) => {

        const input = find(productFieldInputs, x => x.productFieldId === productField.productFieldId)
        const inputPriceBundle = find(productFieldInputPrices, x => x.productFieldId === productField.productFieldId)

        return (
          <ProductField
            key={productField.productFieldId}
            {...productField}

            onChange={onChange.bind(null, productField.productFieldId, productFieldIndex)}
            onReset={onReset.bind(null, productField.productFieldId)}

            input={input}
            inputPriceBundle={inputPriceBundle}
            inputPriceBundleState={productFieldInputPriceState}

            numOfVariants={numOfVariants}
          />
        )
      })}
    </div>
  )
}

ProductFields.defaultProps = {
  numOfVariants: 1
}

ProductFields.propTypes = {
  productFields: PropTypes.arrayOf(
    PropTypes.shape({
      productFieldId: PropTypes.number.isRequired,
      fieldId: PropTypes.number.isRequired,
    })
  ).isRequired,
  // productFieldInputs: PropTypes.arrayOf(
  //   ProductField.propTypes.input
  // ),
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  numOfVariants: PropTypes.number
}
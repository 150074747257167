import React from 'react';

import { cleanArray } from '../utils/array';

function FontAwesomeIcon({ name, size, fixedWidth = false, spin = false, ...props }) {

  const faProps = cleanArray([
    name,
    size,
    fixedWidth && 'fw',
    spin && 'spin'
  ], [false])

  const classNames = [
    'fa',
    ...(faProps.map(p => `fa-${p}`))
  ]

  return (
    <i className={classNames.join(" ")}/>
  )
}

const Icon = FontAwesomeIcon;
export default Icon;

export const CloseIcon = (props) => <Icon name='multiply' {...props} />;
export const EditIcon = (props) => <Icon name='pencil' {...props} />;
export const DeleteIcon = (props) => <Icon name='trash' {...props} />;
export const ChangeIcon = (props) => <Icon name='exchange' {...props} />;

export const SigninIcon = (props) => <Icon name='sign-in' {...props} />;
export const UserIcon = (props) => <Icon name='user-o' {...props} />;
export const StaffIcon = (props) => <Icon name='user-secret' {...props} />;

export const DashboardIcon = (props) => <Icon name='home' {...props} />;
export const ListIcon = (props) => <Icon name='list-ul' {...props} />;
export const OrderIcon = (props) => <Icon name='shopping-cart' {...props} />;
export const AddressIcon = (props) => <Icon name='address-book-o' {...props} />;
export const EventIcon = (props) => <Icon name='star-o' {...props} />;
export const ContactIcon = (props) => <Icon name='envelope-o' {...props} />;
export const ImageIcon = (props) => <Icon name='image' {...props} />;
export const ProceedIcon = (props) => <Icon name='arrow-right' {...props} />;
export const CheckIcon = (props) => <Icon name='check' {...props} />;

export const LoadingIcon = (props) => <Icon name='spinner' spin {...props} />;
export const AddIcon = (props) => <Icon name='plus' {...props} />;

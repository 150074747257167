import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import { scrollToTop } from '../hoc';

import find from 'lodash/find';


import BlockArea from '../BlockArea';
import { TwoColumnLayout } from '../../components/layouts';
import DocumentMeta from '../../components/documentMeta';
import SideColumn from '../../components/SideColumn';

import { Sheet } from '../../components/layouts';

import routes from '../../routes';
import { withRouter } from 'react-router-dom';

export default withRouter(connect((state, {match: {params: {pageName}}}) => ({
  blockPage: find(state.blockPages, p => p.slug === pageName)
}))(scrollToTop((props, prevProps) => true)(translate('blockPage')(({match: {params: {pageName}}, blockPage, t, ...props}) => {

  const blockConditions = [`page:${pageName}`];

  return (
    <div>
      <DocumentMeta
        title={blockPage ? blockPage.title || blockPage.name : t('title', {context: pageName})}
        description={blockPage ? blockPage.description : ''}
        url={routes.BLOCK_PAGE.get(pageName)}
      />
      <TwoColumnLayout
        columnOne={(
          <Sheet className="p-4">
            <BlockArea areaSlug="main" conditions={blockConditions}/>
          </Sheet>
        )}
        columnTwo={(
          <SideColumn blockConditions={blockConditions}/>
        )}
      />
    </div>
  );
}))));
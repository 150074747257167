import React from 'react'
import PropTypes from 'prop-types'

import Card, { CardImage, CardBody, CardBodyHeader } from 'components/Card'


export default function ProductCard({ product, ...cardProps }) {
  return (
    <Card {...cardProps}>
      {product.image && <CardImage image={product.image} />}
      <CardBody>
        <CardBodyHeader text={product.name} />
      </CardBody>
    </Card>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}
import React from 'react'
import { connect } from 'react-redux'

import {
  setUseDate,
  setName,
  setComment,
  setDiscountCode
} from '../../actions'

import { Translate } from 'containers/translates'
import OrderSectionSheet, { OrderSectionSheetHeader, OrderSectionSheetBody } from './OrderSectionSheet'

import Input from '../forms/Input'
import UseDateInput from '../UseDateInput'

const OrderDetailsSection = connect(state => {
  const ret = {
    useDate: state.order.useDate,
    name: state.order.name,
    comment: state.order.comment,
    discountCode: state.order.discountCode,
    discountCodeError: state.order.discountCodeError,
    discountData: state.order.discountData,
    order: state.order,
  }
  return ret
}, {
    setUseDate,
    setName,
    setComment,
    setDiscountCode
  })(class _OrderDetailSection extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        useDate: this.props.useDate || null,
        name: this.props.name || '',
        comment: this.props.comment || '',
        discountCode: this.props.discountCode || '',
        eventName: "",
      }
    }

    onChangeUseDate = (useDate) => {
      this.setState({ useDate: useDate || null }, () => {
        this.submitUseDate()
      })
    }

    onChangeEventName = (eventName) => {
      this.setState({ eventName })
    }

    onChangeName = (name) => {
      this.setState({ name })
    }

    onChangeComment = (comment) => {
      this.setState({ comment })
    }

    onChangeDiscountCode = (discountCode) => {
      this.setState({ discountCode })
    }

    onSubmit = () => {
      this.submitUseDate()
    }

    onBlurComment = () => {
      this.submitComment()
    }

    onBlurName = () => {
      this.submitName()
    }

    onBlurUseDate = () => {
      this.submitUseDate()
    }

    onBlurDiscountCode = () => {
      this.submitDiscountCode()
    }

    submitUseDate = () => {
      this.props.setUseDate(this.state.useDate)
    }

    submitName = () => {
      this.props.setName(this.state.name)
    }

    submitComment = () => {
      this.props.setComment(this.state.comment)
    }

    submitDiscountCode = () => {
      if (this.props.discountCode !== this.state.discountCode) {
        this.props.setDiscountCode(this.state.discountCode)
      }
    }

    render() {
      const { sectionState, t } = this.props
      const { eventName, useDate } = this.state

      return (
        <Translate ns="details">
          {({ t }) => (
            <OrderSectionSheet state={sectionState}>
              <OrderSectionSheetHeader no={2}>
                {t('heading')}
              </OrderSectionSheetHeader>
              <OrderSectionSheetBody>
                {
                  sectionState !== 'disabled' ? (
                    <div className="form-group mt-4">
                      <div className="row">
                        <div className="col col-sm-6">
                          <div>
                            {
                              false &&
                              <div>
                                <label className="d-block">
                                  <h3>{t('fields.eventName.label')}</h3>
                                  <Input type='text' value={eventName || ""} onChange={this.onChangeEventName} />
                                </label>
                              </div>
                            }

                            <div>
                              <label className="d-block">
                                <h3>{t('fields.useDate.label')}</h3>
                                <div>
                                  <UseDateInput
                                    value={this.state.useDate}
                                    onChange={this.onChangeUseDate}
                                    t={t}
                                  />
                                </div>
                              </label>
                            </div>

                            {/* <div>
                              <label className="d-block">
                                <h3>{t('fields.name.label')}</h3>
                                <div>
                                  <Input type='text' onChange={this.onChangeName} value={this.state.name || ""} onBlur={this.onBlurName} />
                                </div>
                              </label>
                            </div> */}

                            <div>
                              <label className="d-block">
                                <h3>{t('fields.comment.label')}</h3>
                                <div>
                                  <Input type='textarea' onChange={this.onChangeComment} value={this.state.comment || ""} onBlur={this.onBlurComment} />
                                </div>
                              </label>
                            </div>

                            <div>
                              <label className="d-block">
                                <h3>{t('fields.discountCode.label')}</h3>
                                <div style={{ position: 'relative' }}>
                                  <Input onChange={this.onChangeDiscountCode} value={this.state.discountCode || ""} onBlur={this.onBlurDiscountCode} />
                                  {
                                    this.state.discountCode && this.state.discountCode === (this.props.discountCode || '') && (
                                      this.props.discountCodeError !== null && (
                                        this.props.discountCodeError === false ? (
                                          <i className="fa fa-check text-success" style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 0,
                                            padding: '10px'
                                          }} />
                                        ) : (
                                            <div className="alert alert-danger mt-2">
                                              {t('fields.discountCode.error', {
                                                context: this.props.discountCodeError,
                                                code: this.state.discountCode,
                                                ...this.props.discountData
                                              })}
                                            </div>
                                          )
                                      )
                                    )
                                  }
                                </div>
                              </label>
                            </div>

                            {
                              false &&
                              <div>
                                <button className="btn btn-outline-dark" onClick={this.onSubmit}>{t('sections.details.buttons.save')}</button>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
              </OrderSectionSheetBody>
            </OrderSectionSheet>
          )}
        </Translate>
      )
    }
  })

export default OrderDetailsSection
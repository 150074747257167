import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import find from 'lodash/find'

import {
  setPaymentMethod,
  setInvoiceAddress,
  refreshCustomer,
  refreshUser
} from 'actions'

import {
  orderApi
} from 'api'

import config from '../../config'

import { hasValidAddress, hasValidContactInformation } from 'utils/content'
import { callbackWithoutPropagationComposer } from 'utils/event'

import { DarkButton } from 'components/buttons'
import useBoolState from 'hooks/useBoolState'
import ModalWithClose, { ModalBody, SIZES } from 'components/modals/Modal'
import ContentHeader from 'components/ContentHeader'
import Table from 'components/Table'
import Address from 'components/Address'
import CreateCustomerFormWrapper from 'components/forms/CreateCustomerFormWrapper'
import { Translate } from 'containers/translates'
import PaymentMethodPriceColumn from './PaymentMethodPriceColumn'
import PaymentMethodMainColumn from './PaymentMethodMainColumn'
import EditCustomerFormWrapper from 'components/forms/EditCustomerFormWrapper';

function OrderPaymentMethodSelectionForOtherThanIndividualAndSignedInUser({
  paymentMethods,
  selectedPaymentMethodId,
  user,
  customer,
  order,
  setPaymentMethod,
  setInvoiceAddress,
  refreshUser,
  refreshCustomer,
}) {

  const [selectInvoiceAddressDialogIsOpen, openSelectInvoiceAddressDialog, closeSelectInvoiceAddressDialog] = useBoolState(false)
  const [createInvoiceAddressFormDialogIsOpen, openCreateInvoiceAddressFormDialog, closeCreateInvoiceAddressFormDialog] = useBoolState(false)
  const [editInvoiceAddressFormDialogIsOpen, openEditInvoiceAddressFormDialog, closeEditInvoiceAddressFormDialog] = useBoolState(false)

  const [lastClickedPaymentMethodId, setLastClickedPaymentMethodId] = React.useState(selectedPaymentMethodId)

  const temporarySelectedPaymentMethodId = createInvoiceAddressFormDialogIsOpen ? lastClickedPaymentMethodId : null
  const preOrJustSelectedPaymentMethodId = selectedPaymentMethodId || temporarySelectedPaymentMethodId

  const selectedInvoiceAddressId = order.customerIdForInvoice

  const invoiceAddresses = React.useMemo(
    () => (
      uniqBy(
        [
          ...(user.customer ? [user.customer] : []),
          ...(
            user.customerAccess
              .filter((customerAccess) => customerAccess.state === 'approved')
              .filter((customerAccess) => ['delivery', 'other', 'invoice'].indexOf(customerAccess.customer.usage) !== -1)
          ),
          ...(
            get(customer, 'data.addresses', [])
              .filter((address) => ['delivery', 'other', 'invoice'].indexOf(address.usage) !== -1)
          )
        ],
        (invoiceAddress) => invoiceAddress.id
      )
        .filter((invoiceAddress) => hasValidAddress(invoiceAddress) && hasValidContactInformation(invoiceAddress))
    ),
    [user, customer]
  )

  const invoiceAddress = find(invoiceAddresses, (invoiceAddress) => invoiceAddress.id === selectedInvoiceAddressId)

  const refresh = React.useMemo(() => () => {
    refreshUser()
    refreshCustomer()
  }, [refreshUser, refreshCustomer])

  const handleSelectPaymentMethod = (dataItem) => {
    if (selectedInvoiceAddressId) {
      setPaymentMethod(dataItem.id)
    }
    else if (invoiceAddresses.length > 0) {
      setLastClickedPaymentMethodId(dataItem.id)
      openSelectInvoiceAddressDialog()
    }
    else {
      setLastClickedPaymentMethodId(dataItem.id)
      openCreateInvoiceAddressFormDialog()
    }
  }

  const handleOnCreatedInvoiceAddress = (createdInvoiceAddressId) => {
    setPaymentMethod(lastClickedPaymentMethodId)
    setInvoiceAddress(createdInvoiceAddressId)
    refresh()
    closeCreateInvoiceAddressFormDialog()
  }

  const handleOnEditedInvoiceAddress = () => {
    refresh()
    closeEditInvoiceAddressFormDialog()
  }

  const lastClickedPaymentMethod = React.useMemo(() => (
    find(paymentMethods, (paymentMethod) => paymentMethod.id === lastClickedPaymentMethodId)
  ), [paymentMethods, lastClickedPaymentMethodId])

  const paymentMethodColumns = React.useMemo(() => [
    {
      key: 'main',
      render(dataItem) {
        return (
          <PaymentMethodMainColumn
            paymentMethod={dataItem}
            isSelected={dataItem.isSelected}
            showInvoiceAddress
            showInvoiceAddressIsOtherThanDeliveryAddress={false}
            invoiceAddress={invoiceAddress ? invoiceAddress : undefined}
            onChangeInvoiceAddress={openSelectInvoiceAddressDialog}
          />
        )
      }
    },
    {
      key: 'price',
      className: "text-right",
      render(dataItem) {
        return (
          <PaymentMethodPriceColumn
            paymentMethod={dataItem}
            isSelected={dataItem.isSelected}
          />
        )
      }
    },
  ], [preOrJustSelectedPaymentMethodId, paymentMethods, invoiceAddress])

  const paymentMethodData = React.useMemo(() => paymentMethods.map((paymentMethod) => ({
    ...paymentMethod,
    isSelected: paymentMethod.id === preOrJustSelectedPaymentMethodId,
    key: paymentMethod.id,
  })))

  const handleReset = () => {
    setPaymentMethod(null)
    setInvoiceAddress(null)
  }

  const handleSelectInvoiceAddress = React.useCallback((invoiceAddress) => {
    setPaymentMethod(lastClickedPaymentMethodId)
    setInvoiceAddress(invoiceAddress.id)
    closeSelectInvoiceAddressDialog()
  }, [setPaymentMethod, lastClickedPaymentMethodId, setInvoiceAddress, closeSelectInvoiceAddressDialog])

  const invoiceAddressColumns = React.useMemo(() => [
    {
      key: "main",
      render(invoiceAddressBundle) {
        return (
          <React.Fragment>
            <h3 className={invoiceAddressBundle.isSelected ? "text-primary" : ""}>{invoiceAddressBundle.name}</h3>
            <Address
              showName={false}
              showContactInformation
              {...invoiceAddressBundle}
            />
          </React.Fragment>
        )
      }
    },
    {
      key: "actions",
      render(invoiceAddressBundle) {
        return (
          <React.Fragment>
            {invoiceAddressBundle.isSelected && (
              <DarkButton
                stopOnClickPropagation
                onClick={openEditInvoiceAddressFormDialog}
              >{"Edit"}</DarkButton>
            )}
          </React.Fragment>
        )
      }
    }
  ], [preOrJustSelectedPaymentMethodId, openEditInvoiceAddressFormDialog, selectedInvoiceAddressId])

  const invoiceAddressBundleData = React.useMemo(() => (
    preOrJustSelectedPaymentMethodId !== undefined ?
      invoiceAddresses.map((invoiceAddress) => ({
        key: invoiceAddress.id,
        isSelected: invoiceAddress.id === selectedInvoiceAddressId,
        ...invoiceAddress
      }))
      : []),
    [invoiceAddresses, preOrJustSelectedPaymentMethodId, selectedInvoiceAddressId]
  )

  return (
    <React.Fragment>
      <Table
        columns={paymentMethodColumns}
        data={paymentMethodData}
        showSelectable
        selectedKey={preOrJustSelectedPaymentMethodId}
        onSelect={handleSelectPaymentMethod}
      />
      <ModalWithClose
        isOpen={selectInvoiceAddressDialogIsOpen}
        onClose={closeSelectInvoiceAddressDialog}
      >
        <ModalBody size={SIZES.LARGE}>
          <Translate ns="invoiceAddressList">
            {({ t }) => (
              <React.Fragment>
                <ContentHeader />
                <Table
                  showTableHead={false}
                  showSelectable
                  columns={invoiceAddressColumns}
                  data={invoiceAddressBundleData}
                  onSelect={handleSelectInvoiceAddress}
                  {...{
                    selectedKey: selectedInvoiceAddressId
                  }}
                />
                <DarkButton onClick={callbackWithoutPropagationComposer(openCreateInvoiceAddressFormDialog)}>
                  {t("createInvoiceAddressButtonLabel")}
                </DarkButton>
              </React.Fragment>
            )}
          </Translate>
        </ModalBody>
      </ModalWithClose>
      <ModalWithClose
        isOpen={createInvoiceAddressFormDialogIsOpen}
        onClose={closeCreateInvoiceAddressFormDialog}
      >
        <Translate ns="invoiceAddressForm">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader />
              {lastClickedPaymentMethod && (
                <CreateCustomerFormWrapper
                  fields={['name', 'mobile', 'email', 'address']}
                  onSubmit={handleOnCreatedInvoiceAddress}
                  initialValues={{
                    infoType: 'address',
                    usage: 'invoice',
                    customerId: customer.id,
                  }}
                  t={t}
                />
              )}
            </React.Fragment>
          )}
        </Translate>
      </ModalWithClose>
      <ModalWithClose
        isOpen={editInvoiceAddressFormDialogIsOpen}
        onClose={closeEditInvoiceAddressFormDialog}
      >
        <Translate ns="invoiceAddressForm">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader />
              {lastClickedPaymentMethod && (
                <EditCustomerFormWrapper
                  fields={['name', 'mobile', 'email', 'address']}
                  onSubmit={handleOnEditedInvoiceAddress}
                  customer={invoiceAddress}
                  t={t}
                />
              )}
            </React.Fragment>
          )}
        </Translate>
      </ModalWithClose>
      {config.DEBUG && <div className="mt-4">
        <DarkButton onClick={handleReset}>{"Reset"}</DarkButton>
      </div>}
    </React.Fragment>
  )
}

OrderPaymentMethodSelectionForOtherThanIndividualAndSignedInUser.propTypes = {
  // Redux props
  paymentMethods: PropTypes.array.isRequired,
  selectedPaymentMethodId: PropTypes.string,
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  setInvoiceAddress: PropTypes.func.isRequired,
  refreshCustomer: PropTypes.func.isRequired,
  refreshUser: PropTypes.func.isRequired,
}

export default connect(state => ({
  paymentMethods: state.order.availablePaymentMethods,
  selectedPaymentMethodId: state.order.paymentMethodId,
  customer: state.customer,
  order: state.order,
  user: state.user,
}), {
    setPaymentMethod,
    setInvoiceAddress,
    refreshUser,
    refreshCustomer
  }
)(OrderPaymentMethodSelectionForOtherThanIndividualAndSignedInUser)
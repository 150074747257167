import {
  commonInputs,
  addButton,
  continueButton,
  saveButton,
  cancelButton
} from './form';

import signinOrRegister from './signinOrRegister'

import userTranslations from './user';

import productTranslations from './product'

const customerTypes = {
  customerType: "<none>",
  customerType_individual: "Privatperson",
  customerType_company: "Företag",
  customerType_union: "Förening",
}

const contactInputs = {
  ...commonInputs,
  name: "Kontaktperson",
}

const commonSignin = {
  header: {
    pre: "",
    heading: "Logga in",
    post: "Har du redan ett konto hos oss? Logga in här."
  },

  form: {
    email: {
      placeholder: "E-post"
    },
    password: {
      placeholder: "Lösenord",
    }
  },
  token: signinOrRegister.token
}

const registerSigninOrSkip = {
  ...customerTypes,
  header: {
    pre: "",
    heading: "Registrera användare",
    post: "Som en registrerad användare så kan du se dina lagda ordrar och du slipper fylla i adressuppgifter framöver."
  },
  registerOrSigninButton: {
    label: "Registrera användare"
  },
  skipRegisterOrSigninButton: {
    label: "Hoppa över"
  },
  signinOrRegister: {
    ...userTranslations.signinOrRegister,

    steps: {
      ...userTranslations.signinOrRegister.steps,
      start: {
        header: {
          ...userTranslations.signinOrRegister.steps.start.header,
          heading: "Registrera",
          post: "Härligt, vi hjälper dig nu att skapa ett konto."
        }
      },
    }
  }
}

const orderCustomerSection = {
  email: {
    ...customerTypes,
    header: {
      pre: "",
      heading: "Fyll i e-post",
      post: ""
    },
    inputs: {
      ...commonInputs
    },
    submit: continueButton
  },

  code: {
    ...customerTypes,
    header: {
      pre: "",
      heading: "Validering av e-post",
      post: "Angiven e-post finns registrerad hos oss. Mata in den femsiffriga kod vi skickat till dig för att gå vidare."
    },
    inputs: {
      code: "Kod",
    },
    submit: continueButton,
    cancelButton: cancelButton
  },

  signin: {
    ...customerTypes,
    ...commonSignin,
    submit: continueButton,
    cancelButton: cancelButton
  },

  createUser: {
    ...customerTypes,
    header: {
      pre: "",
      heading: "Skapa användare",
      post: ""
    },
    inputs: {
      ...commonInputs,
      name: "Namn",
    },
    submit: {
      label: "Spara användare"
    },
    cancelButton: cancelButton
  },

  editMissingUserContactDetails: {
    ...customerTypes,

    header: {
      pre: "",
      heading: "{{ userName }}",
      post: "Komplettering av dina kontaktuppgifter"
    },
    inputs: contactInputs,
    submit: saveButton
  },

  createFirstDelivery: {
    ...customerTypes,

    header: {
      pre: "",
      heading: "Leveransuppgifter",
      post: "Vart ska vi skicka beställningen?"
    },
    inputs: contactInputs,
    submit: saveButton
  },

  selectDelivery: {

    ...customerTypes,

    list: {
      header: "Levereras till",
    },

    addButton: {
      ...addButton
    },

    selectCustomer: {
      button: {
        label: "Byt kund"
      }
    },

    end: {
      header: {
        pre: "",
        heading: "{{ customerName }}",
        post: "Nu är du redo att handla åt {{ customerName }}"
      },
      closeButton: {
        label: "Ok"
      },
      customerOrdersPage: {
        label: "Orderhistorik"
      },
      applyForCustomerAdminButton: {
        label: "Ansök om admin-rättigheter"
      }
    },

    new: {
      header: {
        pre: "",
        heading: "Skapa leveransuppgifter",
        post: "",
      },
      inputs: contactInputs,
      submit: saveButton
    },

    edit: {
      header: {
        pre: "",
        heading: "Redigera leveransuppgifter",
        post: "",
      },
      inputs: contactInputs,
      submit: saveButton
    },

    invoice: {
      header: "Faktura",

      options: {
        inDelivery: "I leverans",
        byDeliveryEmail: "Skicka till {{ email }}",
        byOtherEmail: "Skicka till annan e-post",
        byOtherEmail_haveEmail: "Skicka till {{ email }}"
      },

      editByOtherMail: {
        header: {
          pre: "",
          heading: "Till vilken e-post ska fakturan skickas?",
          post: ""
        },
        inputs: commonInputs,
        submit: saveButton
      }
    },

    select: {
      header: {
        pre: "",
        heading: "Val av kund",
        post: "Utöver att handla till dig själv, så kan du representera din förening eller ditt företag."
      },

      list: {
        header: "Dina kunder",
        item: {
          type: "<fel>",
          type_individual: "Privatperson",
          type_company: "Företag",
          type_union: "Förening",
        },
        submit: {
          label: "Spara kundval"
        },
        addButton: {
          label: "Lägg till kund"
        }
      },

      typeSelection: {
        header: {
          pre: "",
          heading: "Välj kundtyp",
          post: "Vilken typ av kund ska ni handla för?"
        }
      },

      union: {
        search: {
          header: {
            pre: "",
            heading: "Föreningsregistret",
            post: "Vi har många föreningar i vårt register. Sök efter din!"
          },
          create: "Skapa ny förening '{{ value }}'"
        },
        create: {
          header: {
            pre: "",
            heading: "Skapa förening",
            post: ""
          },
          inputs: {
            name: "Namn på förening",
            activities: "Aktiviteter",
          },
          submit: saveButton
        },
      },

      company: {
        create: {
          header: {
            pre: "",
            heading: "Skapa företag",
            post: ""
          },
          inputs: {
            name: "Företagsnamn",
            registrationNumber: "Organisationsnummer",
          },
          submit: saveButton
        },
      }
    }
  }
}

const order = {
  title: "Din beställning",
  table: {
    column: {
      article: "Artikel",
      articleNumber: "Artikelnummer",
      description: "",
      quantity: "Antal",
      unitPrice: "A-pris",
      name: {
        archivedInfo: "(arkiverad)"
      },
      addToOrderButton: {
        label: "Kopiera"
      }
    }
  },

  rows: {
    sum: {
      label: "Summa"
    },
    reset: {
      confirm: "Vill du verkligen nollställa ordern?"
    },
    remove: {
      confirm: "Vill du ta bort orderraden?"
    },
    resetButton: {
      label: "Ta bort alla rader"
    },
    edit: {
      save: "Spara ändringar",
      cancel: "Avbryt"
    },
    copy: {
      openButton: {
        label: "Återanvänd för beställning"
      },
      save: "Lägg till i beställning",
      cancel: "Avbryt"
    }
  },

  row: {
    quantity: "1 st",
    quantity_plural: "{{count}} st",
  },

  sections: {
    header: {
      heading: "Din beställning",
    },
    rows: {
      heading: "Kontrollera",
      empty: "Din varukorg är för tillfället tom.",
      product: {
        productFields: productTranslations.productFields
      }
    },
    details: {
      heading: "Detaljer",
      buttons: {
        save: "Spara"
      },

      fields: {
        useDate: {
          label: "Användningsdatum",
          placeholder: "Välj datum"
        },
        discountCode: {
          label: "Rabattkod",
          error: "Oväntat fel",
          error_NOT_FOUND: "Rabattkoden hittades inte",
          error_SUM_TOO_SMALL: "Minsta ordervärde för rabattkoden \"{{code}}\" är {{minOrderSum}}kr.",
        },
        comment: {
          label: "Meddelande"
        },
        name: {
          label: "Namn på ordern"
        }
      },
    },

    customer: {
      heading: "Kunduppgifter",
      ...customerTypes,

      resetCustomerSelectionButtonLabel: "Byt kund",
      editUserCustomerButtonLabel: "Redigera kunduppgifter",

      buttons: {
        changeCustomerType: {
          label: "Byt kundtyp"
        }
      },

      user: {
        header: {
          pre: "",
          heading: "Skapa användare",
          post: ""
        },
        signin: commonSignin,
        registerUserButtonLabel: "Registrera användare",
        continueWithoutUserButtonLabel: "Fortsätt utan användare"
      },

      start: {
        header: {
          pre: "",
          heading: "Vem handlar du för?",
          post: ""
        },
        ...customerTypes,
        selectCustomerTypeHeader: "Välj kundtyp:"
      },

      union: {
        search: {
          header: {
            pre: "",
            heading: "Föreningsregistret",
            post: "Vi har många föreningar i vårt register. Sök efter din!"
          },
          create: "Skapa ny förening '{{ value }}'"
        },

        create: {
          header: {
            pre: "",
            heading: "Skapa förening",
            post: ""
          },
          inputs: {
            name: "Namn på förening",
            activities: "Aktiviteter",
          },
          submit: saveButton
        },

        email: orderCustomerSection.email,
        code: orderCustomerSection.code,
        signin: orderCustomerSection.signin,
        createUser: orderCustomerSection.createUser,
        editMissingUserContactDetails: orderCustomerSection.editMissingUserContactDetails,
        createFirstDelivery: orderCustomerSection.createFirstDelivery,
        selectDelivery: orderCustomerSection.selectDelivery,
        registerSigninOrSkip: registerSigninOrSkip,
      },

      company: {
        create: {
          header: {
            pre: "",
            heading: "Skapa företag",
            post: ""
          },
          inputs: {
            name: "Företagsnamn",
          },
          submit: saveButton
        },

        email: orderCustomerSection.email,
        code: orderCustomerSection.code,
        signin: orderCustomerSection.signin,
        createUser: orderCustomerSection.createUser,
        editMissingUserContactDetails: orderCustomerSection.editMissingUserContactDetails,
        createFirstDelivery: orderCustomerSection.createFirstDelivery,
        selectDelivery: orderCustomerSection.selectDelivery,
        registerSigninOrSkip: registerSigninOrSkip,
      },

      individual: {
        create: {
          header: {
            pre: "",
            heading: "Fyll i dina uppgifter",
            post: ""
          },
          inputs: {
            ...commonInputs,
            name: "Namn",
          },
          submit: saveButton
        },

        edit: {
          header: {
            pre: "",
            heading: "Fyll i dina uppgifter",
            post: ""
          },
        },

        email: orderCustomerSection.email,
        code: orderCustomerSection.code,
        signin: orderCustomerSection.signin,
        createUser: orderCustomerSection.createUser,
        createFirstDelivery: orderCustomerSection.createFirstDelivery,
        selectDelivery: orderCustomerSection.selectDelivery,
        registerSigninOrSkip: registerSigninOrSkip,

        editMissingDetails: {
          ...customerTypes,

          header: {
            pre: "",
            heading: "Fyll i dina uppgifter",
            post: ""
          },
          inputs: {
            ...commonInputs,
            name: "Namn",
          },
          submit: saveButton
        },

        view: {
          ...orderCustomerSection.selectDelivery,
          // ...extend ..
        },
      }
    },

    delivery: {
      heading: "Välj leveransmetod",
      deliveryMethod: {
        prices: {
          free: "Ingen kostnad"
        }
      },
      deliveryAddressList: {
        header: {
          pre: "",
          heading: "Välj",
          post: ""
        },
        createDeliveryAddressButtonLabel: "Skapa ny leveransadress"
      },
      deliveryAddressForm: {
        header: {
          pre: "",
          heading: "Ny leveransadress",
          post: ""
        },
        inputs: {
          ...commonInputs,
          name: "Kontaktperson"
        },
        submit: saveButton
      },
      changeButtonLabel: "Ändra",
    },
    payment: {
      heading: "Välj betalsätt",
      invoiceAddressIsOtherThanDeliveryAddressLabel: "Annan fakturaadress",
      changeToInvoiceIsOtherThanDeliveryAddressButtonLabel: "Byt fakturaadress",
      invoiceAddressList: {
        header: {
          pre: "",
          heading: "Välj",
          post: ""
        },
        createInvoiceAddressButtonLabel: "Skapa ny fakturaadress"
      },
      invoiceAddressForm: {
        header: {
          pre: "",
          heading: "Ny fakturaadress",
          post: ""
        },
        inputs: {
          ...commonInputs,
          name: "Kontaktperson"
        },
        submit: saveButton
      },
      changeButtonLabel: "Ändra",
    },
    summary: {
      heading: "Slutför beställning",
      sum: {
        label: "Summa"
      },
      button: {
        label: "Genomför köp",
        label_sending: "Genomför ..",
        label_sent: "Köpet genomfördes"
      },
      items: {
        products: {
          label: "Summa order",
        },
        deliveryMethod: {
          label: "Leveranskostnad"
        },
        paymentMethod: {
          label: "Betalmetodskostnad"
        }
      },
      startedLocalFilesUpload: {
        header: "Filer laddas upp",
        description: "",
      },
      startedOrderCreate: {
        header: "Order skapas",
        description: "",
      },
      startedSwishPayment: {
        header: "Acceptera betalning i Swish",
        descriptionRowOne: "Öppna Swish",
        descriptionRowTwo: "Godkänn betalning",
        descriptionRowThree: "Klart",
      },
      paymentErrorState: {
        header: "Fel vid betalning",
        description: "",
        closeButtonLabel: "Stäng",
      },
      submittedState: {
        header: "",
      }
    }
  },

  paymentMethods: {
    invoice: {
      name: "Faktura",
      description: "Betala med faktura"
    },
    ecard: {
      name: "Kortbetalning",
      description: "Kommer snart!"
    },
  },

  modals: {
    addedRow: {
      header: "{{ productName }} tillagd i beställning",
      body: "{{ count }} st, trevligt!",
      buttons: {
        toCheckout: "Gå till kassan",
        backToShopping: "Handla vidare",
        saveUseDate: "Spara"
      },
      useDate: {
        label: "Användningsdatum"
      }
    },
    sentOrder: {
      buttons: {
        close: "Stäng"
      },
      defaultMessage: "Tack för ordern. Vi arbetar så fort vi kan .. bla bla bla ..",
      defaultHeader: "Ordern skickades!",
    },
  },

  buttons: {
    reset: "Nollställ order",
    resetUserSettings: "Nollställ produktformulären"
  },

  existingCustomerForm: {
    typeSection: {
      header_invoice: "Faktureringsadress",
      header_delivery: "Leveransadress",
    },
  }
}

export default order
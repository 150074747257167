import signinOrRegister from './signinOrRegister'
import event from './event'
import eventToOrderWizard from './eventToOrderWizard'

const contestNameVariations = {
  team: {
    rows: "prestationspris lag {{ contestName }}",
    rowsAndCopies: "prestationspris individuellt {{ contestName }}",
    copies: "deltagarpris {{ contestName }}",
  },
  individual: {
    rows: "prestationspris {{ contestName }}",
    copies: "deltagarpris {{ contestName }}"
  }
}
const nextButton = {
  label: "Nästa"
}

export default {
  type: {
    label_individual: "Privatperson",
    label_company: "Företag",
    label_union: "Förening",
    label_notSelected: "Ej vald",

    selected: {
      label_individual: "Kundtyp: Privatperson",
      label_company: "Kundtyp: Företag",
      label_union: "Kundtyp: Förening",
    },

    select: {
      label: "Välj kundtyp"
    },
  },

  customerTypes: {
    union: "Förening",
    company: "Företag",
    individual: "Privatperson",
  },

  select: {
    button: {
      label: "Sök efter kund"
    }
  },

  selected: {
    header: "Vald kund",
    button: {
      label: "Vald kund"
    }
  },

  selectType: {
    header: "Välj kundtyp",
  },

  selectExisting: {
    header: "Välj existerande kund",
    placeholder: "Sök på namn eller kundnummer"
  },

  saveButton: {
    label: "Spara kunduppgifter",
    label_loading: "Sparar .."
  },

  sections: {
    union: {
      heading: "Föreningen"
    },
    company: {
      heading: "Företag",
    },

    invoice: {
      heading: "Fakturering",
      inDelivery: "Bifoga faktura i leveransen",
      sendByMail: "E-posta faktura"
    },
    contact: {
      heading: "Kontaktperson"
    },
    delivery: {
      heading: "Leveransadress",
      deliverToInvoiceAddress: "Leverera till fakturaadress"
    },
    newsletter: {
      heading: "Nyhetsbrev",
      checkbox: {
        label: "Jag vill prenumerera på Lundgrens nyhetsbrev"
      }
    }
  },

  inputs: {
    title_name: "Ange namn (minst 2 bokstäver)",
    title_street: "Ange adress (bokstäver och siffror)",
    title_zip: "Ange postnummer (5 siffror)",
    title_city: "Ange ort (minst 2 bokstäver)",
    title_mobile: "Ange mobilnummer (minst 8 siffror)",
  },

  fields: {
    name: {
      label_individual: "För- och efternamn",
      label_union: "Namn på förening",
      label_company: "Företag",
      placeholder_individual: "För- och efternamn",
      placeholder_union: "Namn på förening",
      placeholder_company: "Namn på företag",
    },

    companyName: {
      label: "Företag",
      placeholder: "Namn på företag",
    },

    unionName: {
      label: "Namn på förening",
      placeholder: "Namn på förening",
    },

    street: {
      label: "Adress",
      placeholder: "Adress",
    },

    zip: {
      label: "Postnummer",
      placeholder: "Postnummer",
    },

    city: {
      label: "Postort",
      placeholder: "Postort",
    },

    email: {
      label: "E-post",
      placeholder: "E-post",
    },

    mobile: {
      label: "Mobil",
      placeholder: "Mobil",
    },

    contact: {
      name: {
        label: "Kontaktperson",
        placeholder: "För- och efternamn",
      },

      email: {
        label: "E-post",
        placeholder: "E-post",
      },

      mobile: {
        label: "Mobil",
        placeholder: "Mobil",
      }
    },

    delivery: {
      name: {
        label: "Kontaktperson",
        placeholder: "Kontaktperson",
      },

      mobile: {
        label: "Mobil",
        placeholder: "Mobil",
      },

      email: {
        label: "E-post",
        placeholder: "E-post",
      },

      street: {
        label: "Adress",
        placeholder: "Adress",
      },

      zip: {
        label: "Postnummer",
        placeholder: "Postnummer",
      },

      city: {
        label: "Postort",
        placeholder: "Postort",
      },
    },

    invoice: {
      name: {
        label: "Namn",
        placeholder: "Namn",
      },

      mobile: {
        label: "Mobil",
        placeholder: "Mobil",
      },

      email: {
        label: "Skicka faktura till",
        placeholder: "E-post",
      },

      street: {
        label: "Adress",
        placeholder: "Adress",
      },

      zip: {
        label: "Postnummer",
        placeholder: "Postnummer",
      },

      city: {
        label: "Postort",
        placeholder: "Postort",
      },
    }


  },
  eventToOrderWizard,
  event,
  events: {
    wizard: {
      introduction: {
        header: {
          pre: "",
          heading: "Evenemangsskaparen",
          post:
            "Evenemangsskaparen är ett nytt sätt att beställa priser till cuper, tävlingar och lopp." +
            "\nNär ni beskrivit ert evenemang, så hjälper Evenemangsskaparen dig med ett orderförslag!"
        },
        button: {
          label: "Kör igång"
        }
      },
      signinOrRegister: {
        ...signinOrRegister,
        steps: {
          ...signinOrRegister.steps,
          start: {
            header: {
              ...signinOrRegister.steps.start.header,
              pre: "Evenemangsskaparen",
              heading: "Registrera / Logga in",
              post: "För att använda vår Evenemangsskapare så behöver du vara en registrerad och inloggad användare.",
            }
          },
        }
      },
      customer: {
        event: {
          ...event,
          details: {
            ...event.details,
            create: {
              ...event.details.create,
              header: {
                pre: "Evenemangsskaparen",
                heading: "Skapa evenemang",
                post: ""
              }
            }
          },
          contests: {
            ...event.contests,
            edit: {
              ...event.contests.edit,
              header: {
                pre: "Evenemangsskaparen",
                heading: "{{ eventNameAndYear }}",
                post: ""
              }
            }
          }
        }
      },
      cancelConfirmationMessage: "Vill du avbryta?",
      orderSuggestion: {
        introduction: {
          header: {
            pre: "Evenemangsskaparen",
            heading: "{{ eventName }} sparat",
            post: "Härligt, nu har du kommit en bra bit på vägen!",
          },
          nextButton: {
            label: "Skapa orderförslag"
          }
        },
        productSelections: {
          categorySelection: {
            header: {
              pre: "Evenemangsskaparen",
              heading: "Välj typ av produkt",
              post: "För {{ eventContestsSummary }}"
            },
            nextButton,
            ...contestNameVariations,
          },
          productSelection: {
            header: {
              pre: "Evenemangsskaparen",
              heading: "Välj produkt",
              post: "För {{ eventContestsSummary }}"
            },
            nextButton,
            ...contestNameVariations
          },
          productFieldsSelection: {
            header: {
              pre: "Evenemangsskaparen",
              heading: "Tillval",
              post: "För {{ eventContestsSummary }}"
            },
            nextButton,
            ...contestNameVariations
          },
        },
        summary: {
          header: {
            pre: "Evenemangsskaparen",
            heading: "Orderförslag för {{ eventName }}",
            post: ""
          },
          addToOrderButton: {
            label: "Lägg till ordern"
          },
          orderSum: "Summa: {{ value }}"
        }
      }
    }
  }
}
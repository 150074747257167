import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './WizardStepBoxes.scss'

export default function WizardStepBoxes({ children }) {
  return (
    <div className="WizardStepBoxes">
      {children}
    </div>
  )
}

WizardStepBoxes.propTypes = {
  children: PropTypes.node
}

export function WizardStepBox({ children, highlighted, disabled, onClick }) {
  const className = classNames(
    "WizardStepBoxes__Item",
    {
      ['--disabled']: disabled,
      ['--highlighted']: highlighted,
      ['--clickable']: onClick !== undefined,
    }
  )

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  )
}

WizardStepBox.propTypes = {
  children: PropTypes.node,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
}

WizardStepBox.defaultProps = {
  highlighted: false,
  disabled: false
}

export function WizardStepArrow({ disabled }) {

  const className = classNames(
    "WizardStepBoxes__Arrow",
    {
      ['--disabled']: disabled,
    }
  )

  return (
    <div className={className}>
      <i className="fa fa-angle-right" />
    </div>
  )
}

WizardStepArrow.propTypes = {
  disabled: PropTypes.bool,
}

WizardStepArrow.defaultProps = {
  disabled: false
}

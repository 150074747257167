export const contentPriceTypes = {
  team: {
    rows: "Prestationspris lag (lagtävling)",
    rowsAndCopies: "Prestationspris individuellt (lagtävling)",
    copies: "Deltagarpris (lagtävling)",
  },
  individual: {
    rows: "Prestationspris (individuell tävling)",
    copies: "Deltagarpris (individuell tävling)",
  }
}


export default {
  details: {
    create: {
      header: {
        pre: "",
        heading: "Skapa evenemang",
        post: ""
      },
    },
    edit: {
      header: {
        pre: "",
        heading: "{{ eventName }} - detaljer",
        post: ""
      },
    },
    inputs: {
      name: "Namn på evenemanget",
      activity: "Typ av sport/aktivitet",
      date: "Tidpunkt"
    },
    submit: {
      label: "Nästa"
    }
  },
  contests: {
    create: {
      header: "Tävlingar",
    },
    edit: {
      header: "{{ eventName }}",
    },
    saveButton: {
      label: "Spara"
    },
    prices: {
      position: {
        description: "Error",
        description_1: "1:a",
        description_2: "2:a",
        description_3: "3:a",
        description_4: "4:a",
        description_5: "5:a",
        description_6: "6:a",
        description_7: "7:a",
        description_8: "8:a",
        description_9: "9:a",
        description_10: "10:a",
        description_11: "11:a",
        description_12: "12:a",
        description_13: "13:a",
        description_14: "14:a",
        description_15: "15:a",
      }
    },
    team: {
      header: "Lagtävlingar",
      name: {
        placeholder: "Namn på klass"
      },
      emptyDescription: "Klass/deltävling, exempelvis F12, A-slutspel",
      addButton: {
        label: "Lägg till lagtävling"
      },
      prices: {
        rows: {
          label: "Prestationspris lag",
          simplifiedDetails: {
            header: {
              pre: "",
              post: "",
              heading: "Prestationspris lag",
            },
            inputs: {
              numOfRows: "Antal lag som belönas",
            },
            submit: {
              label: "Spara"
            },
            remove: {
              label: "Ta bort"
            }
          }
        },
        rowsAndCopies: {
          label: "Prestationspris individuellt",
          numOfCopies: "{{count}} deltagare / lag",
          simplifiedDetails: {
            header: {
              pre: "",
              post: "",
              heading: "Prestationspris individuellt",
            },
            inputs: {
              numOfRows: "Antal lag som belönas",
              numOfCopies: "Antal deltagare per lag",
            },
            submit: {
              label: "Spara"
            },
            remove: {
              label: "Ta bort"
            }
          }
        },
        copies: {
          label: "Deltagarpris",
          numOfCopies: "{{count}} deltagare",
          simplifiedDetails: {
            header: {
              pre: "",
              post: "",
              heading: "Deltagarpris",
            },
            inputs: {
              numOfCopies: "Totala antalet deltagare",
            },
            submit: {
              label: "Spara"
            },
            remove: {
              label: "Ta bort"
            }
          }
        },
      },
    },
    individual: {
      header: "Individuella tävlingar",
      name: {
        placeholder: "Namn på klass"
      },
      emptyDescription: "Klass/deltävling, exempelvis F12, Lilla loppet eller Elit herrar",
      prices: {
        rows: {
          label: "Prestationspris",
          simplifiedDetails: {
            header: {
              pre: "{{ eventContestName }}",
              post: "",
              heading: "Prestationspris",
            },
            inputs: {
              numOfRows: "Antal som belönas",
            },
            submit: {
              label: "Spara"
            },
            remove: {
              label: "Ta bort"
            }
          }
        },
        copies: {
          label: "Deltagarpris",
          numOfCopies: "{{count}} deltagare",
          simplifiedDetails: {
            header: {
              pre: "{{ eventContestName }}",
              post: "",
              heading: "Deltagarpris",
            },
            inputs: {
              numOfCopies: "Totala antalet deltagare",
            },
            submit: {
              label: "Spara"
            },
            remove: {
              label: "Ta bort"
            }
          }
        },
      },
      addButton: {
        label: "Lägg till individuell tävling"
      }
    },
  }
}


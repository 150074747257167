import { TAGS, TAG_CATEGORIES } from '../actions';

export function tags(state = [], action) {
  switch(action.type) {
    case TAGS.FETCH.RECEIVE:
      return [...action.items];
    default:
      return state;
  }
}

export function tagCategories(state = [], action) {
  switch(action.type) {
    case TAG_CATEGORIES.FETCH.RECEIVE:
      return [...action.items];
    default:
      return state;
  }
}

import { PRODUCTS } from '../../actions';

const initState = null;

const items = (state = initState, action) => {
  
  switch(action.type) {
    case PRODUCTS.FETCH.RECEIVE:
      return [
        ...(Array.isArray(state) ? state : []),
        ...action.items
      ];
    case PRODUCTS.FETCH.REQUEST:
      return state;
    case PRODUCTS.INVALIDATE:
      return initState;
    default: 
      return state;
  }
};


export default items;
import React from 'react'
import PropTypes from 'prop-types'

import memoize from 'lodash/memoize'
import uniqBy from 'lodash/uniqBy'

import { Translate } from 'containers/translates'

import Card, { CardImage, CardIcon, CardBody } from 'components/Card'
import { SecondaryButton, DarkButton } from 'components/buttons'

import './PriceBundlesList.css'
import Trophy from 'components/Trophy/Trophy';

export function PriceBundlesList({ children }) {
  return (
    <div className="PriceBundlesList">
      {children}
    </div>
  )
}

PriceBundlesList.propTypes = {
  children: PropTypes.node
}

const uniqContentAndPriceTypePriceBundles = memoize((priceBundles) => (
  uniqBy(priceBundles, (priceBundle) => `${priceBundle.contestType}-${priceBundle.priceType}`)
))

export function PriceBundlesListItem({ priceBundles, product, onClick }) {
  const firstPriceBundle = priceBundles[0]

  return (
    <Translate>
      {({ t }) => (
        <div className="PriceBundlesList__Item">
          <div className="">
            {
              product ?
                (
                  <Card onClick={onClick} size="tiny">
                    <CardImage image={product.image} />
                    <CardBody>
                      <DarkButton size="sm">{t('changeButton.label')}</DarkButton>
                    </CardBody>
                  </Card>
                ) :
                (
                  <Card onClick={onClick} size="tiny">
                    <CardIcon>
                      <span style={{
                        opacity: .5
                      }}>
                        <Trophy
                          variant={{
                            'team.rows': "trophy",
                            'team.rowsAndCopies': "goldMedal",
                            'team.copies': "medal",
                            'individual.rows': "trophy",
                            'individual.copies': "medal",
                          }[`${firstPriceBundle.contestType}.${firstPriceBundle.priceType}`]}
                          size="default"
                        />
                      </span>
                    </CardIcon>
                    <CardBody>
                      <SecondaryButton size="sm">{t('selectButton.label')}</SecondaryButton>
                    </CardBody>
                  </Card>
                )
            }
          </div>
          <div className="ml-2">
            <p>
              {uniqContentAndPriceTypePriceBundles(priceBundles).map((priceBundle, priceBundleIndex) => (
                <React.Fragment key={priceBundleIndex}>
                  {t(`contentPriceTypes.${priceBundle.contestType}.${priceBundle.priceType}`)} <br />
                </React.Fragment>
              ))}
            </p>
            <p style={{ fontSize: "80%" }} className="mb-0">
              <i>
                {t('priceBundleSummary', {
                  commaSeparatedContestNames: priceBundles.map((priceBundle) => priceBundle.contestName).join(", ")
                })}
              </i>
            </p>
          </div>
        </div>
      )}
    </Translate>
  )
}

PriceBundlesListItem.propTypes = {
  priceBundles: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  product: PropTypes.object,
}

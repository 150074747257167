import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import find from 'lodash/find'
import get from 'lodash/get'
import entries from 'lodash/entries'
import last from 'lodash/last'
import times from 'lodash/times'
import constant from 'lodash/constant'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

import { caseSelector } from 'utils/helpers'
import { cleanArray, createArray } from 'utils/array'
import { formatPrice } from 'utils/content';
import { PromiseWrapper } from 'utils/promises';

import { fetchArticlePriceBundle } from 'api';
import { addOrderRows } from 'actions'

import {
  getArticleOfAttributeValueId,
  sumProductFieldInputPrices,
  sumPriceBundles,
  productFieldInputIsEmpty
} from 'selectors/productFields'

import { attributeValueIdsToString } from 'selectors/selectedProduct';

import Slider from 'rc-slider'

import ModalButton from 'components/modals/ModalButton'
import Modal, { ModalBody, LARGE, VERTICAL_MODAL_POSITIONS } from 'components/modals/Modal'
import { Button, ButtonContainer } from 'components/buttons'
import Input from 'components/forms/Input'
import { BareContentHeader } from 'components/ContentHeader';

import { LoadingIcon, CheckIcon } from 'components/Icon'

import ProductField from 'components/Product/ProductField'
import { Translate } from 'containers/translates';

import { OrderRowTable } from 'components/OrderRows'

import { UseTextTemplateContext } from 'components/fields/contexts'


const
  PRESELECT_NUM_OF_ROWS = 'PRESELECT_NUM_OF_ROWS',
  ATTRIBUTE_VALUE_QUANTITIES = 'ATTRIBUTE_VALUE_QUANTITIES',
  OTHER_ATTRIBUTES = 'OTHER_ATTRIBUTES',
  PRODUCT_FIELDS = 'PRODUCT_FIELDS',
  SUMMARY = 'SUMMARY'


const
  ATTRIBUTE_VIEW_SIMPLE = 'ATTRIBUTE_VIEW_SIMPLE',
  ATTRIBUTE_VIEW_TABLE = 'ATTRIBUTE_VIEW_TABLE'


class AttributeStep extends React.Component {

  renderSimleViewBundleCard = (bundle, bundleIndex) => {
    const {
      attribute,
      attributes,
      product,
      articles,
      priceBundles,
      mode
    } = this.props

    const article = getArticleOfAttributeValueId(articles, attribute.id, bundle.attributeValueId)
    const priceBundle = find(priceBundles, ({ articleId }) => articleId === article.id)

    const images = [
      article.image,
      product.image,
      ...product.images,
    ].filter(i => i !== null);

    const [image] = images;

    const rowIdentifier = mode.displayProps.rowIdentifiers[bundleIndex]

    return (
      <div className="Card --sm" key={bundleIndex}>
        <div className="CardImage" >
          {
            image &&
            <img src={image.processedUrls.default} />
          }
        </div>

        <div className="CardBody">
          <h3 className="CardBodyHeader text-primary">
            {rowIdentifier ? `${rowIdentifier} - ` : ''}
            {attributeValueIdsToString(article.attributeValueIds, attributes, false)}
          </h3>
          <div className="CardBodyDescription">
            {
              priceBundle && (
                <span>
                  {bundle.quantity} x {formatPrice(priceBundle.price, 'kr')}
                </span>
              )
            }
          </div>
        </div>

      </div>
    )
  }

  renderSimpleView = () => {
    const {
      attributeValueQuantityBundles,
      priceBundles,
      mode
    } = this.props

    const firstBundle = attributeValueQuantityBundles[0]
    const everyQuantity = firstBundle ? firstBundle.quantity : 1

    const priceSum = sumPriceBundles(priceBundles, false)

    const minValue = 1
    const maxValue = mode.maxNumOfRows || 20
    const numOfValues = (maxValue - minValue + 1)
    const defaultValue = mode.defaultPreselectedNumOfRows || minValue
    const value = attributeValueQuantityBundles.length || defaultValue

    const marks = createArray(numOfValues, (markIndex) => markIndex + minValue)
      .reduce(
        (accum, markValue) => {
          const markProps = (() => {
            if (markValue === value) {
              return <strong className='text-primary'>{markValue}</strong>
            }
            else if (markValue === defaultValue) {
              return <span className='text-primary'>{markValue}</span>
            }
            else {
              return <span className=''>{markValue}</span>
            }
          })()

          return {
            ...accum,
            [markValue]: markProps
          }
        },
        {}
      )

    return (
      <Translate ns="attributeValueQuantitiesSimple">
        {({ t }) => (
          <React.Fragment>
            <BareContentHeader
              pre={mode.displayProps.preHeader ? mode.displayProps.preHeader : undefined}
              text={mode.displayProps.simpleAttributes.header ? mode.displayProps.simpleAttributes.header : undefined}
              post={mode.displayProps.simpleAttributes.description ? mode.displayProps.simpleAttributes.description : undefined}
            />
            <div>

              <hr />

              <div>
                <label>
                  {mode.displayProps.simpleAttributes.numLabel}
                </label>
                <div
                  style={{
                    maxWidth: 400,
                    height: 50
                  }}
                >
                  <Slider
                    marks={marks}
                    min={minValue}
                    max={maxValue}
                    defaultValue={value}
                    onChange={this.props.onChangePreselectedNumOfRows}
                  />
                </div>
              </div>

              <div className="mt-3 CardList">
                {attributeValueQuantityBundles.map(this.renderSimleViewBundleCard)}
              </div>

              <div className="d-md-flex align-items-center mt-3">
                <label htmlFor="everyQuantity" className="mb-md-0">
                  {mode.displayProps.simpleAttributes.quantityLabel}
                </label>

                <Input
                  id="everyQuantity"
                  className="ml-md-2"
                  style={{
                    maxWidth: 120
                  }}
                  type="number"
                  value={everyQuantity || ''}
                  required
                  min={1}
                  onChange={(quantityStr) => this.props.onSetAllQuantities(Number(quantityStr))}
                />

                <button
                  type="button"
                  className="btn btn-link ml-md-auto"
                  onClick={() => this.props.onSetView(ATTRIBUTE_VIEW_TABLE)}
                >
                  {mode.displayProps.simpleAttributes.tableButton}
                </button>

              </div>
              <hr />
              <div>
                {t('sum.label')}: {formatPrice(priceSum, 'kr')}
              </div>
            </div>

          </React.Fragment>
        )}
      </Translate>
    )
  }

  renderTableView = () => {
    const {
      articles,
      attribute,
      attributeValues,
      attributeValueQuantityBundles,
      mode
    } = this.props

    return (
      <Translate ns="attributeValueQuantities">
        {({ t }) => (
          <React.Fragment>
            <BareContentHeader
              pre={mode.displayProps.preHeader ? mode.displayProps.preHeader : undefined}
              text={mode.displayProps.attributesTable.header ? mode.displayProps.attributesTable.header : undefined}
              post={mode.displayProps.attributesTable.description ? mode.displayProps.attributesTable.description : undefined}
            />

            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: 40 }}></th>
                  <th></th>
                  <th style={{ width: 100 }}></th>
                  <th style={{ width: 60 }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  attributeValueQuantityBundles.map((bundle, bundleIndex) => {
                    return (
                      <tr key={bundleIndex}>
                        <td style={{ verticalAlign: "middle" }}>
                          <strong>
                            {mode.displayProps.rowIdentifiers[bundleIndex] || ''}
                          </strong>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            value={bundle.attributeValueId}
                            onChange={(e) => this.props.onSet(bundleIndex, {
                              attributeValueId: Number(e.target.value),
                              quantity: bundle.quantity,
                            })}
                          >
                            {attributeValues.map((_attributeValue, attributeValueIndex) => {
                              const article = find(
                                articles,
                                ({ attributeValueIds, ...article }) => (
                                  attributeValueIds[attribute.id] === _attributeValue.id
                                )
                              )

                              return (
                                <option key={attributeValueIndex} value={_attributeValue.id}>
                                  {_attributeValue.name}, {formatPrice(article.price.default, 'kr')}
                                </option>
                              )
                            })}
                          </select>
                        </td>
                        <td>
                          <Input
                            type="number"
                            min={1}
                            value={bundle.quantity || ''}
                            required
                            onChange={(value) => {
                              const quantity = Number(value)
                              if (bundleIndex === 0) {
                                this.props.onSetAllQuantities(quantity)
                              }
                              else {
                                this.props.onSet(bundleIndex, {
                                  attributeValueId: bundle.attributeValueId,
                                  quantity,
                                })
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            type="button"
                            outline
                            color="dark"
                            onClick={() => this.props.onRemove(bundleIndex)}
                          >
                            <i className="fa fa-remove" />
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>

            {
              mode.maxNumOfRows < attributeValueQuantityBundles.length && (

                <div>
                  <Button
                    type="button"
                    outline
                    color="dark"
                    onClick={this.props.onAdd}
                  >
                    <i className="fa fa-plus" />
                  </Button>
                </div>
              )
            }
          </React.Fragment>
        )}
      </Translate>
    )
  }

  render() {
    return caseSelector({
      [ATTRIBUTE_VIEW_SIMPLE]: this.renderSimpleView,
      [ATTRIBUTE_VIEW_TABLE]: this.renderTableView
    })(this.props.view)()
  }
}

AttributeStep.defaultProps = {
  view: ATTRIBUTE_VIEW_SIMPLE
}


class ProductFieldsStep extends React.Component {

  renderProductField(productFieldBundle) {

    const { productFieldInputs, productFieldInputPrices, mode, numOfVariants } = this.props

    const productFieldInputPrice = find(productFieldInputPrices, (x) => x.productFieldId === productFieldBundle.productFieldId)

    return (
      <UseTextTemplateContext.Provider value={true}>
        <ProductField
          {...productFieldBundle}
          input={productFieldInputs[productFieldBundle.productFieldId]}
          inputPriceBundle={productFieldInputPrice}
          inputPriceBundleState={'loaded'}
          onChange={(input) => this.props.onSet(productFieldBundle.productFieldId, input)}
          onReset={() => { }}
          numOfVariants={numOfVariants}
          bulkableVariables={mode.bulkableVariables}
        />
      </UseTextTemplateContext.Provider>
    )
  }

  render() {
    const { productFields, productFieldInputs, mode } = this.props

    return (
      <Translate ns="productFields">
        {({ t }) => (
          <React.Fragment>
            <BareContentHeader
              pre={mode.displayProps.preHeader ? mode.displayProps.preHeader : undefined}
              text={mode.displayProps.productFields.header ? mode.displayProps.productFields.header : undefined}
              post={mode.displayProps.productFields.description ? mode.displayProps.productFields.description : undefined}
            />
            <hr />
            {
              productFields.map((productFieldBundle, productFieldBundleIndex) => (
                <React.Fragment key={productFieldBundleIndex}>
                  {this.renderProductField(productFieldBundle)}
                </React.Fragment>
              ))
            }
          </React.Fragment>
        )}
      </Translate>
    )
  }
}

ProductFieldsStep.propTypes = {
  onDone: PropTypes.func.isRequired,
  onSet: PropTypes.func.isRequired
}


const Summary = connect((state) => ({
  productAttributes: state.productAttributes
}))(function _Summary({ priceBundles, orderRows, productAttributes, product: { articles, ...product }, mode }) {

  const priceSum = sumPriceBundles(priceBundles, true)

  return (
    <Translate ns="summary">
      {({ t }) => (
        <React.Fragment>
          <BareContentHeader
            pre={mode.displayProps.preHeader ? mode.displayProps.preHeader : undefined}
            text={mode.displayProps.summary.header ? mode.displayProps.summary.header : undefined}
            post={mode.displayProps.summary.description ? mode.displayProps.summary.description : undefined}
          />
          <OrderRowTable
            showTableHead={false}
            orderRows={orderRows.map((row) => ({
              ...row,
              productFieldInputs: entries(row.productFieldInputs).map(([productFieldId, productFieldInputValues]) => {
                return {
                  productFieldId,
                  ...productFieldInputValues
                }
              })
            }))}
            productArticles={articles.map((article) => ({
              ...article,
              product
            }))}
            productAttributes={productAttributes}
            removeRow={() => null}
            isEditable={false}
          />
          <div className="text-right">
            {t('sum.label')}: {formatPrice(priceSum, 'kr')}
          </div>
        </React.Fragment>
      )}
    </Translate>

  )
})

class _ProductAttributePackageWizard extends React.Component {
  state = {
    productFieldInputs: {},
    preselectedNumOfRows: null,
    everyQuantity: 1,
    attributeValueQuantityBundles: [],
    stepIndex: null,
    attributeView: ATTRIBUTE_VIEW_SIMPLE,
    priceBundles: [],
    submitAndDoneButtonState: null,
    submitAndRestartButtonState: null,
  }

  wrappedFetchPromise = null

  constructor(props) {
    super(props)

    this.debouncedUpdatePriceBundles = debounce(this.updatePriceBundles, 200)
  }

  updatePriceBundles = () => {

    if (this.wrappedFetchPromise) {
      this.wrappedFetchPromise.cancel()
    }

    this.wrappedFetchPromise = new PromiseWrapper(
      Promise.all(
        this.getOrderRows().map(
          ({ articleId, quantity, productFieldInputs }) =>
            (
              fetchArticlePriceBundle(
                articleId,
                {
                  productFieldInputs: productFieldInputs.filter((x) => !productFieldInputIsEmpty(x)),
                  quantity
                }
              ).then(({ item }) => item)
            )
        )
      )
    )

    this.wrappedFetchPromise.promise
      .then((priceBundles) => {
        this.setState({
          priceBundles
        })
        this.wrappedFetchPromise = null;
      })
      .catch(({ isCanceled }) => {

        if (!isCanceled) {
          this.setState({
            priceBundles: []
          })
        }
        this.wrappedFetchPromise = null;
      });
  }

  componentDidMount() {
    this.debouncedUpdatePriceBundles()
  }

  componentDidUpdate(prevProps, prevState) {

    function bundleMapper({ attributeValueId, quantity }) {
      return {
        attributeValueId,
        quantity
      }
    }
    const nextBundles = this.state.attributeValueQuantityBundles.map(bundleMapper)
    const prevBundles = prevState.attributeValueQuantityBundles.map(bundleMapper)

    if (
      !isEqual(prevBundles, nextBundles) ||
      !isEqual(this.state.productFieldInputs, prevState.productFieldInputs)
    ) {
      this.debouncedUpdatePriceBundles()
    }
  }

  setAllQuantitiesOnAttributeValueQuantityBundle = (quantity) => {
    this.setState({
      attributeValueQuantityBundles: this.state.attributeValueQuantityBundles.map(({ attributeValueId }) => ({
        attributeValueId,
        quantity
      })),
      everyQuantity: quantity
    })
  }

  setAttributeValueQuantityBundle = (bundleIndex, { attributeValueId, quantity, unitPrice }) => {
    this.setState({
      attributeValueQuantityBundles: this.state.attributeValueQuantityBundles.map((bundle, _bundleIndex) => {
        if (bundleIndex === _bundleIndex) {
          return {
            attributeValueId,
            quantity,
            unitPrice
          }
        }

        return bundle
      })
    })
  }

  static makePreselectedAttributeValueQuantityBundles = (props, numOfBundles, quantity = 1) => {
    const { attributeValueIds } = props
    const numOfAttributeValues = attributeValueIds.length

    return times(numOfBundles, constant(null)).map((_null, bundleIndex) => ({
      attributeValueId: attributeValueIds[(() => {

        let currentAttributeValueIndex = 0
        const arr = []

        for (let i = 0; i < numOfBundles; i++) {

          const isFirst = i === 0
          const isLast = currentAttributeValueIndex === numOfAttributeValues - 1

          if (isFirst) {
            currentAttributeValueIndex = 0
          }
          else if (!isLast) {

            if (numOfAttributeValues <= 3) {
              currentAttributeValueIndex += 1
            }
            else {
              const numOfBundlesLeft = numOfBundles - i
              const numOfAttributeValuesLeft = numOfAttributeValues - currentAttributeValueIndex
              const canFitEveryOther = numOfBundlesLeft < numOfAttributeValuesLeft / 2
              currentAttributeValueIndex += canFitEveryOther ? 2 : 1
            }

          }

          arr.push(currentAttributeValueIndex)

          if (i === bundleIndex) {
            break
          }
        }

        return currentAttributeValueIndex

      })()],
      quantity,
      unitPrice: undefined,
    }))
  }

  addPreselectedAttributeValueQuantityBundles = (numOfBundles, cb = () => null) => {
    this.setState({
      attributeValueQuantityBundles: _ProductAttributePackageWizard.makePreselectedAttributeValueQuantityBundles(
        this.props,
        numOfBundles,
        this.state.everyQuantity,
      )
    }, cb)
  }

  addNextAttributeValueQuantityBundle = () => {
    const { attributeValueIds } = this.props

    const lastAttributeValueQuantityBundle = last(this.state.attributeValueQuantityBundles)

    const nextAttributeValueId = (() => {
      let nextAttributeValueIdIndex = 0

      if (lastAttributeValueQuantityBundle) {
        const lastAttributeValueIdIndex = attributeValueIds.indexOf(lastAttributeValueQuantityBundle.attributeValueId)
        nextAttributeValueIdIndex = (
          attributeValueIds.length > lastAttributeValueIdIndex + 1 ?
            lastAttributeValueIdIndex + 1 :
            lastAttributeValueIdIndex
        )
      }

      return attributeValueIds[nextAttributeValueIdIndex]
    })()

    const nextQuantity = (
      lastAttributeValueQuantityBundle ?
        lastAttributeValueQuantityBundle.quantity :
        1
    )

    this.setState({
      attributeValueQuantityBundles: [
        ...this.state.attributeValueQuantityBundles,
        {
          attributeValueId: nextAttributeValueId,
          quantity: nextQuantity,
          unitPrice: undefined
        }
      ]
    })
  }

  removeAttributeValueQuantityBundle = (bundleIndex) => {
    this.setState({
      attributeValueQuantityBundles: this.state.attributeValueQuantityBundles.filter((_, _bundleIndex) => bundleIndex !== _bundleIndex)
    })
  }

  setProductFieldInput = (productFieldId, input) => {
    this.setState({
      productFieldInputs: {
        ...this.state.productFieldInputs,
        [productFieldId]: {
          ...(this.state.productFieldInputs[productFieldId] || {}),
          ...input
        }
      }
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const hasChanged = (
      nextProps.mode !== prevState.lastMode ||
      nextProps.attributeId !== prevState.lastAttributeId
    )

    if (hasChanged) {
      return {
        lastMode: nextProps.mode,
        lastAttributeId: nextProps.attributeId,
        productFieldInputs: nextProps.storedProductFieldInputs,
        attributeValueQuantityBundles: _ProductAttributePackageWizard.makePreselectedAttributeValueQuantityBundles(
          nextProps,
          nextProps.mode.defaultPreselectedNumOfRows || 1,
          prevState.nextQuantity
        ),
        stepIndex: 0,
        steps: cleanArray([
          ATTRIBUTE_VALUE_QUANTITIES,
          false && OTHER_ATTRIBUTES,
          nextProps.productFields.length > 0 && PRODUCT_FIELDS,
          SUMMARY
        ], [false])
      }
    }

    return null
  }

  handleSubmitAndEnd = () => {
    this.setState({
      submitAndDoneButtonState: 'loading',
    })

    setTimeout(() => {

      this.setState({
        submitAndDoneButtonState: 'success',
      })

      this.props.addOrderRows(this.getOrderRows())

      setTimeout(() => {

        this.setState({
          submitAndDoneButtonState: null,
        }, this.props.onDone)
      }, 500)
    }, 500)
  }

  handleSubmitAndRestart = () => {
    this.setState({
      submitAndRestartButtonState: 'loading',
    })

    setTimeout(() => {
      this.setState({
        submitAndRestartButtonState: 'success',
      })

      this.props.addOrderRows(this.getOrderRows())

      setTimeout(() => {
        this.setState({
          submitAndRestartButtonState: null,
          stepIndex: 0
        })
      }, 500)
    }, 500)
  }

  getOrderRowProductInputs(bundleIndex) {
    return entries(this.state.productFieldInputs).map(
      ([productFieldId, productFieldInput]) => ({
        productFieldId: Number(productFieldId),
        ...(({ variants, ...productFieldInput }) => (
          variants ? variants[bundleIndex] : productFieldInput
        ))(productFieldInput)
      })
    )
  }

  getOrderRows = () => {
    const { articles, attributeId, nextOrderRowId } = this.props
    const { attributeValueQuantityBundles, priceBundles } = this.state

    return attributeValueQuantityBundles.map(({ attributeValueId, quantity }, bundleIndex) => {
      const article = find(
        articles,
        ({ attributeValueIds, ...article }) => (
          attributeValueIds[attributeId] === attributeValueId
        )
      )

      const priceBundle = find(priceBundles, ({ articleId }) => articleId === article.id)
      const unitPrice = priceBundle ?
        (
          priceBundle.price +
          sumProductFieldInputPrices(priceBundle.productFieldInputPrices)
        ) :
        undefined

      return {
        id: (nextOrderRowId + bundleIndex),
        articleId: article && article.id,
        quantity,
        unitPrice,
        productFieldInputs: this.getOrderRowProductInputs(bundleIndex)
      }
    })
  }

  handlePrev = () => {
    this.setState({
      stepIndex: this.state.stepIndex - 1
    })
  }

  handleNext = () => {

    (new Promise((resolve) => {
      switch (this.state.steps[this.state.stepIndex]) {
        case PRESELECT_NUM_OF_ROWS:
          this.addPreselectedAttributeValueQuantityBundles(this.state.preselectedNumOfRows, resolve)
          break;
        default:
          resolve()
          break;
      }
    }))
      .then(() => {
        this.setState(() => {
          const { stepIndex } = this.state
          return {
            stepIndex: stepIndex + 1
          }
        })
      })
  }

  handleChangeAttributeView = (view) => {
    this.setState({
      attributeView: view
    })
  }

  render() {
    const {
      mode,
      product,
      attribute,
      attributes,
      attributeValues,
      articles
    } = this.props

    const {
      stepIndex,
      attributeValueQuantityBundles,
      priceBundles
    } = this.state

    const hint = {
      [ATTRIBUTE_VALUE_QUANTITIES]: (
        this.state.attributeView ?
          mode.displayProps.simpleAttributes.hint :
          mode.displayProps.attributesTable.hint
      ),
      [PRODUCT_FIELDS]: mode.displayProps.productFields.hint,
      [SUMMARY]: mode.displayProps.summary.hint,
    }[this.state.steps[stepIndex]]

    return (

      <Translate ns="product.attributePackageWizard">
        {({ t }) => (
          <React.Fragment>

            <div className="text-center">
              <div className="steps">
                {this.state.steps.map((step, _stepIndex) => (
                  <div
                    key={_stepIndex}
                    className={["step", _stepIndex === stepIndex && "current", _stepIndex < stepIndex && "previous"].filter(Boolean).join(" ")}
                    onClick={
                      _stepIndex < stepIndex ?
                        (() => {
                          this.setState({
                            stepIndex: _stepIndex
                          })
                        }) :
                        undefined
                    }
                  />
                ))}
              </div>
            </div>
            {caseSelector({
              [ATTRIBUTE_VALUE_QUANTITIES]: () => (
                <AttributeStep
                  attributeValueQuantityBundles={attributeValueQuantityBundles}
                  priceBundles={priceBundles}
                  product={product}
                  articles={articles}
                  attributes={attributes}
                  attribute={attribute}
                  attributeValues={attributeValues}
                  view={this.state.attributeView}
                  onChangePreselectedNumOfRows={(numOfRows) => this.addPreselectedAttributeValueQuantityBundles(numOfRows)}
                  onAdd={this.addNextAttributeValueQuantityBundle}
                  onRemove={this.removeAttributeValueQuantityBundle}
                  onSet={this.setAttributeValueQuantityBundle}
                  onSetAllQuantities={this.setAllQuantitiesOnAttributeValueQuantityBundle}
                  onSetView={this.handleChangeAttributeView}
                  mode={mode}
                />
              ),
              [PRODUCT_FIELDS]: () => (
                <ProductFieldsStep
                  productFields={this.props.productFields}
                  productFieldInputs={this.state.productFieldInputs}
                  productFieldInputPrices={priceBundles.length ? priceBundles[0].productFieldInputPrices : []}
                  onSet={this.setProductFieldInput}
                  onDone={() => this.setState({
                    stepIndex: this.state.steps.indexOf(SUMMARY)
                  })}
                  mode={mode}
                  numOfVariants={this.state.attributeValueQuantityBundles.length}
                />
              ),
              [SUMMARY]: () => (
                <Summary
                  product={this.props.product}
                  orderRows={this.getOrderRows()}
                  priceBundles={priceBundles}
                  mode={mode}
                />
              )
            }, () => (
              <p>{"DEFAULT."}</p>
            ))(this.state.steps[stepIndex])()}
            <hr />
            {
              hint && (
                <p>
                  <i className="fa fa-lightbulb-o" /> {hint}
                </p>
              )
            }
            <ButtonContainer>
              {/* {
                stepIndex !== 0 && (
                  <div className="mr-3">

                    <Button outline color="dark" onClick={this.handlePrev}>
                      {t('prevButton.label')}
                    </Button>
                  </div>
                )
              } */}
              {caseSelector({
                [ATTRIBUTE_VALUE_QUANTITIES]: () => (
                  <React.Fragment>
                    <Button
                      className="ml-md-auto"
                      disabled={attributeValueQuantityBundles.filter((x) => x.quantity).length === 0}
                      onClick={this.handleNext}
                      color="primary"
                    >
                      {t('nextButton.label')}
                    </Button>
                  </React.Fragment>
                ),
                [SUMMARY]: () => (
                  <React.Fragment>
                    <Button
                      className="ml-md-auto"
                      onClick={this.handleSubmitAndRestart}
                      disabled={this.state.submitAndRestartButtonState !== null}
                      color={this.state.submitAndRestartButtonState === 'success' ? 'success' : 'primary'}
                    >
                      {this.state.submitAndRestartButtonState === 'loading' && <LoadingIcon fixedWidth />}
                      {this.state.submitAndRestartButtonState === 'success' && <CheckIcon fixedWidth />}
                      <i className="fa fa-cart-plus fa-fw" /> {mode.displayProps.summary.addMoreButton}
                    </Button>
                    <Button
                      onClick={this.handleSubmitAndEnd}
                      disabled={this.state.submitAndDoneButtonState !== null}
                      color={this.state.submitAndDoneButtonState === 'success' ? 'success' : 'primary'}
                    >
                      {this.state.submitAndDoneButtonState === 'loading' && <LoadingIcon fixedWidth />}
                      {this.state.submitAndDoneButtonState === 'success' && <CheckIcon fixedWidth />}
                      <i className="fa fa-cart-plus fa-fw" /> {mode.displayProps.summary.addToOrderButton}
                    </Button>
                  </React.Fragment>
                )
              }, () => (
                <React.Fragment>
                  <Button
                    className="ml-md-auto"
                    onClick={this.handleNext}
                    color="primary"
                  >
                    {t('nextButton.label')}
                  </Button>
                </React.Fragment>
              ))(this.state.steps[stepIndex])()}
            </ButtonContainer>
          </React.Fragment>
        )}
      </Translate>
    )

  }
}

const ProductAttributePackageWizard = connect((state, { productFields }) => {

  const productFieldsWithoutTemplates = productFields.filter(({ productFieldId }) => {
    const fullProductField = find(state.productFields, (x) => x.id === productFieldId)
    return !fullProductField.textTemplates || fullProductField.textTemplates.length === 0
  })

  const moreProps = {
    storedProductFieldInputs: productFieldsWithoutTemplates.reduce(
      (productFieldInputs, productField) => ({
        ...productFieldInputs,
        [productField.productFieldId]: (() => {
          const storedProductFieldInput = find(state.userSettings.fieldInputs, (x) => x.fieldId === productField.fieldId)

          if (storedProductFieldInput) {
            return storedProductFieldInput
          }

          return {}
        })()
      }),
      {}
    )
  }
  return moreProps

})(_ProductAttributePackageWizard)


ProductAttributePackageWizard.propTypes = {
  attribute: PropTypes.object.isRequired,
  attributeValues: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired
}

const ConnectedProductAttributePackageWizard = connect(
  (state, { attributeId, attributeValueIds, otherAttributeValueIds }) => {
    const attribute = find(state.productAttributes, ({ id }) => attributeId === id)
    const attributeValues = attribute.values.filter(({ id }) => attributeValueIds.indexOf(id) !== -1)

    return {
      attribute,
      attributeValues,
      nextOrderRowId: state.order.nextRowId,
      attributes: state.productAttributes
    }
  },
  {
    addOrderRows
  }
)(ProductAttributePackageWizard)

ConnectedProductAttributePackageWizard.propTypes = {
  attributeId: PropTypes.number.isRequired,
  attributeValueIds: PropTypes.arrayOf(
    PropTypes.number
  ),
  productFields: PropTypes.array,
  otherAttributeValueIds: PropTypes.object,
  articles: PropTypes.array.isRequired,
  product: PropTypes.object.isRequired,
  mode: PropTypes.object.isRequired,
}

ConnectedProductAttributePackageWizard.defaultProps = {
  otherAttributeValueIds: {},
  productFields: []
}


export default function ProductAttributePackageWizardModal({ renderModalButton, ...props }) {
  return (
    <ModalButton
      prerenderModalComponent
      ModalComponent={({ isOpen, onClose }) => (
        <Modal
          verticalPosition={VERTICAL_MODAL_POSITIONS.TOP}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalBody size={LARGE}>
            <ConnectedProductAttributePackageWizard
              onDone={onClose}
              {...props}
            />
          </ModalBody>
        </Modal>
      )}
      ButtonComponent={({ toggle }) => renderModalButton({ toggle })}
    />
  )
}
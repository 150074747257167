import { PRODUCT_FIELDS, FIELD_SELECTIONS } from '../actions';

export function productFields(state = [], action) {
  switch(action.type) {
    case PRODUCT_FIELDS.FETCH.RECEIVE:
      return [
        ...action.items
      ];
    default:
      return state;
  }
}

export function fieldSelections(state = [], action) {
  switch(action.type) {
      case FIELD_SELECTIONS.FETCH.RECEIVE:
      return [
          ...action.items
      ];
      default:
      return state;
  }
}
export const AUTH = {
  SIGNINGIN: {
    REQUEST: 'AUTH.SIGNINGIN.REQUEST',
    SUCCESS: 'AUTH.SIGNINGIN.SUCCESS',
    FAILED: 'AUTH.SIGNINGIN.FAILED',
  },

  RESET_ERROR: 'AUTH.RESET_ERROR',

  SIGNIN: 'AUTH.SIGNIN',
  SIGNIN_WITH_TOKEN: 'AUTH.SIGNIN_WITH_TOKEN',
  SIGNOUT: 'AUTH.SIGNOUT',
}

export const signin = (username, password) => ({
  type: AUTH.SIGNIN,
  username, password
});

export const signinWithToken = (token) => ({
  type: AUTH.SIGNIN_WITH_TOKEN,
  token
})

export const signout = () => ({
  type: AUTH.SIGNOUT
});

export const signinSuccess = (token, userName) => ({
  type: AUTH.SIGNINGIN.SUCCESS,
  token, userName
});

export const signinFailed = (e) => ({
  type: AUTH.SIGNINGIN.FAILED,
  error: e
});

export const resetSigninError = () => ({
  type: AUTH.RESET_ERROR
})
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormGroup } from "reactstrap";

import { caseSelector } from 'utils/helpers';
import { patterns, parsers } from 'utils/values';
import { cleanArray } from 'utils/array';
import { cleanObject } from 'utils/object';

import Form, { FormInput, LabeledFormInput, FormRow, FormButton } from './Form';

import {
  CustomerTranslate,
} from 'containers/translates';

import { DarkButton, PrimaryButton } from '../buttons';


class CustomerForm extends React.Component {

  oneOfIsIncludedInFields(fieldsToBeChecked) {
    const { fields } = this.props;

    return fieldsToBeChecked.filter(f => fields.indexOf(f) !== -1).length > 0;
  }

  fieldHasFocusOnMount(field) {
    const { fields } = this.props;
    const _fieldHasFocusOnMount = fields.indexOf(field) === 0;
    return _fieldHasFocusOnMount;
  }

  handleSubmit = (...args) => {
    this.props.onSubmit(...args)
  }

  render() {
    const {
      customer,
      customer: {
        name,
        email,
        mobile,
        street,
        zip,
        city,
        activities
      },
      onSubmit,
      buttonComponent: ButtonComponent = PrimaryButton,
      t,
      prefilledInputValues
    } = this.props;

    const initialInputValues = cleanObject({
      name,
      email,
      mobile,
      street,
      zip,
      city,
      activities
    });

    return (
      <Form
        t={t}
        onSubmit={this.handleSubmit}
        initialInputValues={initialInputValues}
        prefilledInputValues={prefilledInputValues}
        valueRules={cleanArray([
          this.oneOfIsIncludedInFields(['name']) && { key: 'name', required: true, ...patterns.name },
          this.oneOfIsIncludedInFields(['email']) && { key: 'email', required: true, ...patterns.email },
          this.oneOfIsIncludedInFields(['mobile']) && { key: 'mobile', required: true, ...patterns.mobile },
          this.oneOfIsIncludedInFields(['address']) && { key: 'street', required: true, ...patterns.street },
          this.oneOfIsIncludedInFields(['address']) && { key: 'zip', required: true, ...patterns.zip },
          this.oneOfIsIncludedInFields(['address']) && { key: 'city', required: true, ...patterns.city },
        ])}
        valueParsers={[
          { key: 'zip', parser: parsers.zip }
        ]}
      >
        {({ isBusy, isDirty }) => (
          <React.Fragment>
            {
              this.oneOfIsIncludedInFields(['name']) &&
              <FormRow>
                <FormGroup className="col-12">
                  <LabeledFormInput t={t}
                    inputName="name"
                    focusOnMount={this.fieldHasFocusOnMount("name")}
                  />
                </FormGroup>
              </FormRow>
            }
            {/* {
                  this.oneOfIsIncludedInFields(['registrationNumber']) &&
                  <FormRow>
                    <FormGroup className="col-12">
                      <LabeledFormInput t={t}
                        inputName="registrationNumber"
                        focusOnMount={this.fieldHasFocusOnMount("registrationNumber")}
                      />
                    </FormGroup>
                  </FormRow>
                } */}
            {
              this.oneOfIsIncludedInFields(['email']) &&
              <FormRow>
                <FormGroup className="col-12">
                  <LabeledFormInput t={t}
                    inputName="email"
                    inputType="email"
                    focusOnMount={this.fieldHasFocusOnMount("email")}
                  />
                </FormGroup>
              </FormRow>
            }
            {
              this.oneOfIsIncludedInFields(['mobile']) &&
              <FormRow>
                <FormGroup className="col-12">
                  <LabeledFormInput t={t}
                    inputName="mobile"
                    inputType="text"
                    focusOnMount={this.fieldHasFocusOnMount("mobile")}
                  />
                </FormGroup>
              </FormRow>
            }
            {
              this.oneOfIsIncludedInFields(['address']) &&
              <React.Fragment>
                <FormRow>
                  <FormGroup className="col-12">
                    <LabeledFormInput
                      t={t}
                      inputName="street"
                      focusOnMount={this.fieldHasFocusOnMount("address")}
                    />
                  </FormGroup>
                </FormRow>
                <FormRow>
                  <FormGroup className="col-sm-4">
                    <LabeledFormInput t={t} inputName="zip" />
                  </FormGroup>
                  <FormGroup className="col-sm-8">
                    <LabeledFormInput t={t} inputName="city" />
                  </FormGroup>
                </FormRow>
              </React.Fragment>
            }
            {
              this.oneOfIsIncludedInFields(['activities']) &&
              <React.Fragment>
                <FormRow>
                  <FormGroup className="col-12">
                    <LabeledFormInput
                      inputName="activities"
                      inputType="select"
                      options={this.props.activities.map(a => ({
                        id: a.id,
                        value: a.id,
                        label: a.name,
                      }))}
                      multi
                      t={t}
                    />
                  </FormGroup>
                </FormRow>

              </React.Fragment>
            }
            {/* <hr /> */}
            <FormRow>
              <FormGroup className="col-12">
                <FormButton buttonComponent={ButtonComponent}>
                  {t('submit.label')}
                </FormButton>
              </FormGroup>
            </FormRow>
          </React.Fragment>
        )}
      </Form>
    )
  }
}

CustomerForm.propTypes = {
  // f: PropTypes.func.isRequired,
  activities: PropTypes.array.isRequired
}

CustomerForm.defaultProps = {
  fields: [
    'name',
    'mobile',
    'email',
    'address',
    // 'activities',
    // 'registrationNumber'
  ],
  customer: {}
}

const ConnectedCustomerForm = connect(state => ({ activities: state.activities }))(CustomerForm);

export default ConnectedCustomerForm;
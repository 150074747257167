import React from 'react'
import PropTypes from 'prop-types'

import { EventToOrderWizardModalStateContext } from 'components/Event/EventToOrderWizardModal'

function EventToOrderWizardModalTrigger({ children }) {
  const { open } = React.useContext(EventToOrderWizardModalStateContext)

  return (
    <span onClick={open}>{children}</span>
  )
}
EventToOrderWizardModalTrigger.propTypes = {
  children: PropTypes.node.isRequired
}

export default EventToOrderWizardModalTrigger
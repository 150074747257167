import event, { contentPriceTypes } from './event'
import form, { continueButton, backButton } from './form'

export default {
  introduction: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "Introduktion",
    },
    assistantComment: {
      header: "Hej!",
      text: "Jag heter Doris och jag skall hjälpa dig att beställa priser till din tävling. Vi kommer tillsammans gå igenom tre steg och när vi är klara så kommer du ha lagt en komplett order."
    },

    stepBoxes: {
      event: {
        header: "Tävling",
        text: "Fyll i namn, datum och klasser.",
        buttonLabel: "Starta"
      },
      products: {
        header: "Priser",
        text: "Välj vilka produkter och tillval",
      },
      order: {
        header: "Orderförslag",
        text: "Doris presenterar ett orderförslag som du kan lägga till i kundvagnen",
      },
    }
  },
  signin: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "Skapa användare",
    },
    assistantComment: {
      header: "",
      text: "Jag har ett ganska enastående minne och sparar all information om din tävling vilket förenklar både årets och nästa års beställning. För att jag skall veta var jag skall spara informationen så behöver du vara en registrerad och inloggad användare. "
    },
  },
  eventDetails: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "Skapa tävling",
    },
    assistantComment: {
      header: "",
      text: "Nu är vi igång. Fyll i uppgifter såsom namn och när tävlingen är. Det är viktig information som jag behöver längre fram."
    },
    customer: {
      event
    },
    inputs: {
      name: "Namn på evenemanget",
      activity: "Typ av sport/aktivitet",
      date: "Tidpunkt"
    },
    button: continueButton,
    form
  },
  eventContests: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Tävlingsstruktur",
    },
    assistantComment: {
      header: "",
      text: "Nu blir jag väldigt glad om du kan hjälpa mig med att fylla i vilka klasser som finns i din tävling samt vilka och hur många priser du skall dela ut."
    },
    contests: event.contests,
    button: continueButton,
  },
  eventSummary: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Tävlingsstruktur",
    },
    assistantComment: {
      header: "",
      text: "Bra jobbat. Nu har jag samlat in all information jag behöver och den är sparad ifall du vill slutföra din order vid senare tillfälle."
    },
    stepBoxes: {
      event: {
        header: "Tävling",
        text: "Fyll i namn, datum och klasser.",
        buttonLabel: "Ändra"
      },
      products: {
        header: "Priser",
        text: "Välj vilka produkter och tillval",
        buttonLabel: "Gå vidare"
      },
      order: {
        header: "Orderförslag",
        text: "Doris presenterar ett orderförslag som du kan lägga till i kundvagnen",
      },
    }
  },
  priceTypeProductsSummary__incomplete: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Priser",
    },
    assistantComment: {
      header: "",
      text: "För att göra det så enkelt som möjligt för dig så har jag grupperat ihop de klasser som skall ha samma produkter. Välj produkt för respektive gruppering."
    },
    contentPriceTypes,
    priceBundleSummary: "Val för {{ commaSeparatedContestNames }}",
    backButton: {
      label: "Ändra tävling"
    },
    submitButton: {
      label: "Skapa orderförslag"
    },
    changeButton: {
      label: "Ändra"
    },
    selectButton: {
      label: "Välj"
    }
  },
  priceTypeProductsSummary__complete: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Priser",
    },
    assistantComment: {
      header: "",
      text: "Wow, nu är vi färdiga med alla val. Nu återstår bara för dig att trycka på knappen “Skapa orderförslag” så kommer jag presentera ett komplett orderförslag för dig."
    },
    contentPriceTypes,
    priceBundleSummary: "Val för {{ commaSeparatedContestNames }}",
    submitButton: {
      label: "Skapa orderförslag"
    },
    backButton: {
      label: "Ändra tävling"
    },
    changeButton: {
      label: "Ändra"
    },
    selectButton: {
      label: "Välj"
    }
  },
  priceTypeProductCategorySelection: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Priser",
    },
    assistantComment: {
      header: "",
      text: "Jag har tagit fram ett par produkttyper som passar din tävling. Vänligen välj en av dessa."
    },
  },
  priceTypeProductSelection: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Priser",
    },
    assistantComment: {
      header: "",
      text: "Jag rekommenderar följande produkter för din tävling. Välj den du gillar mest."
    },
  },
  priceTypeProductFieldSelection: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Priser",
    },
    assistantComment: {
      header: "",
      text: "Nu är det dags för dig att välja tillval. De tillval du väljer gäller alla priser i denna gruppering."
    },
    submitButton: continueButton,
  },

  orderSuggestion: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Orderförslag",
    },
    assistantComment: {
      header: "",
      text: "Här är ditt färdiga orderförslag. När du trycker på knappen “lägg beställning” så läggs alla produkter med dess tillval till kundvagnen. Vill du lägga till andra typer av artiklar såsom lotter eller kläder så gör du det på vanligt sätt. Du kan också ändra på enskilda produkter från orderförslaget i kassan."
    },
    submitButton: {
      label: "Lägg beställning"
    },
    backButton: {
      label: "Ändra priser"
    },
    orderSum: "Summa: {{ value }}"
  },
  complete: {
    header: {
      pre: "Tävlingsbeställning",
      heading: "{{ eventNameAndYear }} - Beställning i varukorgen",
    },
    assistantComment: {
      header: "",
      text: "Nu är vi i mål och du slutför nu köpet precis som vanligt i kassan. Sedan vill jag redan nu tipsa om att ta hjälp av mig även nästa år. Då kommer du att kunna skapa ett orderförslag utifrån den tävlingsstruktur som vi har skapat i år. Då går det ännu fortare!"
    },
    gotoOrderButton: {
      label: "Till kassan"
    },
    gotoHomeButton: {
      label: "Lägg till fler produkter"
    },
  },
}
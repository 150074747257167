import React from 'react';
import { CUSTOMER_TYPES } from 'selectors/customer';
import CustomerForm from 'components/forms/CustomerForm';

export default class CreateIndividualCustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCreated = this.onCreated.bind(this);
  }

  onSubmit(inputValues) {

    return this.props.onSubmit({
      ...inputValues,
      type: CUSTOMER_TYPES.INDIVIDUAL
    })
  }

  onCreated(customerId) {
    this.props.onCreated(customerId)
  }

  render() {
    const { prefilledInputValues = {}, t } = this.props;

    return (

      <CustomerForm
        prefilledInputValues={prefilledInputValues}
        fields={[
          'name',
          'mobile',
          'email',
          'address',
        ]}
        onSubmit={this.onSubmit}
        t={t}
      />
    )
  }
}
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
} from 'actions'

import { OneColumnLayout } from 'components/layouts';
import { Translate } from 'containers/translates';
import PaymentSection from 'components/Order/PaymentSection';

function TestPage3() {
  return (
    <OneColumnLayout>
      <h1>{"Test page 2"}</h1>
      <div>
        <Translate ns="order.sections">
          {() => (
            <PaymentSection
              sectionState="active"
            />
          )}
        </Translate>
      </div>
    </OneColumnLayout>
  )
}

TestPage3.propTypes = {
  customer: PropTypes.object.isRequired,
}

export default connect(({ customer }) => ({ customer }), {
})(TestPage3)
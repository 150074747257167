import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';
import qs from 'qs';

import { scrollToTop } from '../hoc';

import { OneColumnLayout } from '../../components/layouts';
import SideColumn from '../../components/SideColumn';
import DocumentMeta from '../../components/documentMeta';
import { PageHeading } from '../../components/content';

import ProductArchive from '../ProductArchive';

// import {ProductSearchBreadcrumb} from '../../components/ProductBreadcrumbs';

const SearchPage = scrollToTop()(connect(state => ({
  isSignedInAndStaff: state.auth.isSignedIn && state.user && state.user.type === 'staff',
}))(translate('search')(({match, location, isSignedInAndStaff, t}) => {
  const {q} = match.params;

  const filters = {
    q,
    excludeItemsWithoutCategory: (isSignedInAndStaff ? false : true)
  }

  return (
    <div>
      <DocumentMeta
        title={t('title', {query: q})}
      />
      <OneColumnLayout>
        <div>
          <PageHeading>{t('header', {query: q})}</PageHeading>
          <ProductArchive filters={filters} blockConditions={['page:search']}/>
        </div>
      </OneColumnLayout>
    </div>
  );
})));

export default SearchPage;
import React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import routes from '../routes';

import { caseSelector } from '../utils/helpers';

import Slider from '../containers/Slider';


export default connect(state => ({
  slidesToShow: caseSelector({
    small: 2,
    medium: 2,
    large: 3,
    extraLarge: 3,
  }, 1)(state.client.mediaType)
}))(function ActivitySlider({activities, slidesToShow}) {
  return (
    <Slider slidesToShow={slidesToShow}>
      {activities.map(activity => (
        <Link to={routes.ACTIVITY.get(activity.slug)} key={activity.id} className="text-center block-link p-1">
          <div style={{backgroundColor: "white", position: "relative"}}>
            <div style={{height: '200px'}}>
              <div className="BackgroundImage--covered" style={{backgroundImage: `url('${activity.image.processedUrls.slide}')`}}></div>
            </div>
            <div className="text-center" style={{position: "absolute", bottom: "0px", marginBottom: "10px", width: "100%"}}>
              <h2 className="display-4 text-white" style={{}}>{activity.name}</h2>
            </div>
          </div>
        </Link>
      ))}
    </Slider>
  )
})
import React from 'react';

import Modal from './Modal';
import SigninOrRegister from 'containers/steps/SigninOrRegister';

export default function SigninOrRegisterModal({ onClose, onDone, includeCompleteStep, ...props }) {
  return (
    <Modal onClose={onClose} {...props}>
      <div style={{ width: '60vw', maxWidth: 400 }}>
        <SigninOrRegister
          onDone={onDone || onClose}
          includeCompleteStep={includeCompleteStep}
        />
      </div>
    </Modal>
  );
}
export default {
  activities: "Sporter",
  title: "Sporter",
  description: "Lundgrens i Gräfsnäs har idrottspriser till alla sporter",
  menu: {
    header: "Sporter",
    featuredActivities: {
      header: "Vanliga sporter"
    },
    toActivityArchive: {
      label: "Alla sporter"
    }
  },
  sections: {
    featured: {
      header: "Utvalda sporter"
    },
    all: {
      header: "Sporter A-Ö"
    }
  }
}
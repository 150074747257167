import React from 'react'
import PropTypes from 'prop-types'

import { Translate } from 'containers/translates'
import { FormGroup } from "reactstrap"
import Form, { LabeledFormInput, FormRow, FormButton } from 'components/forms/Form'
import Modal, { ModalBody, SMALL } from 'components/modals/Modal'

import ContentHeader from 'components/ContentHeader'

export default class EventContestPriceModal extends React.Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    // onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showNumOfRows: PropTypes.bool,
    numOfRows: PropTypes.number,
    showNumOfCopies: PropTypes.bool,
    numOfCopies: PropTypes.number,
    contest: PropTypes.object.isRequired
  }

  static defaultProps = {
    showNumOfRows: true,
    showNumOfCopies: true
  }

  handleSubmit = ({ numOfCopies, numOfRows }) => {
    this.props.onSubmit({
      numOfCopies,
      numOfRows
    })
  }

  render() {

    const {
      isOpen,
      onCancel,
      onRemove,
      showNumOfRows, numOfRows,
      showNumOfCopies, numOfCopies,
    } = this.props

    const autoFocusNumOfRows = showNumOfRows
    const autoFocusNumOfCopies = !autoFocusNumOfRows && showNumOfCopies

    return (
      <Translate ns="simplifiedDetails">
        {({ t }) => (
          <Modal
            isOpen={isOpen}
            onClose={onCancel}
          >
            <ModalBody size={SMALL}>
              <React.Fragment>
                <ContentHeader context={{
                  eventContestName: this.props.contest.name
                }} />
                <Form
                  t={t}
                  onSubmit={this.handleSubmit}
                  valueRules={[
                    showNumOfRows && {
                      key: "numOfRows",
                      required: true
                    },
                    showNumOfCopies && {
                      key: "numOfCopies",
                      required: true
                    },
                  ].filter(Boolean)}
                  prefilledInputValues={{
                    numOfCopies: numOfCopies,
                    numOfRows: numOfRows
                  }}

                >
                  {() => (
                    <React.Fragment>
                      {
                        showNumOfRows &&
                        <FormRow>
                          <FormGroup className="col-12">
                            <LabeledFormInput
                              {...{ t }}
                              inputName="numOfRows"
                              inputType="number"
                              max={15}
                              focusOnMount={autoFocusNumOfRows}
                            />
                          </FormGroup>
                        </FormRow>
                      }
                      {
                        showNumOfCopies &&
                        <FormRow>
                          <FormGroup className="col-12">
                            <LabeledFormInput
                              {...{ t }}
                              inputName="numOfCopies"
                              inputType="number"
                              focusOnMount={autoFocusNumOfCopies}
                            />
                          </FormGroup>
                        </FormRow>
                      }
                      <FormRow>
                        <FormGroup className="col-12">
                          <FormButton>
                            {t('submit.label')}
                          </FormButton>
                          {true && (
                            <button className="btn btn-outline-dark ml-2" onClick={onRemove}>
                              {t('remove.label')}
                            </button>
                          )}
                        </FormGroup>
                      </FormRow>
                    </React.Fragment>
                  )}
                </Form>
              </React.Fragment>
            </ModalBody>
          </Modal>
        )}
      </Translate>
    )
  }
}

import React from 'react';
import { connect } from 'react-redux';

import { fetchProductCollection } from '../api';

import { waitUntilPromiseIsResolved } from '../containers/hoc';
import { caseSelector } from '../utils/helpers';

import { Link } from 'react-router-dom';
import routes from '../routes';

import ProductSlidesBox from './ProductSlidesBox';


function _CollectionSlidesBox({ name, title, productIds, slidesToShow, onClickProduct }) {
  return (
    <ProductSlidesBox
      header={title}
      slidesToShow={slidesToShow}
      listType={`collection--${name}`}
      productIds={productIds}
      onClickProduct={onClickProduct}
      boxed
    />
  );
}

export default waitUntilPromiseIsResolved(function fetchProductCollectionForSlidesBox({ productCollectionId }) {
  return fetchProductCollection(productCollectionId);
}, (productCollection) => ({
  ...productCollection
}))(connect((state, ownProps) => ({
  slidesToShow: caseSelector({
    small: 3,
    medium: 4,
    large: 5,
    extraLarge: 6,
  }, 2)(state.client.mediaType)
}))(_CollectionSlidesBox));
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import find from 'lodash/find'
import get from 'lodash/get'

import { caseSelector } from 'utils/helpers'

import {
  selectedFieldTemplate,
  fieldVariableInput
} from 'actions'

import { Button, ButtonContainer } from 'components/buttons'

import Modal, { ModalBody, SMALL } from 'components/modals/Modal'

import TemplateTextWidget from './TemplateTextWidget'
import { FREETEXT_SELECTION } from './FreeTextWidget'

import SimpleTextField from './SimpleTextField'

import { Translate } from 'containers/translates'

import { UseTextTemplateContext } from './contexts'


class SubmitWrapper extends React.Component {
  state = {
    state: null
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.state)
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  handleChange = (state) => {
    this.setState({ state })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.initialState === undefined) {
      return {
        initialState: nextProps.initialState,
        state: nextProps.initialState
      }
    }

    return null
  }

  render() {
    const { children, t } = this.props
    return (
      <React.Fragment>
        {
          children({
            ...(this.state.state),
            onChange: this.handleChange
          })
        }
        <hr />
        <ButtonContainer>
          <div className="text-right">
            <Button type="button" onClick={this.handleSubmit}>{t('submitButton.label')}</Button>
            <Button type="button" color="dark" outline onClick={this.handleCancel}>{t('cancelButton.label')}</Button>
          </div>
        </ButtonContainer>
      </React.Fragment>
    )
  }
}

SubmitWrapper.propTypes = {
  initialState: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}


const NO_TEMPLATE_SELECTION = null

const TextFieldWithTemplatesInner = connect(
  (state, ownProps) => {

    let storedSelectedTemplateId = state.userSettings.fieldTemplateIdSelection

    if (!find(ownProps.textTemplates, (x) => x.id === storedSelectedTemplateId)) {
      storedSelectedTemplateId = undefined
    }

    return {
      storedSelectedTemplateId
    }

  },
  {
    selectedFieldTemplate,
    fieldVariableInput

  }
)(class _TextFieldWithTemplatesInner extends React.Component {

  state = {
    selectedTemplateId: NO_TEMPLATE_SELECTION,
    lastStoredSelectedTemplateId: NO_TEMPLATE_SELECTION
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.lastStoredSelectedTemplateId === NO_TEMPLATE_SELECTION) {
      let nextSelectedTemplateId = NO_TEMPLATE_SELECTION

      const prevSelectedTemplateId = get(nextProps.input, ['meta', 'templateId'])

      if (prevSelectedTemplateId !== undefined) {
        nextSelectedTemplateId = prevSelectedTemplateId
      }
      else if (nextProps.storedSelectedTemplateId) {
        nextSelectedTemplateId = nextProps.storedSelectedTemplateId
      }

      if (nextSelectedTemplateId !== NO_TEMPLATE_SELECTION) {
        return {
          lastStoredSelectedTemplateId: nextSelectedTemplateId,
          selectedTemplateId: nextSelectedTemplateId
        }
      }
    }

    return null
  }

  getSelectedTemplate = () => find(
    this.props.textTemplates,
    (x) => x.id === this.state.selectedTemplateId
  )

  handleTemplateSelection = (templateId) => {
    this.setState(
      { selectedTemplateId: templateId },
      () => this.props.selectedFieldTemplate(templateId)
    )
  }

  handleResetTemplateSelection = () => {
    this.setState(
      { selectedTemplateId: NO_TEMPLATE_SELECTION },
      () => this.props.selectedFieldTemplate(NO_TEMPLATE_SELECTION)
    )
  }

  renderTemplateSelection = () => (
    <Translate ns="templateSelection">
      {({ t }) => (
        <React.Fragment>
          <h3>{t('header')}</h3>
          {this.props.textTemplates.map((textTemplate) => (
            <Button
              color="light"
              className={[
                "text-left",
                "align-top",
                "m-1"
              ].join(" ")}
              key={textTemplate.id}
              onClick={() => this.handleTemplateSelection(textTemplate.id)}
            >
              <strong className="d-block">{textTemplate.name}</strong>
              <span style={{
                whiteSpace: "pre-wrap"
              }}>
                {textTemplate.placeholder}
              </span>
            </Button>
          ))}
          <Button
            color="light"
            className={[
              "text-left",
              "align-top",
              "m-1"
            ].join(" ")}
            onClick={() => this.setState({ selectedTemplateId: FREETEXT_SELECTION })}
          >
            <strong className="d-block">{t('freeTextButton.label.header')}</strong>
            <span>{t('freeTextButton.label.description')}</span>
          </Button>
        </React.Fragment>
      )}
    </Translate>
  )

  handleFreeTextChange = (input) => {
    this.props.onChange({
      ...input,
      meta: {
        templateId: FREETEXT_SELECTION,
      }
    })
  }

  renderFreetextField = () => (
    <Translate>
      {({ t }) => (
        <React.Fragment>
          <h3 className="d-flex align-items-center">
            <span>{t('freeText.header')}</span>
            <Button
              outline
              size="sm"
              color="dark"
              className="ml-auto"
              onClick={this.handleResetTemplateSelection}
            >
              {t('switchTemplateButton.label')}
            </Button>
          </h3>
          <hr />
          <SubmitWrapper
            onCancel={this.props.onCancel}
            onSubmit={this.handleFreeTextChange}
            t={t}
            initialState={this.props.input}
          >
            {({ onChange }) => (
              <SimpleTextField
                {...this.props}
                onChange={onChange}
              />
            )}
          </SubmitWrapper>

        </React.Fragment>
      )}
    </Translate>
  )

  handleTemplateInputChange = (input) => {
    this.props.fieldVariableInput(input.meta.variableInputs)
    this.props.onChange(input)
  }

  renderTemplate = () => {
    const template = this.getSelectedTemplate()

    const variableInputs = get(this.props.input, 'meta.variableInputs', {})

    return (
      <Translate>
        {({ t }) => (
          <React.Fragment>
            <h3 className="d-flex align-items-center">
              <span>{template.name}</span>
              <Button
                outline
                size="sm"
                color="dark"
                className="ml-auto"
                onClick={this.handleResetTemplateSelection}
              >
                {t('switchTemplateButton.label')}
              </Button>
            </h3>
            <hr />
            <SubmitWrapper
              onCancel={this.props.onCancel}
              onSubmit={this.handleTemplateInputChange}
              t={t}
              initialState={this.props.input}
            >
              {({ onChange }) => (
                <TemplateTextWidget
                  {...this.props}
                  {...template}
                  variableInputs={variableInputs}
                  onChange={onChange}
                />
              )}
            </SubmitWrapper>



          </React.Fragment>
        )}
      </Translate>
    )

  }

  render() {
    const { selectedTemplateId } = this.state

    return (
      <Translate ns="textField">
        {({ t }) =>
          (
            caseSelector(
              {
                [NO_TEMPLATE_SELECTION]: this.renderTemplateSelection,
                [FREETEXT_SELECTION]: this.renderFreetextField,
              },
              this.renderTemplate
            )(selectedTemplateId)()
          )
        }
      </Translate>
    )
  }
})


class TextFieldWithTemplates extends React.Component {
  state = {
    modalIsOpen: false
  }

  toggleModal = () => {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  handleInputChange = (data) => {
    this.props.onChange(data)
    this.toggleModal()
  }

  handleCancel = () => this.toggleModal()

  render() {

    const { input } = this.props

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.toggleModal}
        >
          <ModalBody size={SMALL}>
            <React.Fragment>
              <h2>{this.props.label}</h2>
              <hr />
              <TextFieldWithTemplatesInner
                {...this.props}
                onChange={this.handleInputChange}
                onCancel={this.handleCancel}
              />
            </React.Fragment>
          </ModalBody>
        </Modal>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 10,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: 3,
            Crsor: "pointer"
          }}
          onClick={this.toggleModal}
        >
          <div
            style={{
              whiteSpace: "pre-wrap"
            }}
          >
            {(() => {
              if (input.variants && input.variants.length > 1) {
                return `${input.variants[0].value} \n...`
              }
              if (input.value) {
                return input.value
              }
              return ''
            })()}
          </div>
          <div style={{
            marginLeft: "auto"
          }}>
            <i className="fa fa-caret-down" />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default function TextField({ textTemplates, ...props }) {
  return (
    <Translate ns="product.productFields">
      {() => (
        <UseTextTemplateContext.Consumer>
          {(useTextTemplates) => {
            return (
              useTextTemplates && !!textTemplates ?
                (
                  <TextFieldWithTemplates
                    textTemplates={textTemplates}
                    {...props}
                  />
                ) :
                (
                  <SimpleTextField
                    {...props}
                  />
                )
            )
          }}
        </UseTextTemplateContext.Consumer>
      )}
    </Translate>
  )
}
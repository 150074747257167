import React from 'react'
import PropTypes from 'prop-types'

import times from 'lodash/times'

import { FormGroup } from 'reactstrap'
import Input from 'components/forms/Input'

export default class TemplateVariableWidget extends React.Component {

  idGenerator = (index) => `${this.props.name}-${index}`

  onChange = (variantIndex, incomingVariantValue) => {
    const nextVariantValues = this.props.variantValues.map(
      (previousVariantValue, _variantIndex) => (
        variantIndex === _variantIndex ?
          incomingVariantValue :
          previousVariantValue
      )
    )
    this.props.onChange(nextVariantValues)
  }

  renderVariant(variantIndex) {
    const variantValue = this.props.variantValues[variantIndex]

    return (
      <Input
        key={variantIndex}
        id={this.idGenerator(variantIndex)}
        onChange={(incomingInput) => this.onChange(variantIndex, incomingInput)}
        value={variantValue}
      />
    )
  }

  render() {
    const { label, numOfVariants } = this.props

    return (
      <FormGroup>
        <React.Fragment>
          <label htmlFor={this.idGenerator(0)}>
            {label}
          </label>
          {
            times(numOfVariants).map((nada, variantIndex) => this.renderVariant(variantIndex))
          }
        </React.Fragment>
      </FormGroup>
    )
  }
}

export const variablePropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

TemplateVariableWidget.propTypes = {
  ...variablePropTypes,
  variantValues: PropTypes.arrayOf(
    PropTypes.string
  ),
  numOfVariants: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

TemplateVariableWidget.defaultProps = {
  numOfVariants: 1,
}
import { PRODUCTS } from '../../actions';

const initState = false;

const isInvalidated = (state = initState, action) => {
  switch(action.type) {
    case PRODUCTS.FETCH.RECEIVE:
      return false;
    case PRODUCTS.FETCH.REQUEST:
    case PRODUCTS.INVALIDATE:
      return true;
    default: 
      return state;
  }
}

export default isInvalidated;
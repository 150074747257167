import React from 'react';
import { withTranslation as translate } from 'react-i18next';

import { withRouter } from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import routes from '../../routes';

import { ModalWithClose } from './Modal';
import BlockArea from '../../containers/BlockArea';

export default withRouter(translate('order')(function _OrderWasSentModel({ order, customer, onClose, t, history, ...props }) {
  React.useEffect(() => {
    history.push(routes.HOME.get())
  }, [])

  return (
    <ModalWithClose
      key="OrderWasSentModel"
      onClose={onClose}
      {...props}
    >
      <BlockArea
        areaSlug="modal-content"
        conditions={['modal:order-was-sent']}
        defaultContent={
          <div>
            <h2>{t('modals.sentOrder.defaultHeader')}</h2>
            <p>{t('modals.sentOrder.defaultMessage')}</p>
          </div>
        }
      />

      <div>
        <button onClick={onClose} className="btn btn-outline-dark">{t('modals.sentOrder.buttons.close')}</button>
      </div>
    </ModalWithClose>
  );
}));
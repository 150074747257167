import React from 'react';
import FilterDropdown from './FilterDropdown';
import { withTranslation as translate } from 'react-i18next';


const ActivitiesFilterDropdown = translate("productArchive")(({
  activities,
  selectedActivitySlug,
  selectActivitySlug,
  t
}) => {

  const fetchOptions = React.useCallback(() => {
    return Promise.resolve(activities.map((activity) => ({
      value: activity.slug,
      label: activity.name,
      count: undefined
    })))
  }, [activities])

  const selectedValues = React.useMemo(() => selectedActivitySlug ? [selectedActivitySlug] : [], [selectedActivitySlug])

  return (
    <FilterDropdown
      label={t('filters.activity.label')}
      fetchOptions={fetchOptions}
      toggleValueSelection={selectActivitySlug}
      selectedValues={selectedValues}
    />
  );
})

export default ActivitiesFilterDropdown
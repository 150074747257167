import find from 'lodash/find';

import { SELECTED_PRODUCT } from '../actions';

const initState = null;

const selectedProduct = (state = initState, action) => {

  switch(action.type) {
    // case SELECTED_PRODUCT.PRE:
    //   return {
    //     selectedArticleIdentifier: action.selectedProduct.defaultArticleIdentifier,
    //     articles: [
    //       {
    //         id: action.selectedProduct.defaultArticleId,
    //         attributeValueIds: {},
    //         optionScopeIds: [],
    //         properties: [],
    //         activityIds: [],
    //         price: action.selectedProduct.defaultPrice,
    //       }
    //     ],
    //     optionScopes: [],
    //     properties: [],
    //     ...action.selectedProduct
    //   };
    case SELECTED_PRODUCT.INVALIDATE:
      return initState;
    case SELECTED_PRODUCT.FETCH.RECEIVE:
      return {
        selectedArticleIdentifier: action.selectedProduct.defaultArticleIdentifier, // ( if action.selectedProduct.selectedArticleIdentifier it will overwrite default )
        ...action.selectedProduct,
      };
    case SELECTED_PRODUCT.CHANGE_ATTRIBUTE:
      return {
        ...state,
        selectedArticleIdentifier: find(
          state.articles,
          (a) => 
            a.attributeValueIds[action.attributeId] &&
            a.attributeValueIds[action.attributeId] === action.attributeValueId
        ).id
      }
    case SELECTED_PRODUCT.CHANGE_SELECTED_ARTICLE:
      return {
        ...state,
        selectedArticleIdentifier: action.articleIdentifier
      }

    default: 
      return state;
  }
};


export default selectedProduct;
import React from 'react';
import { withTranslation as translate } from 'react-i18next';

import { ModalWithClose } from './Modal';

export default translate('product')(({image, onClose, t, ...props}) => {
  return (
    <ModalWithClose onClose={onClose} {...props}>
      <img src={image.url} role="presentation" style={{maxWidth: "100%"}}/>
    </ModalWithClose>
  );
});
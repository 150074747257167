export default {
  header: "Sökresultat för '{{query}}'",
  title: "Sökresultat för '{{query}}'",
  placeholder: "Sök",
  type: {
    label: "<Ingen typ>",
    label_activity: "Sport",
    label_product_category: "Produktkategori",
    label_product: "Produkt",
    label_article: "Artikel",
  }
}
import React from 'react'
import PropTypes from 'prop-types'

import Heading from 'components/Heading'

import { Translate } from 'containers/translates'


export function BareContentHeader({ text, headingNo, pre, post }) {
  return (
    <header>
      {
        pre && <small>{pre}</small>
      }
      <Heading no={headingNo}>{text}</Heading>
      {
        post && <p>{post}</p>
      }
    </header>
  )
}

BareContentHeader.propTypes = {
  text: PropTypes.string.isRequired,
  headingNo: PropTypes.number,
  pre: PropTypes.string,
  post: PropTypes.string,
}

BareContentHeader.defaultProps = {
  headingNo: 2,
}

export default function ContentHeader({ context, withPre, withPost, headingNo }) {

  return (
    <Translate ns="header">
      {({ t }) => (
        <BareContentHeader
          text={t('heading', context)}
          headingNo={headingNo}
          pre={withPre ? t('pre', context) : undefined}
          post={withPost ? t('post', context) : undefined}

        />
      )}
    </Translate>
  )
}

ContentHeader.propTypes = {
  context: PropTypes.object,
  withPre: PropTypes.bool,
  withPost: PropTypes.bool,
  headingNo: PropTypes.number,
}

ContentHeader.defaultProps = {
  context: {},
  withPre: true,
  withPost: true,
}
import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';

import find from 'lodash/find';
import isEqual from 'lodash/isEqual';


import { getProductOptions } from '../../actions';
import { buildOptionSelectionSummary } from '../../selectors/order';
import { fetchArticlePriceBundle } from '../../api';

import { PromiseWrapper } from '../../utils/promises';
import { formatPrice } from '../../utils/content';
import { cleanObject } from '../../utils/object';

import { makeSingleToggable } from '../../containers/hoc';

import { FormGroup } from 'reactstrap';
import Input from '../forms/Input';
import { BaseForm } from '../forms/Form'

import LoadingIndicator from '../spinners';

import ProductOptions from './ProductOptions';
import ProductFields from './ProductFields';
import { setTimeout, clearTimeout } from 'timers';


const optionScopeImageVariants = {
  thumb: {
    h: 65
  }
}

const sortOptionScopes = (optionScopes) => optionScopes.slice(0).sort((o1, o2) => {
  const o1Prio = (o1.isRequired ? 2 : 0) + (o1.isDefault ? 1 : 0);
  const o2Prio = (o2.isRequired ? 2 : 0) + (o2.isDefault ? 1 : 0);
  return o2Prio - o1Prio;
});

const sortAndGroupOptionScopes = (optionScopes) => {
  const sortedOptionScopes = sortOptionScopes(optionScopes);

  const requiredOptionScopes = sortedOptionScopes.filter(({ isRequired }) => isRequired);
  const defaultOptionalOptionScopes = sortedOptionScopes.filter(({ isRequired, isDefault }) => !isRequired && isDefault);
  const secondaryOptionalOptionScopes = sortedOptionScopes.filter(({ isRequired, isDefault }) => !isRequired && !isDefault);

  return [
    requiredOptionScopes,
    defaultOptionalOptionScopes,
    secondaryOptionalOptionScopes
  ];
}

const constructOrderRowOptionSelectionPicks = (currentSelectedOptions, optionScopeIdentifier, optionSelectionIdentifier = null, incomingInputValues = null) => {
  let selectedOptionSelection = null;

  if (optionSelectionIdentifier !== null) {

    const currentSelectedOptionSelection = currentSelectedOptions[optionScopeIdentifier];

    selectedOptionSelection = {
      optionSelectionIdentifier,
      ...cleanObject({
        inputValues: {
          ...(currentSelectedOptionSelection ? currentSelectedOptionSelection.inputValues : {}),
          ...(incomingInputValues || {})
        }
      })
    }
  }

  return cleanObject({
    ...currentSelectedOptions,
    [optionScopeIdentifier]: selectedOptionSelection
  });
}

const ToggleableOptionsSection = (initialValue) => makeSingleToggable(class _ToggleableOptionSection extends React.Component {
  componentDidUpdate(prevProps) {
    const haveDataHasChanged = prevProps.haveData !== this.props.haveData;

    if (this.props.haveData && haveDataHasChanged) {
      this.props.toggle(true);
    }
  }

  render() {
    const { body, header, isActive, toggle } = this.props;

    return (
      <div>
        <header onClick={() => toggle()}>
          {
            isActive ?
              <i className="fa fa-caret-down pull-left" /> :
              <i className="fa fa-caret-right pull-left" />
          } {header}
        </header>

        <div>
          {isActive && body}
        </div>
      </div>
    );
  }
});

const MinifiedToggleableOptionSection = ToggleableOptionsSection(false);
const ExpandedToggleableOptionSection = ToggleableOptionsSection(true);



const ProductArticleForm = connect(({ productOptions }, { optionScopes, isReady }) => {
  const productArticleOptions = isReady ? productOptions.filter(o => optionScopes.map(os => os.optionId).indexOf(o.id) !== -1) : [];

  return {
    options: productArticleOptions
  }
})(translate('product')(class _ProductArticleForm extends React.Component {
  constructor(props) {
    super(props);


    this._updateArticlePriceBundle = this._updateArticlePriceBundle.bind(this);

    this.state = {
      validationErrors: [],
      optionSelectionPicks: {},
      productFieldInputs: [],
      inputValues: {},
      quantity: this.props.minimumQuantity,
      basePrice: 0,
      productFieldInputPrices: [],
      productFieldInputPriceState: undefined
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const wasNotReady = !prevProps.isReady;
    const isReady = this.props.isReady;

    if (wasNotReady && isReady) {
      this.setInitData();
      this.updateArticlePriceBundle();
    }

    const articleIdWasChanged = (this.props.articleId !== prevProps.articleId);
    const productFieldInputsWasChanged = (this.state.productFieldInputs !== prevState.productFieldInputs);
    const quantityWasChanged = (this.state.quantity !== prevState.quantity);

    if (
      articleIdWasChanged ||
      productFieldInputsWasChanged ||
      quantityWasChanged
    ) {
      this.updateArticlePriceBundle();
    }
  }

  componentDidMount() {
    if (this.props.isReady) {
      this.setInitData();
      this.updateArticlePriceBundle();
    }
  }

  setInitData() {

    const {
      transformIncomingValuesToState = (props) => ({})
    } = this.props;

    const incomingValuesState = transformIncomingValuesToState(this.props)
    this.setState(incomingValuesState);
  }

  onChangeQuantity = (e, ...args) => {
    const newQuantity = Number(e.target.value);

    this.setState({
      quantity: newQuantity
    });
  }

  onChangeProductFieldInput = (productFieldId, productFieldIndex, productFieldInput) => {

    this.setState((state) => {

      const unChangedProductFieldInputs = state.productFieldInputs.filter(x => x.productFieldId !== productFieldId);
      const newProductFieldInput = {
        productFieldId,
        ...productFieldInput
      };

      const updatedProductFieldInputs = [
        ...unChangedProductFieldInputs.slice(0, productFieldIndex),
        newProductFieldInput,
        ...unChangedProductFieldInputs.slice(productFieldIndex)
      ];

      return {
        productFieldInputs: updatedProductFieldInputs
      }
    }, () => {

      const productField = find(this.props.productFields, x => x.productFieldId === productFieldId);

      if (this.props.onFieldInput) {
        const { settingInputs, ...fieldInput } = productFieldInput;
        this.props.onFieldInput(productField.fieldId, fieldInput);

        settingInputs.forEach(({ settingId: settingFieldId, ...settingFieldInput }) => {
          this.props.onFieldInput(settingFieldId, settingFieldInput);
        })

      }
    });
  }

  _updateArticlePriceBundle = () => {

    this._updateArticlePriceBundleTimeout = null;

    this.setState({
      productFieldInputPriceState: 'loading'
    })

    this.wrappedFetchPromise = new PromiseWrapper(
      fetchArticlePriceBundle(
        this.props.articleId,
        {
          productFieldInputs: this.state.productFieldInputs,
          quantity: this.state.quantity
        }
      )
    );

    this.wrappedFetchPromise.promise
      // .then(data => new Promise(r => setTimeout(() => r(data), 1000)))
      .then(data => {
        const {
          productFieldInputPrices,
          price,

        } = data.item;

        this.setState({
          productFieldInputPrices,
          basePrice: price,
          productFieldInputPriceState: 'loaded'
        })

        this.wrappedFetchPromise = null;
      })
      .catch((msg) => {

        this.setState({
          productFieldInputPriceState: 'error'
        })

        this.wrappedFetchPromise = null;
      });
  }

  updateArticlePriceBundle() {

    if (this.wrappedFetchPromise) {
      this.wrappedFetchPromise.cancel();
    }

    if (this._updateArticlePriceBundleTimeout) {
      clearTimeout(this._updateArticlePriceBundleTimeout);
    }

    this._updateArticlePriceBundleTimeout = setTimeout(this._updateArticlePriceBundle, 500);

    if (!this.state.productFieldInputPriceState !== 'invalid') {
      this.setState({
        productFieldInputPriceState: 'invalid'
      });
    }
  }

  onResetProductFieldInput = (productFieldId) => {
    this.setState((state) => {
      return {
        productFieldInputs: state.productFieldInputs.filter(x => x.productFieldId !== productFieldId)
      }
    })
  }

  onChangeOptionSelectionPick = (optionScopeIdentifier, optionSelectionIdentifier, inputValues = {}) => {

    this.setState(state => {

      const nextOptionSelectionPicks = constructOrderRowOptionSelectionPicks(state.optionSelectionPicks, optionScopeIdentifier, optionSelectionIdentifier, inputValues);
      return {
        optionSelectionPicks: nextOptionSelectionPicks
      }
    });

    if (this.props.onChangeOptionSelectionPick !== undefined) {
      this.props.onChangeOptionSelectionPick(optionScopeIdentifier, optionSelectionIdentifier, inputValues);
    }
  }

  onResetOptionSelectionPick = (optionScopeIdentifier) => {
    this.setState(state => ({
      optionSelectionPicks: constructOrderRowOptionSelectionPicks(state.optionSelectionPicks, optionScopeIdentifier, null)
    }));

    if (this.props.onResetOptionSelectionPick !== undefined) {
      this.props.onResetOptionSelectionPick(optionScopeIdentifier);
    }
  }

  onChangeInputValue = (inputId, inputValue = null) => {
    this.setState(state => ({
      inputValues: {
        ...state.inputValues,
        [inputId]: inputValue
      }
    }));
  }

  validateOptionSelectionPicks = (optionScopes, optionSelectionPicks) => {

    const { t } = this.props;

    const validationResults = optionScopes.map(optionScope => {
      const optionScopeIdentifier = optionScope.identifier;
      const selectedOption = optionSelectionPicks[optionScopeIdentifier];

      let validates = true;
      let errorMsg = null;
      let optionId = optionScope.optionId;

      if (optionScope.isRequired && selectedOption === undefined) {
        validates = false;
        errorMsg = t('validationError.option.isRequired', { name: optionScope.name });
      }

      return {
        optionId,
        validates,
        errorMsg
      }
    });

    return validationResults.filter(({ validates }) => !validates);
  }

  onSubmit = (e) => {

    e.preventDefault();

    const { inputs, onSubmit, optionScopes, options } = this.props;
    const { quantity, optionSelectionPicks, productFieldInputs } = this.state;

    const validationErrors = this.validateOptionSelectionPicks(optionScopes, optionSelectionPicks);

    if (validationErrors.length === 0) {

      const transformedOptionSelectionPicks = Object.keys(optionSelectionPicks).map(optionScopeIdentifier => {
        const _selectedOption = optionSelectionPicks[optionScopeIdentifier];
        const optionScope = find(optionScopes, os => os.identifier === optionScopeIdentifier);

        const option = find(options, o => o.id === optionScope.optionId);
        const optionSelection = option && find(option.selections, s => s.identifier === _selectedOption.optionSelectionIdentifier);
        const inputIds = Object.keys(_selectedOption.inputValues);

        const selectedOption = {
          optionId: optionScope.optionId,
          optionScopeId: optionScope.id,
          selectionId: optionSelection.id,
          inputs: inputIds.map(inputId => ({
            inputId: Number(inputId),
            value: _selectedOption.inputValues[inputId]
          }))
        };

        return {
          ...selectedOption,
          summary: buildOptionSelectionSummary(selectedOption, optionScope, option)
        }
      });

      const unitPrice = this.getUnitPrice();

      const inputValues = Object.keys(this.state.inputValues).map(inputIdStr => {
        const inputId = Number(inputIdStr);
        const input = find(inputs, i => i.id === inputId);

        return {
          inputId,
          name: input.name,
          value: this.state.inputValues[inputId]
        }
      })

      onSubmit(quantity, unitPrice, inputValues, transformedOptionSelectionPicks, productFieldInputs);

    }

    this.setState({ validationErrors });

    return false;
  }

  getSelectionPrice(optionScopeIdentifier, { optionSelectionIdentifier }) {
    const optionScope = find(this.props.optionScopes, os => os.identifier === optionScopeIdentifier);
    const option = find(this.props.options, o => o.id === optionScope.optionId);
    const selection = find(option.selections, s => s.identifier === optionSelectionIdentifier);

    return (selection && selection.price || 0);
  }

  getUnitPrice() {
    // const { price } = this.props;
    const { optionSelectionPicks, productFieldInputPrices, basePrice } = this.state;

    const quantity = Math.max(this.state.quantity, 1);

    // const pricesForQuantity = price.data.filter(x => x.min_qty <= quantity);
    // const [{price: basePrice}] = sortBy(pricesForQuantity, x => x.price);

    const optionSelectionPicksPriceSum = Object.keys(optionSelectionPicks).map(optionScopeIdentifier => {
      return this.getSelectionPrice(optionScopeIdentifier, optionSelectionPicks[optionScopeIdentifier])
    }).reduce((accum, selectionPrice) => accum + selectionPrice, 0);

    const productFieldInputsPriceSum = productFieldInputPrices
      .filter(productFieldInputPrice => ('additionalPriceCase' in productFieldInputPrice))
      .reduce(
        (accum, productFieldInputPrice) => accum + productFieldInputPrice.additionalPriceCase.price,
        0
      );

    return (
      basePrice +
      optionSelectionPicksPriceSum +
      productFieldInputsPriceSum
    )
  }

  getPriceSum() {
    const unitPrice = this.getUnitPrice();
    const { quantity } = this.state;
    return unitPrice * quantity;
  }

  render() {
    if (!this.props.isReady) {
      return <LoadingIndicator />;
    }

    const { minimumQuantity, maximumQuantity, inputs, price, optionScopes, productFields, t } = this.props;
    const {
      optionSelectionPicks,
      productFieldInputs,
      productFieldInputPrices,
      productFieldInputPriceState,
      inputValues,
      validationErrors,
      quantity
    } = this.state;

    const [
      requiredOptionScopes,
      defaultOptionalOptionScopes,
      secondaryOptionalOptionScopes
    ] = sortAndGroupOptionScopes(optionScopes);

    const secondaryOptionalOptionScopesHaveData = find(
      secondaryOptionalOptionScopes.map(os => optionSelectionPicks[os.identifier] !== undefined),
      Boolean
    ) !== undefined;

    const sharedProductOptionsProps = {
      optionSelectionPicks: optionSelectionPicks,
      imageVariants: optionScopeImageVariants,
      onChange: this.onChangeOptionSelectionPick,
      onReset: this.onResetOptionSelectionPick
    }

    return (
      <BaseForm onSubmit={this.onSubmit}>
        <div>

          {
            requiredOptionScopes.length > 0 &&
            <div>

              <ProductOptions
                key="required-options"
                optionScopes={requiredOptionScopes}
                {...sharedProductOptionsProps}
              />

              <hr />
            </div>
          }

          {
            defaultOptionalOptionScopes.length > 0 &&
            <div>
              <h2>{t('form.options.default.header')}</h2>
              <ProductOptions
                key="default-optional-options"
                optionScopes={defaultOptionalOptionScopes}
                {...sharedProductOptionsProps}
              />
              <hr />
            </div>
          }

          {
            secondaryOptionalOptionScopes.length > 0 &&
            <div>

              <MinifiedToggleableOptionSection header={
                <h3>{t('form.options.secondary.header')}</h3>
              } body={
                <ProductOptions
                  key="secondary-optional-options"
                  optionScopes={secondaryOptionalOptionScopes}
                  {...sharedProductOptionsProps}
                />
              } haveData={secondaryOptionalOptionScopesHaveData}
              />

              <hr />
            </div>
          }

          {
            inputs !== undefined && inputs.length > 0 &&
            <div>
              {inputs.map(input => (
                <FormGroup key={input.id}>
                  <h4>{input.name}</h4>
                  <Input
                    type="textarea"
                    placeholder={input.name}
                    value={inputValues[input.id] || ""}
                    onChange={inputValue => this.onChangeInputValue(input.id, inputValue || null)}
                  />
                </FormGroup>
              ))}
              <hr />
            </div>
          }

          {
            productFields !== undefined && productFields.length > 0 &&
            productFieldInputs !== undefined &&
            <div>
              <ProductFields
                productFields={productFields}
                productFieldInputs={productFieldInputs}
                productFieldInputPrices={productFieldInputPrices}
                productFieldInputPriceState={productFieldInputPriceState}
                onChange={this.onChangeProductFieldInput}
                onReset={this.onResetProductFieldInput}
              />
            </div>
          }

          <FormGroup className="text-center text-md-left">

            <label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={minimumQuantity} max={maximumQuantity}
                  required
                  value={quantity || ''}
                  onChange={this.onChangeQuantity}
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    á {formatPrice(this.getUnitPrice(), 'kr')}
                  </div>
                </div>
              </div>
            </label>

            <div>
              {validationErrors.map(({ optionId, errorMsg }) => (
                <p key={optionId} style={{ color: 'red' }}>{errorMsg}</p>
              ))}
            </div>

            {this.props.children}

          </FormGroup>
        </div>
      </BaseForm>
    );
  }
}));

export default connect(({ productOptions }, { optionScopes }) => {

  const allOptionIds = optionScopes.map(({ optionId }) => optionId);

  function inAllOptions(o) {
    return allOptionIds.indexOf(o.id) !== -1;
  }

  function getIds(collection) {
    return collection.map(({ id }) => id);
  }

  const fetchedOptionIds = getIds(productOptions.filter(o => o.state === 'fetched' && inAllOptions(o)));
  const requestedOptionIds = getIds(productOptions.filter(o => o.state === 'requested' && inAllOptions(o)));
  const fetchedOrRequestedOptionIds = getIds(productOptions.filter(o => inAllOptions(o)));

  const notRequestedOptionIds = allOptionIds.filter(id => fetchedOrRequestedOptionIds.indexOf(id) === -1);

  const isReady = fetchedOptionIds.length === allOptionIds.length;

  return {
    notRequestedOptionIds,
    fetchedOptionIds: productOptions.map(o => o.id),
    isReady
  }

}, {
    getProductOptions
  })(class _ProductArticleFormWrapper extends React.Component {

    getProductOptionsIfNecessary(fromUpdate) {
      const { notRequestedOptionIds } = this.props;

      if (notRequestedOptionIds.length > 0) {
        this.props.getProductOptions(
          notRequestedOptionIds,
          optionScopeImageVariants
        );
      }
    }

    componentDidMount() {
      this.getProductOptionsIfNecessary(false);
    }

    componentDidUpdate(prevProps) {

      const isReadyDiffers = this.props.isReady !== prevProps.isReady;
      const notRequestedOptionIdsDiffers = !isEqual(this.props.notRequestedOptionIds, prevProps.notRequestedOptionIds);

      if (isReadyDiffers || notRequestedOptionIdsDiffers) {
        this.getProductOptionsIfNecessary(true);
      }
    }

    render() {
      const {
        getProductOptions,
        notRequestedOptionIds,
        fetchedOptionIds,
        ...props
      } = this.props;

      return (
        <ProductArticleForm
          {...props}
        />
      );
    }
  });
import React from 'react';
import Input from '../forms/Input';

const ProductOptionSelectionInput = ({name, value, onChange, type = "textarea", ...props}) => {
  return (
    <Input 
      type={type}
      placeholder={name}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}


const ProductOptionSelectionInputs = ({inputs, getInputValue, onChange, onReset}) => {
  
  const _onChange = (inputId, inputValue) => {
    if(inputValue) {
      onChange({
        [inputId]: inputValue
      });
    }
    else {
      onReset();
    }
  }

  return (
    <div>
      {inputs.map(input => {
        const {type, ...inputProps} = input

        const inputTypeProps = {
          'string': {
            type: 'text'
          },
          'big-string': {
            type: 'textarea'
          },
          'number': {
            type: 'number',
          }
        }[type] || 'text';

        return (
          <div key={input.id} className="my-1">
            <ProductOptionSelectionInput 
              value={getInputValue(input.id)}
              onChange={_onChange.bind(null, input.id)}
              {...inputProps}
              {...inputTypeProps}
            />
          </div>
        )
      })}
    </div>
  );
}

export default ProductOptionSelectionInputs;
import React from 'react';
import Helmet from 'react-helmet';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom'

import { waitUntilSatisfyingState } from './hoc';

import Messages from './Messages';
import Modals from './Modals';

import Top from '../components/Top';
import Footer from '../components/Footer';

import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import BlockPage from './pages/BlockPage';
import ProductCategoryPage from './pages/ProductCategoryPage';
import ProductDetailPage from './pages/ProductDetailPage';
import ActivityArchivePage from './pages/ActivityArchivePage';
import ActivityPage from './pages/ActivityPage';
import OrderPage from './pages/OrderPage';
import UserPage from './pages/UserPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import CustomerPage from './pages/CustomerPage';

import { ActivityIdInUserSettingsProvider } from '../components/contexts/ActivityIdContext'

import { GlobalEventStateProvider } from 'components/Event/EventStateContext'
import { createDummyEvent } from 'components/Event/data'
import { EventToOrderWizardModalAndStateWrapper } from 'components/Event/EventToOrderWizardModal'

import routes from '../routes';
import {
  setUseDate
} from '../actions';
import { connect } from 'react-redux';
import moment from '../../node_modules/moment/moment';


import './App.scss';
import TestPage from './pages/TestPage';
import TestPage2 from './pages/TestPage2';
import TestPage3 from './pages/TestPage3';

function Wrapper({ children }) {
  return (
    <div>
      <Helmet
        link={[
          { rel: "apple-touch-icon", sizes: "57x57", href: require('../images/favicon/apple-icon-57x57.png') },
          { rel: "apple-touch-icon", sizes: "60x60", href: require('../images/favicon/apple-icon-60x60.png') },
          { rel: "apple-touch-icon", sizes: "72x72", href: require('../images/favicon/apple-icon-72x72.png') },
          { rel: "apple-touch-icon", sizes: "76x76", href: require('../images/favicon/apple-icon-76x76.png') },
          { rel: "apple-touch-icon", sizes: "114x114", href: require('../images/favicon/apple-icon-114x114.png') },
          { rel: "apple-touch-icon", sizes: "120x120", href: require('../images/favicon/apple-icon-120x120.png') },
          { rel: "apple-touch-icon", sizes: "144x144", href: require('../images/favicon/apple-icon-144x144.png') },
          { rel: "apple-touch-icon", sizes: "152x152", href: require('../images/favicon/apple-icon-152x152.png') },
          { rel: "apple-touch-icon", sizes: "180x180", href: require('../images/favicon/apple-icon-180x180.png') },
          { rel: "icon", type: "image/png", sizes: "192x192", href: require('../images/favicon/android-icon-192x192.png') },
          { rel: "icon", type: "image/png", sizes: "32x32", href: require('../images/favicon/favicon-32x32.png') },
          { rel: "icon", type: "image/png", sizes: "96x96", href: require('../images/favicon/favicon-96x96.png') },
          { rel: "icon", type: "image/png", sizes: "16x16", href: require('../images/favicon/favicon-16x16.png') },
        ]}

        meta={[
          { name: "msapplication-TileColor", content: "#ffffff" },
          { name: "msapplication-TileImage", content: "/ms-icon-144x144.png" },
          { name: "theme-color", content: "#ffffff" },
        ]}
      />
      {children}
    </div>
  )
}


function FullPageLayout({ children }) {
  return (
    <div>
      <div className="container-fluid-without-gutter">
        <div id="Wrapper">
          <Top />
          <div id="Body" className="flex-1">
            {children}
          </div>
          <footer className="">
            <Footer />
          </footer>
        </div>
      </div>
      <Messages />
      <Modals />
    </div>
  )
}

function BareLayout({ children }) {
  return (
    <div>
      {children}
    </div>
  )
}


function SimpleLayoutWithCenteredContent({ children }) {
  return (
    <div className="full-size--with-centered-content">
      <div>
        {children}
      </div>
    </div>
  )
}

function AppRoute({ layoutComponent: Layout, component: Component, ...routeProps }) {
  return (
    <Route
      {...routeProps}
      render={(componentProps) => (
        <Layout>
          <Component {...componentProps} />
        </Layout>
      )}
    />
  )
}

const OrderUseDateCleaner = withRouter(connect(state => ({
  order: state.order,
}), { setUseDate })(function (props) {
  React.useEffect(() => {
    if (props.order.useDate) {
      const now = moment().startOf("day")
      const useDate = moment(props.order.useDate)

      if (useDate.isBefore(now)) {
        props.setUseDate(null);
      }
    }
  }, [props.order.useDate])

  return null
}))

const InnerApp = withRouter(function InnerApp(props) {
  return (
    <React.Fragment>
      <ActivityIdInUserSettingsProvider>
        <GlobalEventStateProvider
        // initialEvent={createDummyEvent({ name: "Testevent" })}
        >
          <EventToOrderWizardModalAndStateWrapper>
            <Wrapper>
              <Switch>
                <AppRoute exact path='/' component={HomePage} layoutComponent={FullPageLayout} />
                <AppRoute path={routes.BLOCK_PAGE.route()} component={BlockPage} layoutComponent={FullPageLayout} />

                <AppRoute exact path={routes.ACTIVITY_ARCHIVE.route()} component={ActivityArchivePage} layoutComponent={FullPageLayout} />
                <AppRoute path={routes.ACTIVITY.route()} component={ActivityPage} layoutComponent={FullPageLayout} />

                <AppRoute path={routes.PRODUCT_CATEGORY.route()} component={ProductCategoryPage} layoutComponent={FullPageLayout} />
                <AppRoute path={routes.PRODUCT_DETAIL.route()} component={ProductDetailPage} layoutComponent={FullPageLayout} />

                <AppRoute path={routes.SEARCH.route()} component={SearchPage} layoutComponent={FullPageLayout} />
                <AppRoute exact path={routes.ORDER.route()} component={OrderPage} layoutComponent={FullPageLayout} />
                <AppRoute path={routes.USER.ROOT.route()} component={UserPage} layoutComponent={FullPageLayout} />

                <AppRoute path={routes.CUSTOMER.route()} component={CustomerPage} layoutComponent={FullPageLayout} />

                <AppRoute exact path={routes.RESET_PASSWORD.route()} component={ResetPasswordPage} layoutComponent={SimpleLayoutWithCenteredContent} />

                <AppRoute exact path="/test" component={TestPage} layoutComponent={BareLayout} />
                <AppRoute exact path="/test2" component={TestPage2} layoutComponent={BareLayout} />
                <AppRoute exact path="/test3" component={TestPage3} layoutComponent={BareLayout} />
              </Switch>
            </Wrapper>
          </EventToOrderWizardModalAndStateWrapper>
        </GlobalEventStateProvider>
      </ActivityIdInUserSettingsProvider>
      <OrderUseDateCleaner />
    </React.Fragment>
  )
})


export default waitUntilSatisfyingState(
  (state) => {
    const haveNecessaryData = (
      state.activities.length > 0 &&
      state.productCategories.length > 0
    );
    return haveNecessaryData;
  },
  () => ({})
)(function App() {
  return (
    <Router >
      <InnerApp />
    </Router>
  )
});
import React from 'react';
import Slider from 'react-slick';
import BlockArea from './BlockArea';
import { withTranslation as translate } from 'react-i18next';

import './Slider.scss';

const defaultSliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
}

export const BlocksSlider = translate()(({blockConditions, t, ...restProps}) => (
  <BlockArea 
    areaSlug="slides"
    conditions={blockConditions}
    className="Slides"
    blockClassName="Slides_Item"
    WrapperComponent={SliderWrapper}
    defaultContent={<div/>}
    {...defaultSliderSettings}
    {...restProps}
  />
));


const SliderWrapper = ({children, className, ...restProps}) => {
  return (
    children.length > 1 ? 
      <div className="SliderWrapper">
        <Slider {...{
            ...defaultSliderSettings,
            ...restProps
          }} className={className}>
          {children}
        </Slider>
      </div> : 
      <div className={className}>
        {children}
      </div>
  );
}

function _emptyCallback() { return null; }

export const ImageSlider = ({images, onClick: onClickDefault, className, itemClassName, ...restProps}) => (
  <SliderWrapper className={`Slider Slider--images ${className || ''}`} {...restProps}>
    {images.map(({onClick = null, ...image}, index) => (
      <div key={index} className={`SliderItem ${itemClassName || ''}`} onClick={(e) => (onClick || onClickDefault || _emptyCallback)(image, e)}>
        <div>
          <img
            src={image.url}
            alt={image.name}
          />
        </div>
      </div>
    ))}
  </SliderWrapper>
)

export default SliderWrapper;
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import find from 'lodash/find';
import entries from 'lodash/entries'

import { Row, Col } from 'reactstrap';

import {
  addOrderRow,
  pickedOptionScopeSelection, resetPickedOptionScopeSelection,
  fieldInput,
  resetFieldInput
} from '../../actions';

import { Sheet } from '../layouts';
import { PageHeading } from '../content';

import ProductDetailAttributes from './ProductDetailAttributes';
import { ProductDetailBreadcrumb } from '../ProductBreadcrumbs';
import ProductDetailDescription from './ProductDetailDescription';
import ProductDetailImages from './ProductDetailImages';
import ProductArticleForm from './ProductArticleForm';
import ProductAttributePackageWizard from './ProductAttributePackageWizard'

import CollectionSlidesBox from '../CollectionSlidesBox';

import './ProductDetail.scss';

export default connect((state) => {
  const product = state.selectedProduct;
  const article = find(product.articles, a => a.identifier === product.selectedArticleIdentifier);

  return {
    product,
    article,
    nextOrderRowId: state.order.nextRowId,
    optionScopeSelectionPicks: state.userSettings.optionScopeSelectionPicks,
    fullProductFields: state.productFields,
    userFieldInputs: state.userSettings.fieldInputs
  }
}, {
    addOrderRow,
    pickedOptionScopeSelection,
    resetPickedOptionScopeSelection,
    fieldInput, resetFieldInput
  })(translate('product')(class _ProductDetailWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.onSubmitArticleForm = this.onSubmitArticleForm.bind(this);
      this.onChangeOptionSelectionPick = this.onChangeOptionSelectionPick.bind(this);
      this.onResetOptionSelectionPick = this.onResetOptionSelectionPick.bind(this);

      this.onFieldInput = this.onFieldInput.bind(this);
      this.onFieldReset = this.onFieldReset.bind(this);
    }

    onSubmitArticleForm(quantity, unitPrice, inputValues, optionSelectionPicks, productFieldInput) {
      this.props.addOrderRow(
        this.props.nextOrderRowId,
        this.props.article.id,
        quantity,
        unitPrice,
        inputValues,
        optionSelectionPicks,
        productFieldInput,
        true,
      );

      if (this.props.postAddToCart) {
        this.props.postAddToCart(unitPrice, quantity);
      }
    }

    onChangeOptionSelectionPick(optionScopeIdentifier, optionSelectionIdentifier, inputValues) {
      this.props.pickedOptionScopeSelection(optionScopeIdentifier, optionSelectionIdentifier, inputValues);
    }

    onResetOptionSelectionPick(optionScopeIdentifier) {
      this.props.resetPickedOptionScopeSelection(optionScopeIdentifier);
    }


    onFieldInput(fieldId, input) {
      this.props.fieldInput(fieldId, input);
    }

    onFieldReset(fieldId) {
      this.props.resetFieldInput(fieldId);
    }

    transformIncomingValuesToState = (productArticleFormProps) => {
      const { optionScopeSelectionPicks } = this.props;
      const { optionScopes, options } = productArticleFormProps;

      const initialOptionSelectionPicks = optionScopes
        .map(os => {
          const selectionPick = optionScopeSelectionPicks[os.identifier];
          if (selectionPick !== undefined) {
            const option = find(options, o => o.id === os.optionId);
            const selection = find(option.selections, s => s.identifier === selectionPick.optionSelectionIdentifier);

            if (selection !== undefined) {
              return {
                [os.identifier]: {
                  optionSelectionIdentifier: selection.identifier,
                  inputValues: {
                    ...(selectionPick.inputValues || {})
                  }
                }
              }
            }
          }

          return null;
        })
        .filter(v => v !== null)
        .reduce((accum, optionScopeSelectionPick) => {
          return {
            ...accum,
            ...optionScopeSelectionPick
          }
        }, {});


      const { fullProductFields, userFieldInputs } = this.props;
      const { productFields } = productArticleFormProps;

      const userFieldInputIds = userFieldInputs.map(x => x.fieldId);
      const productFieldInputs = productFields
        .filter(x => userFieldInputIds.indexOf(x.fieldId) !== -1)
        .map(({ productFieldId, fieldId, ...more }) => {
          const { fieldId: _, ...userFieldInput } = find(userFieldInputs, x => x.fieldId === fieldId)
          const productField = find(fullProductFields, x => x.id === productFieldId)

          return {
            ...more,
            productFieldId,
            fieldId,
            productField,
            userFieldInput,
          }
        })
        // .filter( // Do not use stored values if value rendered from not used template
        //   ({ productField, userFieldInput }) => {
        //     return (
        //       (!productField.textTemplates || !productField.textTemplates.length) ||
        //       [
        //         FREETEXT_SELECTION,
        //         ...productField.textTemplates.map((x) => x.id)
        //       ].indexOf(
        //         get(userFieldInput, 'meta.templateId')
        //       ) !== -1
        //     )
        //   }
        // )
        .map(({ productFieldId, productField, userFieldInput }) => {
          const settingInputs = (productField.settings || [])
            .filter(x => userFieldInputIds.indexOf(x.id) !== -1)
            .map(
              ({ id: settingFieldId }) => {
                const { fieldId, ...userFieldSettingInput } = find(userFieldInputs, x => x.fieldId === settingFieldId);

                return {
                  settingId: settingFieldId,
                  ...userFieldSettingInput
                }
              }
            )

          return {
            productFieldId,
            ...userFieldInput,
            settingInputs
          }
        });

      return {
        optionSelectionPicks: initialOptionSelectionPicks,
        productFieldInputs
      };
    }

    renderFormContent() {
      const {
        product,
        article,
        changeProductAttribute,
        t
      } = this.props;

      const articleOptionScopes = product.optionScopes.filter(os => article.optionScopeIds.indexOf(os.id) !== -1);

      return (
        <React.Fragment>
          <ProductDetailAttributes
            key="attributes"
            attributes={product.attributes}
            article={article}
            articles={product.articles}
            onChange={changeProductAttribute}
          />
          <ProductArticleForm
            articleId={article.id}
            minimumQuantity={product.minimumOrderAmount}
            maximumQuantity={9999}
            price={article.price}
            inputs={(product.inputs || []).filter(x => article.inputIds.indexOf(x.id) !== -1)}
            optionScopes={articleOptionScopes}
            productFields={product.productFields}
            onSubmit={this.onSubmitArticleForm}
            onChangeOptionSelectionPick={this.onChangeOptionSelectionPick}
            onResetOptionSelectionPick={this.onResetOptionSelectionPick}
            transformIncomingValuesToState={this.transformIncomingValuesToState}
            onFieldInput={this.onFieldInput}
            onFieldReset={this.onFieldReset}
          >
            <button className="btn btn-primary btn-lg" type="submit">{t('addToCart')}</button>
          </ProductArticleForm>
        </React.Fragment>
      )
    }

    renderFormWithAttributePackageMode() {
      const {
        product,
      } = this.props;

      const [
        [firstAttributeIdWithPackageMode, firstAttributePackageMode] = [],
      ] = entries(product.attributePackageModes)

      return (
        <React.Fragment>
          <div className="mb-1">
            <ul className="nav nav-tabs nav-fill px-4 mt-3">
              <li className="nav-item">
                <span
                  className="nav-link active bg-transparent"
                  style={{ borderBottomColor: "#f6f6f6" }}
                  href="javascript:;">
                  {firstAttributePackageMode.displayProps.singleOrderButton}
                </span>
              </li>
              <li className="nav-item">
                <ProductAttributePackageWizard
                  productId={product.id}
                  articles={product.articles}
                  attributeId={Number(firstAttributeIdWithPackageMode)}
                  attributeValueIds={product.attributeValueIds[firstAttributeIdWithPackageMode]}
                  productFields={product.productFields}
                  mode={firstAttributePackageMode}
                  product={product}
                  renderModalButton={({ toggle }) => (
                    <a className="nav-link" href="javascript:;" onClick={toggle}>
                      <i className="fa fa-external-link fa-fw" />
                      {firstAttributePackageMode.displayProps.packageOrderButton}
                    </a>
                  )}
                />
              </li>
            </ul>
          </div>

          <div className="p-4">
            {this.renderFormContent()}
          </div>
        </React.Fragment>
      )
    }

    renderFormWithoutAttributePackageMode() {
      const {
        t,
        product,
      } = this.props
      return (
        <div className="px-4 pb-4 p-md-4">
          <h3 className="text-primary">{t('form.header', { productName: product.name })}</h3>
          <div className="mt-2">
            {this.renderFormContent()}
          </div>
        </div>
      )
    }

    render() {
      const {
        product,
        article,
      } = this.props;

      const allImages = [];
      if (article.image) {
        allImages.push(article.image);
      }
      else if (product.image) {
        allImages.push(product.image);
      }
      allImages.push(...product.images);

      const haveImages = allImages.length > 0;

      const [
        [firstAttributeIdWithPackageMode, firstAttributePackageMode] = [],
      ] = entries(product.attributePackageModes)
      const haveAttributePackageModes = !!firstAttributeIdWithPackageMode

      return (
        <div>
          {
            product.mainCategoryId &&
            <ProductDetailBreadcrumb productName={product.name} categoryId={product.mainCategoryId} />
          }
          <PageHeading>{product.name}</PageHeading>

          <Sheet className="ProductSheet mt-4">

            <Row className="row--no-gutter">
              <Col xs={12} md={7} lg={8}>
                <Row className="row--no-gutter">
                  {
                    haveImages &&
                    <Col xs={12} lg={6}>
                      <div className="p-4">
                        <ProductDetailImages
                          productName={product.name}
                          images={allImages}
                          urlSelector={image => ({
                            name: product.name,
                            url: image.processedUrls.slide
                          })}
                        />
                      </div>
                    </Col>
                  }

                  {
                    <Col xs={12} lg={haveImages ? 6 : 12}>
                      <div className="px-4 p-md-4 ">
                        <hr className="d-lg-none" />
                        <ProductDetailDescription
                          {...product}
                          article={article}
                        />
                      </div>
                    </Col>
                  }
                </Row>
              </Col>

              <Col xs={12} md={5} lg={4} className="ProductSheet_Form">
                <div>

                  <div className="pt-4 pt-md-0">
                    {
                      haveAttributePackageModes ?
                        this.renderFormWithAttributePackageMode() :
                        this.renderFormWithoutAttributePackageMode()
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Sheet>

          {product.connectedCollections &&
            product.connectedCollections.map(({ id }) => (
              <div className="mt-4" key={id}>
                <CollectionSlidesBox productCollectionId={id} />
              </div>
            ))
          }
        </div>
      );
    }
  }));
import React from 'react';

import { CUSTOMER_TYPES, SORTED_CUSTOMER_TYPES } from 'selectors/customer';

import { CustomerTranslate } from 'containers/translates';

import { DarkButton, ButtonContainer } from 'components/buttons';

class CustomerTypeButton extends React.Component {
  render() {
    const { customerType, onClick, ...buttonProps } = this.props;
    return (
      <DarkButton onClick={onClick} {...buttonProps}>
        <CustomerTranslate>
          {({ t }) => (
            t(`customerTypes.${customerType}`)
          )}
        </CustomerTranslate>

      </DarkButton>
    )
  }
}

export default function CustomerTypeForm({
  onSubmit,
  t,
  customerTypes = SORTED_CUSTOMER_TYPES,
  buttonProps = {}
}) {
  return (
    <ButtonContainer>
      {customerTypes.map(customerType => (
        <CustomerTypeButton
          key={customerType}
          customerType={customerType}
          onClick={() => onSubmit(customerType)}
          {...buttonProps}
        />
      ))}
    </ButtonContainer>
  )
}
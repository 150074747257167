import React from 'react';

import routes from 'routes';

import {
  getActivityBySlug
} from 'selectors';

import {
  setUserActivity
} from 'actions';

import { waitUntilSatisfyingState } from '../hoc';
import { scrollToTop } from '../hoc';

import BaseArchivePage from './BaseArchivePage';

import { OneColumnLayout } from 'components/layouts';
import PageHeader from 'components/PageHeader';
import BlockArea from '../BlockArea';
import GridArea from 'components/Grid/GridArea';

import DocumentMeta from 'components/documentMeta';

import ProductArchive from '../ProductArchive';
import { CategoryFilter, PriceRangeFilter } from 'components/productFilters';
import ProductArchiveOrdering from 'components/ProductArchiveOrdering';

import { ActivityBreadcrumbs } from 'components/Breadcrumb';

class ActivityPage extends BaseArchivePage {

  composeProductFiltersFromParams() {
    const { activity } = this.props;
    const { category } = this.getQueryParams();

    let filters = {
      ...super.composeProductFiltersFromParams(),
      activitySlugs: [activity.slug]
    };

    if (category !== undefined) {
      filters.categorySlugs = [category];
    }

    return filters;
  }

  getUrlWithQuery(newQuery) {
    const { activity: { slug } } = this.props;
    return routes.ACTIVITY.get(slug, newQuery);
  }

  componentDidMount() {
    const { activity, setUserActivity } = this.props;
    setUserActivity(activity.slug);
  }

  render() {

    const { activity, productCategories } = this.props;
    const productFilters = this.composeProductFiltersFromParams();
    const { category: categorySlug, priceRange, ordering } = this.getQueryParams();

    const blockConditions = [
      'page:activity',
      `activity:${activity.id}`,
      `activity:${activity.slug}`
    ];

    return (

      <div>
        <DocumentMeta
          title={activity.altTitle || activity.name}
          description={activity.altDescription || activity.description || ''}
          url={routes.ACTIVITY.get(activity.slug, this.getQueryParams())}
          imageUrl={activity.image ? activity.image.processedUrls.default : null}
        />
        <GridArea
          areaSlug={"page-hero"}
          conditions={[blockConditions]}
        />

        <OneColumnLayout>
          <div>
            <ActivityBreadcrumbs activityId={activity.id} />
            <PageHeader title={activity.name} description={activity.description || ""} />
            <BlockArea areaSlug="main-top" conditions={blockConditions} />
            <div>
              <hr />
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <CategoryFilter productCategories={productCategories} selectedCategorySlug={categorySlug} onChange={this.composeSetFilter("category")} />
                  {
                    false &&
                    <PriceRangeFilter selectedPriceRange={priceRange} onChange={this.composeSetFilter("priceRange")} />
                  }
                </div>
                <div className="col-md-4 offset-md-4 col-xs-12">
                  <hr className="d-md-none" />
                  <ProductArchiveOrdering selectedOrdering={ordering} onChange={this.setOrdering} />
                </div>
              </div>
              <hr />
            </div>
            <div>
              <ProductArchive
                filters={productFilters}
                ordering={ordering}
                blockConditions={blockConditions}
                listType={`activity--${activity.slug}`}
              />
            </div>
          </div>
        </OneColumnLayout>
      </div>
    );
  }
}

function OuterActivityPage(props) {
  return (
    <React.Fragment key={props.activity.id}>
      <ActivityPage {...props} />
    </React.Fragment>
  )
}

export default waitUntilSatisfyingState(
  (state) => state.activities.length > 0 && state.productCategories.length > 0,
  (state, ownProps) => {
    const { productCategories, activities } = state;
    const { match: { params: { activitySlug } } } = ownProps;
    const activity = getActivityBySlug(activities, activitySlug);

    return {
      productCategories,
      activity,
      isSignedIn: state.auth.isSignedIn,
      isSignedInAndStaff: state.auth.isSignedIn && state.user && state.user.type === 'staff',
    }
  },
  {
    setUserActivity
  }
)(scrollToTop()(OuterActivityPage));
import React from 'react';
import { connect } from 'react-redux';

import { withTranslation as translate } from 'react-i18next';
import { withRouter } from "react-router-dom";
import routes from '../routes';

import { fetchProducts, search } from '../api';
import { PromiseWrapper } from '../utils/promises';
import { caseSelector } from '../utils/helpers';

import { getProductCategoryFlatTree } from '../selectors';

import Autosuggest from './forms/Autosuggest';

import './NavbarSearch.scss';

const NavbarSearch = withRouter(connect(state => ({
  isSignedIn: state.auth.isSignedIn,
  isSignedInAndStaff: state.auth.isSignedIn && state.user && state.user.type === 'staff',
  getProductCategoryTree: (productCategoryId) => getProductCategoryFlatTree(state, productCategoryId)
}))(translate("search")(class NavbarSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      q: null
    }

    this.submit = this.submit.bind(this);
    this.dataToSuggestions = this.dataToSuggestions.bind(this);
    this.createPromise = this.createPromise.bind(this);

  }

  navigate(url) {
    this.props.history.push(url);

    if (this.props.onNavigate) {
      this.props.onNavigate(url);
    }
  }

  submit(evt) {
    evt.preventDefault();

    this.navigate(routes.SEARCH.get(this.state.q));
    return false;
  }

  // dataToSuggestions({items}) {
  //   return items.map(({identifier, numOfArticles, selectedArticleIdentifier, defaultArticleIdentifier = null, name}) => {
  //     const articleIdentifier = numOfArticles > 1 && (selectedArticleIdentifier || defaultArticleIdentifier) || null;
  //     const url = routes.PRODUCT_DETAIL.get(identifier, articleIdentifier);

  //     return {
  //       name,
  //       url
  //     };
  //   });
  // }

  dataToSuggestions(data) {
    const { hits = [] } = data || {}

    const ret = hits.map(item => {

      const itemData = item._source;
      const itemId = Number(item._id);

      const itemType = item._index;
      const itemTypei18n = this.props.t('type.label', { context: itemType });


      const imageUrl = itemData.image_data && itemData.image_data.id ? itemData.image_data.processedUrls.mini : null;

      return caseSelector({
        'article': () => ({
          name: itemData.display,
          type: itemTypei18n,
          imageUrl: imageUrl,
          url: routes.PRODUCT_DETAIL.get(
            itemData.product_slug || itemId,
            itemData.article_slug || null
          ),
        }),
        'product': () => ({
          name: itemData.display,
          type: itemTypei18n,
          imageUrl: imageUrl,
          url: routes.PRODUCT_DETAIL.get(
            itemData.slug || itemId
          ),
        }),
        'product_category': () => ({
          name: itemData.display,
          type: itemTypei18n,
          imageUrl: imageUrl,
          url: routes.PRODUCT_CATEGORY.get(this.props.getProductCategoryTree(itemId))
        }),
        'activity': () => ({
          name: itemData.display,
          type: itemTypei18n,
          imageUrl: imageUrl,
          url: routes.ACTIVITY.get(itemData.slug)
        })
      }, () => ({
        name: itemData.display,
        type: itemTypei18n,
        imageUrl: imageUrl,
        url: "#"
      }))(itemType)()
    });

    return ret;
  }

  createPromise(value) {
    // return fetchProducts({
    //   q: this.state.q,
    //   excludeItemsWithoutCategory: (this.props.isSignedIn ? false : true),
    //   limit: 5,
    // });

    const includeAll = this.props.isSignedInAndStaff;

    return search(this.state.q, includeAll, {
      imageVariants: {
        small: {
          h: 40,
          w: 40
        },
      }
    });
  }

  render() {
    const { t } = this.props;

    return (
      <form action={routes.SEARCH.get()} method="GET" onSubmit={this.submit} id="Navbar_Search">
        <div className="input-container">
          <Autosuggest
            dataToSuggestions={this.dataToSuggestions}
            createPromise={this.createPromise}
            onSetValue={(value) => this.setState({ q: value })}
            onSuggestionSelected={(suggestion) => this.navigate(suggestion.url)}
            value={this.state.q}
            placeholder={t('placeholder')}
            autoFocus
            triggerResetsValue
          />
        </div>
        <div className="button-container">
          <button type="submit"><i className="fa fa-search"></i></button>
        </div>
      </form>
    );
  }
})));

export default NavbarSearch;
import React from 'react';
import { arrayToMaybeClassNameAsProps } from 'utils/elements';

import { Sheet } from 'components/layouts';


export function OrderSectionSheetHeader({ no, children }) {
  return (
    <h2 {...arrayToMaybeClassNameAsProps(["text-center text-sm-left"]) }>
      <span>{no}.</span> {children}
    </h2>
  )
}

export function OrderSectionSheetBody({ children }) {
  return (
    <div className="mt-3">
      {children}
    </div>
  )
}

export default function OrderSectionSheet({ children, sectionState, ...props }) {
  return (
    <Sheet className="mt-4 mb-4 px-4 py-4" state={sectionState} {...props}>
      {children}
    </Sheet>
  )
}
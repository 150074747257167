import React from 'react';
import './List.scss';

import { cleanArray } from 'utils/array';

export function ListItem({className, children, ...props}) {
  return (
    <li className={cleanArray(["List_Item", className]).join(" ")} {...props}>
      {children}
    </li>
  )
}

export default function List({className, children, ...props}) {
  return (
    <ul className={cleanArray(["List", className]).join(" ")} {...props}>
      {children}
    </ul>
  )
}
import { ORDER, CUSTOMER } from '../actions'

const initState = null


export default function orderSubmitState(state = initState, action) {
  switch (action.type) {
    case ORDER.SET_SUBMIT_STATE:
      return action.submitState;
    case CUSTOMER.RESET:
    case ORDER.RESET:
      return initState

    default:
      return state
  }
}
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import {
  signin,
  resetSigninError
} from '../../actions';

import { ModalWithClose } from './Modal';
import Input from '../forms/Input';

export default connect(state => ({
  isSignedIn: state.auth.isSignedIn,
  error: state.auth.error,
}), {
  signin,
  resetSigninError
})(translate('auth')(class _StaffSigninModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      password: null,
      justSignedIn: false
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(fieldName) {
    return (value) => {
      this.setState({
        [fieldName]: value
      });
    }
  }

  onSubmit() {
    this.props.signin(
      this.state.username,
      this.state.password
    );
  }

  componentDidUpdate(prevProps) {
    const justSignedIn = this.props.isSignedIn && !prevProps.isSignedIn;

    if(justSignedIn) {
      this.setState({
        justSignedIn: true
      }, () => {
        setTimeout(() => {
          this.props.onClose();
        }, 2000);
      });
    }

  }

  render() {
    const {t, error, onClose, isOpen, ...props} = this.props;

    const _onClose = () => {
      this.props.resetSigninError();
      onClose();
    }

    return (
      <ModalWithClose onClose={_onClose} isOpen={isOpen}>
          
        <h2>{t('signIn.header')}</h2>
        
        <div className="form-group">
          <Input type="text" placeholder={t('signIn.email.placeholder')} onChange={this.onChange("username")}/>
          <Input type="password" placeholder={t('signIn.password.placeholder')} onChange={this.onChange("password")}/>
        </div>

        {
          error && 
          <p className="text-danger">
            {error}
          </p>
        }

        {
          this.state.justSignedIn &&
          <p className="text-success">
            {t('signIn.success.message')}
          </p>
        }

        <div className="form-group">
          <button onClick={this.onSubmit} className="btn btn-outline-dark">{t('signIn.submit')}</button>
        </div>
      </ModalWithClose>
    )
  }
}));
import PropTypes from 'prop-types'
import { CONTEST_TYPES, PRICE_TYPES } from './constants'

export const EventContestPriceRow = {
  propTypes: {
    numOfCopies: PropTypes.number
  }
}

export const EventContestPrice = {
  propTypes: {
    type: PropTypes.oneOf([
      PRICE_TYPES.COPIES,
      PRICE_TYPES.ROWS,
      PRICE_TYPES.ROWS_AND_COPIES,
    ]),
    rows: PropTypes.arrayOf(
      PropTypes.shape(
        EventContestPriceRow.propTypes
      )
    )
  }
}

export const EventContest = {
  propTypes: {
    name: PropTypes.string,
    type: PropTypes.oneOf([
      CONTEST_TYPES.TEAM,
      CONTEST_TYPES.INDIVIDUAL
    ]),
    rows: PropTypes.arrayOf(
      PropTypes.shape(
        EventContestPrice.propTypes
      )
    )
  }
}

export const Event = {
  propTypes: {
    name: PropTypes.string,
    date: PropTypes.date,
    activityId: PropTypes.number,
    contests: PropTypes.arrayOf(
      PropTypes.shape(
        EventContest.propTypes
      )
    )
  },
  valueRules: [
    {
      key: "name",
      required: true
    },
    {
      key: "activity",
      required: true
    },
    {
      key: "date",
      required: true
    }
  ]
}
import React from 'react';
import first from 'lodash/first';

import { testCustomersCode } from 'api';
import promiseHandler from 'containers/hoc/promiseHandler';

import { SingleInputSimplifiedForm } from 'components/forms/SimplifiedForm';

const TEST_RESULTS = {
  VALID: 'valid',
  NOT_VALID: 'not_valid'
}

const TestCustomerCodeForm = promiseHandler(class _TestCustomerCodeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      testResult: null,
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit({ code }) {

    const { makePromise, onSuccess, customerIds } = this.props;

    makePromise(testCustomersCode(customerIds, code))
      .then(resp => {

        const { result } = resp;

        this.setState(() => ({
          testResult: result
        }), () => {
          switch (result) {
            case TEST_RESULTS.VALID:
              onSuccess(customerIds);
              break;
            case TEST_RESULTS.NOT_VALID:
              break;
            default:
              console.warn("What is this?", resp);
              break;
          }
        })

      })
      .catch(resp => {
        console.error("Ooops", resp);
      });
  }

  render() {
    const { t } = this.props;
    return (
      <SingleInputSimplifiedForm
        t={t}
        inputName="code"
        onSubmit={this.onSubmit}
        inputProps={{
          pattern: "\\d{5}",
        }}
        errorMessage={this.state.testResult === TEST_RESULTS.NOT_VALID && t('steps.validateCustomerAccessCode.validationError.wrongCode')}
      />
    )
  }
});

export default TestCustomerCodeForm;
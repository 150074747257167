
let nextMessageId = 0;

export const MESSAGES = {
  ADD: 'MESSAGES.ADD',
  REMOVE: 'MESSAGES.REMOVE'
}


export const addMessage = (title, body, autoRemoveIn = false) => {
  return {
    type: MESSAGES.ADD,
    id: ++nextMessageId,
    title, body, autoRemoveIn
  };
}

export const removeMessage = (messageId) => {
  return {
    type: MESSAGES.REMOVE,
    messageId
  };
}
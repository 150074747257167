import React from 'react';
import { withTranslation as i18nextTranslate } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  translate,
  RenderProps
} from './hoc';

import config from '../config';

export function translateFactory(nsAndKeyBase) {
  return translate(nsAndKeyBase)(RenderProps);
}

export const CommonTranslate = translateFactory('common');
export const UserTranslate = translateFactory('user');
export const UserOrdersTranslate = translateFactory('user.orders');
export const UserCustomerAddressListTranslate = translateFactory('user.customerAddressList');
export const UserContactTranslate = translateFactory('user.contact');
export const UserCustomersTranslate = translateFactory('user.customers');

export const CustomerTranslate = translateFactory('customer');


class _Translate extends React.Component {

  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    children: PropTypes.func.isRequired,
    ns: PropTypes.string,
    context: PropTypes.object
  }

  getChildContext() {
    return {
      ns: this.getI18nFullKeys().join("."),
      context: this.getI18nContext(),
    }
  }

  getI18LocalKeys = (subKey = '') => {
    return [
      ...(this.props.ns ? this.props.ns.split(".") : []),
      ...(subKey ? subKey.split(".") : [])
    ];
  }

  getI18nFullKeys = (subKey = '') => {
    return [
      ...(this.context.ns ? this.context.ns.split(".") : []),
      ...(this.getI18LocalKeys(subKey)),
    ];
  }


  _createKey = (ns, ...keys) => {
    return `${ns}:${keys.join(".")}`
  }

  composeI18Key = (subKey) => {
    return this._createKey(
      ...this.getI18nFullKeys(subKey)
    )
  }

  getI18nContext = (subContext) => {
    return {
      ...(this.context.context || {}),
      ...(this.props.context || {}),
      ...(subContext || {})
    };
  }

  t = (subKey, subContext) => {
    const [fullNs, ...fullKeys] = this.getI18nFullKeys(subKey);
    const [localNs, ...localKeys] = this.getI18LocalKeys(subKey);

    const fullContext = this.getI18nContext(subContext);

    const _checkForExistingAndMaybeRender = (ns, keys, context) => {
      const key = this._createKey(ns, ...keys);
      const translationExists = this.i18nTranslationExists(key)

      return [
        key,
        translationExists,
        translationExists ? this.props.t(key, context) : null
      ];
    }

    const [fullNsAndKey, fullKeyExists, fullKeyTranslation] = _checkForExistingAndMaybeRender(fullNs, fullKeys, fullContext);
    const warnAboutMissing = false //  config.DEBUG;

    if (fullKeyExists) {
      return fullKeyTranslation;
    }
    else {
      if (warnAboutMissing) {
        console.warn("Full key doesnt exist", fullNs, fullKeys);
      }

      const [localNsAndKey, localKeyExists, localKeyTranslation] = _checkForExistingAndMaybeRender(localNs, localKeys, fullContext);

      if (!localKeyExists) {
        if (warnAboutMissing) {
          console.warn("Local key doesnt exist", localNs, localKeys);
        }
        return `${fullNsAndKey} (${localNsAndKey})`;
      }
      else {
        return localKeyTranslation;
      }

    }
  }

  i18nTranslationExists = (fullKey) => {
    return this.props.i18n.exists(fullKey)
  }

  render() {
    return (
      <RenderProps
        t={this.t}
        getI18nFullKeys={this.getI18nFullKeys}
        composeI18Key={this.composeI18Key}
      >{this.props.children}</RenderProps>
    )
  }
}

_Translate.childContextTypes = {
  ns: PropTypes.string,
  context: PropTypes.object
}

_Translate.contextTypes = {
  ns: PropTypes.string,
  context: PropTypes.object
}

export const Translate = i18nextTranslate()(_Translate);

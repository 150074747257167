import React from 'react';
import { connect } from 'react-redux';

import { Translate } from 'containers/translates';
import OrderSectionSheet, { OrderSectionSheetHeader, OrderSectionSheetBody } from './OrderSectionSheet';
import OrderRows from 'components/OrderRows';

function OrderRowsSection({ sectionState, rows, t }) {
  return (
    <Translate ns="rows">
      {({ t }) => (
        <OrderSectionSheet state={sectionState}>
          <OrderSectionSheetHeader no={1}>
            {t('heading')}
          </OrderSectionSheetHeader>
          {
            sectionState !== 'disabled' && (
              <OrderSectionSheetBody>
                <OrderRows
                  {...{
                    orderRows: rows,
                  }}
                  isEditable
                />
              </OrderSectionSheetBody>
            )
          }
        </OrderSectionSheet>
      )}
    </Translate>
  )
}

export default connect(state => ({
  rows: state.order.rows
}))(OrderRowsSection);
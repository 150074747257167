import React from 'react';

import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import { Link, withRouter } from 'react-router-dom';
import routes from '../routes';

import { toggleClass } from '../utils/elements';
import { getFeaturedActivities } from '../selectors/activities';

import { makeToggable, makeSingleToggable } from '../containers/hoc';
import { VerticalSlide } from './transitions';

import Navbar from './Navbar';
import NavbarSearch from './NavbarSearch';
import BlockArea from '../containers/BlockArea';

import TopUserMenu from './TopUserMenu';

import './Top.scss';

const CollapsableNavbarSection = makeSingleToggable(({ body, header, isActive, toggle }) => (
  <div>
    <header onClick={() => toggle()}>
      {
        isActive ?
          <i className="fa fa-angle-down" /> :
          <i className="fa fa-angle-right" />
      } {header}
    </header>

    <div>
      <VerticalSlide>
        {isActive && body}
      </VerticalSlide>
    </div>
  </div>
));

const ProductCategorySection = translate('category')(({ id, name, slug, children, t, onClickedLink, ...props }) => {
  return (
    children.length ?
      <CollapsableNavbarSection
        header={
          <span>{name}</span>
        }
        body={
          <div className="children">
            <div key="parent" >
              <Link to={routes.PRODUCT_CATEGORY.get([{ slug }])} onClick={onClickedLink}>
                {t('menu.toParent.label', { categoryName: name })}
              </Link>
            </div>
            {children.map(childCategory => (
              <div key={childCategory.id}>
                <Link to={routes.PRODUCT_CATEGORY.get([{ slug }, { slug: childCategory.slug }])} onClick={onClickedLink}>
                  {childCategory.name}
                </Link>
              </div>
            ))}
          </div>
        }
      /> :
      <Link to={routes.PRODUCT_CATEGORY.get([{ slug }])} onClick={onClickedLink}>
        {name}
      </Link>
  )
})

const ActivitiesSection = translate('activities')(({ activities, t, onClickedLink, ...props }) => {

  return (
    <CollapsableNavbarSection
      header={
        <span>{t('menu.header')}</span>
      }
      body={
        <div className="children">
          {activities.map(({ id, name, slug }) => (
            <div key={id}>
              <Link to={routes.ACTIVITY.get(slug)} onClick={onClickedLink}>
                {name}
              </Link>
            </div>
          ))}
          <div key="parent">
            <Link to={routes.ACTIVITY_ARCHIVE.get()} onClick={onClickedLink}>
              {t('menu.toActivityArchive.label')}
            </Link>
          </div>
        </div>
      }
    />
  )
})


const Logo = () => <Link to=""><img style={{ width: "60px", height: "60px", backgroundColor: "red", border: "none" }} /></Link>

const NavbarInner = connect((state) => {
  const {
    productCategories
  } = state;

  return {
    productCategories,
    activities: getFeaturedActivities(state),
  }
})(({ productCategories, activities, onClickedLink }) => (
  <div className="Navbar_Container_Inner">

    {productCategories.map(rootCategory => (
      <ProductCategorySection key={rootCategory.id} {...rootCategory} onClickedLink={onClickedLink} />
    ))}

    <ActivitiesSection activities={activities} onClickedLink={onClickedLink} />
  </div>
));

const DefaultTop = withRouter(makeToggable(['navbarIsExpanded', 'searchbarIsExpanded'])(class _Top extends React.Component {
  constructor(props) {
    super(props);

    this.resetAll = this.resetAll.bind(this);
  }

  componentDidUpdate(prevProps) {
    toggleClass(document.documentElement, "navbar-is-expanded", this.props.navbarIsExpanded);
  }

  resetAll() {
    this.props.toggle('navbarIsExpanded', false);
    this.props.toggle('searchbarIsExpanded', false);
  }

  render() {

    const { toggle, navbarIsExpanded, searchbarIsExpanded } = this.props;

    return (
      <div className="Top Top--default">
        <div className="Top_Inner">
          {
            navbarIsExpanded &&
            <div className="Top_Navbar">
              <VerticalSlide>
                {
                  navbarIsExpanded &&
                  <NavbarInner key="menu" onClickedLink={this.resetAll} />
                }
              </VerticalSlide>
            </div>
          }

          <header className="Top_Header --use-float">
            <Link to={routes.HOME.get()} className="Top_Header_Item Top_Header_Item--center">
              <BlockArea areaSlug="top-logo" />
            </Link>
            <div className="Top_Header_Item Top_Header_Item--left">
              <button className={`Navbar_Toggler ${navbarIsExpanded && '--active'}`} onClick={() => toggle('navbarIsExpanded', undefined, true)}>
                {
                  navbarIsExpanded ?
                    <i className="fa fa-close" /> :
                    <i className="fa fa-bars" />
                }
              </button>
            </div>
            <div className="Top_Header_Item Top_Header_Item--left">
              <button className={`Searchbar_Toggler ${searchbarIsExpanded && '--active'}`} onClick={() => toggle('searchbarIsExpanded', undefined, true)}>
                {
                  searchbarIsExpanded ?
                    <i className="fa fa-close" /> :
                    <i className="fa fa-search" />
                }
              </button>

            </div>
            <div className="Top_Header_Item Top_Header_Item--right">
              <TopUserMenu />
            </div>
          </header>


          {
            searchbarIsExpanded &&
            <div className="Top_Searchbar">
              <NavbarSearch onNavigate={this.resetAll} />
            </div>
          }
        </div>
      </div>
    );
  }
}));


const ExtendedTop = ({ }) => {
  return (
    <div className="Top Top--extended">
      <div className="Top_Inner">
        <header className="Top_Header --use-flex">
          <Link to={routes.HOME.get()} className="Top_Header_Item Top_Header_Item--left pl-3 pr-1"><BlockArea areaSlug="top-logo" className="Top_Blocks" blockClassName="Top_Blocks_Item" /></Link>
          <div style={{
            flex: "1 0 33%"
          }}>
            <BlockArea className="Top_Blocks Top_Header_Item Top_Header_Item--left" blockClassName="Top_Blocks_Item d-inline-block text-white px-3" areaSlug="top-left" markdownOptions={{ headerLevelStart: 1 }} />
          </div>
          <BlockArea className="Top_Blocks Top_Header_Item Top_Header_Item--right" blockClassName="d-inline-block text-white px-3" blockStyle={{ minWidth: 140 }} areaSlug="top-right" />
          <div className="Top_Header_Item Top_Header_Item--right ml-auto">
            <TopUserMenu />
          </div>
        </header>
      </div>
      <Navbar />
    </div>
  )
};


const topFactory = ({ useExtended, ...props }) => {
  return useExtended ?
    <ExtendedTop {...props} /> :
    <DefaultTop {...props} />
}

export default connect(state => {
  const { client } = state;
  return {
    useExtended: client.greaterThan.large
  }
})(topFactory);
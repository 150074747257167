import React from 'react';
import { connect } from 'react-redux';

import { getProductCategoryFlatTree } from '../selectors';

import { caseSelector } from '../utils/helpers';

import { Link } from 'react-router-dom';
import routes from '../routes';

import ProductSlidesBox from './ProductSlidesBox';


class FeaturedProductCategory extends React.Component {
  render() {
    const {name, slug, featuredTitle, featuredProductIds, slidesToShow, productCategoryTree} = this.props;

    return (
      <ProductSlidesBox
        header={
          <Link to={routes.PRODUCT_CATEGORY.get(productCategoryTree.map(c => ({slug: c.slug})))}>
            {featuredTitle || name}
          </Link>
        }
        slidesToShow={slidesToShow}
        listType={`featured-in-category--${productCategoryTree.map(c => c.slug).join('/')}`}
        productIds={featuredProductIds}
        boxed
      />
    );
  }
}

export default connect((state, ownProps) => ({
  productCategoryTree: getProductCategoryFlatTree(state, ownProps.id),
  slidesToShow: caseSelector({
    small: 3,
    medium: 4,
    large: 5,
    extraLarge: 6,
  }, 2)(state.client.mediaType)
}))(FeaturedProductCategory);
import React from 'react'
import PropTypes from 'prop-types'

import { caseSelector } from 'utils/helpers'

import { FormGroup } from 'reactstrap'
import Input from 'components/forms/Input'
import { Button, ButtonContainer } from 'components/buttons'

import { Translate } from 'containers/translates'

export const FREETEXT_SELECTION = 'free'

export default class FreeTextWidget extends React.Component {

  state = {
    value: ''
  }

  handleChange = (value) => {
    this.setState({
      value
    })
  }

  handleSubmit = () => {
    this.props.onChange({
      value: this.state.value,
      meta: {
        templateId: FREETEXT_SELECTION
      }
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.input.value !== prevState.initialValue) {
      return {
        initialValue: nextProps.input.value,
        value: nextProps.input.value,
      }
    }

    return null
  }

  render() {
    const { type, onChange, onReset, placeholder, ...inputProps } = this.props

    return (
      <Translate ns="freeText">
        {({ t }) => (
          <React.Fragment>
            <FormGroup>
              <Input
                name="input"
                type={caseSelector({
                  'long_text': 'textarea',
                  'number': 'number'
                }, 'text')(type)}
                value={this.state.value}
                onChange={this.handleChange}
                placeholder={placeholder}
              />
            </FormGroup>
            <ButtonContainer>
              <Button type="button" onClick={this.handleSubmit}>{t('submitButton.label')}</Button>
              <Button type="button" color="light" onClick={this.props.onCancel}>{t('cancelButton.label')}</Button>
            </ButtonContainer>
          </React.Fragment>
        )}
      </Translate>
    )
  }
}



import { PRODUCTS } from '../../actions';

const initState = null;

const filters = (state = initState, action) => {

  switch(action.type) {
    case PRODUCTS.FETCH.REQUEST:
      return {...action.filters};
    default:
      return state;
  }
}

export default filters;
import moment from 'moment';
import moment_sv from 'moment/locale/sv';

import { cleanArray } from 'utils/array';

function _maybePlusOrMinus(n, forcePlusOrMinusDisplay) {
  return n < 0 ? '-' : (
    forcePlusOrMinusDisplay ? '+' : ''
  )
}

export function formatNumber(value, numOfDecimals = undefined, forcePlusOrMinusDisplay = false) {
  const n = Number(value);

  function _localizeNumber(numStr) {
    return numStr.replace('.', ',');
  }

  function _addSpaceInNumber(numStr) {
    const [i, d] = numStr.split(".");

    let iStr = i;

    const r = /^(\d{0,2})(\d{3})?(\d{3})?(\d{3})?(\d{3})?(\d{3})?$/;
    const iMatch = i.toString().match(r);

    if (iMatch) {
      iStr = iMatch.slice(1).filter(Boolean).join(" ");
    }

    return [iStr, d].join('.');
  }

  function _numToString(n) {
    if (numOfDecimals !== undefined) {
      return n.toFixed(numOfDecimals)
    }
    else {
      return n.toString();
    }
  }

  const numStr = _localizeNumber(
    _addSpaceInNumber(
      _numToString(
        Math.abs(n)
      )
    )
  );

  return `${_maybePlusOrMinus(n, forcePlusOrMinusDisplay)}${numStr}`;
}

export const formatPrice = (value, currency = false, forcePlusOrMinusDisplay = false) => {
  const formattedPrice = formatNumber(value, 2, forcePlusOrMinusDisplay);
  return `${formattedPrice}${currency !== null ? ` ${currency}` : ''}`;

};

const weightUnitConvertions = {
  g: {
    kg: value => Math.round(1000 * value / 1000) / 1000,
  },
  kg: {
    g: value => Math.round(value * 1000),
  }
}

const convertWeight = (value, fromUnit, toUnit) => weightUnitConvertions[fromUnit][toUnit](value);

export const formatWeight = (value, fromUnit = 'kg', toUnit = 'g', renderUnit = true) => {
  return `${convertWeight(value, fromUnit, toUnit)} ${renderUnit && toUnit}`;
}


export function toFormattedDateStr(dateOrdateStrOrMoment) {
  return moment(dateOrdateStrOrMoment).format('Do MMMM, YYYY');
}

export function toOnelineAddress(obj) {
  return `${obj.street}, ${obj.zip} ${obj.city}`;
}

export function hasValidAddress(obj) {
  return (
    obj.street !== undefined &&
    obj.city !== undefined
  )
}

export function hasValidContactInformation(obj) {
  return (
    obj.email !== undefined &&
    obj.mobile !== undefined
  )
}
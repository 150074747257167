import React from 'react';
import { Translate } from 'containers/translates';

import Modal from './Modal';
import UserNewPasswordForm from 'components/forms/UserNewPasswordForm';
import ContentHeader from 'components/ContentHeader';

export default function UserNewPasswordModal({ onClose, ...props }) {
  return (
    <Translate ns="newPassword">
      {({ t }) => (
        <Modal onClose={onClose} {...props}>
          <ContentHeader />
          <hr />
          <UserNewPasswordForm
            onAfterSubmit={onClose}
            t={t}
          />
        </Modal>
      )}
    </Translate>
  )
};
import React from 'react'
import PropTypes from 'prop-types'

import { Translate } from 'containers/translates';

import { ModalBody, VERTICAL_MODAL_POSITIONS, SIZES, BareModal } from 'components/modals/Modal';

import EventToOrderWizard from 'components/Event/EventToOrderWizard'
import useBoolState from 'hooks/useBoolState';


export const EventToOrderWizardModalStateContext = React.createContext({
  isOpen: false,
  open: () => null,
  close: () => null
})


export function EventToOrderWizardModalAndStateWrapper({ children }) {
  const [isOpen, open, close] = useBoolState(false)

  const eventToOrderWizardModalState = React.useMemo(() => ({
    isOpen,
    open,
    close
  }), [isOpen])

  return (
    <EventToOrderWizardModalStateContext.Provider value={eventToOrderWizardModalState}>
      {children}
      <EventToOrderWizardModal />
    </EventToOrderWizardModalStateContext.Provider>

  )
}

EventToOrderWizardModalAndStateWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

function EventToOrderWizardModal() {
  const { isOpen, close } = React.useContext(EventToOrderWizardModalStateContext)

  return (
    <Translate ns="customer">
      {({ t }) => (
        <BareModal
          isOpen={isOpen}
          onClose={close}
          confirmCloseMessage={t("events.wizard.cancelConfirmationMessage")}
          verticalPosition={VERTICAL_MODAL_POSITIONS.TOP}
        >
          <ModalBody size={SIZES.LARGE}>
            <EventToOrderWizard
              onComplete={close}
              onCancel={close}
            />
          </ModalBody>
        </BareModal>
      )}
    </Translate>
  )
}
EventToOrderWizardModal.propTypes = {
}

export default EventToOrderWizardModal
import React from 'react';

export const ProductOptionSelectionWrapper = ({children}) => (
  <label className="Product_Option_Selection">
    {children}
  </label>
)

const ProductOptionSelection = ({image, attributeIsId, attributeValue, price, isSelected, onChange}) => (
  <ProductOptionSelectionWrapper>
    <input type="radio" checked={isSelected} onChange={() => onChange()} className="invisible"/>
    <div>
      <img src={image && image.processedUrls.thumb} role="presentation"/>
      <div>{attributeIsId && attributeValue} {price > 0 && `(+${price} kr)`}</div>
    </div>
  </ProductOptionSelectionWrapper>
)


class ProductOptionSelections extends React.Component {

  wrapperElement = null;
  hasBeenChecked = false;

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if(!this.hasBeenChecked) {
      const inputElement = this.wrapperElement.querySelector("input:checked");

      if(inputElement) {
        const offsetTop = inputElement.parentElement.offsetTop;
        const margins = 15;
        this.wrapperElement.scrollTop = (offsetTop-margins);
        this.hasBeenChecked = true;
      }
    }
  }

  render() {

    const {optionId, selections, selectedSelection, onChange, onReset} = this.props;

    const props = {
    };

    if(selectedSelection !== null) {
      props['data-have-selected-selection'] = null;
    }

    return (
      <div className="Product_Option_Selections" data-have-selected-selection={selectedSelection !== null ? true : null} ref={el => this.wrapperElement = el}>
        <div className="Product_Option_Selections_Inner">
          <label key="reset" className="Product_Option_Selection_Reset" onClick={onReset}>
            {/* <input type="radio" onChange={}/> */}
          </label>
          {selections.map(selection => {
            const isSelected = selectedSelection !== null && selectedSelection.optionSelectionIdentifier === selection.identifier;

            return (
              <ProductOptionSelection 
                key={selection.id}
                {...selection}
                onChange={onChange.bind(null, selection.identifier)}
                isSelected={isSelected}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default ProductOptionSelections;
import { caseSelector } from './helpers';

const supportsStorage = () => typeof (Storage) !== undefined;

const getStorage = (useSession) => {
  return useSession ? window.sessionStorage : window.localStorage;
}

const storageKeyPre = "_WEBSHOP-";

const _setStorageItem = (key, value, useSession) => {
  return getStorage(useSession).setItem(key, value);
}

const _getStorageItem = (key, useSession) => {
  return getStorage(useSession).getItem(key);
}

const _removeStorageItem = (key, useSession) => {
  return getStorage(useSession).removeItem(key);
}

export const setInStorage = (objToSave, { useSession = false } = {}) => {
  if (!supportsStorage()) {
    return;
  }

  for (let key in objToSave) {
    _setStorageItem(key, objToSave[key], useSession);
  }
}

export const getFromStorage = (key, defaultValue = null, { useSession = false } = {}) => {
  if (!supportsStorage()) {
    return defaultValue;
  }

  const storedValue = _getStorageItem(key, useSession);
  const ret = (storedValue !== null ? storedValue : defaultValue);
  return ret;
}

export const removeFromStorage = (key, useSession = false) => {
  if (!supportsStorage()) {
    return null;
  }

  return _removeStorageItem(key, useSession);
}

export function parseBoolean(value) {
  return Boolean(caseSelector({
    1: true,
    true: true,
    0: false,
    false: false
  }, false)(value))
}
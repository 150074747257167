import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { callbackWithoutPropagationComposer } from 'utils/event';

function Table({ data, columns, selectedKey, onSelect, showTableHead, showSelectable }) {

  const _columns = React.useMemo(() => [
    ...(showSelectable ? [{
      key: "radio",
      style: {
        width: 40
      },
      render(dataItem) {
        return (
          <input
            type="radio"
            checked={dataItem.key === selectedKey}
            readOnly
          />
        )
      }
    }] : []),
    ...columns,
  ], [columns, showSelectable, selectedKey])

  return (
    <table className="table">
      {showTableHead && (
        <thead>
          <tr>
            {
              _columns.map(({ title, key, className }) => (
                <th key={key} className={className}>
                  {title || ''}
                </th>
              ))
            }
          </tr>
        </thead>
      )}
      <tbody>
        {
          data.map((dataItem) => (
            <tr
              key={dataItem.key}
              onClick={(
                !dataItem.isSelected && onSelect !== undefined ?
                  callbackWithoutPropagationComposer(() => onSelect(dataItem)) :
                  undefined
              )}
              className={classNames({
                "table-active": dataItem.isSelected
              })}
            >
              {
                _columns.map((column) => (
                  <td
                    key={`${column.key}-${dataItem.key}`}
                    className={column.className}
                    style={column.style}
                  >
                    {column.render(dataItem)}
                  </td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  showTableHead: PropTypes.bool,
  showSelectable: PropTypes.bool,
  selectedKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])
}

Table.defaultProps = {
  showTableHead: true,
  showSelectable: false
}

export default Table
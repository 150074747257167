import find from 'lodash/find';
import intersection from 'lodash/intersection';
import first from 'lodash/first';

import { getProductOptionById } from './productOptions';
import { getProductCategoryFlatTree } from './productCategories';

export const getArticle = (state, articleIdentifier) => {
  return find(state.selectedProduct.articles, (article) => articleIdentifier === article.identifier)
}

export const getOptionScopes = (state, optionScopeIds) => {
  return state.selectedProduct.optionScopes.filter((optionScope) => optionScopeIds.indexOf(optionScope.id) !== -1);
}

export const getSelectedArticle = (state) => {
  return getArticle(state, state.selectedProduct.selectedArticleIdentifier);
}

export const getSelectedArticleOptionScopes = (state) => {
  const selectedArticle = getSelectedArticle(state);
  return getOptionScopes(state, selectedArticle.optionScopeIds);
}


export const attributeValuesToString = (attributeValues, startWithComma = true) => attributeValues.map(av => `, ${av.value}`).join('').substr(startWithComma ? 0 : 2);

export const attributeValueIdsToString = (attributeValueIdsObj, attributes, startWithComma = true) => {
  return attributeValuesToString(Object.keys(attributeValueIdsObj).map((attributeId) => {
    const attribute = find(attributes, a => a.id == attributeId);

    if(attribute === undefined) {
      return '';
    }

    const attributeValue = find(attribute.values, v => v.id == attributeValueIdsObj[attributeId]);
    return attributeValue;
  }), startWithComma);
}

export function getProductsFromHistoryByArticleIds(state, articleIds) {
  return state.selectedProductHistory
    .map(obj => obj.product)
    .filter(p => intersection(p.articles.map(a => a.id), articleIds).length > 0)
}

export function getProductFromHistoryByArticleId(state, articleId) {
  return first(getProductsFromHistoryByArticleIds(state, [articleId]));
}
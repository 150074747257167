import React from 'react'
import {
  createCustomerEvent,
  editCustomerEvent
} from 'api'

import {
  exportEvent, createEmptyEvent
  // importEvent
} from './data'

export default function useEventState(initialEvent = undefined) {
  const [event, setEvent] = React.useState(
    initialEvent !== undefined ?
      initialEvent :
      createEmptyEvent()
  )
  const [isSyncing, setIsSyncing] = React.useState(false)

  const sync = React.useCallback((customerId) => new Promise((
    resolve,
    // reject
  ) => {
    const { id: eventId, ...eventData } = event
    const eventHasBeenSynced = eventId !== null

    const exportedEventData = exportEvent(eventData)

    setIsSyncing(true)

    function createSyncPromise() {
      if (eventHasBeenSynced) {
        return editCustomerEvent(
          customerId,
          eventId,
          exportedEventData
        )
      }
      else {
        return createCustomerEvent(
          customerId,
          exportedEventData
        )
          .then(({ createdId }) => {
            setEvent({
              ...event,
              id: createdId
            })
            return createdId
          })
      }
    }

    createSyncPromise().then(
      () => {
        setIsSyncing(false)
        resolve()
      },
      (error) => {
        console.warn("-event sync error-", error)
        setIsSyncing(false)
        resolve()
      },
    )
  }), [event])

  const set = React.useCallback((eventPayload) => {
    return new Promise((resolve) => {
      setEvent({
        ...event,
        ...eventPayload
      })

      setTimeout(resolve, 10)
    })
  }, [event])



  return {
    event,
    set,
    sync,
    isSyncing
  }
}
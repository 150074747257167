export const SELECTED_PRODUCT = {
  PRE: 'SELECTED_PRODUCT.PRE',
  FETCH: {
    REQUEST: 'SELECTED_PRODUCT.FETCH.REQUEST',
    RECEIVE: 'SELECTED_PRODUCT.FETCH.RECEIVE',
    FAILURE: 'SELECTED_PRODUCT.FETCH.FAILURE'
  },
  GET: 'SELECTED_PRODUCT.GET',
  INVALIDATE: 'SELECTED_PRODUCT.INVALIDATE',
  CHANGE_ATTRIBUTE: 'SELECTED_PRODUCT.CHANGE_ATTRIBUTE',
  CHANGE_SELECTED_ARTICLE: 'SELECTED_PRODUCT.CHANGE_SELECTED_ARTICLE',
};

export const preSelectedProduct = (selectedProduct) => {
  return {
    type: SELECTED_PRODUCT.PRE,
    selectedProduct
  }
}

export const requestSelectedProduct = (productIdentifier, articleIdentifier = null, imageVariants = {}) => {
  return {
    type: SELECTED_PRODUCT.FETCH.REQUEST,
    productIdentifier,
    articleIdentifier,
    imageVariants
  }
}

export const receiveSelectedProduct = (selectedProduct) => {
  return {
    type: SELECTED_PRODUCT.FETCH.RECEIVE,
    selectedProduct
  }
}

export const failedSelectedProduct = (error) => {
  return {
    type: SELECTED_PRODUCT.FETCH.FAILURE,
    error
  }
}

export const getSelectedProduct = (productIdentifier, articleIdentifier = null, imageVariants = {}) => {
  return {
    type: SELECTED_PRODUCT.GET,
    productIdentifier, 
    articleIdentifier,
    imageVariants
  }
}

export const invalidateSelectedProduct = () => {
  return {
    type: SELECTED_PRODUCT.INVALIDATE
  }
}



export const changeProductAttribute = (attributeId, attributeValueId) => {
  return {
    type: SELECTED_PRODUCT.CHANGE_ATTRIBUTE,
    attributeId,
    attributeValueId
  }
}

export const changeSelectedArticle = (articleIdentifier) => ({
  type: SELECTED_PRODUCT.CHANGE_SELECTED_ARTICLE,
  articleIdentifier
})

import React from 'react';
import { connect } from 'react-redux';

import { connectFactory } from 'containers/Connect';

import {
  customerName as customerNameSelector,
  customerType as customerTypeSelector,
  customerExist as customerExistSelector
} from 'selectors/customer';

import {
  numOfOrderRows as numOfOrderRowsSelector
} from 'selectors/order';

import {
  userIsSignedInAndLoaded as userIsSignedInAndLoadedSelector
} from 'selectors/user';

import { LinkAndScrollToTop } from './Link';
import { TransitionWhenStateChanges } from './transitions';

import routes from '../routes';

import {
  UserIcon,
  OrderIcon
} from './Icon';

import { LinkButton } from './buttons';

import { caseSelector } from '../utils/helpers';

import Modal from './modals/Modal';
import ModalButton from './modals/ModalButton';
import SigninOrRegisterModal from './modals/SigninOrRegisterModal';
import GetStartedModal from 'components/modals/GetStartedModal';

import { UserTranslate, Translate } from 'containers/translates';


const useExtendedIfGreaterThanMedium = (state) => ({
  useExtended: state.client.greaterThan.medium
});


const CustomerWizardButton = connect(useExtendedIfGreaterThanMedium)(function _CustomerWizardButton({
  useExtended,
  userIsSignedInAndLoaded
}) {
  return (
    <Translate ns="user">
      {({ t }) => (
        <ModalButton
          key={userIsSignedInAndLoaded}
          ModalComponent={SigninOrRegisterModal}
          ButtonComponent={({ onClick }) => {

            if (userIsSignedInAndLoaded) {
              return null
            }

            return (
              <LinkButton className="pl-4 pr-3 text-white UserMenu_Link" onClick={onClick}>
                <UserIcon fixedWidth /> {
                  useExtended && <span>{t('signinOrRegister.button.label')}</span>
                }
              </LinkButton>
            )
          }}
        />
      )}
    </Translate>
  )
});

const GetStartedButton = connect(useExtendedIfGreaterThanMedium)(function _GetStartedButton({ useExtended }) {
  return (
    <ModalButton
      ModalComponent={GetStartedModal}
      ButtonComponent={({ onClick }) => (
        <LinkButton className="pl-4 pr-3 text-white" onClick={onClick}>
          {
            useExtended ?
              <span>Kom igång</span> :
              <UserIcon />
          }
        </LinkButton>
      )}
    />
  )
});


const UserHomeLink = connect(
  state => ({
    customerName: customerNameSelector(state),
    ...useExtendedIfGreaterThanMedium(state)
  })
)(({ customerName, useExtended }) => (
  <LinkAndScrollToTop to={routes.USER.ROOT.get()} className="UserMenu_Link">
    <UserIcon fixedWidth /> {useExtended && customerName}
  </LinkAndScrollToTop>
))

const CustomerWithAnonymousUserHomeLink = connect(
  state => ({
    customerName: customerNameSelector(state),
    ...useExtendedIfGreaterThanMedium(state)
  })
)(({ customerName, useExtended }) => (
  <LinkAndScrollToTop to={routes.CUSTOMER.get()} className="UserMenu_Link">
    {customerName}
  </LinkAndScrollToTop>
))

const Minicart = connect(state => ({ numOfOrderRows: numOfOrderRowsSelector(state) }))(({ numOfOrderRows }) => (
  <LinkAndScrollToTop to={routes.ORDER.get()} className="UserMenu_Minicart">
    <OrderIcon fixedWidth />

    <span className="UserMenu_Minicart_NumOfOrderRows">
      <TransitionWhenStateChanges timeout={0} state={{ numOfOrderRows }}>
        {({ transitionState }) => {
          return (
            <strong className={transitionState}>
              {numOfOrderRows}
            </strong>
          )
        }}
      </TransitionWhenStateChanges>
    </span>
  </LinkAndScrollToTop>
))

export default connect(state => ({
  userIsSignedInAndLoaded: userIsSignedInAndLoadedSelector(state),
  customerExist: customerExistSelector(state),
  customerType: customerTypeSelector(state),
  customerName: state.customer.data.name,
  state
}))(function TopUserMenu({ userIsSignedInAndLoaded }) {
  return (
    <div className="UserMenu">
      {
        userIsSignedInAndLoaded && <UserHomeLink />
      }
      <CustomerWizardButton
        userIsSignedInAndLoaded={userIsSignedInAndLoaded}
      />
      <Minicart />
    </div>
  )
});
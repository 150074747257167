import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import {
//   setCustomer as setCustomerForOrder,
// } from 'actions'
import {
  setCustomerType,
  setAndFetchCustomer,
  resetCustomer
} from 'actions';

import { Translate } from 'containers/translates'
import OrderSectionSheet, { OrderSectionSheetHeader, OrderSectionSheetBody } from './OrderSectionSheet'
import { SectionState } from './propTypes';
import OrderCustomerSelection from './OrderCustomerSelection';

function CustomerSection({ customer, sectionState, setCustomer, setCustomerType, resetCustomer }) {
  return (
    <Translate ns="customer">
      {({ t }) => (
        <OrderSectionSheet state={sectionState}>
          <OrderSectionSheetHeader no={3}>
            {t('heading', { context: customer.customerType })}
          </OrderSectionSheetHeader>
          <OrderSectionSheetBody>
            {
              sectionState !== 'disabled' ? (
                <OrderCustomerSelection
                  selectedCustomerId={customer.id}
                  selectedCustomerType={customer.customerType}
                  onSelectCustomer={setCustomer}
                  onSelectCustomerType={setCustomerType}
                  onResetCustomer={resetCustomer}
                  t={t}
                />
              ) : null
            }
          </OrderSectionSheetBody>
        </OrderSectionSheet>
      )}
    </Translate>
  )
}

CustomerSection.propTypes = {
  customer: PropTypes.object.isRequired,
  sectionState: SectionState,
  setCustomer: PropTypes.func.isRequired,
  setCustomerType: PropTypes.func.isRequired,
  resetCustomer: PropTypes.func.isRequired,
}

export default connect(
  state => ({
    customer: state.customer,
  }), {
    setCustomer: setAndFetchCustomer,
    setCustomerType,
    resetCustomer
  }
)(CustomerSection)
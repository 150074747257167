export const PRODUCT_ATTRIBUTES = {
  FETCH: {
    REQUEST: 'PRODUCT_ATTRIBUTES.FETCH.REQUEST',
    RECEIVE: 'PRODUCT_ATTRIBUTES.FETCH.RECEIVE',
    FAILURE: 'PRODUCT_ATTRIBUTES.FETCH.FAILURE'
  },
  GET: 'PRODUCT_ATTRIBUTES.GET'
};

export const requestProductAttributes = () => {
  return {
    type: PRODUCT_ATTRIBUTES.FETCH.REQUEST,
  }
}

export const receiveProductAttributes = (items) => {
  return {
    type: PRODUCT_ATTRIBUTES.FETCH.RECEIVE,
    items
  }
}

export const getProductAttributes = () => {
  return {
    type: PRODUCT_ATTRIBUTES.GET
  }
}

export const failedProductAttributes = (error) => {
  return {
    type: PRODUCT_ATTRIBUTES.FETCH.FAILURE,
    error
  }
}
import React from 'react';
import { withTranslation as translate } from 'react-i18next';

import BlockArea from '../../containers/BlockArea';
import { BareModal } from './Modal';
import SelectCustomerType from '../forms/SelectCustomerType';

export default translate('customer')(({onClose, t, ...props}) => (
  <BareModal onClose={onClose} {...props}>
    <div className="text-center">
      <BlockArea 
        areaSlug="modal-content"
        conditions={["modal:select-customer-type"]}
        defaultContent={(
          <div>
            <h2>{t('selectType.header')}</h2>
            <hr/>
          </div>
        )}
      />

      <div className="px-3">
        <SelectCustomerType 
          onSelectCallback={() => onClose()}
        />
      </div>
    </div>
  </BareModal>
));
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import find from 'lodash/find'

import { caseSelector } from 'utils/helpers'

import {
  fetchCustomerEvents,
  removeCustomerEvent
} from 'api'

import { importEvent } from 'selectors/event'
import { Event } from './props'

import EventModal from './EventModal'
import OrderSuggestionModal from './OrderSuggestionModal'

import { DarkButton, ButtonContainer } from 'components/buttons'

const EVENT_ACTIONS = {
  CREATE: 'create',
  EDIT: 'edit',
  MAKE_ORDER_SUGGESTION: 'makeOrderSuggestion'
}

class Events extends React.Component {

  state = {
    selectedEventId: null,
    eventAction: null,
    events: []
  }

  static propTypes = {
    customerId: PropTypes.number.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape(
        Event.propTypes
      )
    )
  }

  componentDidMount() {
    this.updateEvents()
  }

  updateEvents = () => {
    fetchCustomerEvents(this.props.customerId)
      .then(({ items: customerEvents }) => {
        this.setState({
          events: customerEvents.map(importEvent)
        })
      })
  }


  selectEvent = (eventId, eventAction) => {
    this.setState({
      selectedEventId: eventId,
      eventAction: eventAction
    })
  }

  deselectEvent = () => {
    this.setState({
      selectedEventId: null,
      eventAction: null
    })
  }

  handleChangeOfEvents = () => {
    this.updateEvents()
    this.deselectEvent()
  }

  handleRemoveEvent = (eventId) => {
    removeCustomerEvent(
      this.props.customerId,
      eventId
    )
      .then(this.updateEvents)
  }

  handleOpenCreateEvent = () => {
    this.setState({
      eventAction: EVENT_ACTIONS.CREATE
    })
  }

  getEvent(eventId) {
    return find(this.state.events, (x) => x.id === eventId)
  }

  render() {

    return (
      <React.Fragment>
        {
          caseSelector({
            [EVENT_ACTIONS.CREATE]: () => (
              <EventModal
                isOpen
                customerId={this.props.customerId}
                onComplete={this.handleChangeOfEvents}
                onClose={this.deselectEvent}
              />
            ),
            [EVENT_ACTIONS.EDIT]: () => (
              <EventModal
                isOpen
                customerId={this.props.customerId}
                onComplete={this.handleChangeOfEvents}
                onClose={this.deselectEvent}
                event={this.getEvent(this.state.selectedEventId)}
              />
            ),
            [EVENT_ACTIONS.MAKE_ORDER_SUGGESTION]: () => (
              <OrderSuggestionModal
                isOpen
                onClose={this.deselectEvent}
                event={this.getEvent(this.state.selectedEventId)}
              />
            )
          }, () => null)(this.state.eventAction)()
        }
        <hr />
        {
          this.state.events.map((event) => (
            <React.Fragment key={event.id}>
              <div>
                <h3>{event.name}, {event.date.toString()}</h3>
                <ButtonContainer>
                  <DarkButton size="sm" outline onClick={() => this.selectEvent(event.id, EVENT_ACTIONS.EDIT)}>{"Edit"}</DarkButton>
                  <DarkButton size="sm" outline onClick={() => this.selectEvent(event.id, EVENT_ACTIONS.MAKE_ORDER_SUGGESTION)}>{"Order Suggestion"}</DarkButton>
                  <DarkButton size="sm" outline onClick={() => this.handleRemoveEvent(event.id)}>{"Remove"}</DarkButton>
                </ButtonContainer>
              </div>
              <hr />
            </React.Fragment>
          ))
        }
        <div>
          <DarkButton onClick={this.handleOpenCreateEvent}>{"Create"}</DarkButton>
        </div>
      </React.Fragment>
    )
  }
}


export default connect(
  (state) => ({
    customerId: state.customer.id,
  })
)(Events)
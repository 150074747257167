import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import assistantImageSrc from 'images/assistant.svg'
import './AssistantComment.css'

export default class AssistantComment extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    startOpen: PropTypes.bool
  }

  static defaultProps = {
    startOpen: false
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.startOpen
    }
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen })

  render() {

    const className = classNames(
      "AssistantComment",
      {
        '--open': this.state.isOpen
      }
    )


    return (
      <div className={className}>
        <div className="AssistantComment__AvatarContainer" onClick={this.toggleOpen}>
          <img
            src={assistantImageSrc}
            style={{
              width: 60,
              height: 60,
              objectFit: "contain",
            }}
          />
        </div>

        <div className="AssistantComment__Content">
          {this.props.children}
        </div>
      </div>
    )
  }
}

import { put, call, select, delay } from 'redux-saga/effects'; // , take

import * as actions from '../actions';
import {
  fetchActivities,
  fetchProductCategories,
  fetchProductAttributes,
  fetchFieldSelections,
  deleteFieldSelection,
  fetchTags,
  fetchTagCategories,
  fetchProductOptions,
  fetchProducts,
  fetchProduct,
  fetchProductFields,
} from '../api';

function fail(e) {
  console.error(e.name, e.message)
}

export function* getActivities() {
  try {
    yield put(actions.requestActivities());
    const { items } = yield call(fetchActivities, {});
    yield put(actions.receiveActivities(items));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedActivities(e))
  }
}

export function* getProductCategories() {
  try {
    yield put(actions.requestProductCategories());
    const { items } = yield call(fetchProductCategories, {});
    yield put(actions.receiveProductCategories(items));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedProductCategories(e));
  }
}

export function* getProductAttributes() {
  try {
    yield put(actions.requestProductAttributes());
    const { items } = yield call(fetchProductAttributes, {});
    yield put(actions.receiveProductAttributes(items));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedProductAttributes(e));
  }
}


export function* getProductFields() {
  try {
    yield put(actions.requestProductFields());
    const { items } = yield call(fetchProductFields);
    yield put(actions.receiveProductFields(items));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedProductFields(e));
  }
}

export function* getFieldSelections() {

  const selectedCustomerId = yield select((state) => state.order.customerId);

  try {
    yield put(actions.requestFieldSelections());
    const { items } = yield call(fetchFieldSelections, selectedCustomerId);
    yield put(actions.receiveFieldSelections(items));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedFieldSelections(e));
  }

  return
}

export function* getTags() {
  try {
    yield put(actions.requestTags());
    const { items } = yield call(fetchTags);
    yield put(actions.receiveTags(items));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedTags(e));
  }
}

export function* getTagCategories() {
  try {
    yield put(actions.requestTagCategories());
    const { items } = yield call(fetchTagCategories);
    yield put(actions.receiveTagCategories(items));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedTagCategories(e));
  }
}



export function* getProductOptions({ productOptionIds, imageVariants }) {

  try {

    yield call(delay, 1); // TODO: Fix - Weird errors if no delay

    const exisitingProductOptionIds = yield select((state) => state.productOptionIds);
    const onlyNewOptionIds = productOptionIds.filter(id => exisitingProductOptionIds.indexOf(id) === -1);

    if (onlyNewOptionIds.length) {

      yield put(actions.requestProductOptions(onlyNewOptionIds));
      const { items } = yield call(fetchProductOptions, onlyNewOptionIds, imageVariants);
      yield put(actions.receiveProductOptions(items));
    }
  }
  catch (e) {
    fail(e);
    yield put(actions.failedProductOptions(e));
  }
}

export function* getSelectedProduct({ productIdentifier, articleIdentifier, imageVariants }) {
  yield put(actions.invalidateSelectedProduct());

  try {
    yield put(actions.requestSelectedProduct(productIdentifier, articleIdentifier, imageVariants));
    const selectedProduct = yield call(fetchProduct, productIdentifier, articleIdentifier, imageVariants);
    yield put(actions.receiveSelectedProduct(selectedProduct));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedSelectedProduct(e));
  }
}

export function* getProducts({ filters, identifier }) {
  if (filters.offset === 0) {
    yield put(actions.invalidateProducts());
    // yield delay(1000);
  }

  try {
    yield put(actions.requestProducts(filters, identifier));
    const { items, totalNumOfItems } = yield call(fetchProducts, filters);
    yield put(actions.receiveProducts(items, totalNumOfItems));
  }
  catch (e) {
    fail(e);
    yield put(actions.failedProducts(e));
  }
}

export function* handleDeleteFieldSelection({ id }) {
  yield call(deleteFieldSelection, id)
  yield put(actions.refreshFieldSelections())

}
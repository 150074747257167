import React from 'react';
import { removeDuplicates } from '../../utils/array';

import { connect } from 'react-redux';

import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import { FormGroup } from 'reactstrap';
import LoadingIndicator from '../spinners';

import { ProductOptionGroupSelectionFilter, ProductOptionActivitySelectionFilter } from './ProductOptionSelectionFilter';
import ProductOptionSelections from './ProductOptionSelections';
import ProductOptionSelectionInputs from './ProductOptionSelectionInputs';
import ProductFormSectionPrice from './ProductFormSectionPrice';

import Markdown from '../Markdown';

import './ProductOption.scss';


const _getActivityIdsFromOptionSelections = (selections) => {
  return removeDuplicates(selections.reduce((accum, {activityIds}) => [...accum, ...activityIds], [])).filter(activityId => activityId !== '*');
}

const ProductOptionWrapper = ({name, identifier, optionScopeSelection, selectedSelection, option, children, ...props}) => {

  const haveSelection = selectedSelection !== null && selectedSelection !== undefined;
  const optionSelection = haveSelection ? find(option.selections, s => s.identifier === selectedSelection.optionSelectionIdentifier) : undefined;

  return (
    <FormGroup key={`product-option-${identifier}`}>
      <h4>
        {
          optionSelection && 
          <ProductFormSectionPrice
            value={optionSelection.price}
            alwaysShowPlusMinus={true}
          />
        }
        <span>{name}</span>
      </h4>
      <div className="Product_Option">
        {children}
      </div>
    </FormGroup>
  );
}

const getInputValue = (selectedSelection, inputId) => {
  const haveInput = !!(selectedSelection && selectedSelection.inputValues && selectedSelection.inputValues[inputId]);
  return haveInput ? selectedSelection.inputValues[inputId] : "";
}

const ProductSingleSelectionInputsOption = (props) => {

  const {
    option: {selections}, 
    selectedSelection,
    onChange, onReset
  } = props;

  const [selection] = selections;
  const {identifier: optionSelectionIdentifier} = selection;

  return (
    <ProductOptionWrapper {...props} from="ProductSingleSelectionInputsOption">
      <ProductOptionSelectionInputs
        inputs={selection.inputs}
        getInputValue={getInputValue.bind(null, selectedSelection)}
        onChange={onChange.bind(null, optionSelectionIdentifier)}
        onReset={onReset}
      />
    </ProductOptionWrapper>
  );
}

class ProductOptionWithGroups extends React.Component {

  hasTestedToSetSelectedOptionGroupIdFromPickedSelection = false;

  constructor(props) {
    super(props);

    this.state = {
      selectedOptionGroupId: this.getInitialGroupId()
    }

    this.setSelectedOptionGroup = this.setSelectedOptionGroup.bind(this);
  }

  getInitialGroupId() {
    const {
      defaultSelectionGroupId,
      option: {
        groups: [firstOptionGroup],
      }
    } = this.props;

    return defaultSelectionGroupId || firstOptionGroup.id;
  }

  setSelectedOptionGroup(optionGroupId = null) {
    this.setState({
      selectedOptionGroupId: optionGroupId
    }, this.onSetSelectedOptionGroup.bind(this, optionGroupId));
  }

  onSetSelectedOptionGroup(optionGroupId) {
    // Nothing .
  }

  getSelectionsByOptionGroupId(selectedOptionGroupId) {
    const {option: {selections}} = this.props;
    return selections.filter(({groupId}) => groupId === selectedOptionGroupId);
  }

  getVisibleSelections() {
    const {selectedOptionGroupId} = this.state;
    return this.getSelectionsByOptionGroupId(selectedOptionGroupId);
  }

  maybeSetSelectedOptionGroupIdFromPickedSelection() {

    if(this.hasTestedToSetSelectedOptionGroupIdFromPickedSelection) {
      return;
    }

    const {
      option,
      selectedSelection
    } = this.props;

    if(selectedSelection) {
      const selection = find(option.selections, s => s.identifier === selectedSelection.optionSelectionIdentifier);
      
      if(selection !== undefined) {
        
        const pickedSelectionGroupId = selection.groupId;

        if(pickedSelectionGroupId !== this.state.selectedOptionGroupId) {
          this.setSelectedOptionGroup(pickedSelectionGroupId);
        }
      }

      this.hasTestedToSetSelectedOptionGroupIdFromPickedSelection = true;
    }
  }

  componentDidMount() {
    this.maybeSetSelectedOptionGroupIdFromPickedSelection()
  }

  componentDidUpdate() {
    this.maybeSetSelectedOptionGroupIdFromPickedSelection();
  }

  renderOptionGroupFilter() {
    const {option: {groups}} = this.props;

    return (
      <ProductOptionGroupSelectionFilter
        optionGroups={groups}
        selectedOptionGroupId={this.state.selectedOptionGroupId}
        onChange={this.setSelectedOptionGroup}
        onReset={this.setSelectedOptionGroup.bind(this, null)}
      />
    )
  }

  renderFilters() {
    const {option: {groups}} = this.props;

    return (
      <div>
        {groups.length > 1 && this.renderOptionGroupFilter()}
      </div>
    )
  }
}


class ProductOneSelectionPerGroupWithInputsOption extends ProductOptionWithGroups {

  onSetSelectedOptionGroup(optionGroupId) {
    const {selectedSelection, onChange, onReset} = this.props;
    const {selectedOptionGroupId} = this.state;
    const [selection] = this.getSelectionsByOptionGroupId(selectedOptionGroupId);

    if(selectedSelection !== null) {
      if(optionGroupId === null) {
        onReset();
      }
      else if(selection.identifier !== selectedSelection.optionSelectionIdentifier) {
        onChange(selection.identifier, null, null);
      }
    }
  }

  render() {

    const {selectedSelection, onChange, onReset} = this.props;
    const {selectedOptionGroupId} = this.state;
    
    const [selection] = this.getVisibleSelections();

    return (

      <ProductOptionWrapper {...this.props} from="ProductOneSelectionPerGroupWithInputsOption">
        {this.renderFilters()}

        {
          selection !== undefined &&
          <ProductOptionSelectionInputs
            inputs={selection.inputs}
            getInputValue={getInputValue.bind(null, selectedSelection)}
            onChange={onChange.bind(null, selection.identifier)}
            onReset={onReset}
          />
        }
      </ProductOptionWrapper>
    );
  }
}


class ProductMultipleSelectionPerGroup extends ProductOptionWithGroups {

  render() {

    const {
      option: {groups, selections},
      option,
      selectedSelection,
      onChange,
      onReset
    } = this.props;

    const visibleSelections = this.getVisibleSelections();

    const _getSelectionDescription = () => {
      const selection = find(option.selections, ({identifier}) => identifier === selectedSelection.optionSelectionIdentifier);
      return (
        selection.description && 
        <div className="mt-2">
          <Markdown content={selection.description}/>
        </div>
        || null
      );
    }

    return (
      <ProductOptionWrapper {...this.props} from="ProductMultipleSelectionPerGroup">
        {this.renderFilters()}

        {
          visibleSelections.length > 0 &&
          <ProductOptionSelections
            selections={visibleSelections}
            optionId={option.id}
            selectedSelection={selectedSelection}
            onChange={onChange}
            onReset={onReset}
          />
        }

        {
          !!selectedSelection && (
            <div>
              {_getSelectionDescription()}
            </div>
          )
        }
      </ProductOptionWrapper>
    );
  }
}

class ProductMultipleSelectionPerGroupWithActivityFilter extends ProductMultipleSelectionPerGroup {
  componentDidUpdate() {
    
    const {selectedActivity, selectedSelection, onReset} = this.props;
    const visibleSelections = this.getVisibleSelections();

    if(selectedSelection !== null) {

      if(!selectedActivity || !find(visibleSelections, ({identifier}) => identifier === selectedSelection.optionSelectionIdentifier)) {
        onReset();
      }
    }
  }

  renderActivityFilter() {
    const {
      activityIdsInSelections
    } = this.props;

    return (
      <ProductOptionActivitySelectionFilter activityIds={activityIdsInSelections}/>
    )
  }

  renderFilters() {
    const parentFilters = super.renderFilters();

    return (
      <div>
        {this.renderActivityFilter()}
        {parentFilters}
      </div>
    );
  }

  getVisibleSelections() {
    const {selectedActivity} = this.props;

    if(!selectedActivity) {
      return [];
    }
    else {
      return super.getVisibleSelections().filter(({activityIds}) => (
        activityIds.indexOf('*') !== -1 ||
        activityIds.indexOf(selectedActivity.id) !== -1
      ));
    }
  }
}

const ConnectedProductMultipleSelectionPerGroupWithActivityFilter = connect((state, ownProps) => {
  const {activities, userSettings} = state;
  // const {activityIdsInSelections} = ownProps;

  // const activitiesInSelections = activities.filter(({id}) => activityIdsInSelections.indexOf(id) !== -1);
  const selectedActivity = find(activities, ({slug}) => slug === userSettings.activitySlug);

  return {
    // activitiesInSelections,
    selectedActivity
  }
})(ProductMultipleSelectionPerGroupWithActivityFilter);



class ProductOptionFactory extends React.Component {

  shouldComponentUpdate(nextProps) {
    const hasChanged = !isEqual(this.props.selectedSelection, nextProps.selectedSelection);
    return hasChanged;
  }

  componentDidMount() {
  }

  render() {
    const {option: {selections, groups}} = this.props;
    const [firstSelection] = selections;


    const optionIsASingleSelectionWithInputs = selections.length == 1 && groups.length == 1 && firstSelection.inputs.length > 0;
    if(optionIsASingleSelectionWithInputs) {
      return <ProductSingleSelectionInputsOption {...this.props}/>;
    }

    const optionHasOneSelectionPerGroupWithInputs = selections.length == groups.length && selections.length <= selections.reduce((accum, {inputs}) => accum + inputs.length, 0);
    if(optionHasOneSelectionPerGroupWithInputs) {
      return <ProductOneSelectionPerGroupWithInputsOption {...this.props}/>;
    }

    const activityIdsInSelections = _getActivityIdsFromOptionSelections(selections);
    const selectionsHaveActivities = activityIdsInSelections.length > 0;
    if(selectionsHaveActivities) {
      return <ConnectedProductMultipleSelectionPerGroupWithActivityFilter {...this.props} activityIdsInSelections={activityIdsInSelections}/>;
    }

    return <ProductMultipleSelectionPerGroup {...this.props}/>;
  }
}

export default ProductOptionFactory;

export const ProductOptionLoading = (props) => (
  <ProductOptionWrapper {...props} from="loading">
    <div style={{border: "1px solid rgba(0, 0, 0, .2", padding: "10px"}}>
      <LoadingIndicator/>
    </div>
  </ProductOptionWrapper>
)

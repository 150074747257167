import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import { Link } from 'react-router-dom';

import { sortBy } from '../../utils/array';
import { caseSelector } from '../../utils/helpers';


import { waitUntilSatisfyingState } from '../hoc';
import { scrollToTop } from '../hoc';


import DocumentMeta from '../../components/documentMeta';
import { TwoColumnLayout } from '../../components/layouts';
import SideColumn from '../../components/SideColumn';

import BlockArea from '../BlockArea';
import ActivitySlider from '../../components/ActivitySlider';

import routes from '../../routes';



const ActivityArchivePage = scrollToTop()(translate('activities')(({activities, numOfCols, t}) => {
  const blockConditions = ['page:activityArchive'];
  // style={{display: "inline-block", width: `calc(100%/${numOfCols})`}}
  return (
    <div>
      <DocumentMeta
        title={t('title')}
        description={t('description')}
        url={routes.ACTIVITY_ARCHIVE.get()}
      />

      <TwoColumnLayout
        columnOne={(
          <div>
            <BlockArea areaSlug="main-top" conditions={blockConditions}/>

            <div className="mt-4">
              <h2>{t('sections.featured.header')}</h2>
              <ActivitySlider activities={activities.filter(({isFeatured}) => isFeatured)}/>
            </div>

            <BlockArea areaSlug="main" conditions={blockConditions}/>

            <div className="mt-4">
              <h2>{t('sections.all.header')}</h2>

              <p style={{columnCount: numOfCols}}>
                {sortBy(activities, a => a.name).map(({id, slug, name}, i) => (
                  <span key={id} style={{display: 'block'}}>
                    <Link to={routes.ACTIVITY.get(slug)}>{name}</Link>
                  </span>
                ))}
                </p>
            </div>

          </div>
        )}
        columnTwo={(
          <SideColumn blockConditions={blockConditions}/>
        )}
      />

    </div>
  );
}));

export default waitUntilSatisfyingState(
  (state) => state.activities.length > 0,
  ({activities, client}) => ({
    activities,
    numOfCols: caseSelector({
      small: 2,
      medium: 3,
      large: 3,
      extraLarge: 4,
    }, 2)(client.mediaType)
  })
)(ActivityArchivePage);
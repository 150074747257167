import config from './config';

function productAndCategoryTreeToGaItem(product, categoryTree) {
  return {
    item_id: `${product.identifier}/${product.selectedArticleIdentifier}`,
    item_name: product.name,
    item_variant: product.selectedArticleIdentifier,
    item_category: categoryTree[0] ? categoryTree[0].name : undefined,
    item_category2: categoryTree[1] ? categoryTree[1].name : undefined,
    item_category3: categoryTree[2] ? categoryTree[2].name : undefined,
    item_category4: categoryTree[3] ? categoryTree[3].name : undefined,
    item_category5: categoryTree[4] ? categoryTree[4].name : undefined,
  }
}

function _triggerEvent(eventName, eventParameters) {
  gtag('event', eventName, eventParameters)
}

export default {
  event: _triggerEvent,
  purchase(orderId, orderTotal, orderTax, orderShipping, orderRowBundles) {
    _triggerEvent('purchase', {
      currency: 'SEK',
      transaction_id: orderId,
      value: orderTotal,
      tax: orderTax,
      shipping: orderShipping,
      items: orderRowBundles.map(({ product, categoryTree, row }) => ({
        ...productAndCategoryTreeToGaItem(
          product,
          categoryTree
        ),
        quantity: row.quantity,
        price: row.unitPrice
      }))
    })
  }
}
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export function getArticleOfAttributeValueId(articles, attributeId, attributeValueId) {
  return find(
    articles,
    ({ attributeValueIds }) => attributeValueIds[attributeId] === attributeValueId
  )
}


export function sumProductFieldInputPrices(productFieldInputPrices) {
  return productFieldInputPrices.reduce((subSum, productFieldInputPrice) => (
    subSum +
    get(productFieldInputPrice, 'additionalPriceCase.price', 0)
  ), 0)
}

export function sumPriceBundles(priceBundles, includeProductFieldInputPrice = false) {
  return priceBundles.reduce((sum, { price, data: { quantity }, productFieldInputPrices }) => (
    sum +
    quantity * (
      price +
      (
        includeProductFieldInputPrice ?
          sumProductFieldInputPrices(productFieldInputPrices) :
          0
      )
    )
  ), 0)
}


export function productFieldInputIsEmpty(productFieldInputBundle) {
  const {
    productFieldId, /* eslint-disable-line */
    ...productFieldInput
  } = productFieldInputBundle

  return isEmpty(productFieldInput)
}


export function createProductFieldInput(productFieldId, productFieldInputData) {
  return {
    productFieldId: Number(productFieldId),
    ...productFieldInputData
  }
}
import React from 'react';

import {
  getOrders
} from '../api';

import { formatPrice, toFormattedDateStr } from '../utils/content';
import { waitUntilPromiseIsResolved } from '../containers/hoc';

import { UserOrdersTranslate, Translate } from '../containers/translates';

import ModalButton from './modals/ModalButton';
import { ModalWithClose } from './modals/Modal';
import OrderModalContent from './modals/OrderModalContent';


function OrderModalButton({ customerId, orderId, children }) {
  return (
    <ModalButton
      ModalComponent={({ onClose, ...modalProps }) => (
        <ModalWithClose
          onClose={onClose}
          {...modalProps}
          style={{
            width: 'calc(100vw - 40px)',
            maxWidth: 1024,
          }}
        >
          <OrderModalContent
            {...{
              customerId,
              orderId
            }}
          />
        </ModalWithClose>
      )}
      ButtonComponent={({ onClick }) => (
        <a href="javascript:;" onClick={onClick}>
          {children}
        </a>
      )}
    />
  )
};

function OrderListComponent({ orders, customerId, ...props }) {
  return (
    <Translate ns="orders">
      {({ t }) => (
        <table className="table">
          <thead>
            <tr>
              <th>{t('table.orderDate.column')}</th>
              <th>{t('table.madeByUser.column')}</th>
              <th className="text-right">{t('table.amount.column')}</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, orderIndex, orders) => (
              <tr key={order.id}>
                <td>
                  <OrderModalButton
                    customerId={customerId}
                    orderId={order.id}
                    order={order}
                  >
                    <i className="fa fa-shopping-cart" /> {toFormattedDateStr(order.orderDate)}
                  </OrderModalButton>
                </td>
                <td>{order.byUserName}</td>
                <td className="text-right">{formatPrice(order.amount.total, 'kr')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Translate>
  )
}

function EmptyOrderListComponent() {
  return (
    <Translate ns="orders">
      {({ t }) => (
        <React.Fragment>
          <p>{t('empty.description')}</p>
        </React.Fragment>
      )}
    </Translate>
  )
}

const OrderList = waitUntilPromiseIsResolved(function ({ customerId }) {
  return getOrders(customerId);
}, ({ items: orders }) => ({
  orders
}))(props => (
  <UserOrdersTranslate>
    {({ t }) => (
      <div>
        <h2>{t('header')}</h2>
        {
          props.orders.length > 0 ?
            <OrderListComponent {...props} /> :
            <EmptyOrderListComponent {...props} />
        }
      </div>
    )}
  </UserOrdersTranslate>
));

export default OrderList;
export default {
  test: "testar {{name}}",
  slides: "Slidear",
  home: "Hem",
  activities: "Sporter",
  title: "Ingen rubrik",
  title_notFound: "Sidan hittades inte (404)",
  notFound: {
    title: "404 - sidan hittades inte",
    description: "Nånting blev fel .."
  },
  actions: {
    add: "Lägg till",
    edit: "Ändra"
  }
}
export const PRODUCTS = {
  FETCH: {
    REQUEST: 'PRODUCTS.FETCH.REQUEST',
    RECEIVE: 'PRODUCTS.FETCH.RECEIVE',
    FAILURE: 'PRODUCTS.FETCH.FAILURE'
  },

  INVALIDATE: 'PRODUCT.INVALIDATE',
  GET: 'PRODUCTS.GET'
};

export const requestProducts = (filters) => {
  return {
    type: PRODUCTS.FETCH.REQUEST,
    filters
  }
}

export const receiveProducts = (items, totalNumOfItems) => {
  return {
    type: PRODUCTS.FETCH.RECEIVE,
    items, totalNumOfItems
  }
}

export const failedProducts = (error) => {
  return {
    type: PRODUCTS.FETCH.FAILURE,
    error
  }
}

export const invalidateProducts = () => {
  return {
    type: PRODUCTS.INVALIDATE
  }
}

export const getProducts = (filters = {}) => {
  return {
    type: PRODUCTS.GET,
    filters
  }
}

export const PRODUCT_FILTERS = {
  CATEGORIES: {
    ADD: 'PRODUCT_FILTERS.CATEGORIES.ADD',
    REMOVE: 'PRODUCT_FILTERS.CATEGORIES.REMOVE',
    CHANGE: 'PRODUCT_FILTERS.CATEGORIES.CHANGE',
    RESET: 'PRODUCT_FILTERS.CATEGORIES.RESET',
  },
  
  ACTIVITIES: {
    ADD: 'PRODUCT_FILTERS.ACTIVITIES.ADD',
    REMOVE: 'PRODUCT_FILTERS.ACTIVITIES.REMOVE',
    CHANGE: 'PRODUCT_FILTERS.ACTIVITIES.CHANGE',
    RESET: 'PRODUCT_FILTERS.ACTIVITIES.RESET',
  },

  // CHANGE: 'PRODUCT_FILTERS.CHANGE',
  // RESET: 'PRODUCT_FILTERS.RESET'
}

export const addCategoryToProductsFilter = (categoryId) => {
  return {
    type: PRODUCT_FILTERS.CATEGORIES.ADD,
    categoryId
  }
}

export const removeCategoryToProductsFilter = (categoryId) => {
  return {
    type: PRODUCT_FILTERS.CATEGORIES.REMOVE,
    categoryId
  }
}

export const changeCategoryToProductsFilter = (categoryId) => {
  return {
    type: PRODUCT_FILTERS.CATEGORIES.CHANGE,
    categoryId
  }
}

export const resetCategoryInProductsFilter = () => {
  return {
    type: PRODUCT_FILTERS.CATEGORIES.RESET
  }
}

export const addActivityToProductsFilter = (activityId) => {
  return {
    type: PRODUCT_FILTERS.ACTIVITIES.ADD,
    activityId
  }
}

export const removeActivityToProductsFilter = (activityId) => {
  return {
    type: PRODUCT_FILTERS.ACTIVITIES.REMOVE,
    activityId
  }
}

export const changeActivityToProductsFilter = (activityId) => {
  return {
    type: PRODUCT_FILTERS.ACTIVITIES.CHANGE,
    activityId
  }
}


export const resetActivityInProductsFilter = () => {
  return {
    type: PRODUCT_FILTERS.ACTIVITIES.RESET
  }
}


export const PRODUCT_ORDERING = {
  CHANGE: 'PRODUCT_ORDERING.CHANGE',
  RESET: 'PRODUCT_ORDERING.RESET',
  OPTIONS: [
    ['NAME', 'Name'],
    ['PRICE_ASC', 'Price in ascending order'],
    ['PRICE_DESC', 'Price in descending order'],
    ['ARTICLE_NUMBER_ASC', 'Article number'],
  ]
}

export const changeProductOrdering = (ordering) => {
  return {
    type: PRODUCT_ORDERING.CHANGE,
    ordering
  }
}

export const resetProductOrdering = () => {
  return {
    type: PRODUCT_ORDERING.RESET
  }
}

import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import config from './config';

import sv_SE from './locales/sv-SE';

i18n
  .use(initReactI18next)
  .init({
    lng: 'sv-SE',
    fallbackLng: 'en',

    // have a common namespace used around the full app
    // ns: ['common'],
    // defaultNS: 'common',

    debug: config.DEBUG,

    interpolation: {
      escapeValue: false // not needed for react!!
    },
    resources: {
      "sv-SE": sv_SE
    }
  }, () => {
    // CALLBACK FOR TESTING
  });


export default i18n;

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import memoize from 'lodash/memoize'

import find from 'lodash/find';

import {
  getActivityById,
  getActivityBySlug
} from '../../selectors';

import {
  setUserActivity
} from '../../actions';

import ActivityIdContext from '../contexts/ActivityIdContext'

import Select from '../forms/Select';

class ProductOptionSelectionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this._noValue = (props.multi ? [] : '');
  }

  onChange(selected, ...args) {
    const {
      onChange,
      onReset,
      valueTransform = v => v,
      multi
    } = this.props;

    if (!multi) {
      if (selected) {
        onChange(selected.value)
      }
      else {
        onReset()
      }
    }
    else {
      throw new Error("TODO: Does not support multi")
    }

    // const isASelection = (
    //   multi ?
    //     selected.length > 0 :
    //     selected !== null
    // )

    // if (isASelection) {
    //   const value = multi ? selected.split(",").map(valueTransform) : valueTransform(selected);
    //   onChange(value);
    // }
    // else {
    //   onReset(multi ? [] : null);
    // }
  }

  getSelectOptions = memoize((filterOptions) =>
    filterOptions.map(({ id, value, text }) => ({ label: text, value }))
  )

  render() {
    const {
      filterOptions,
      selectedFilterValue,
      placeholder,
      selectedValueLabel,
      multi = false,
      isDisabled = false
    } = this.props;

    const selectOptions = this.getSelectOptions(filterOptions);

    return (
      <Select
        options={selectOptions}
        value={selectedFilterValue}
        placeholder={placeholder}
        onChange={this.onChange}
        selectedValueLabel={selectedValueLabel}
        multi={multi}
        isDisabled={isDisabled}
      />
    );
  }
}

export const ProductOptionGroupSelectionFilter = translate("product")(({ optionGroups, selectedOptionGroupId = null, onChange, onReset, t }) => (
  <ProductOptionSelectionFilter
    filterOptions={optionGroups.map(({ id, name }) => ({ id: id, value: id, text: name }))}
    valueTransform={Number}
    selectedFilterValue={selectedOptionGroupId || ''}
    placeholder={t("options.selectionFilter.noSelected", { context: "optionGroup" })}
    onChange={onChange}
    onReset={onReset}
    selectedValueLabel={t('options.selectionFilter.selectedValue.label', { context: "optionGroup" })}
  />
));

const ActivitySelectionFilter = translate("product")(({ activities, activitySlugInUserSettings, setUserActivity, t }) => (
  <ActivityIdContext.Consumer>
    {(activityId) => {

      const selectedActivitySlug = activityId !== null ? find(activities, (a) => a.id === activityId).slug : null

      return (
        <ProductOptionSelectionFilter
          filterOptions={activities.map(({ slug, name }) => ({ id: slug, value: slug, text: name }))}
          valueTransform={String}
          selectedFilterValue={selectedActivitySlug || ''}
          placeholder={t("options.selectionFilter.noSelected", { context: "activity" })}
          onChange={setUserActivity}
          onReset={() => setUserActivity(null)}
          selectedValueLabel={t('options.selectionFilter.selectedValue.label', { context: "activity" })}
          isDisabled={activitySlugInUserSettings !== null && activitySlugInUserSettings !== selectedActivitySlug}
        />
      )
    }}
  </ActivityIdContext.Consumer>
));

export const ProductOptionActivitySelectionFilter = connect((state, ownProps) => {
  const { userSettings } = state;
  const { activityIds } = ownProps;

  return {
    activitySlugInUserSettings: userSettings.activitySlug,
    activities: state.activities.filter(x => activityIds.indexOf(x.id) !== -1)
  }
}, {
    setUserActivity
  })(ActivitySelectionFilter);


export const ProductOptionTagsFilter = translate("product")(connect((state, { tagIds }) => {

  return {
    tags: state.tags
      .filter(x => tagIds.indexOf(x.id) !== -1)
      .map(
        tag => ({
          ...tag,
          categoryName: (find(state.tagCategories, x => x.id === tag.categoryId) || { 'name': 'no name' }).name
        })
      )
  }
})(({ tags, selectedTagIds = [], onChange, onReset, t }) => (
  <ProductOptionSelectionFilter
    filterOptions={tags.map(
      ({ id, name, categoryName }) => ({
        id: id,
        value: id,
        text: `${categoryName ? `${categoryName} / ` : ''}${name}`,
      })
    )}
    valueTransform={Number}
    selectedFilterValue={selectedTagIds}
    placeholder={t("options.selectionFilter.noSelected", { context: "tag" })}
    onChange={onChange}
    onReset={onReset}
    selectedValueLabel={t('options.selectionFilter.selectedValue.label', { context: "tag" })}
    multi
  />
)));
import React from 'react';
import { ImageSlider } from '../../containers/Slider';
import ProductImagesModal from '../modals/ProductImagesModal';

export default class ProductDetailImages extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeImage: null
    }

    this.openImageModal = this.openImageModal.bind(this);
    this.closeImageModal = this.closeImageModal.bind(this);
  }

  openImageModal(image) {
    this.setState({
      activeImage: image
    })
  }

  closeImageModal() {
    this.setState({
      activeImage: null
    })
  }

  render() {
    const {images, urlSelector = image => ({...image}), onClick} = this.props;
    const {activeImage} = this.state;
    const isOpen = activeImage !== null;

    return (
      <div>
        <ImageSlider
          images={images.map(urlSelector)}
          onClick={this.openImageModal}
          className="--big --clickable --zoomable"
        />

        {
          isOpen &&
          <ProductImagesModal
            isOpen={isOpen}
            image={activeImage}
            onClose={this.closeImageModal}
          />
        }
      </div>
    );
  }
}
import React from 'react'
import PropTypes from 'prop-types'

import {
  orderApi
} from 'api'

import CustomerForm from './CustomerForm'

export default function CreateCustomerFormWrapper({ t, fields, onSubmit, initialValues }) {
  const handleSubmit = React.useCallback((payload) => {
    orderApi.createCustomer({
      ...initialValues,
      ...payload
    })
      .then(({ createdIds }) => {
        if (onSubmit) {
          onSubmit(createdIds[0])
        }
      })
  })

  return (
    <CustomerForm
      t={t}
      fields={fields}
      onSubmit={handleSubmit}
    />
  )
}

CreateCustomerFormWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  fields: PropTypes.array,
  onSubmit: PropTypes.func,
}

CreateCustomerFormWrapper.defaultProps = {
  initialValues: {}
}
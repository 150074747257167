import React from 'react'
import Textarea from './Textarea'
import DatePicker from './DatePicker'
import Select from './Select'


import './Input.scss'

class _Input extends React.Component {

  updateValidationErrors() {
    this.el.setCustomValidity(this.props.validationErrors.map(x => x.error).join(", ") || '')
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.el.focus()
      this.el.select()
    }

    this.updateValidationErrors()
  }

  componentDidUpdate() {
    this.updateValidationErrors()
  }

  parseInputValue(value) {
    switch (this.props.type) {
      case 'number':
        return value.length ? Number(value) : value
      default:
        return value
    }
  }

  getInputValue(e) {
    return (
      (this.props.getInputValue) ||
      ((el) => this.parseInputValue(el.value))
    )(this.el)
  }

  onChange = (e) => {
    this.props.onChange(this.getInputValue(e), e)
  }

  ref = (el) => {
    this.el = el
  }

  render() {
    const {
      extras,
      className,
      addFormControlClassName = true,
      style,
      type,
      onChange,
      validationErrors,
      focusOnMount,
      ...inputProps
    } = this.props

    const classNames = [
      addFormControlClassName && 'form-control',
      ...(addFormControlClassName ? extras.map((x) => `form-control-${x}`).join(" ") : []),
      ...className.split(" ")
    ].filter(Boolean)

    return (
      <input
        className={classNames.join(' ')}
        style={style}
        type={type}
        onChange={this.onChange}
        ref={this.ref}
        {...inputProps}
      />
    )
  }
}

function SingleFileInput({ name, onChange }) {
  return (
    <input
      className="form-control"
      name={name}
      type="file"
      onChange={(e) => {
        onChange(e.target.files[0], e)
      }}
    />
  )
}

export default function Input({
  inputName = null,
  placeholder,
  value,
  onChange,
  type = "text",
  extras = [],
  className,
  addFormControlClassName,
  style,
  options,
  multi,
  busy,
  validationErrors = [],
  hasTriedSubmit,
  ...props
}) {

  const sharedProps = {
    name: inputName,
    placeholder,
    value,
    onInvalid: (e) => e.target.classList.add('--has-been-validated'),
    disabled: busy,
    className,
    style,
    ...props,
  }

  switch (type) {
    case 'textarea':
      return (
        <Textarea
          onChange={onChange}
          extras={extras}
          {...sharedProps}
        />
      )

    case 'datepicker':
      return (
        <DatePicker
          onChange={onChange}
          validationErrors={validationErrors}
          hasTriedSubmit={hasTriedSubmit}
          {...sharedProps}
        />
      )

    case 'select':
      return (
        <Select
          onChange={onChange}
          options={options}
          multi={multi}
          {...sharedProps}
        />
      )

    case 'file':
      return (
        <SingleFileInput
          name={name}
          type={type}
          onChange={onChange}
          validationErrors={validationErrors}
          extras={extras}
          {...sharedProps}
        />
      )

    default:
      return (
        <_Input
          type={type}
          onChange={onChange}
          validationErrors={validationErrors}
          extras={extras}
          addFormControlClassName={addFormControlClassName}
          {...sharedProps}
        />
      )
  }
}

Input.defaultProps = {
  className: "",
  style: {}
}
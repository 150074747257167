import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import { ModalWithClose, OVERFLOWS } from './Modal';
// import SelectCustomerForm from '../forms/SelectCustomerForm';
import UserCustomers from 'components/UserCustomers';

export default translate('user')(function _SelectCustomerModal({ onClose, onDone, onSubmit, t, ...props }) {
  return (
    <ModalWithClose overflow={OVERFLOWS.VISIBLE} onClose={onClose} {...props}>
      <div>
        <UserCustomers
          t={t}
          onSubmit={onSubmit}
          onDone={(...args) => {
            if (onDone) {
              onDone(...args);
            }
            onClose(...args);
          }}
        />
      </div>
    </ModalWithClose>
  )
});
import React from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';

class DropdownWidget extends React.Component {

  onChange = (e) => {
    this.props.onChange(Number(e.target.value))
  }

  render() {
    const {
      selections,
      selectedSelectionId
    } = this.props

    return (
      <select
        className="form-control"
        onChange={this.onChange}
        value={selectedSelectionId}
      >
        {selections.map(({ id, name }) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </select>
    )
  }
}


DropdownWidget.propTypes = {
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedSelectionId: PropTypes.number
}

export default DropdownWidget
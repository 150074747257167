import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { getProductCategoryById } from 'selectors';
import Card, { CardImage, CardBody, CardBodyHeader } from 'components/Card'


function ProductCategoryCard({ productCategory, ...cardProps }) {
  return (
    <Card {...cardProps}>
      {productCategory.image && <CardImage image={productCategory.image} />}
      <CardBody>
        <CardBodyHeader text={productCategory.name} />
      </CardBody>
    </Card>
  )
}

ProductCategoryCard.propTypes = {
  productCategory: PropTypes.object.isRequired,
}

export default connect(
  (state, { productCategoryId }) => {
    return {
      productCategory: getProductCategoryById(state, productCategoryId)
    }
  }
)(ProductCategoryCard)


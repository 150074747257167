import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  setDeliveryMethod,
  setDeliveryAddress,
} from 'actions'

import Table from 'components/Table'
import DeliveryMethodPriceColumn from './DeliveryMethodPriceColumn'
import DeliveryMethodMainColumn from './DeliveryMethodMainColumn'


function OrderDeliveryMethodSelectionForIndividual({
  deliveryMethods,
  selectedDeliveryMethodId,
  setDeliveryMethod,
  setDeliveryAddress,
  customer,
}) {

  const handleSelectDeliveryMethod = React.useCallback((dataItem) => {
    setDeliveryMethod(dataItem.id)
    setDeliveryAddress(customer.id)
  }, [setDeliveryMethod, setDeliveryAddress, customer])

  const deliveryMethodColumns = React.useMemo(() => [
    {
      key: 'main',
      render(dataItem) {
        return (
          <DeliveryMethodMainColumn
            deliveryMethod={dataItem}
            isSelected={dataItem.isSelected}
            showDeliveryAddress={false}
          />
        )
      }
    },
    {
      key: 'price',
      className: "text-right",
      render(dataItem) {
        return (
          <DeliveryMethodPriceColumn
            deliveryMethod={dataItem}
            isSelected={dataItem.isSelected}
          />
        )
      }
    },
  ], [])

  const deliveryMethodData = React.useMemo(() => deliveryMethods.map((deliveryMethod) => ({
    ...deliveryMethod,
    isSelected: deliveryMethod.id === selectedDeliveryMethodId,
    key: deliveryMethod.id,
  })), [deliveryMethods, selectedDeliveryMethodId])


  return (
    <React.Fragment>
      <Table
        columns={deliveryMethodColumns}
        data={deliveryMethodData}
        showSelectable
        selectedKey={selectedDeliveryMethodId}
        onSelect={handleSelectDeliveryMethod}
      />
    </React.Fragment>
  )
}

OrderDeliveryMethodSelectionForIndividual.propTypes = {
  deliveryMethods: PropTypes.array.isRequired,
  selectedDeliveryMethodId: PropTypes.number,
  customer: PropTypes.object.isRequired,
  setDeliveryMethod: PropTypes.func.isRequired,
  setDeliveryAddress: PropTypes.func.isRequired,
}

export default connect(state => ({
  deliveryMethods: state.order.availableDeliveryMethods,
  selectedDeliveryMethodId: state.order.deliveryMethodId,
  customer: state.customer,
}), {
    setDeliveryMethod,
    setDeliveryAddress,
  }
)(OrderDeliveryMethodSelectionForIndividual)
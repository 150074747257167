import React from 'react';
import PropTypes from 'prop-types'

import { patterns } from 'utils/values';

import Form, { FormInput, LabeledFormInput, FormButton } from 'components/forms/Form';
import { FormGroup, Label, Input, Alert } from 'reactstrap';


export default function CreateLocalSelectionForm({ selectionTemplates, onSubmit, t }) {
  return (
    <Form
      t={t}
      valueRules={[
        {
          key: "file",
          required: true
        },
        {
          key: "name",
          required: true,
          // ...patterns.retypePassword,
          // method: (value, values) => {
          //   const retypeMatches = values.password === values.retypePassword;
          //   return [
          //     retypeMatches,
          //     "noMatch"
          //   ];
          // }
        },
        {
          key: "selectionTemplateId",
          required: true
        }
      ]}
      onSubmit={onSubmit}
      onChange={(key, keyState, state, setState) => {
        if (key === 'file') {
          const file = keyState
          setState({
            name: file.name
          })
        }
      }}
    >
      {({ getStateOf, getValidationErrorsOf, onInputChangeFactory, onInputChange, hasTriedSubmit }) => {

        const selectionTemplateIdValidationErrors = getValidationErrorsOf('selectionTemplateId')

        return (
          <React.Fragment>

            <FormGroup>
              <LabeledFormInput
                {...{ t }}
                inputName="file"
                inputType="file"
              />
            </FormGroup>

            <FormGroup>
              <LabeledFormInput
                {...{ t }}
                inputName="name"
                inputType="text"
              />
            </FormGroup>

            <FormGroup tag="fieldset">
              <legend className="h3">{t('inputs.selectionTemplate')}</legend>
              {
                selectionTemplates.map((selectionTemplate, selectionTemplateIndex) => {
                  const isSelected = getStateOf('selectionTemplateId') === selectionTemplate.id

                  return (
                    <FormGroup check key={selectionTemplateIndex}>
                      <Label check>
                        <Input
                          type="radio"
                          checked={isSelected}
                          onChange={() => onInputChange('selectionTemplateId', selectionTemplate.id)}
                        /> {selectionTemplate.name}
                      </Label>
                    </FormGroup>
                  )
                })
              }
              {
                hasTriedSubmit && selectionTemplateIdValidationErrors.length > 0 && (
                  <Alert className="mt-2" color="danger">
                    {selectionTemplateIdValidationErrors.map(({ error }) => error).join(", ")}
                  </Alert>
                )
              }
            </FormGroup>

            <hr />
            <FormGroup>
              <FormButton color="primary">
                {t('submit.label')}
              </FormButton>
            </FormGroup>
          </React.Fragment>
        )
      }}
    </Form>
  )
};


CreateLocalSelectionForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectionTemplates: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired
}
import React from 'react'
import PropTypes from 'prop-types'

import Modal, { ModalBody, LARGE } from 'components/modals/Modal'
import { Event } from './props'
import ActivityIdContext from '../contexts/ActivityIdContext'

import OrderSuggestionSteps from './OrderSuggestionSteps'

class OrderSuggestionModal extends React.Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    event: PropTypes.shape(Event.propTypes).isRequired
  }

  handleComplete = () => {
    this.props.onClose()
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <ModalBody size={LARGE}>
          <React.Fragment>
            <ActivityIdContext.Provider value={this.props.event.activityId}>
              <OrderSuggestionSteps
                event={this.props.event}
                onComplete={this.handleComplete}
              />
            </ActivityIdContext.Provider>
          </React.Fragment>
        </ModalBody>
      </Modal>
    )
  }
}


export default OrderSuggestionModal
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import {
  setUseDate
} from '../../actions';

import { ModalWithClose } from './Modal';
import Input from '../forms/Input';

import { withRouter } from "react-router-dom";
import routes from '../../routes';


import BlockArea from '../../containers/BlockArea';
import UseDateInput from '../UseDateInput';


const SetUseDateForm = connect(state => ({
  useDate: state.order.useDate
}), {
    setUseDate
  })(translate('order')(class _SetUseDateForm extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        useDate: null
      }

      this.onChangeUseDate = this.onChangeUseDate.bind(this);
      this.saveUseDate = this.saveUseDate.bind(this);
    }

    onChangeUseDate(useDate) {
      var regex = /(\d{4})(-?)/;

      this.setState({
        useDate: useDate
      }, (state) => { });
    }

    saveUseDate() {
      this.props.setUseDate(this.state.useDate);
    }

    render() {

      const { t, saveUseDate } = this.props;

      return (
        <div className="alert alert-info">
          <BlockArea
            areaSlug="modal-content"
            conditions={['modal:added-to-order--set-use-date']}
          />
          <div>
            <label className="d-block">
              <span>{t('modals.addedRow.useDate.label')}</span>
              <div>
                <UseDateInput
                  onChange={this.onChangeUseDate}
                  value={this.state.useDate}
                  t={t}
                />
              </div>
            </label>

            <button onClick={this.saveUseDate} className="btn btn-primary btn-sm">{t('modals.addedRow.buttons.saveUseDate')}</button>
          </div>
        </div>
      );
    }
  }));


export default withRouter(connect(state => ({
  useDate: state.order.useDate
}), {
    setUseDate
  })(translate('order')(({ product, orderRow, useDate, onClose, t, history, ...props }) => (

    <ModalWithClose onClose={onClose} {...props}>
      <div>
        <BlockArea
          areaSlug="modal-content"
          conditions={['modal:added-to-order']}
          context={{ productName: product.name, count: orderRow.quantity }}
          defaultContent={(
            <div>
              <h2>{t('modals.addedRow.header', { productName: product.name })}</h2>
              <p>{t('modals.addedRow.body', { productName: product.name, count: orderRow.quantity })}</p>
            </div>
          )}
        />

        <hr />

        {
          useDate === null ?
            <SetUseDateForm /> :
            <div>
              <button onClick={() => {
                onClose();
                history.push(routes.ORDER.get());
              }} className="btn btn-outline-dark">{t('modals.addedRow.buttons.toCheckout')}</button>
              <button onClick={onClose} className="btn btn-outline-dark">{t('modals.addedRow.buttons.backToShopping')}</button>
            </div>
        }
      </div>
    </ModalWithClose>
  ))));
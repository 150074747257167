import { USER } from '../actions';
import { cleanObject } from '../utils/object';

const initState = {
  state: null,
  id: undefined,
  type: undefined,
  name: '',
  customer: undefined,
  lastSelectedCustomerId: undefined,
  defaultCustomerId: undefined,
  customerAccess: [],
};


function state_(state = initState.state, action) {
  switch(action.type) {
    case USER.FETCH.REQUEST:
      return 'syncing';
    case USER.RESET:
      return initState.state;
    case USER.FETCH.RECEIVE:
      return 'loaded';
    case USER.FETCH.FAILURE:
      return 'error';
    default:
      return state;
  }
}

export default function user(state = initState, action) {
  switch (action.type) {
    case USER.FETCH.REQUEST:
      return {
        ...state,
        state: state_(state.state, action),
      }
    case USER.FETCH.RECEIVE:
      return {
        ...state,
        ...action.data,
        state: state_(state.state, action),
      }
    case USER.RESET:
      return initState;
    default:
      return state;
  }
}
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';
import { FormGroup } from 'reactstrap';

import { cleanObject } from '../utils/object';

import {
  setCustomerType
} from '../actions';

import Input from './forms/Input';
import { patterns } from '../utils/values';

const CustomerInput = translate('customer')(({ inputName, dataKey, data, dataGetter, context, type = 'text', onChange, pattern, title, required, showLabel = false, extras, t, disabled = false }) => {

  dataKey = dataKey || inputName;
  dataGetter = (data) => data[dataKey];

  return (
    <label className="d-block">
      {
        showLabel &&
        <span className="label">{t(`fields.${inputName}.label`, { context })}</span>
      }
      <Input
        inputName={inputName}
        type={type}
        placeholder={t(`fields.${inputName}.placeholder`, { context })}
        value={dataGetter(data) || ""}
        onChange={(value) => {
          onChange(dataKey, value);
        }}
        extras={extras}
        pattern={pattern}
        title={title && t(`inputs.title`, { context: title })}
        required={required}
        disabled={disabled}
      />
    </label>
  );
});

const IndividualFormGroups = translate('customer')(({ data, onChange, t }) => (
  <div>

    <FormGroup>
      <div className="row">
        <div className="col col-sm-6">
          <div className="row">
            <div className="col col-sm-12">
              <CustomerInput showLabel inputName="name" data={data} onChange={onChange} context="individual" extras={['lg']} {...patterns.name} required />
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
    <FormGroup>
      <div className="row">
        <div className="col col-sm-6">
          <div className="row">
            <div className="col col-12">
              <CustomerInput showLabel inputName="street" data={data} onChange={onChange} context="individual" {...patterns.street} required />
            </div>
          </div>

          <div className="row">
            <div className="col col-4">
              <CustomerInput showLabel inputName="zip" data={data} onChange={onChange} context="individual" {...patterns.zip} required />
            </div>
            <div className="col col-8">
              <CustomerInput showLabel inputName="city" data={data} onChange={onChange} context="individual" {...patterns.city} required />
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
    <FormGroup>
      <div className="row">
        <div className="col col-sm-6">
          <div className="row">
            <div className="col col-6">
              <CustomerInput showLabel inputName="mobile" data={data} onChange={onChange} context="individual" {...patterns.mobile} required />
            </div>
            <div className="col col-6">
              <CustomerInput showLabel type="email" inputName="email" data={data} onChange={onChange} context="individual" required />
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
  </div>
));

const UnionFormGroups = translate('customer')(({ data, onChange, separateDeliveryAddress, separateInvoiceAddress, onChangeSeparateDeliveryAddress, onChangeSeparateInvoiceAddress, t, mainCustomerIsEditable = false }) => (
  <div>
    <h3 className="text-center text-sm-left">{t('sections.union.heading')}</h3>

    <FormGroup>
      <div className="row">
        <div className="col col-xs-12 col-sm-6">
          <CustomerInput
            inputName="unionName" data={data} dataKey="name" onChange={onChange} context="union" extras={['lg']} {...patterns.name} required
            disabled={!mainCustomerIsEditable}
          />
        </div>
      </div>
    </FormGroup>

    <hr />

    <h3 className="text-center text-sm-left">{t('sections.delivery.heading')}</h3>

    <FormGroup>
      <div className="row">
        <div className="col col-sm-6">

          <div className="row">
            <div className="col col-sm-6">
              <CustomerInput showLabel required inputName="delivery.name" dataKey="delivery[0].name" data={data} onChange={onChange} context="union" {...patterns.name} />
            </div>
          </div>

          <div className="row">
            <div className="col col-12">
              <CustomerInput showLabel required inputName="delivery.street" dataKey="delivery[0].street" data={data} onChange={onChange} context="union" {...patterns.street} />
            </div>
          </div>

          <div className="row">
            <div className="col col-4">
              <CustomerInput showLabel required inputName="delivery.zip" dataKey="delivery[0].zip" data={data} onChange={onChange} context="union" {...patterns.zip} />
            </div>
            <div className="col col-8">
              <CustomerInput showLabel required inputName="delivery.city" dataKey="delivery[0].city" data={data} onChange={onChange} context="union" {...patterns.city} />
            </div>
          </div>

        </div>
      </div>

      <div className="row">
        <div className="col col-sm-6">
          <div className="row">
            <div className="col col-6">
              <CustomerInput showLabel inputName="delivery.mobile" dataKey="delivery[0].mobile" data={data} onChange={onChange} context="union" {...patterns.mobile} />
            </div>
            <div className="col col-6">
              <CustomerInput showLabel type="email" inputName="delivery.email" dataKey="delivery[0].email" data={data} onChange={onChange} context="union" />
            </div>
          </div>
        </div>
      </div>
    </FormGroup>

    <hr />

    <h3 className="text-center text-sm-left">{t('sections.invoice.heading')}</h3>

    <div className="text-center text-sm-left">
      <label>
        <input
          type="radio"
          checked={!separateInvoiceAddress}
          onChange={() => onChangeSeparateInvoiceAddress(false)}
        /> {t('sections.invoice.inDelivery')}
      </label> <label className="ml-2">
        <input
          type="radio"
          checked={separateInvoiceAddress}
          onChange={() => onChangeSeparateInvoiceAddress(true)}
        /> {t('sections.invoice.sendByMail')}
      </label>
    </div>

    {
      separateInvoiceAddress &&
      <FormGroup>
        <div className="row">
          <div className="col col-sm-6">
            <div className="row">
              <div className="col col-6">
                <CustomerInput showLabel type="email" inputName="invoice.email" dataKey="invoice[0].email" data={data} onChange={onChange} context="union" />
              </div>
            </div>
          </div>
        </div>
      </FormGroup>
    }
  </div>
));


const CompanyFormGroups = translate('customer')(({ data, onChange, separateDeliveryAddress, separateInvoiceAddress, onChangeSeparateDeliveryAddress, onChangeSeparateInvoiceAddress, t }) => (
  <div>

    <h3 className="text-center text-sm-left">{t('sections.company.heading')}</h3>

    <FormGroup>
      <div className="row">
        <div className="col col-xs-12 col-sm-6">
          <CustomerInput inputName="companyName" data={data} dataKey="name" onChange={onChange} context="company" extras={['lg']} {...patterns.name} required />
        </div>
      </div>
    </FormGroup>

    <hr />

    <h3 className="text-center text-sm-left">{t('sections.delivery.heading')}</h3>

    <FormGroup>
      <div className="row">
        <div className="col col-sm-6">
          <div className="row">
            <div className="col col-sm-6">
              <CustomerInput showLabel required inputName="delivery.name" dataKey="delivery[0].name" data={data} onChange={onChange} context="company" {...patterns.name} />
            </div>
          </div>

          <div className="row">
            <div className="col col-12">
              <CustomerInput showLabel required inputName="delivery.street" dataKey="delivery[0].street" data={data} onChange={onChange} context="company" {...patterns.street} />
            </div>
          </div>

          <div className="row">
            <div className="col col-4">
              <CustomerInput showLabel required inputName="delivery.zip" dataKey="delivery[0].zip" data={data} onChange={onChange} context="company" {...patterns.zip} />
            </div>
            <div className="col col-8">
              <CustomerInput showLabel required inputName="delivery.city" dataKey="delivery[0].city" data={data} onChange={onChange} context="company" {...patterns.city} />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col col-sm-6">

          <div className="row">
            <div className="col col-6">
              <CustomerInput showLabel inputName="delivery.mobile" dataKey="delivery[0].mobile" data={data} onChange={onChange} context="company" {...patterns.mobile} />
            </div>
            <div className="col col-6">
              <CustomerInput showLabel type="email" inputName="delivery.email" dataKey="delivery[0].email" data={data} onChange={onChange} context="company" />
            </div>
          </div>

        </div>
      </div>
    </FormGroup>

    <hr />

    <h3 className="text-center text-sm-left">{t('sections.invoice.heading')}</h3>

    <div className="text-center text-sm-left">
      <label>
        <input
          type="radio"
          checked={!separateInvoiceAddress}
          onChange={() => onChangeSeparateInvoiceAddress(false)}
        /> {t('sections.invoice.inDelivery')}
      </label> <label className="ml-2">
        <input
          type="radio"
          checked={separateInvoiceAddress}
          onChange={() => onChangeSeparateInvoiceAddress(true)}
        /> {t('sections.invoice.sendByMail')}
      </label>
    </div>

    {
      separateInvoiceAddress &&
      <FormGroup>
        <div className="row">
          <div className="col col-sm-6">
            <div className="row">
              <div className="col col-6">
                <CustomerInput showLabel type="email" inputName="invoice.email" dataKey="invoice[0].email" data={data} onChange={onChange} context="company" />
              </div>
            </div>
          </div>
        </div>
      </FormGroup>
    }
  </div>
));

export default connect(state => ({
  selectedCustomerType: state.customer.customerType,
  isSubmitting: state.customer.isSyncing
}), {
    setCustomerType
  })(translate('customer')(class _CustomerForm extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        ...this.props.initialData,
        ...this.getInitState(),
      };

      this.onSubmit = this.onSubmit.bind(this);
      this.onChange = this.onChange.bind(this);

      this.onChangeSubscribeToNewsletter = this.onChangeSubscribeToNewsletter.bind(this);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.selectedCustomerType !== this.props.selectedCustomerType) {
        this.reset();
      }
    }

    getInitState() {
      return {
        subscribeToNewsletter: this.props.showSubscribeToNewsletter
      }
    }

    reset() {
      const resetState = Object.keys(this.state).reduce((accum, key) => {
        return {
          ...accum,
          [key]: null
        }
      }, {});

      this.setState({
        ...resetState,
        ...this.getInitState()
      });
    }

    onSubmit(e) {
      const { subscribeToNewsletter } = this.state;

      e.preventDefault();
      e.stopPropagation();

      this.props.onSubmit(this.state);

      if (subscribeToNewsletter) {

      }

      return false;
    }

    onChange(inputName, inputValue) {
      this.setState({
        [inputName]: inputValue
      }, (state) => {
      });
    }

    onChangeSubscribeToNewsletter() {
      this.setState((state) => ({
        subscribeToNewsletter: !state.subscribeToNewsletter
      }))
    }

    render() {

      const { subscribeToNewsletter, ...data } = this.state;
      const { t, selectedCustomerType, setCustomerType, isSubmitting, showSubscribeToNewsletter } = this.props;

      const FormContentComponentClass = {
        'union': UnionFormGroups,
        'company': CompanyFormGroups,
        'association': CompanyFormGroups,
        'community': CompanyFormGroups,
        'individual': IndividualFormGroups
      }[selectedCustomerType];

      return (
        <div>
          <div>
            <form onSubmit={this.onSubmit}>
              <FormContentComponentClass
                data={data}
                onChange={this.onChange}
                separateDeliveryAddress={this.props.separateDeliveryAddress}
                separateInvoiceAddress={this.props.separateInvoiceAddress}
                onChangeSeparateDeliveryAddress={this.props.onChangeSeparateDeliveryAddress}
                onChangeSeparateInvoiceAddress={this.props.onChangeSeparateInvoiceAddress}
              />
              <hr />
              {
                false && showSubscribeToNewsletter && (
                  <React.Fragment>
                    <h3 className="text-center text-sm-left">{t('sections.newsletter.heading')}</h3>
                    <div className="text-center text-sm-left">
                      <label>
                        <input
                          type="checkbox"
                          checked={subscribeToNewsletter}
                          onChange={this.onChangeSubscribeToNewsletter}
                        /> {t('sections.newsletter.checkbox.label')}
                      </label>
                    </div>
                    <hr />
                  </React.Fragment>
                )
              }

              <div className="text-center text-sm-left">
                <button className={`btn ${isSubmitting ? "btn-outline-primary" : "btn-primary"}`} disabled={this.state.isFetching}>
                  {
                    isSubmitting ?
                      <span>
                        <i className="fa fa-circle-o-notch fa-spin fa-fw" /> {t('saveButton.label', { context: "loading" })}
                      </span> :
                      <span>{t('saveButton.label')}</span>
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      )
    }
  }));
import { PRODUCT_CATEGORIES } from '../actions';

const productCategories = (state = [], action) => {
  switch(action.type) {
    case PRODUCT_CATEGORIES.FETCH.RECEIVE:
      return [
        ...action.items
      ];
    default:
      return state;
  }
}

export default productCategories;
import isArray from 'lodash/isArray';
import { isAbsolute } from 'path';

export const removeDuplicates = (a) => {
  var seen = {};
  return a.filter((item) => {

    if (seen.hasOwnProperty(item)) {
      return false;
    }

    seen[item] = true;
    return true;
  });
}

export const cleanArray = (a, moreRemovableValues = []) => {

  const removableValues = [
    ...moreRemovableValues,
    null,
    undefined
  ];

  return a.filter(x => removableValues.indexOf(x) === -1);
}

export const sortBy = (a, func = x => x) => {
  return [...a].sort((i1, i2) => func(i1) <= func(i2) ? -1 : 1);
}

export const makeRows = (a, numInRow) => a.reduce(
  (accum, item, itemIndex) => {
    const bucket = parseInt(itemIndex / numInRow);

    if (accum[bucket] === undefined) {
      accum[bucket] = [];
    }

    accum[bucket].push(item);

    return accum;
  },
  []
)

export const getLast = (a) => a.slice(-1)[0];

export function forceArray(value) {
  return isArray(value) ? value : [value];
}


export function createArray(num, mapper) {
  const a = []

  for (let i = 0; i < num; i++) {
    a.push(mapper(i))
  }

  return a
}
import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

function getProcessedUrls(urls, urlId) {
  return get(urls, urlId, urls.default)
}


export default function Image({ src }) {
  return (
    <img
      src={src}
    />
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired
}

export function ImageFromProcessedUrls({ processedUrls, urlId, ...imageProps }) {
  return (
    <Image
      src={getProcessedUrls(processedUrls, urlId)}
      {...imageProps}
    />
  )
}

ImageFromProcessedUrls.propTypes = {
  processedUrls: PropTypes.object.isRequired,
  urlId: PropTypes.string,
}

ImageFromProcessedUrls.defaultProps = {
  urlId: 'default',
}
import React from 'react'
import PropTypes from 'prop-types'

import trophy from './svg/002-trophy-1.svg'
import goldMedal from './svg/003-gold-medal.svg'
import medal from './svg/011-medal-1.svg'

const variantToSvgMap = {
  trophy,
  goldMedal,
  medal,
}

const sizeToStyleMap = {
  small: {
    width: 30,
    height: 30,
  },
  default: {
    width: 50,
    height: 50,
  },
  large: {
    width: 80,
    height: 80,
  },
}

function Trophy(props) {
  return (
    <img
      src={variantToSvgMap[props.variant]}
      style={{
        ...sizeToStyleMap[props.size]
      }}
    />
  )
}

Trophy.propTypes = {
  variant: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
}

Trophy.defaultProps = {
  size: 'default'
}

export default Trophy


import React from 'react';
import { caseSelector } from 'utils/helpers';

import head from 'lodash/head';

export const STATES = {
  SYNCING: 'STATES.SYNCING',
  SYNCED: 'STATES.SYNCED',
  FAILED: 'STATES.FAILED',
};

const stepStateComposer = (WrappedComponent) => class StepStateWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stepState: null,
      stepStack: [],
      stepData: {}
    }

    this.setStep = this.setStep.bind(this);
    this.setStepState = this.setStepState.bind(this);

    this.setPrevStep = this.setPrevStep.bind(this);
    this.renderCurrentStepHelper = this.renderCurrentStepHelper.bind(this);
    this.defaultStepRenderer = this.defaultStepRenderer.bind(this);
  }

  _setStepThenMaybeCb(step, stepState, {
    cb = undefined,
    data = undefined
  } = {}) {

    return new Promise(resolve => {
      this.setState((state) => ({
        stepStack: (
          step !== undefined ?
            [
              step,
              ...state.stepStack
            ] :
            state.stepStack
        ),
        stepState: (stepState !== undefined ? stepState : state.stepState),
        stepData: (data !== undefined ? data : this.state.stepData)
      }), () => {
        if (cb !== undefined) {
          setTimeout(cb, 1000);
        }
        resolve();
      });
    })
  }

  setStep(step, stepState, options) {
    return this._setStepThenMaybeCb(step, stepState, options);
  }

  setStepState(stepState, options) {
    return this._setStepThenMaybeCb(undefined, stepState, options);
  }

  setPrevStep() {
    this.setState((state) => ({
      stepStack: state.stepStack.slice(1),
      stepData: {},
    }))
  }

  defaultStepRenderer() {
    return (
      <div className="alert-warning">
        <h3>Nothing to render!</h3>
        <pre>{JSON.stringify(this.state)}</pre>
      </div>
    )
  }

  renderCurrentStepHelper(stepsRenderer, defaultStepRenderer) {
    return caseSelector(
      stepsRenderer,
      (defaultStepRenderer !== undefined ? defaultStepRenderer : this.defaultStepRenderer)
    )(this.getCurrentStep())()
  }

  getCurrentStep() {
    return head(this.state.stepStack);
  }

  render() {
    const { stepStack, stepState, stepData } = this.state;
    const step = this.getCurrentStep();
    const hasPrevStep = stepStack.length <= 1;

    return (
      <WrappedComponent
        step={step}
        stepData={stepData}
        stepState={stepState}
        setStep={this.setStep}
        setStepState={this.setStepState}
        setPrevStep={this.setPrevStep}
        hasPrevStep={hasPrevStep}
        renderCurrentStepHelper={this.renderCurrentStepHelper}
        {...this.props}
      />
    )
  }
};

export default stepStateComposer;
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import {
  setCustomerType
} from '../../actions';

export default connect(state => ({
  selectedCustomerType: state.customer.customerType,
}), {
  setCustomerType
})(translate('customer')(({selectedCustomerType, setCustomerType, onSelectCallback, t}) => {
  return (
    <div>
      {['union', 'company', 'individual'].map(customerType => (
        <button key={customerType} onClick={() => {
          setCustomerType(customerType);
          if(onSelectCallback) {
            onSelectCallback(customerType);
          }
        }} className={`m-2 btn btn-${customerType === selectedCustomerType ? "primary" : "outline-dark"}`}>
          {t('type.label', {context: customerType})}
        </button>
      ))}
    </div>
  )
}));
import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { testToken } from '../../api';
import { signinWithToken } from '../../actions';

import routes from '../../routes';

import { waitUntilPromiseIsResolved } from '../hoc';

import { getQueryParamsFromLocation } from '../../utils/url';
import { RejectedPromiseCreator } from '../../utils/promises';

import DocumentMeta from '../../components/documentMeta';
import { OneColumnLayout } from '../../components/layouts';
import SideColumn from '../../components/SideColumn';
import { Sheet } from '../../components/layouts';
import BlockArea from '../BlockArea';
import { PageHeading } from 'components/content';

import UserNewPasswordForm from '../../components/forms/UserNewPasswordForm';

export default withRouter(translate('user')(waitUntilPromiseIsResolved(function ({ location }) {
  const { token } = getQueryParamsFromLocation(location);
  function validateToken(token) {
    return token !== undefined && true;
  }

  return (
    validateToken(token) ?
      testToken(token) :
      RejectedPromiseCreator()
  );

}, function ({ user }) {
  return ({
    user
  })
})(connect((state) => ({
  isSignedIn: state.auth.isSignedIn
}), {
    signinWithToken
  })(class _ResetPasswordPage extends React.Component {

    render() {
      const { user, history, location, signinWithToken, isSignedIn, t } = this.props;
      const { token } = getQueryParamsFromLocation(location);

      const pageName = 'resetPassword';
      const blockConditions = [`page:${pageName}`];

      if (isSignedIn) {
        return (
          <Redirect to={routes.USER.ROOT.get()} />
        )
      }

      return (
        <div>
          <DocumentMeta
            title={t('title', { context: pageName })}
          />
          <Sheet className="p-4">
            <BlockArea
              areaSlug="main"
              conditions={blockConditions}
              defaultContent={(
                <div>
                  <PageHeading>{t('resetPassword.title', {
                    email: user.email,
                    name: user.name
                  })}</PageHeading>
                  <p>{t('resetPassword.description')}</p>
                </div>
              )}
            />
            <hr />
            <UserNewPasswordForm
              token={token}
              t={t}
              onAfterSubmit={function () {
                signinWithToken(token);
              }}
            />
          </Sheet>
        </div>
      );
    }
  }, undefined, function _ResetPasswordErrorPage({ errorMessage, t }) {
    return (
      <Sheet className="p-4">
        <BlockArea
          areaSlug="main"
          conditions={['page:resetPasswordError']}
          defaultContent={(
            <div>
              <PageHeading>{t('resetPassword.error.title')}</PageHeading>
              <p>{t('resetPassword.error.description')}</p>
            </div>
          )}
        />
      </Sheet>
    )
  }))));
import React from 'react';
import PropTypes from 'prop-types'

import MarkdownToJSX from 'markdown-to-jsx'
import EventToOrderWizardModalTrigger from './markdownComponents/EventToOrderWizardModalTrigger'

const defaultMardownToJSXOptions = {
  overrides: {
    EventToOrderWizardModalTrigger: {
      component: EventToOrderWizardModalTrigger
    }
  }
}

function Markdown({
  content,
  options,
  markdownOptions,
  ...props
}) {
  try {
    return (
      <MarkdownToJSX
        options={{
          ...defaultMardownToJSXOptions,
          ...options,
          overrides: {
            ...defaultMardownToJSXOptions.overrides,
            ...options.overrides,
          }
        }}
        {...props}
      >
        {content}
      </MarkdownToJSX>
    )
  } catch (error) {
    return content
  }
}

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
  options: PropTypes.object,
  markdownOptions: PropTypes.object,
}

Markdown.defaultProps = {
  options: {
    overrides: {}
  }
}

export default Markdown
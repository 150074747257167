import React from 'react';
import { connect } from 'react-redux';


import { removeModal } from '../../actions';
import AddedOrderRowModal from './AddedOrderRowModal';
import OrderWasSentModal from './OrderWasSentModal';

export const MODAL_COMPONENTS = {
  ADDED_ORDER_ROW: 'ADDED_ORDER_ROW',
  ORDER_WAS_SENT: 'ORDER_WAS_SENT',
}

const ModalFactory = connect(null, {
  removeModal
})(({modalComponentId, modalComponentProps = {}, removeModal, isOpen}) => {

  const ModalComponent = {
    [MODAL_COMPONENTS.ADDED_ORDER_ROW]: AddedOrderRowModal,
    [MODAL_COMPONENTS.ORDER_WAS_SENT]: OrderWasSentModal,
  }[modalComponentId];

  return (
    <ModalComponent
      {...modalComponentProps}
      onClose={() => removeModal(modalComponentId)}
      isOpen={isOpen}
    />
  );
})

export default ModalFactory;

export const anyChar = "a-zA-ZåäöÅÄÖ";
export const anyDigit = "0-9";
export const whitespace = " \\s\\uFEFF\\xA0";

export const patterns = {
  name: {
    // pattern: `.{2,}`,
    minLength: 4,
    title: "name",
    key: "name",
  },
  email: {
  },
  street: {
    pattern: `([${anyChar}${anyDigit}\\./\\-:]{1,}[${whitespace}]?)+`,
    minLength: 4,
    title: "street",
    key: "street"
  },
  zip: {
    pattern: `^([${anyDigit}]{3})([${whitespace}]?)([${anyDigit}]{2})$`,
    // minLength: 5,
    // maxLength: 6,
    title: "zip",
    key: "zip"
  },
  city: {
    pattern: `([${anyChar}\\-]{1,}[${whitespace}]?)+`,
    title: "city",
    key: "city"
  },
  mobile: {
    pattern: `[${anyDigit}+\\-${whitespace}]{10,}`,
    title: "mobile",
    key: "mobile"
  },
  password: {
    minLength: 4,
  }
}

export const parsers = {
  zip: (value) => {
    const valueStripped = value
      .replace(/[^\d]/g, '');

    if (valueStripped.length > 3) {
      return `${valueStripped.slice(0, 3)} ${valueStripped.slice(3)}`;
    }
    
    return valueStripped;
  }
}
import React from 'react'
import PropTypes from 'prop-types'
import RotatingPlane from './spinners'

function LoadingLayer({ isLoading }) {
  return (
    isLoading ?
      (
        <div className="d-flex align-items-center" style={{
          position: 'absolute',
          left: '0px',
          top: '0px',
          width: '100%',
          height: '100%',
          backgroundColor: '#ffffff99',
        }}>
          <RotatingPlane />
        </div>
      ) : null
  )
}

LoadingLayer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

export default LoadingLayer

export function LoadingLayerContainer({ children, isLoading }) {
  return (
    <div style={{ position: "relative" }}>
      {children}
      <LoadingLayer isLoading={isLoading} />
    </div>
  )
}

LoadingLayerContainer.propTypes = {
  children: PropTypes.node,
  isLoading: LoadingLayer.propTypes.isLoading
}
import React from 'react';
import { withTranslation as translate } from 'react-i18next';

const defaultOrderingOptions = [
  {name: "name", value: "NAME"},
  {name: "price", value: "PRICE_ASC"},
  {name: "articleNumber", value: "ARTICLE_NUMBER_ASC"},
];

export const defaultOrdering = "PRICE_ASC";

export default translate('productArchive')(({orderingOptions = defaultOrderingOptions, selectedOrdering = defaultOrdering, onChange, t}) => {

  const _onChange = (evt) => {
    onChange(evt.target.value);
  }

  return (
    <label className="d-flex justify-content-center justify-content-md-right align-items-center mb-0">
      <span className="text-nowrap mr-1">{t('ordering.label')}</span>
      <select className="form-control" onChange={_onChange} value={selectedOrdering}>
        {orderingOptions.map(({value, name}) => (
          <option key={value} value={value}>{t('ordering.option.label', {context: name})}</option>
        ))}
      </select>
    </label>
  );
});
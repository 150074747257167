import React from 'react'
import PropTypes from 'prop-types'

import { caseSelector } from 'utils/helpers'

import TextField from './TextField'
import SelectionField from './SelectionField'


export default function Field({
  type,
  input,
  onChange,
  onReset,
  selectionIds,
  defaultSelectionId,
  ...props
}) {

  return (
    <div>
      {
        caseSelector({
          selection: (
            <SelectionField
              selectionIds={selectionIds}
              defaultSelectionId={defaultSelectionId}
              input={input}
              onChange={onChange}
              onReset={onReset}
              {...props}
            />
          )
        }, (
            <TextField
              {...props}
              type={type}
              input={input}
              onChange={onChange}
              onReset={onReset}
              placeholder={props.placeholder}
              textTemplates={props.textTemplates}
            />
          ))(type)
      }
    </div>
  )
}

Field.defaultProps = {
  type: 'text',
}

const inputPropTypes = PropTypes.shape({
  value: PropTypes.string,
  selectionId: PropTypes.number
})

Field.propTypes = {
  type: PropTypes.string,
  widget: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  // input: inputPropTypes,
  variantInputs: PropTypes.arrayOf(
    inputPropTypes
  ),
  inputPreview: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}
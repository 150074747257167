import find from 'lodash/find'

import { takeLatest, takeEvery, put, call, fork, select, all, delay } from 'redux-saga/effects' // , take

import { REHYDRATE } from 'redux-persist'

import { setInStorage, removeFromStorage } from 'utils/storage'

import * as actions from 'actions'
import { userIsSignedInAndLoaded } from 'selectors/user'
import { customerExist } from 'selectors/customer'

import {
  fetchBlockPages,
  fetchUser,
  getToken,
  addCustomerAccess,
  removeCustomerAccess,
  selectCustomer as apiSelectCustomer
} from 'api'


import orderSaga from './order'
import customerSaga from './customer'
import {
  getActivities,
  getFieldSelections,
  getProductAttributes,
  getProductCategories,
  getProductFields,
  getProductOptions,
  getProducts,
  getSelectedProduct,
  getTagCategories,
  getTags,
  handleDeleteFieldSelection
} from './product'


import config from '../config'


function fail(e) {
  console.error(e.name, e.message)
}

function* getBlockPages() {
  try {
    yield put(actions.requestBlockPages())
    const { items } = yield call(fetchBlockPages, {})
    yield put(actions.receiveBlockPages(items))
  }
  catch (e) {
    fail(e)
    yield put(actions.failedBlockPages(e))
  }
}



function* signinWithToken({ token, userName = '' }) {
  setInStorage({ [config.STORAGE_TOKEN_KEY]: token })
  yield put(actions.signinSuccess(token, userName))
}

function* signin({ username, password }) {
  try {
    const { token } = yield call(getToken, username, password)
    yield put(actions.signinWithToken(token))

  } catch (e) {
    fail(e)

    yield put(actions.signinFailed({
      error: 'Wrong username and/or password'
    }[e.message] || "Unknows error"))
  }
}

function* signout() {
  removeFromStorage(config.STORAGE_TOKEN_KEY)
  yield 1
}

function* prefetchSaga() {
  yield fork(getProductCategories)
  yield fork(getActivities)
  yield fork(getProductAttributes)
  yield fork(getBlockPages)
  yield fork(getFieldSelections)
  yield fork(getProductFields)
  yield fork(getTags)
  yield fork(getTagCategories)
}

function* productsSaga() {
  yield all([
    takeLatest(actions.SELECTED_PRODUCT.GET, getSelectedProduct),
    takeLatest(actions.PRODUCTS.GET, getProducts),
    takeEvery(actions.PRODUCT_OPTIONS.GET, getProductOptions),
  ])
}

function* authSaga() {
  yield all([
    takeLatest(actions.AUTH.SIGNIN, signin),
    takeLatest(actions.AUTH.SIGNIN_WITH_TOKEN, signinWithToken),
    takeLatest(actions.AUTH.SIGNOUT, signout),
  ])
}

function* getUser() {
  try {
    yield put(actions.requestUser())

    const { item } = yield call(fetchUser)
    yield put(actions.receiveUser(item))

    const currentCustomerId = yield select(state => state.customer.id)

    if (currentCustomerId === null) {
      const { lastSelectedCustomerId } = item
      if (lastSelectedCustomerId) {
        yield put(actions.setAndFetchCustomer(lastSelectedCustomerId))
      }
    }

    yield delay(100)
    yield fork(getFieldSelections)
  }
  catch (e) {
    fail(e)
    yield put(actions.failedUser(e))
  }
}

function* onSigninSuccess() {
  yield put(actions.getUser())
}

function* onSignout() {
  yield put(actions.resetUser())
  yield put(actions.resetCustomer())
}

function* maybeUpdateCachedData() {
  const _userIsSignedInAndLoaded = yield select(state => userIsSignedInAndLoaded(state))
  if (_userIsSignedInAndLoaded) {
    yield put(actions.refreshUser())
  }

  const _customerExist = yield select(state => customerExist(state))
  if (_customerExist) {
    yield put(actions.refreshCustomer())
  }
}

function* refreshUser() {

  const authTokenExists = yield select(state => state.auth.token !== null)
  if (authTokenExists) {
    yield fork(getUser)
  }
  else {
    console.warn("No need to refresh user")
  }
}

function* selectCustomer({ id: customerId }) {
  yield call(apiSelectCustomer, customerId)
  yield put(actions.setAndFetchCustomer(customerId))
}

function* onAddCustomerAccess({ customerId, accessLevel }) {

  try {
    yield put(actions.requestAddCustomerAccess())
    const { item } = yield call(addCustomerAccess, customerId, accessLevel)
    yield put(actions.successAddCustomerAccess())

    // Updates user data ..
    yield fork(getUser)
    const { customerAccess } = yield select(({ user }) => user)

    const justAddedAndApprovedCustomerAccess = find(
      customerAccess,
      x => (
        x.customerId === customerId &&
        x.state === "approved"
      )
    )

    if (justAddedAndApprovedCustomerAccess) {
      yield put(actions.selectCustomer(customerId))
    }
  }
  catch (e) {
    fail(e)
    yield put(actions.failedAddCustomerAccess(e))
  }
}

function* onRemoveCustomerAccess({ id }) {

  try {
    yield call(removeCustomerAccess, id)
    yield fork(getUser)
  }
  catch (e) {
    fail(e)
  }
}

function* handleCustomerSetAndFetch({ id: customerId }) {
  yield put(actions.refreshFieldSelections())
}

function* userSaga() {

  yield all([
    takeLatest(REHYDRATE, maybeUpdateCachedData),
    takeLatest(actions.AUTH.SIGNINGIN.SUCCESS, onSigninSuccess),
    takeLatest(actions.AUTH.SIGNOUT, onSignout),
    takeLatest(actions.USER.GET, getUser),
    takeLatest(actions.USER.REFRESH, refreshUser),
    takeLatest(actions.USER.CUSTOMER_ACCESS.ADD.INIT, onAddCustomerAccess),
    takeLatest(actions.USER.CUSTOMER_ACCESS.REMOVE, onRemoveCustomerAccess),
    takeLatest(actions.USER.SELECT_CUSTOMER, selectCustomer),
    takeLatest(actions.FIELD_SELECTIONS.REFRESH, getFieldSelections),
    takeLatest(actions.FIELD_SELECTIONS.DELETE, handleDeleteFieldSelection),
    takeLatest(actions.CUSTOMER.SET_AND_FETCH, handleCustomerSetAndFetch),
  ])
}

export function* rootSaga() {
  yield all([
    fork(prefetchSaga),
    fork(customerSaga),
    fork(orderSaga),
    fork(productsSaga),
    fork(authSaga),
    fork(userSaga),
  ])
}
import React from 'react'
import PropTypes from 'prop-types'

import { formatPrice } from '../../utils/content'

function DeliveryMethodPriceColumn({ deliveryMethod }) {
  const hasPrice = deliveryMethod.price > 0
  const hasDefaultPrice = deliveryMethod.priceDefault > 0
  const hasNoPriceButDefaultPrice = hasDefaultPrice && !hasPrice

  return (
    <React.Fragment>
      <div>
        <span className={hasNoPriceButDefaultPrice ? "text-success h5" : ""}>
          {formatPrice(deliveryMethod.price, 'kr', true)}
        </span>
      </div>
      {
        hasNoPriceButDefaultPrice &&
        <div style={{ textDecoration: "line-through" }}>
          {formatPrice(deliveryMethod.priceDefault, 'kr', true)}
        </div>
      }
    </React.Fragment>
  )
}

DeliveryMethodPriceColumn.propTypes = {
  deliveryMethod: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default DeliveryMethodPriceColumn

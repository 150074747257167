import React from 'react'
import PropTypes from 'prop-types'

import { Button as BootstrapButton } from 'reactstrap'

import { cleanArray } from 'utils/array'

import './buttons.scss'

export function Button({ stopOnClickPropagation, onClick, ...BootstrapButtonProps }) {
  const handleClick = React.useCallback((evt) => {
    if (stopOnClickPropagation) {
      evt.stopPropagation()
    }

    if (onClick) {
      onClick(evt)
    }

  }, [onClick, stopOnClickPropagation])

  return (
    <BootstrapButton
      {...BootstrapButtonProps}
      onClick={onClick ? handleClick : undefined}
    />
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  stopOnClickPropagation: PropTypes.bool,
}

export const DarkButton = (props) => <Button color='dark' {...props} />
export const DangerButton = (props) => <Button color='danger' {...props} />
export const PrimaryButton = (props) => <Button color='primary' {...props} />
export const SecondaryButton = (props) => <Button color='secondary' {...props} />
export const LinkButton = (props) => <Button color='link' {...props} />
export const NoBackgroundButton = (props) => <Button color='no-background' {...props} />


export function ButtonContainer({ className, children }) {
  return (
    <div className={cleanArray(["Buttons", className]).join(" ")}>
      {children}
    </div>
  )
}
import React from 'react';
import { connect } from 'react-redux';

import {
  selectCustomer,
  removeCustomerAccess,
  refreshUser
} from 'actions';

import {
  addCustomerAccess as apiAddCustomerAccess,
  orderApi
} from 'api';


import { CUSTOMER_TYPES } from 'selectors/customer';

import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import head from 'lodash/head';


import { caseSelector } from 'utils/helpers';
import { confirmDialog } from 'utils/dialog';

import { UserCustomersTranslate } from 'containers/translates';
import stepStateComposer, { STATES } from 'containers/hoc/stepStateComposer';

import ContentHeader from 'components/ContentHeader';
import List, { ListItem } from 'components/List';
import Form, { FormInput, FormButton } from 'components/forms/Form';
import Input from 'components/forms/Input';
import { DarkButton, PrimaryButton, NoBackgroundButton, ButtonContainer } from 'components/buttons';
import { AddIcon, DeleteIcon } from 'components/Icon';

import UnionNodeSearchForm from 'components/forms/UnionNodeSearchForm';
import UnionOrCompanyCreatingAndOrSelecting from 'containers/steps/UnionOrCompanyCreatingAndOrSelecting';

import { translateFactory, Translate } from 'containers/translates';

const UserCustomersList = connect((state) => ({
  user: state.user,
  selectedCustomerId: state.customer.id,
}))(class UserCustomersList extends React.Component {
  constructor(props) {
    super(props);

    this.onCustomerSelectionSubmit = this.onCustomerSelectionSubmit.bind(this);
  }

  onCustomerSelectionSubmit({ customerId }) {
    return this.props.onSelect(customerId);
  }

  render() {
    const { user, selectedCustomerId, t } = this.props;

    const { customerAccess, customer } = user;
    const items = uniqBy([{
      ...customer,
      accessLevel: 'user',
      removable: false
    }, ...customerAccess.map(ca => ({
      id: ca.customerId,
      name: ca.customer.name,
      type: ca.customer.type,
      accessLevel: ca.userAccessLevel,
      removable: true
    }))], x => x.id);

    const onlyCompanyUnionAndUserCustomerItems = items.filter(x => (
      x.accessLevel === 'user' ||
      ['company', 'union'].indexOf(x.type) !== -1
    ));

    return (
      <Translate ns="list">
        {({ t }) => (
          <React.Fragment>
            <h3>{t('header')}</h3>

            <Form
              t={t}
              onSubmit={this.onCustomerSelectionSubmit}
              initialInputValues={{
                customerId: selectedCustomerId
              }}
            >
              {({ getStateOf, onInputChange, onInputChangeFactory, isBusy }) => (
                <React.Fragment>
                  <List>
                    {onlyCompanyUnionAndUserCustomerItems.map(item => {
                      const isSelected = selectedCustomerId === item.id;

                      return (
                        <ListItem key={item.id}>
                          <div className="d-flex px-2 align-items-center">
                            <label className="py-3 m-0 flex-1">
                              <input type="radio" onChange={() => onInputChange("customerId", item.id)} checked={getStateOf('customerId') === item.id} className="mr-3" />
                              <span className={['font-weight-bold', isSelected ? 'text-primary' : ''].join(" ")}>{item.name}</span>
                              <span className="font-weight-light ml-1">({t('item.type', { context: item.type })})</span>
                            </label>
                            {
                              item.removable &&
                              <NoBackgroundButton disabled={isSelected} size="sm" onClick={!isSelected ? () => this.props.onRemove(item.id) : () => { }}>
                                <DeleteIcon />
                              </NoBackgroundButton>
                            }
                          </div>
                        </ListItem>
                      )
                    })}
                  </List>

                  <ButtonContainer className="mt-3">
                    <FormButton buttonComponent={PrimaryButton} disabled={getStateOf('customerId') === selectedCustomerId}>
                      {t('submit.label')}
                    </FormButton>
                    <DarkButton onClick={this.props.onClickAdd}>
                      {t('addButton.label')}
                    </DarkButton>
                  </ButtonContainer>
                </React.Fragment>
              )}
            </Form>

          </React.Fragment>
        )}
      </Translate>

    )
  }
});


class UserCustomersAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerType: null
    }
  }

  onSelectCustomerType = (customerType) => {
    this.setState(() => ({
      customerType
    }));
  }

  render() {
    return (
      <UserCustomersTranslate>
        {({ t }) =>
          <React.Fragment>
            <ContentHeader context={{ context: 'add', userName: "Peter" }} />
            <hr />
            {caseSelector({
              [CUSTOMER_TYPES.UNION]: () => (
                <div>
                  <UnionNodeSearchForm
                    onSelect={(...args) => {
                      
                    }}
                    t={t}
                  />
                </div>
              ),
              [CUSTOMER_TYPES.COMPANY]: () => (
                <div>
                  <h3>COMPANY CREATION!</h3>
                  <p># TODO: Fix ..</p>
                </div>
              ),
            }, () => (
              <React.Fragment>
                <h3>Select customer type</h3>
                <hr />
                <div className="text-center">
                  {[
                    CUSTOMER_TYPES.UNION,
                    CUSTOMER_TYPES.COMPANY
                  ].map(customerType => (
                    <DarkButton outline size="lg" className="mx-2" key={customerType} onClick={this.onSelectCustomerType.bind(this, customerType)}>
                      {t(`customerTypes.${customerType}`)}
                    </DarkButton>
                  ))}
                </div>
                <hr />
              </React.Fragment>
            ))(this.state.customerType)()}

            <ButtonContainer className="mt-3">
              <DarkButton onClick={this.props.onClickCancel}>Cancel</DarkButton>
            </ButtonContainer>
          </React.Fragment>
        }
      </UserCustomersTranslate>
    )
  }
}





// TODO: Make UserCustomerStaffList

const STEPS = {
  SELECT: 'STEPS.SELECT',
  ADD: 'STEPS.ADD',
  END: 'STEPS.END',
}

const UserCustomers = connect(
  (state) => ({
    user: state.user,
    customer: state.customer,
  }), {
    selectCustomer,
    removeCustomerAccess,
    refreshUser
  }
)(stepStateComposer(
  class _UserCustomers extends React.Component {
    constructor(props) {
      super(props);

      this.onSelectCustomer = this.onSelectCustomer.bind(this);
      this.onAddCustomer = this.onAddCustomer.bind(this);
      this.onRemoveCustomer = this.onRemoveCustomer.bind(this);
    }

    setStepToAdd = () => this.props.setStep(STEPS.ADD)
    setStepToSelect = () => this.props.setStep(STEPS.SELECT)

    onAddCustomer(data) {

      let { id: customerId } = data;

      if (customerId === undefined) {
        return orderApi.createCustomer(data)
          .then(resp => head(resp.createdIds))
          .then(this.onSelectCustomer)
      }
      else {
        return apiAddCustomerAccess(customerId)
          .then(() => this.onSelectCustomer(customerId))
      }
    }

    onSelectCustomer(customerId) {
      this.props.selectCustomer(customerId);
      
      if(this.props.onSubmit) {
        this.props.onSubmit(customerId);
      }
    }

    onRemoveCustomer(customerId) {
      const { user, customer, t } = this.props;

      const customerAccessToBeRemoved = find(user.customerAccess, x => x.customerId === customerId);

      if (confirmDialog(t('removeUserCustomerAccess.confirm.message', { customerName: customerAccessToBeRemoved.customer.name }))) {
        // If selected customer is removed, then fallback to user customer
        if (customer.id === customerId) {
          this.props.selectCustomer(this.props.user.customer.id);
        }

        this.props.removeCustomerAccess(customerAccessToBeRemoved.id);
      }
    }

    componentDidMount() {
      this.props.setStep(STEPS.SELECT);
    }

    componentDidUpdate(prevProps) {
      const { step, customer } = this.props;
      const { customer: prevCustomer } = prevProps;

      if (step !== STEPS.END) {
        // New customer selection has now loaded.
        if (customer.id !== prevCustomer.id) {
          this.props.setStep(STEPS.END, null)
            .then(this.props.refreshUser);
        }
      }
    }

    render() {

      const { step, customer, user } = this.props;

      return (

        caseSelector({
          [STEPS.SELECT]: () => (
            <Translate ns="select">
              {({ t }) => (
                <React.Fragment>
                  <ContentHeader context={{ customerName: customer.data.name }} />
                  <UserCustomersList
                    t={t}
                    onClickAdd={this.setStepToAdd}
                    onSelect={this.onSelectCustomer}
                    onRemove={this.onRemoveCustomer}
                  />
                </React.Fragment>
              )}
            </Translate>
          ),

          [STEPS.ADD]: () => (
            <UnionOrCompanyCreatingAndOrSelecting
              onClickCancel={this.setStepToSelect}
              onSubmit={this.onAddCustomer}
            />
          ),
          [STEPS.END]: () => (
            <Translate ns="end">
              {({ t }) => (
                <React.Fragment>
                  <ContentHeader context={{ customerName: customer.data.name }} />
                  <hr />
                  <ButtonContainer>
                    <PrimaryButton onClick={this.props.onDone}>{t('closeButton.label')}</PrimaryButton>
                    {/* <DarkButton onClick={this.props.onDone}>{t('customerOrdersPage.label')}</DarkButton> */}
                    {/* <DarkButton onClick={this.props.onDone}>{t('applyForCustomerAdminButton.label')}</DarkButton> */}
                  </ButtonContainer>
                </React.Fragment>
              )}
            </Translate>
          )
        }, () => (
          <div>HJellou {step}</div>
        ))(step)()
      )
    }
  }
));

export default UserCustomers;
import React from 'react'
import PropTypes from 'prop-types'

function AddressLine({ line }) {
  return (
    <React.Fragment>
      {line} <br />
    </React.Fragment>
  )
}

AddressLine.propTypes = {
  line: PropTypes.node.isRequired,
}

export default function Address({ name, street, zip, city, mobile, email, showName, showContactInformation, ghost }) {
  return (
    <p className={ghost ? "font-italic text-muted" : ""}>
      {showName && <AddressLine line={name} />}
      <AddressLine line={street} />
      <AddressLine line={<React.Fragment>{zip} {city}</React.Fragment>} />
      {showContactInformation && (
        <React.Fragment>
          {mobile && <AddressLine line={mobile} />}
          {email && <AddressLine line={email} />}
        </React.Fragment>
      )}
    </p>
  )
}

Address.propTypes = {
  name: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  mobile: PropTypes.string,
  email: PropTypes.string,
  showName: PropTypes.bool,
  showContactInformation: PropTypes.bool,
  ghost: PropTypes.bool
}

Address.defaultProps = {
  showName: false
}

export function ContactInformation({ name, mobile, email, showName }) {
  return (
    <p>
      {showName && <AddressLine line={name} />}
      {mobile && <AddressLine line={mobile} />}
      {email && <AddressLine line={email} />}
    </p>
  )
}

ContactInformation.propTypes = {
  name: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  showName: PropTypes.bool
}

ContactInformation.defaultProps = {
  showName: false
}
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { toDate } from 'utils/date'
import { formatPrice } from 'utils/content';


import find from 'lodash/find'
import templateCompiler from 'lodash/template'
import entries from 'lodash/entries'

import { caseSelector } from 'utils/helpers'

import {
  sumProductFieldInputPrices,
  productFieldInputIsEmpty
} from 'selectors/productFields'

import { addOrderRows } from 'actions'

import {
  fetchCustomerEventPriceTypeProducts,
  fetchArticlePriceBundle
} from 'api'


import { waitUntilPromiseIsResolved } from 'containers/hoc';

import { PrimaryButton, ButtonContainer } from 'components/buttons'
import { Translate } from 'containers/translates'
import ContentHeader from 'components/ContentHeader'

import { Event } from './props'

import { OrderRowTable } from 'components/OrderRows'

function getOrderRows({ event, productBundles, products, priceBundles, nextOrderRowId = 0 }) {

  let id_ = nextOrderRowId
  function nextId() {
    return ++id_
  }

  const eventName = event.name
  const eventYear = toDate(event.date).getFullYear()

  const orderRows = productBundles.reduce(
    (orderRowsAccum, { eventContestPriceTypeBundles, productFieldInputs, productId }, priceTypeBundleIndex) => {

      const product = find(products, (x) => x.id === productId)

      return [
        ...orderRowsAccum,
        ...(
          eventContestPriceTypeBundles.reduce(
            (subOrderRowsAccum, eventContestPriceBundle, eventContestPriceBundleIndex) => {
              return [
                ...subOrderRowsAccum,
                ...(
                  eventContestPriceBundle.rows.map((row, rowIndex) => {
                    const article = product.articles[rowIndex]

                    const tempOrderRowId = nextId()
                    const quantity = row.numOfCopies
                    const articleId = article.id

                    const priceBundle = find(priceBundles, ({ articleId }) => articleId === article.id)
                    const unitPrice = priceBundle ?
                      (
                        priceBundle.price +
                        sumProductFieldInputPrices(priceBundle.productFieldInputPrices)
                      ) :
                      undefined

                    return {
                      id: tempOrderRowId,
                      articleId,
                      quantity,
                      unitPrice,
                      productFieldInputs: entries(productFieldInputs).map(
                        ([productFieldId, productFieldInputValues]) => {
                          return {
                            productFieldId: Number(productFieldId),
                            ...(() => {
                              if (productFieldInputValues.textTemplate) {
                                const eventContestName = eventContestPriceBundle.contestName
                                const eventContestPosition = [
                                  "1:a",
                                  "2:a",
                                  "3:a",
                                  "4:a",
                                  "5:a",
                                  "6:a",
                                  "7:a",
                                  "8:a",
                                  "9:a",
                                  "10:a",
                                  "11:a",
                                  "12:a",
                                  "13:a",
                                  "14:a",
                                  "15:a",
                                ][rowIndex]

                                const variableInputs = {
                                  eventName,
                                  eventYear,
                                  eventContestName,
                                  eventContestPosition
                                }

                                const value = templateCompiler(
                                  productFieldInputValues.textTemplate.template
                                )(variableInputs)

                                return {
                                  value,
                                  meta: {
                                    templateId: productFieldInputValues.textTemplate.id,
                                    variableInputs
                                  }
                                }
                              }

                              return productFieldInputValues
                            }
                            )()
                          }
                        })
                    }
                  })
                )
              ]
            },
            []
          )
        )
      ]
    },
    []
  )

  return orderRows
}

class Summary extends React.Component {
  render() {
    const productArticles = this.props.products.reduce(
      (productArticlesAccum, product) =>
        ([
          ...productArticlesAccum,
          ...product.articles.map(
            (article) => ({
              ...article,
              product
            })
          )
        ]),
      []
    )

    const orderSum = this.props.orderRows.reduce((orderSumAccum, orderRow) => (
      orderSumAccum +
      (orderRow.quantity * orderRow.unitPrice)
    ), 0)

    return (
      <Translate ns="summary">
        {({ t }) => (
          <React.Fragment>
            <ContentHeader context={{
              eventName: this.props.event.name,
            }} />

            <div className="mt-3">
              <OrderRowTable
                orderRows={this.props.orderRows}
                productArticles={productArticles}
                productAttributes={this.props.productAttributes}
                removeRow={() => null}
                showTableHead={false}
                isEditable={false}
              />
            </div>
            <div className="mt-2">
              <strong>{t('orderSum', { value: formatPrice(orderSum, 'kr') })}</strong>
            </div>
            <div className="mt-2">
              <ButtonContainer>
                <PrimaryButton onClick={() => this.props.onAddToOrder(this.props.orderRows)}>
                  {t('addToOrderButton.label')}
                </PrimaryButton>
              </ButtonContainer>
            </div>
          </React.Fragment>
        )}
      </Translate>
    )
  }
}

const ConnectedSummary = connect((state) => ({
  nextOrderRowId: state.order.nextRowId
}))(
  waitUntilPromiseIsResolved(
    ({ event, productBundles, products }) => {
      const orderRows = getOrderRows({
        event,
        productBundles,
        products,
        priceBundles: [],
      })

      return Promise.all(
        orderRows.map(
          ({ articleId, quantity, productFieldInputs }) =>
            (
              fetchArticlePriceBundle(
                articleId,
                {
                  productFieldInputs: productFieldInputs.filter((x) => !productFieldInputIsEmpty(x)),
                  quantity
                }
              ).then(({ item }) => item)
            )
        )
      )
    },
    (priceBundles, { event, productBundles, products, nextOrderRowId }) => ({
      orderRows: getOrderRows({
        event,
        productBundles,
        products,
        priceBundles,
        nextOrderRowId,
      })
    })
  )(
    connect((state) => ({
      productAttributes: state.productAttributes
    }))(
      Summary
    )
  )
)


const ORDER_SUGGESTION_STEPS = {
  PRODUCTS: 'products',
  SUMMARY: 'summary'
}

class OrderSuggestionSteps extends React.Component {
  state = {
    selectedProductBundles: [],
    step: ORDER_SUGGESTION_STEPS.PRODUCTS
  }

  static propTypes = {
    event: PropTypes.shape(Event.propTypes).isRequired,
    onComplete: PropTypes.func.isRequired
  }

  handleSelectProduct = (productId, moreData) => {
    this.setState({
      selectedProductBundles: [
        ...this.state.selectedProductBundles,
        {
          productId,
          ...moreData
        }
      ],
    })
  }

  handleAddToOrder = (orderRows) => {
    this.props.addOrderRows(orderRows)
    setTimeout(() => {
      this.props.onComplete()
    }, 500)
  }

  render() {

    return (
      <Translate ns="orderSuggestion">
        {({ t }) => (
          caseSelector({
            [ORDER_SUGGESTION_STEPS.PRODUCTS]: () => (
              <ProductSelectionSteps
                event={this.props.event}
                customerEventPriceTypeProducts={this.props.customerEventPriceTypeProducts}
                onSelectProduct={this.handleSelectProduct}
                onComplete={() => this.setState({ step: ORDER_SUGGESTION_STEPS.SUMMARY })}
              />
            ),
            [ORDER_SUGGESTION_STEPS.SUMMARY]: () => (
              <ConnectedSummary
                event={this.props.event}
                products={this.props.customerEventPriceTypeProducts.map(({ product }) => product)}
                productBundles={this.state.selectedProductBundles}
                onAddToOrder={this.handleAddToOrder}
              />
            ),
          })(this.state.step)()
        )}
      </Translate>
    )
  }

}

export default waitUntilPromiseIsResolved(
  () => fetchCustomerEventPriceTypeProducts(),
  ({ items: customerEventPriceTypeProducts }) => ({
    customerEventPriceTypeProducts
  })
)(connect(null, {
  addOrderRows
})(OrderSuggestionSteps))
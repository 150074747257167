import React from 'react';
import ReactSelect, { components as ReactSelectComponents } from 'react-select';

import { withTranslation as translate } from 'react-i18next';

import find from 'lodash/find'

const customReactSelectStyles = {
  control: (base, state) => ({
    ...base,
    backgroundColor: "white"
  }),
  dropdownIndicator: (base, state) => {
    if (state.isFocused) {
      return base
    }

    return {
      ...base,
      color: "#666"
    }
  },
  indicatorSeparator: (base) => {
    return {
      ...base,
      display: 'none'
    }
  }
}

class _Select extends React.Component {
  componentDidMount() {
    if (this.props.focusOnMount) {
      this.reactSelectRef.focus()
    }
  }

  handleChange = (selected, ...args) => {
    this.props.onChange(selected)
  }

  render() {
    const {
      t,
      selectedValueLabel = null,
      focusOnMount,
      clearable,
      multi,
      searchable,
      onChange,
      value,
      options,
      ...selectProps
    } = this.props;

    const isClearable = clearable
    const isMulti = multi
    const isSearchable = searchable

    const selectedOption = find(options, (option) => option.value === value)

    return (
      <ReactSelect
        ref={(ref) => { this.reactSelectRef = ref }}
        components={{
          SingleValue: ({ children, ...props }) => (
            <ReactSelectComponents.SingleValue {...props}>
              {
                selectedValueLabel ? (
                  <span>{selectedValueLabel}: <strong>{children}</strong></span>
                ) : children
              }
            </ReactSelectComponents.SingleValue>
          )
        }}
        noResultsText={t("select.noResultsText")}
        isClearable={isClearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        onChange={this.handleChange}
        options={options}
        value={selectedOption}
        {...selectProps}
        // className="react-select"
        styles={customReactSelectStyles}
      />
    );
  }
}

export default translate('form')(_Select);
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, FormGroup, Label } from "reactstrap";
import Form, { FormInput, FormButton } from 'components/forms/Form';
import { PrimaryButton } from 'components/buttons';
import { ProceedIcon, LoadingIcon } from 'components/Icon';


function SimplifiedForm({
  inputs,
  showLoadingIndicator = false,
  errorMessage,
  t,
  size = 'default',
  ...formProps
}) {
  return (
    <Form t={t} {...formProps}>
      {({ getStateOf, onInputChangeFactory, isBusy, isDirty }) => (
        <React.Fragment>
          <div className="d-flex align-items-end">
            <div className="flex-1 m-0" >
              <React.Fragment>
                {
                  inputs.map(({ name, ...inputProps }) => (
                    <Label className="mb-0 d-block" style={{ position: "relative" }} key={name}>
                      <FormInput
                        name={name}
                        placeholder={t(`form.${name}.placeholder`)}
                        extras={[size]}
                        {...inputProps}
                      />
                      {
                        showLoadingIndicator &&
                        <div className="d-inline-block mr-2" style={{ position: "absolute", top: '50%', right: 0, transform: "translateY(-50%)", pointerEvents: "none" }}>
                          <i className="fa fa-spinner fa-pulse fa-fw" />
                        </div>
                      }
                    </Label>
                  ))
                }
              </React.Fragment>
            </div>
            <div className="flex-0 ml-2">
              <FormButton
                buttonComponent={PrimaryButton}
                {...{
                  size
                }}
              >
                <ProceedIcon />
              </FormButton>
            </div>
          </div>

          {
            errorMessage && (
              <div className="alert alert-danger alert-sm mt-2 p-1 px-2">
                {`${errorMessage}`}
              </div>
            )
          }
        </React.Fragment>
      )}
    </Form>
  );
};

export default SimplifiedForm;

export function SingleInputSimplifiedForm({ inputName, inputType, inputProps = {}, ...formProps }) {
  return (
    <SimplifiedForm
      inputs={[{
        name: inputName,
        type: inputType,
        required: true,
        focusOnMount: true,
        ...inputProps
      }]}
      {...formProps}
    />
  )
};
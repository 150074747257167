import React from 'react';
import { PromiseWrapper } from 'utils/promises';

const promiseHandler = (WrappedComponent) => class PromiseComposerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.makePromise = this.makePromise.bind(this);
  }

  makePromise(promiseToBeWrapped) {
    this._promiseWrapper = new PromiseWrapper(promiseToBeWrapped);
    return this._promiseWrapper.promise;
  }

  componentWillUnmount() {
    if (this._promiseWrapper) {
      this._promiseWrapper.cancel();
      this._promiseWrapper = null;
    }
  }

  render() {
    return (
      <WrappedComponent
        makePromise={this.makePromise}
        {...this.props}
      />
    )
  }
};

export default promiseHandler;
function makeCoolSelection(numOfSelections, amount, index) {

  let currentIndex = 0
  const arr = []

  for (let i = 0; i < amount; i++) {

    const isFirst = i === 0
    const isLast = currentIndex === numOfSelections - 1

    if (isFirst) {
      currentIndex = 0
    }
    else if (!isLast) {

      if (numOfSelections <= 3) {
        currentIndex += 1
      }
      else {
        const amountLeft = amount - i
        const numOfSelectionsLeft = numOfSelections - currentIndex
        const canFitEveryOther = amountLeft < numOfSelectionsLeft / 2
        currentIndex += canFitEveryOther ? 2 : 1
      }

    }

    arr.push(currentIndex)

    if (i === index) {
      break
    }
  }

  return currentIndex

}

module.exports = {
  makeCoolSelection
}
import React from 'react';
import { withTranslation as translate } from 'react-i18next';

import Helmet from 'react-helmet';

import { cleanObject } from '../utils/object';

const DocumentMeta = translate('documentMeta')(function _HelmetWrapper({ title, description, url, imageUrl, t, noindex = false }) {

  description = description || t('defaultDescription');

  const image = imageUrl || null;

  let links = [];

  let standardMeta = {
    description
  }

  if (noindex) {
    standardMeta.robots = "noindex";
  }

  let ogMeta = {
    title,
    description,
    image,
    // url,
    site_name: t('siteName'),
    type: 'website',
    locale: 'se_SV',
  }

  let twitterMeta = {
    card: 'summary',
    site: `@${t('twitterName')}`,
  }

  if (url) {

    const fullUrl = `https://www.lundgrens.com${url || ''}`;

    // links.push({
    //   rel: "canonical",
    //   href: 
    // });

    ogMeta.url = fullUrl;
  }

  ogMeta = cleanObject(ogMeta);
  twitterMeta = cleanObject(twitterMeta);

  return (
    <Helmet
      titleTemplate={t('titleTemplate')}
      defaultTitle={t('defaultTitle')}
      title={title}
      meta={[
        ...Object.keys(standardMeta).map(standardKey => ({
          name: standardKey,
          content: standardMeta[standardKey],
        })),
        ...Object.keys(ogMeta).map(ogKey => ({
          property: `og:${ogKey}`,
          content: ogMeta[ogKey],
        })),
        ...Object.keys(twitterMeta).map(twitterKey => ({
          name: `twitter:${twitterKey}`,
          content: twitterMeta[twitterKey],
        }))
      ]}
      link={links}
    />
  );
});

export default DocumentMeta;
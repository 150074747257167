export const PRODUCT_OPTIONS = {
  FETCH: {
    REQUEST: 'PRODUCT_OPTIONS.FETCH.REQUEST',
    RECEIVE: 'PRODUCT_OPTIONS.FETCH.RECEIVE',
    FAILURE: 'PRODUCT_OPTIONS.FETCH.FAILURE'
  },

  INVALIDATE: 'PRODUCT_OPTIONS.INVALIDATE',
  GET: 'PRODUCT_OPTIONS.GET'
};

export const requestProductOptions = (productOptionIds) => {
  return {
    type: PRODUCT_OPTIONS.FETCH.REQUEST,
    productOptionIds
  }
}

export const receiveProductOptions = (items) => {
  return {
    type: PRODUCT_OPTIONS.FETCH.RECEIVE,
    items
  }
}

export const failedProductOptions = (error) => {
  return {
    type: PRODUCT_OPTIONS.FETCH.FAILURE,
    error
  }
}

export const invalidateProductOptions = () => {
  return {
    type: PRODUCT_OPTIONS.INVALIDATE
  }
}

export const getProductOptions = (productOptionIds, imageVariants = {}) => {
  return {
    type: PRODUCT_OPTIONS.GET,
    productOptionIds,
    imageVariants
  }
}
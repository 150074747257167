import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import times from 'lodash/times'
import last from 'lodash/last'
import find from 'lodash/find'

import classNames from 'classnames'

import { useTranslation } from 'react-i18next'

import { caseSelector } from 'utils/helpers'

import {
  createEmptyContest,
  duplicateContest,
  contestPriceRowsIsValid
} from 'selectors/event'

import { Translate } from 'containers/translates'
import Input from 'components/forms/Input'

import { DarkButton } from 'components/buttons'
import { DeleteIcon } from 'components/Icon'
import Trophy from 'components/Trophy/Trophy';

import { CONTEST_TYPES, PRICE_TYPES, DEFAULT_CONTEST_PRICE_TYPES_ROWS } from './constants'
import { Event, EventContest } from './props'

import EventContestPriceModal from './EventContestPriceModal'

import './EventContestsForm.css'

export class EventContestsOfTypeForm extends React.Component {

  state = {
    selectedContestId: null,
    selectedPriceIndex: null
  }

  static propTypes = {
    contestType: EventContest.propTypes.type.isRequired,
    contests: Event.propTypes.contests,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  }

  handleChangePrice = (changedContestId, changedPriceIndex, incomingPriceData) => {
    const contestPrices = this.getContest(changedContestId).prices
    this.props.onChange(
      changedContestId,
      {
        prices: contestPrices.map((price, priceIndex) => (
          priceIndex === changedPriceIndex ?
            {
              ...price,
              ...incomingPriceData
            } :
            price
        ))
      }
    )
  }

  handleTogglePriceSelection = (changedContestId, changedPriceIndex, forceValue = undefined) => {
    const { contest, price } = this.getContestAndPrice(changedContestId, changedPriceIndex)
    const willBeSelected = forceValue !== undefined ? forceValue : !price.isSelected

    if (willBeSelected) {
      this.handleChangePrice(changedContestId, changedPriceIndex, {
        isSelected: true
      })
      if (!contestPriceRowsIsValid(price.rows)) {
        this.selectContestPrice(changedContestId, changedPriceIndex)
      }
    }
    else {
      this.handleChangePrice(changedContestId, changedPriceIndex, {
        isSelected: false,
        rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[contest.type][price.type]
      })
    }
  }

  selectContestPrice = (contestId, priceIndex) => {
    this.setState({
      selectedContestId: contestId,
      selectedPriceIndex: priceIndex
    })
  }

  deselectContestPrice = () => {
    this.setState({
      selectedContestId: null,
      selectedPriceIndex: null
    })
  }

  getContest(contestId) {
    return find(this.props.contests, (x) => x.id === contestId)
  }

  getContestPrice(contestId, priceIndex) {
    const contest = this.getContest(contestId)
    return contest.prices[priceIndex]
  }

  getContestAndPrice(contestId, priceIndex) {
    const contest = this.getContest(contestId)
    const price = this.getContestPrice(contestId, priceIndex)

    return {
      contest,
      price
    }
  }

  getSelectedContestAndPrice() {
    const hasSelectedContestAndPrice = (
      this.state.selectedContestId !== null &&
      this.state.selectedPriceIndex !== null
    )

    if (!hasSelectedContestAndPrice) {
      return undefined
    }

    return this.getContestAndPrice(
      this.state.selectedContestId,
      this.state.selectedPriceIndex
    )
  }

  getContestsOfType(contestType) {
    return this.props.contests.filter((contest) => contest.type === contestType)
  }

  renderContests = (contestsT) => {

    const [commonT] = useTranslation("common")

    return (
      <div className="EventContestsOfType">
        {
          this.getContestsOfType(this.props.contestType).map((contest, contestIndex, teamContests) => (
            <Translate ns={contest.type} key={contest.id}>
              {({ t: contestTypeT }) => (
                <div className="EventContestsOfType_Item">
                  <div className="d-flex">
                    <Input
                      type="text"
                      onChange={(value) => this.props.onChange(contest.id, {
                        name: value
                      })}
                      value={contest.name}
                      placeholder={contestTypeT("name.placeholder")}
                      focusOnMount
                      onKeyPress={(e) => {
                        const isLast = teamContests.length - 1 === contestIndex

                        if (!isLast) {
                          return
                        }

                        switch (e.key) {
                          case 'Enter':
                            this.props.onAdd(contest.type)
                            break
                        }
                      }}
                    />
                    <div className="ml-2">
                      <button className="btn btn-dark" onClick={() => this.props.onRemove(contest.id)} tabIndex="-1">
                        <DeleteIcon fixedWidth />
                      </button>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="EventContestPrices">
                      {
                        contest.prices.map((price, priceIndex) => {

                          const priceHasValidRows = contestPriceRowsIsValid(price.rows)
                          const priceIsValidAndSelected = priceHasValidRows && price.isSelected

                          const className = classNames("EventContestPrices_Item", {
                            "-is-selected": price.isSelected,
                            "-is-valid": priceHasValidRows
                          })

                          return (
                            <div
                              key={priceIndex}
                              className="EventContestPrices_ItemWrapper"
                            >
                              <div
                                className={className}
                                onClick={() => {
                                  if (priceIsValidAndSelected) {
                                    this.selectContestPrice(contest.id, priceIndex)
                                  }
                                  else {
                                    this.handleTogglePriceSelection(contest.id, priceIndex)
                                  }
                                }}
                              >
                                <div className="EventContestPrices_Item_Icon">
                                  <Trophy
                                    variant={{
                                      'team.rows': "trophy",
                                      'team.rowsAndCopies': "goldMedal",
                                      'team.copies': "medal",
                                      'individual.rows': "trophy",
                                      'individual.copies': "medal",
                                    }[`${this.props.contestType}.${price.type}`]}
                                    size="large"
                                  />
                                </div>
                                <div className="EventContestPrices_Item_Description">
                                  <h4 className={classNames("mb-0", {
                                    "text-secondary": priceIsValidAndSelected
                                  })}>
                                    {contestTypeT(`prices.${price.type}.label`, { context: price.type })}
                                  </h4>
                                  <p>
                                    {
                                      priceIsValidAndSelected &&
                                      caseSelector({
                                        [PRICE_TYPES.ROWS]: () => (
                                          times(price.rows.length, (n) => n + 1)
                                            .map((position) => contestsT('prices.position.description', { context: position.toString() }))
                                            .join(", ")
                                        ),
                                        [PRICE_TYPES.ROWS_AND_COPIES]: () => {
                                          const positionsDisplay =
                                            times(price.rows.length, (n) => n + 1)
                                              .map((position) => contestsT('prices.position.description', { context: position.toString() }))
                                              .join(", ")

                                          const numOfCopiesDisplay = contestTypeT(`prices.${price.type}.numOfCopies`, { count: get(price, 'rows[0].numOfCopies', 0) })

                                          return `${positionsDisplay} - ${numOfCopiesDisplay}`
                                        },
                                        [PRICE_TYPES.COPIES]: () => (
                                          contestTypeT(`prices.${price.type}.numOfCopies`, { context: PRICE_TYPES.COPIES, count: get(price, 'rows[0].numOfCopies', 0) })
                                        ),
                                      })(price.type)()
                                    }
                                  </p>
                                </div>
                                <div className="EventContestPrices_Item_Actions">
                                  {priceIsValidAndSelected ? (
                                    <button
                                      className="btn btn-dark btn-sm"
                                    >{commonT("actions.edit")}</button>
                                  ) : (
                                      <button
                                        className="btn btn-outline-dark btn-sm"
                                      >{commonT("actions.add")}</button>
                                    )}
                                </div>
                                {/* <div className="form-check">
                                <label className="form-check-label">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    addFormControlClassName={false}
                                    onChange={() => this.handleTogglePriceSelection(contest.id, priceIndex)}
                                    checked={price.isSelected}
                                    tabIndex="-1"
                                  /> {contestTypeT(`prices.${price.type}.label`, { context: price.type })} {
                                    price.isSelected &&
                                    caseSelector({
                                      [PRICE_TYPES.ROWS]: () => (
                                        times(price.rows.length, (n) => n + 1)
                                          .map((position) => contestsT('prices.position.description', { context: position.toString() }))
                                          .join(", ")
                                      ),
                                      [PRICE_TYPES.ROWS_AND_COPIES]: () => {
                                        const positionsDisplay =
                                          times(price.rows.length, (n) => n + 1)
                                            .map((position) => contestsT('prices.position.description', { context: position.toString() }))
                                            .join(", ")

                                        const numOfCopiesDisplay = contestTypeT(`prices.${price.type}.numOfCopies`, { count: get(price, 'rows[0].numOfCopies', 0) })

                                        return `${positionsDisplay} - ${numOfCopiesDisplay}`
                                      },
                                      [PRICE_TYPES.COPIES]: () => (
                                        contestTypeT(`prices.${price.type}.numOfCopies`, { context: PRICE_TYPES.COPIES, count: get(price, 'rows[0].numOfCopies', 0) })
                                      ),
                                    })(price.type)()
                                  }
                                </label>
                              </div> */}
                                {/* {
                                price.isSelected && (
                                  <span style={{ cursor: "pointer" }} onClick={() => this.selectContestPrice(contest.id, priceIndex)} tabIndex="-1" className="ml-2">
                                    <EditIcon fixedWidth />
                                  </span>
                                )
                              } */}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>

                </div>
              )}
            </Translate>
          ))

        }
      </div>
    )
  }

  renderEmpty = (t) => {
    return (
      <p className="font-italic text-muted">{t(`${this.props.contestType}.emptyDescription`)}</p>
    )
  }

  renderModal = () => {

    const selectedContestAndPrice = this.getSelectedContestAndPrice()
    const rows = selectedContestAndPrice.price.rows
    const numOfRows = rows.length
    const numOfCopies = numOfRows > 0 ? rows[0].numOfCopies : undefined

    const hasValidRows = contestPriceRowsIsValid(selectedContestAndPrice.price.rows)

    return (
      <Translate ns={`${selectedContestAndPrice.contest.type}.prices.${selectedContestAndPrice.price.type}`}>
        {() => (
          <EventContestPriceModal
            isOpen
            contest={selectedContestAndPrice.contest}
            onCancel={() => {
              this.handleChangePrice(
                this.state.selectedContestId,
                this.state.selectedPriceIndex,
                {
                  isSelected: hasValidRows
                }
              )
              this.deselectContestPrice()
            }}
            onRemove={() => {
              this.handleChangePrice(
                this.state.selectedContestId,
                this.state.selectedPriceIndex,
                {
                  isSelected: false,
                  rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[selectedContestAndPrice.contest.type][selectedContestAndPrice.price.type]
                }
              )
              this.deselectContestPrice()
            }}
            onSubmit={({
              numOfRows = 1,
              numOfCopies = 1
            }) => {
              this.handleChangePrice(
                this.state.selectedContestId,
                this.state.selectedPriceIndex,
                {
                  rows: times(
                    numOfRows,
                    () => ({
                      numOfCopies
                    })
                  )
                }
              )
              this.deselectContestPrice()
            }}
            showNumOfRows={[PRICE_TYPES.ROWS, PRICE_TYPES.ROWS_AND_COPIES].indexOf(selectedContestAndPrice.price.type) !== -1}
            numOfRows={numOfRows}
            showNumOfCopies={[PRICE_TYPES.ROWS_AND_COPIES, PRICE_TYPES.COPIES].indexOf(selectedContestAndPrice.price.type) !== -1}
            numOfCopies={numOfCopies}
          />
        )}
      </Translate>
    )
  }

  render() {

    const selectedContestAndPrice = this.getSelectedContestAndPrice()
    const hasSelectedContestAndPrice = selectedContestAndPrice !== undefined

    return (
      <Translate ns="contests">
        {({ t: contestsT }) => (
          <React.Fragment>
            {
              hasSelectedContestAndPrice &&
              this.renderModal()
            }
            {
              this.getContestsOfType(this.props.contestType).length > 0 ?
                this.renderContests(contestsT) :
                this.renderEmpty(contestsT)
            }

            <div className="mt-2">
              <DarkButton outline onClick={() => this.props.onAdd(this.props.contestType)}>
                {contestsT(`${this.props.contestType}.addButton.label`)}
              </DarkButton>
            </div>
          </React.Fragment>
        )}
      </Translate>
    )
  }
}


export default class EventContestsForm extends React.Component {
  static propTypes = {
    contests: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  }

  getNextId = () => {
    const lastContest = last(this.props.contests)
    return lastContest ?
      lastContest.id + 1 :
      1
  }

  handleChange = (changedContestId, incomingContestData) => {
    this.props.onChange(this.props.contests.map((contest) => (
      changedContestId === contest.id ?
        ({
          ...contest,
          ...incomingContestData
        }) :
        contest
    )))
  }

  handleRemove = (contestIdToBeRemoved) => {
    this.props.onChange(this.props.contests.filter((contest) => contestIdToBeRemoved !== contest.id))
  }

  handleAdd = (contestType) => {
    const lastOfContestType = last(this.props.contests.filter(x => x.type === contestType))
    const emptyContest = createEmptyContest(
      contestType,
      this.getNextId()
    )

    this.props.onChange([
      ...this.props.contests,
      lastOfContestType ?
        duplicateContest(lastOfContestType, emptyContest) :
        emptyContest
    ])
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <h3>{this.props.t(`contests.${CONTEST_TYPES.TEAM}.header`)}</h3>
          <EventContestsOfTypeForm
            contestType={CONTEST_TYPES.TEAM}
            contests={this.props.contests}
            onAdd={this.handleAdd}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
          />
        </div>

        <div className="mt-3">
          <h3>{this.props.t(`contests.${CONTEST_TYPES.INDIVIDUAL}.header`)}</h3>
          <EventContestsOfTypeForm
            contestType={CONTEST_TYPES.INDIVIDUAL}
            contests={this.props.contests}
            onSubmit={this.handleSubmit}
            onAdd={this.handleAdd}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
          />
        </div>
      </React.Fragment>
    )
  }
}



import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  signout
} from 'actions'

import CustomerSection from 'components/Order/CustomerSection'
import { OneColumnLayout } from 'components/layouts';
import { Translate } from 'containers/translates';
import { Button } from 'components/buttons';

function TestPage({ user, signout }) {
  return (
    <OneColumnLayout>
      <h1>{"Test page"}</h1>
      <p>{JSON.stringify(user)}</p>
      <Button onClick={signout}>X</Button>
      <div>
        <Translate ns="order.sections">
          {() => (
            <CustomerSection sectionState="active" />
          )}
        </Translate>
      </div>
    </OneColumnLayout>
  )
}

TestPage.propTypes = {
  user: PropTypes.object.isRequired,
  signout: PropTypes.func.isRequired,
}

export default connect(({ user }) => ({ user }), {
  signout
})(TestPage)
import React from 'react'
import PropTypes from 'prop-types'

const FieldInputsContext = React.createContext(null)

class FieldInputsContextProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }

  constructor(props) {
    super(props)

    this.state = {
      fieldInputs: [],
      onFieldInput: this.handleFieldInput
    }
  }

  handleFieldInput = (fieldId, fieldInput) => {
    this.setState({
      fieldInputs: [
        ...this.state.fieldInputs,
        {
          fieldId,
          input: fieldInput
        }
      ]
    })
  }

  render() {
    return (
      <FieldInputsContext.Provider value={this.state}>
        {this.props.children}
      </FieldInputsContext.Provider>
    )
  }
}

export default {
  Provider: FieldInputsContextProvider,
  Consumer: FieldInputsContext.Consumer
}
import { MODALS } from '../actions';


const initState = [];

export default (state = initState, action) => {
  switch(action.type) {
    case MODALS.ADD:
      return [
        ...state,
        {
          modalComponentId: action.modalComponentId,
          modalComponentProps: action.modalComponentProps
        }
      ]
    case MODALS.REMOVE:
      return state.filter(modal => modal.modalComponentId !== action.modalComponentId);
    default:
      return state;
  }
}
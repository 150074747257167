import React from 'react';
import { setPassword } from 'api';
import { patterns } from 'utils/values';

import Form, { FormInput, LabeledFormInput, FormButton } from 'components/forms/Form';
import { FormGroup } from 'reactstrap';


export default function UserNewPasswordForm({ onAfterSubmit, token = undefined, t }) {
  return (
    <Form
      t={t}
      valueRules={[
        {
          key: "password",
          required: true,
          ...patterns.password
        },
        {
          key: "retypePassword",
          required: true,
          ...patterns.retypePassword,
          method: (value, values) => {
            const retypeMatches = values.password === values.retypePassword;
            return [
              retypeMatches,
              "noMatch"
            ];
          }
        }
      ]}
      onSubmit={function _passwordSubmit(formState) {
        const { password, retypePassword } = formState;

        if (password === retypePassword) {
          setPassword(formState.password, token)
            .then(function () {
              onAfterSubmit();
            })
            .catch(function (...args) {
            })
        }
        else {
          // Validation error
        }
      }}>
      {({ getStateOf, onInputChangeFactory }) => (
        <div>
          <FormGroup>
            <LabeledFormInput
              {...{t}}
              inputName="password"
              inputType="password"
            />
          </FormGroup>

          <FormGroup>
            <LabeledFormInput
              {...{t}}
              inputName="retypePassword"
              inputType="password"
            />
          </FormGroup>

          <FormGroup>
            <FormButton>
              {t('submit.label')}
            </FormButton>
          </FormGroup>
        </div>
      )}
    </Form>
  )
};

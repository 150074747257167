import React from 'react';
import Markdown from '../components/Markdown';

import { PageHeading } from './content';

const PageHeader = ({title, description = false, useParser = true}) => (
  <header>
    <PageHeading>{title}</PageHeading>
    <div className="text-center text-md-left px-2 px-md-0" style={{maxWidth: '600px'}}>
      {
        description && 
        (
          useParser ? 
          <Markdown content={description}/> : 
          <p>{description}</p>
        )
      }
    </div>
  </header>
)

export default PageHeader;
import React from 'react';
import { searchUnionNodes } from 'api';

import Autosuggest from './Autosuggest';

const ID_NEW = 'new';

export default class UnionNodeSearchForm extends React.Component {
  dataToSuggestions = (items, inputValue) => {
    const { t } = this.props;

    return [
      ...((items || []).map(({ id, display_name, city }) => {
        return {
          id,
          name: display_name,
          display: display_name,
          customerName: display_name,
          city: city
        };
      })),
      {
        id: ID_NEW,
        display: t('create', { value: inputValue }),
        name: inputValue,
      }
    ];
  }

  createPromise = (value) => {
    return searchUnionNodes({
      q: value,
      limit: 4,
    });
  }

  onSuggestionSelected = (suggestion) => {
    const {
      onSelect = () => null,
      onSelectId = () => null,
      onCreate = onSelect
    } = this.props;

    if (suggestion.id === ID_NEW) {
      onCreate(suggestion);
    }
    else {
      onSelect(suggestion);
      onSelectId(suggestion.id);
    }
  }

  render() {
    return (
      <div>
        <div>
          <Autosuggest
            dataToSuggestions={this.dataToSuggestions}
            createPromise={this.createPromise}
            onSetValue={(value) => this.setState({ q: value })}
            onSuggestionSelected={this.onSuggestionSelected}
            placeholder={"Sök i föreningsregistret"}
            className="form-control form-control-lg"
            autoFocus
          />
        </div>
      </div>
    );
  }
}
/* eslint-disable no-undef */
import { parseBoolean } from 'utils/storage';

const config = {
    ENABLE_GOOGLE_ANALYTICS_LOGGING: parseBoolean(process.env.ENABLE_GOOGLE_ANALYTICS_LOGGING),
    ENABLE_SENTRY: parseBoolean(process.env.ENABLE_SENTRY),
    DEBUG: parseBoolean(process.env.DEBUG),
    API_URL: process.env.API_URL,
    API_DEFAULT_TOKEN: process.env.API_DEFAULT_TOKEN,
    STORAGE_TOKEN_KEY: '_userToken',
}

if (config.DEBUG) {
    window.config = config
}

export default config;
import React from 'react'
import PropTypes, { bool } from 'prop-types'

import isEqual from 'lodash/isEqual'
import memoize from 'lodash/memoize'

import { cleanArray } from 'utils/array'

import { RenderProps } from 'containers/hoc'
import promiseHandler from 'containers/hoc/promiseHandler'
import { Translate } from 'containers/translates'

import Input from './Input'
import { Label, Row, FormGroup as bootstrapFormGroup } from 'reactstrap'
import { DarkButton } from 'components/buttons'
import { cleanObject } from 'utils/object'


const FormContext = React.createContext({})


export const FormRow = ({ children }) => (
  <Row className="form-row">{children}</Row>
)

export const FormGroup = bootstrapFormGroup

export function FormInput({ name, ...inputProps }) {

  return (
    <FormContext.Consumer>
      {({ getStateOf, getValidationErrorsOf, onInputChangeFactory, isBusy, isDirty, hasTriedSubmit }) => (
        <Input
          {...inputProps}
          name={name}
          value={getStateOf(name)}
          validationErrors={getValidationErrorsOf(name)}
          hasTriedSubmit={hasTriedSubmit}
          onChange={onInputChangeFactory(name)}
          busy={isBusy}
        />
      )}
    </FormContext.Consumer>
  )
}

export function FormButton({ buttonComponent: ButtonComponent = DarkButton, children, disabled, ...buttonProps }) {
  return (
    <FormContext.Consumer>
      {({ isBusy, isDirty }) => (
        <ButtonComponent {...buttonProps} disabled={disabled !== undefined ? disabled : (!isDirty || isBusy)}>
          {children}
        </ButtonComponent>
      )}
    </FormContext.Consumer>

  )
}

export const LabeledFormInput = ({ inputName, inputType, t, ...inputProps }) => (
  <React.Fragment>
    <Label
      className="d-block mb-1"
      for={`input-${inputName}`}
    >
      {t(`inputs.${inputName}`, { context: 'label' })}
    </Label>
    <FormInput
      name={inputName}
      type={inputType}
      id={`input-${inputName}`}
      placeholder={t(`inputs.${inputName}`, { context: 'placeholder' })}
      {...inputProps}
    />
  </React.Fragment>
)

export class BaseForm extends React.Component {
  el = null

  submit = (event) => {
    event.preventDefault()

    const submittedFormEl = event.target

    if (submittedFormEl === this.el) {
      this.props.onSubmit(event)
    }
    else {
      console.warn("Some other form is trying to hijack this form :/(", this.el, submittedFormEl)
    }

    return false
  }

  render() {
    const { children } = this.props

    return (
      <form
        onSubmit={this.submit}
        ref={(el) => this.el = el}
      >
        {children}
      </form>
    )
  }
}



class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.getResetState(props)
  }

  getResetStateState = () => {
    return {
      isBusy: false,
      isDirty: false,
      hasTriedSubmit: false,
      validationErrors: [],
    }
  }

  getResetState = (props) => {
    return {
      ...(props.initialInputValues || {}),
      ...(this.getResetStateState()),
    }
  }

  getInputValuesState = memoize((state) => {
    const { isDirty, isBusy, hasTriedSubmit, validationErrors: _, ...inputValues } = state
    return inputValues
  })

  testValueRule = (valueRule, values) => {

    const key = valueRule.name || valueRule.key
    const { t } = this.props

    function _errorFactory(errorKey, errorContext = {}) {
      return {
        key,
        error: (t ? t(`validationErrors.${errorKey}`, errorContext) : errorKey)
      }
    }

    if (key) {
      const { [key]: value } = values

      if (value) {
        if (valueRule.pattern) {
          const r = new RegExp(valueRule.pattern)
          const valueIsValid = r.test(value)

          if (!valueIsValid) {
            return _errorFactory('patternMismatch', {
              context: valueRule.context,
              pattern: valueRule.pattern
            })
          }
        }

        if (valueRule.minLength) {
          if (value.length < valueRule.minLength) {
            return _errorFactory('tooShort', { minLength: valueRule.minLength })
          }
        }

        if (valueRule.maxLength) {
          if (value.length > valueRule.maxLength) {
            return _errorFactory('tooLong', { maxLength: valueRule.maxLength })
          }
        }

        if (valueRule.method) {
          const [methodValidates, methodErrorKey] = valueRule.method(value, values)
          if (!methodValidates) {
            return _errorFactory(methodErrorKey)
          }
        }
      }
      else {
        if (valueRule.required) {
          return _errorFactory('required')
        }
      }
    }

    return true
  }

  validate = () => {
    const { valueRules = [], t } = this.props
    const inputValues = this.getInputValuesState(this.state)

    const validationErrors = valueRules
      .map(valueRule => this.testValueRule(valueRule, inputValues))
      .filter(x => x !== true)

    return [
      validationErrors.length === 0,
      validationErrors
    ]
  }

  onSubmit = () => {

    const [
      isValid,
      validationErrors
    ] = this.validate()

    if (isValid) {
      this.setState(() => ({
        isBusy: true,
        hasTriedSubmit: true,
        validationErrors: []
      }), () => {

        const inputValues = this.getInputValuesState(this.state)
        const onSubmitPromise = (this.props.onSubmit(inputValues) || new Promise(r => setTimeout(r, 1000)))

        this.props.makePromise(onSubmitPromise)
          .then((resp) => {
            this.setState(
              () => (this.props.resetAfterSubmit ? this.getResetState(this.props) : this.getResetStateState()),
              () => {
              }
            )
          })
          .catch((resp) => {
            if (resp && !resp.isCanceled) {

              this.setState(() => ({
                isBusy: false
              }))
            }
          })
      })
    }
    else {
      this.setState(() => ({
        validationErrors,
        hasTriedSubmit: true
      }))
    }
  }

  _onIncomingInputValues = (incomingInputValues, cb) => {
    this.setState(state => {
      const currentInputValues = this.getInputValuesState(state)

      const nextInputValues = cleanObject({
        ...currentInputValues,
        ...incomingInputValues
      }, [""])

      const initialInputValues = cleanObject(
        this.getInputValuesState(this.getResetState(this.props)),
        [""]
      )

      const isEqualToInitialInputValues = isEqual(
        initialInputValues,
        nextInputValues
      )

      return {
        ...incomingInputValues,
        isDirty: !isEqualToInitialInputValues,
      }
    }, () => {
      if (cb) {
        cb(this.state)
      }
    })
  }

  onInputChange = (key, value, cb) => {
    const valueParsers = (this.props.valueParsers || []).filter(x => x.key === key).map(x => x.parser)

    const parsedValue = valueParsers.reduce(
      (accum, parser) => parser(accum),
      value
    )

    return this._onIncomingInputValues(
      { [key]: parsedValue },
      (state) => {
        if (cb) {
          cb(key, state[key], state)
        }
        if (this.props.onChange) {
          this.props.onChange(key, state[key], state, this.setState.bind(this))
        }
      }
    )
  }

  onInputChangeFactory = (key, cb) => (value) => this.onInputChange(key, value, cb)

  getStateOf = (key, defaultValue = '') => {
    return (this.state[key] || defaultValue)
  }

  getValidationErrorsOf = (key) => {
    const [isValid, validationErrors] = this.validate()
    return validationErrors.filter(x => x.key === key)
  }

  componentDidMount() {
    this.setState(
      () => this.getResetState(this.props),
      () => {
        if (this.props.prefilledInputValues) {
          this._onIncomingInputValues(this.props.prefilledInputValues)
        }
      }
    )
  }

  componentWillUnmount() {
    if (this._promiseWrapper) {
      this._promiseWrapper.cancel()
      this._promiseWrapper = null
    }
  }


  constructContextApi = memoize((state) => ({
    getStateOf: this.getStateOf,
    getValidationErrorsOf: this.getValidationErrorsOf,
    onInputChange: this.onInputChange,
    onInputChangeFactory: this.onInputChangeFactory,
    isBusy: this.state.isBusy,
    isDirty: this.state.isDirty,
    hasTriedSubmit: this.state.hasTriedSubmit,
    formValues: this.getInputValuesState(this.state)
  }))

  render() {
    const contextApi = this.constructContextApi(this.state)

    return (
      <BaseForm
        onSubmit={this.onSubmit}
        className={cleanArray(["form", this.props.className]).join(" ")}
      >
        <FormContext.Provider value={contextApi}>
          <RenderProps
            children={this.props.children}
            {...contextApi}
          />
        </FormContext.Provider>

      </BaseForm>
    )
  }
}

Form.propTypes = {
  t: PropTypes.func.isRequired
}

export default promiseHandler(({ ...props }) => (
  <Translate ns="form">
    {({ t }) => (
      <Form {...props} t={t} />
    )}
  </Translate>
))
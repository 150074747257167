import React from 'react'
import PropTypes from 'prop-types'

import { DarkButton } from 'components/buttons'
import { Translate } from 'containers/translates'
import Address from 'components/Address'
import DeliveryMethodNameAndDescription from './DeliveryMethodNameAndDescription'
import { callbackWithoutPropagationComposer } from 'utils/event'


function DeliveryMethodMainColumn({ deliveryMethod, isSelected, showDeliveryAddress, deliveryAddress, onChangeDeliveryAddress }) {
  const renderedNameAndDescription = React.useMemo(() => (
    <DeliveryMethodNameAndDescription
      {...{
        deliveryMethod: deliveryMethod,
        isSelected: isSelected
      }}
    />
  ), [deliveryAddress, isSelected])

  if (!showDeliveryAddress) {
    return renderedNameAndDescription
  }

  return (
    <Translate>
      {({ t }) => (
        <React.Fragment>
          {renderedNameAndDescription}
          {isSelected && deliveryAddress !== undefined && (
            <div className="mt-2">
              <h4>{deliveryAddress.name}</h4>
              <Address
                showName={false}
                showContactInformation
                {...deliveryAddress}
              />
              <DarkButton
                outline
                size="sm"
                onClick={callbackWithoutPropagationComposer(onChangeDeliveryAddress)}
              >
                {t('changeButtonLabel')}
              </DarkButton>
            </div>
          )}
        </React.Fragment>
      )}
    </Translate>
  )
}

DeliveryMethodMainColumn.propTypes = {
  deliveryMethod: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showDeliveryAddress: PropTypes.bool.isRequired,
  onChangeDeliveryAddress: PropTypes.func,
  deliveryAddress: PropTypes.object,
}

export default DeliveryMethodMainColumn

export function confirmDialog(text) {

  const confirm =
    (window && window.confirm) ||  (
      (text) => {
        console.warn("window.confirm does not exist?", text);
        return true;
      }
    );

  return confirm(text);
}
import React from 'react';
import first from 'lodash/first';

import { testEmail } from 'api';
import promiseHandler from 'containers/hoc/promiseHandler';

import { caseSelector } from 'utils/helpers';

import { SingleInputSimplifiedForm } from 'components/forms/SimplifiedForm';

const TestEmailForm = promiseHandler(class _TestEmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(formState) {
    const { email } = formState;

    return this.props.makePromise(
      testEmail(email)
    )
      .then(({ result, userId = null, customerIds = [], customerNames = [] }) => {
        caseSelector(
          {
            'user_email': () => this.props.onFoundUser({ email, userId }),
            'customer_email': () => this.props.onFoundCustomer({ email, customerIds, nameSuggestion: first(customerNames) }),
          },
          () => this.props.onNotFound({ email })
        )(result)();

        return Promise.resolve();
      })
  }

  render() {
    return (
      <SingleInputSimplifiedForm
        t={this.props.t}
        inputType="email" inputName="email"
        onSubmit={this.onSubmit}
      />
    )
  }
});

export default TestEmailForm;
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as i18nextTranslate } from 'react-i18next';

import isObject from 'lodash/isObject'
import isEqual from 'lodash/isEqual';

import { PromiseWrapper } from 'utils/promises';
import { windowScrollToTop } from 'utils/elements';

import VisibilitySensor from 'react-visibility-sensor';
import LoadingIndicator from 'components/spinners';


const checkForSatisfyingStateAndThenConnect = (checkForSatisfyingState, mapStateToProps, mapDispatchToProps) => {
  const __connect = connect((state, ownProps) => {
    const stateIsSatisfying = checkForSatisfyingState(state, ownProps);
    const mappedProps = mapStateToProps(state, ownProps);

    return {
      stateIsSatisfying,
      ...mappedProps
    }
  }, mapDispatchToProps);

  return __connect;
}


export const waitUntilSatisfyingState = (checkForSatisfyingState, mapStateToProps, mapDispatchToProps, callbackIfNotSatisfied = null) => {
  return (WrapperComponent, WaitingComponent = LoadingIndicator) => {
    return checkForSatisfyingStateAndThenConnect(checkForSatisfyingState, mapStateToProps, mapDispatchToProps)(class TT extends React.Component {
      componentDidMount() {
        const { stateIsSatisfying } = this.props;
        if (!stateIsSatisfying && callbackIfNotSatisfied !== null) {
          callbackIfNotSatisfied(this.props);
        }
      }

      render() {
        const { stateIsSatisfying, ...props } = this.props;
        return (
          stateIsSatisfying ?
            <WrapperComponent {...props} /> :
            <WaitingComponent />
        );
      }
    })
  }
}


export function waitUntilPromiseIsResolved(promiseCallback, mapDataToProps = (state) => ({ ...state }), shouldRerunPromise = null, extraData = {}) {
  return (WrapperComponent, WaitingComponent, ErrorComponent) => class PromiseWaiter extends React.Component {
    constructor(props) {
      super(props);

      this.haveNewPromiseData = false;

      this.state = {
        data: null,
        isEnded: false,
        promiseResult: null,
        errorMessage: null,
      };

      this._count = 0;
    }

    runPromise() {

      this._count++;
      const promise = promiseCallback(this.props);

      this.wrappedFetchPromise = new PromiseWrapper(promise);
      this.wrappedFetchPromise.promise
        .then(data => {
          this.haveNewPromiseData = true;

          this.setState({
            data: data,
            isEnded: true,
            wasResolved: true
          });
        })
        .catch((data) => {
          if (data && data.isCanceled) {
            return;
          }

          this.haveNewPromiseData = true;

          this.setState(state => ({
            data: null,
            isEnded: true,
            wasResolved: false,
            errorMessage: data.message
          }), function () {
          });
        });
    }

    shouldComponentUpdate(nextProps) {
      return this.haveNewPromiseData;
    }

    componentDidMount() {
      this.runPromise();
    }

    componentDidUpdate(prevProps) {
      if (shouldRerunPromise !== null) {
        if (shouldRerunPromise(prevProps, this.props)) {
          this.runPromise();
        }
      }
    }

    componentWillUnmount() {
      if (this.wrappedFetchPromise) {
        this.wrappedFetchPromise.cancel();
      }
    }

    render() {

      const { wasResolved, isEnded, data, errorMessage } = this.state;

      return (
        isEnded ? (
          wasResolved ?
            <WrapperComponent
              {...mapDataToProps(data, this.props)}
              {...this.props}
            /> :
            (
              ErrorComponent ?
                <ErrorComponent {...this.props} errorMessage={errorMessage} /> : (
                  <div className="alert alert-danger">
                    <p>
                      Ojojojoj, fel. {errorMessage}
                    </p>
                  </div>
                )
            )
        ) : (
            WaitingComponent ?
              <WaitingComponent {...this.props} /> :
              <LoadingIndicator />
          )
      )
    }
  }
}

export const mountWhenVisible = (visibilitySensorProps = {}) => {
  return (WrappedComponent) => class HOC extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isVisible: false
      };

      this.onChangeVisibility = this.onChangeVisibility.bind(this);
    }

    onChangeVisibility(isVisible) {

      if (isVisible && !this.state.isVisible) {
        this.setState({ isVisible: true });
      }
    }

    render() {
      const { isVisible } = this.state;
      const mergedVisibilitySensorProps = {
        partialVisibility: true,
        intervalCheck: false,
        scrollCheck: true,
        scrollInterval: 1,
        ...(visibilitySensorProps || {})
      };

      return (
        <div>
          <VisibilitySensor onChange={this.onChangeVisibility} {...mergedVisibilitySensorProps} />
          {isVisible && <WrappedComponent {...this.props} />}
        </div>
      )
    }
  }
}

export const makeToggable = (attrs) => {

  if (!Array.isArray(attrs)) {
    attrs = [attrs];
  }

  attrs = attrs.map(attr => isObject(attr) ? {
    name: attr.name,
    initialValue: (attr.initialValue || false)
  } : {
      name: attr,
      initialValue: false
    });

  return (WrappedComponent) => class HOC extends React.Component {
    constructor(props) {
      super(props);

      const state = attrs.reduce((accum, { name, initialValue }) => ({
        ...accum,
        [name]: initialValue
      }), {});

      this.state = state;
      this.toggle = this.toggle.bind(this);
    }

    toggle(attr, force = undefined, resetRest = false) {
      this.setState(state => {
        return {
          ...(resetRest ? attrs.reduce((accum, a) => ({
            ...accum,
            [a.name]: a.initialValue
          }), {}) : {}),
          [attr]: (force !== undefined ? force : !state[attr])
        };
      });
    }

    render() {
      return <WrappedComponent toggle={this.toggle} {...this.state} {...this.props} />;
    }
  }
}

export const makeSingleToggable = (WrappedComponent, initialValue = false) => makeToggable({ name: 'isActive', initialValue })(({ toggle, isActive, ...props }) => (
  <WrappedComponent toggle={(force) => toggle('isActive', force)} isActive={isActive} {...props} />
));



export const scrollToTop = (shouldScrollToTopAftercomponentDidUpdate = () => false) => (WrappedComponent) => class HOC extends React.Component {

  scrollToTop() {
    windowScrollToTop(false);
  }

  componentDidMount() {
    this.scrollToTop();
  }

  componentDidUpdate(prevProps) {
    const shouldScrollToTop = shouldScrollToTopAftercomponentDidUpdate(this.props, prevProps);

    if (shouldScrollToTop) {
      this.scrollToTop();
    }
  }

  render() {
    return <WrappedComponent {...this.props} />;
  }
}


export function translate(nsAndKeyBase) {

  const [
    ns,
    splittedKeyBase = []
  ] = nsAndKeyBase.split(".");

  return (WrappedComponent) => i18nextTranslate(ns)(class _innerTranslate extends React.Component {
    constructor(props) {
      super(props);

      this.t = this.t.bind(this);
    }

    getFullKey(key) {
      const fullKey = [
        ...splittedKeyBase,
        key
      ].join(".");
      return fullKey;
    }

    t(key, context) {
      return this.props.t(this.getFullKey(key), context);
    }

    render() {
      const { t, ...props } = this.props;

      return (
        <WrappedComponent
          {...props}
          t={this.t}
        />
      )
    }
  })
}


export const RenderProps = function ({ children, ...props }) {
  return children(props);
}


export const withState = function (initState, stateKey = '_RANDOM_STATE_KEY_') {

  const isSingleState = !isObject(initState);

  function statify(state) {
    if (isSingleState) {
      return {
        [stateKey]: state
      }
    }
    else {
      return {
        ...state
      }
    }
  }

  return (Component) => class _withStateContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        ...statify(initState)
      };

      this.changeState = this.changeState.bind(this);
      this.isEqualToCurrentState = this.isEqualToCurrentState.bind(this);
    }

    changeState(newState) {
      this.setState((state) => ({
        ...(statify(newState))
      }), function () {
      })
    }

    isEqualToCurrentState(state) {
      if (isSingleState) {
        return state === this.state[stateKey];
      }
      else {
        return isEqual(state, this.state);
      }
    }

    getState() {
      return (
        isSingleState ?
          this.state[stateKey] :
          this.state
      );
    }

    render() {
      const { ...props } = this.props;
      const { ...stateProps } = this.state;

      return (
        <Component
          {...props}
          {...stateProps}
          state={this.getState()}
          changeState={this.changeState}
          isEqualToCurrentState={this.isEqualToCurrentState}
        />
      )
    }
  }
}

class WithStateContainer extends React.Component {
  componentDidMount() {
    const {
      initialState: stateWhenMounted
    } = this.props;

    if (stateWhenMounted) {
      this.props.changeState(stateWhenMounted);
    }
  }
  render() {
    const {
      initialState: stateWhenMounted,
      ...props
    } = this.props;

    return (
      <RenderProps {...props} />
    )
  }
}

export function withStateFactory(initState) {
  return withState(initState)(WithStateContainer);
}

const _ToggleState = withStateFactory(true);

export function ToggleState({ children, ...props }) {
  return (
    <_ToggleState>
      {({ state, changeState, isEqualToCurrentState }) => (
        children({
          toggle: () => changeState(!state),
          isCurrent: isEqualToCurrentState
        })
      )}
    </_ToggleState>
  )
}

// export function withSingleStateFactory(initStateValue, stateKey = '_RANDOM_STATE_KEY_') {
//   return withState({
//     [stateKey]: initStateValue
//   })(({ changeState, [stateKey]: stateValue, initialState, ...props }) => {
//     return (
//       <RenderProps
//         changeState={(newStateValue) => changeState({
//           [stateKey]: newStateValue
//         })}
//         state={stateValue}
//         initialState={(
//           initialState ? {
//             [stateKey]: initialState
//           } : {}
//         )}
//         {...props}
//       />
//     )
//   })
// }
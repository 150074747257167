import { cleanObject } from 'utils/object'

export const ORDER = {
  FETCH: {
    REQUEST: 'ORDER.FETCH.REQUEST',
    RECEIVE: 'ORDER.FETCH.RECEIVE',
    FAILURE: 'ORDER.FETCH.FAILURE'
  },

  GET: 'ORDER.GET',
  INVALIDATE: 'ORDER.INVALIDATE',

  ROWS: {
    FETCH: {
      REQUEST: 'ORDER.ROWS.FETCH.REQUEST',
      RECEIVE: 'ORDER.ROWS.FETCH.RECEIVE',
      FAILURE: 'ORDER.ROWS.FETCH.FAILURE'
    },

    ADD: 'ORDER.ROWS.ADD',
    ADD_MULTIPLE: 'ORDER.ROWS.ADD_MULTIPLE',
    ADDED: 'ORDER.ROWS.ADDED',

    EDIT: 'ORDER.ROWS.EDIT',
    EDITED: 'ORDER.ROWS.EDITED',

    REMOVE: 'ORDER.ROWS.REMOVE',
    REMOVED: 'ORDER.ROWS.REMOVED',

    RESET: 'ORDER.ROWS.RESET',
    RESETED: 'ORDER.ROWS.RESETED',
  },

  SET_USE_DATE: 'ORDER.SET_USE_DATE',
  SET_NAME: 'ORDER.SET_NAME',
  SET_COMMENT: 'ORDER.SET_COMMENT',
  SET_DISCOUNT_CODE: 'ORDER.SET_DISCOUNT_CODE',
  SET_DELIVERY_METHOD: 'ORDER.SET_DELIVERY_METHOD',
  SET_PAYMENT_METHOD: 'ORDER.SET_PAYMENT_METHOD',
  SET_CUSTOMER: 'ORDER.SET_CUSTOMER',
  SET_DELIVERY_ADDRESS: 'ORDER.SET_DELIVERY_ADDRESS',
  SET_INVOICE_ADDRESS: 'ORDER.SET_INVOICE_ADDRESS',

  SET_SUBMIT_STATE: 'ORDER.SET_SUBMIT_STATE',

  RESET: 'ORDER.RESET',

  CREATE_AND_FETCH: 'ORDER.CREATE_AND_FETCH',
  EDIT_AND_FETCH: 'ORDER.EDIT_AND_FETCH',
  SEND_AND_RESET: 'ORDER.SEND_AND_RESET',

  CREATE_SEND_AND_RESET: 'ORDER.CREATE_SEND_AND_RESET',
  REMOVE_AND_RESET: 'ORDER.REMOVE_AND_RESET',

  CREATE_ORDER_AND_START_PAYMENT_AND_WAIT_FOR_RESOLVED_PAYMENT: 'ORDER.CREATE_ORDER_AND_START_PAYMENT_AND_WAIT_FOR_RESOLVED_PAYMENT',

  UPLOAD_LOCAL_FILES: 'ORDER.UPLOAD_LOCAL_FILES',
  LOCAL_FILES_HAS_BEEN_UPLOADED: 'ORDER.LOCAL_FILES_HAS_BEEN_UPLOADED',

  TEMP_FETCH: {
    REQUEST: 'ORDER.TEMP_FETCH.REQUEST',
    RECEIVE: 'ORDER.TEMP_FETCH.RECEIVE',
    FAILURE: 'ORDER.TEMP_FETCH.FAILURE'
  },

  GET_TEMP: 'ORDER.GET_TEMP',

  SEND: 'ORDER.SEND',
  SENT: 'ORDER.SENT',

};

export const addOrderRow = (id, articleId, quantity, unitPrice, inputValues, optionSelectionPicks, productFieldInputs, showConfirmationModal=false) => {
  return {
    type: ORDER.ROWS.ADD,
    id, articleId, quantity, unitPrice, inputValues, optionSelectionPicks, productFieldInputs,
    showConfirmationModal
  }
}

export const addOrderRows = (rows) => {
  return {
    type: ORDER.ROWS.ADD_MULTIPLE,
    rows
  }
}

export const editOrderRow = (id, { quantity, unitPrice, inputValues, optionSelectionPicks, productFieldInputs }, silent = false) => {
  return {
    type: ORDER.ROWS.EDIT,
    id,
    quantity,
    unitPrice,
    inputValues,
    optionSelectionPicks,
    productFieldInputs,
    silent
  }
}

export const removeOrderRow = (id) => {
  return {
    type: ORDER.ROWS.REMOVE,
    id
  }
}

export const resetOrderRows = () => {
  return {
    type: ORDER.ROWS.RESET
  }
}

// export const createSendAndResetOrder = (customerId, data) => ({
//   type: ORDER.CREATE_SEND_AND_RESET,
//   customerId, data
// })

export const createAndFetchOrder = (customerId, data) => {
  return {
    type: ORDER.CREATE_AND_FETCH,
    customerId, data
  }
}

export const createOrderAndStartPaymentAndWaitForResolvedPayment = (customerId, data) => {
  return {
    type: ORDER.CREATE_ORDER_AND_START_PAYMENT_AND_WAIT_FOR_RESOLVED_PAYMENT,
    customerId, data
  }
}

export const editAndFetchOrder = (customerId, id, data) => {
  return {
    type: ORDER.EDIT_AND_FETCH,
    customerId, id, data
  }
}

export const getTempOrder = (customer, data) => {
  return {
    type: ORDER.GET_TEMP,
    customer,
    data
  }
}

export const requestTempOrder = (data) => ({
  type: ORDER.TEMP_FETCH.REQUEST,
  data
})

export const receiveTempOrder = (data) => ({
  type: ORDER.TEMP_FETCH.RECEIVE,
  data
})

export const receiveOrder = (data) => {
  return {
    type: ORDER.FETCH.RECEIVE,
    data
  }
}

export const sendOrder = (orderId) => ({
  type: ORDER.SEND,
  orderId,
})

export const orderSent = (orderId) => ({
  type: ORDER.SENT,
  orderId
})

export const resetOrder = () => {
  return {
    type: ORDER.RESET
  }
}

export const sendAndResetOrder = (customerId, id, data) => {
  return {
    type: ORDER.SEND_AND_RESET,
    customerId, id, data
  }
}

export const removeAndResetOrder = (customerId, id) => {
  return {
    type: ORDER.REMOVE_AND_RESET,
    customerId, id
  }
}

export const setUseDate = (useDate) => {
  return {
    type: ORDER.SET_USE_DATE,
    useDate
  }
}

export const setName = (name) => {
  return {
    type: ORDER.SET_NAME,
    name
  }
}

export const setComment = (comment) => {
  return {
    type: ORDER.SET_COMMENT,
    comment
  }
}

export const setDiscountCode = (discountCode) => {
  return {
    type: ORDER.SET_DISCOUNT_CODE,
    discountCode
  }
}

export const setDeliveryMethod = (deliveryMethodId) => {
  return {
    type: ORDER.SET_DELIVERY_METHOD,
    deliveryMethodId
  }
}

export const setPaymentMethod = (paymentMethodId) => {
  return {
    type: ORDER.SET_PAYMENT_METHOD,
    paymentMethodId
  }
}

export const setSubmitState = (submitState) => {
  return {
    type: ORDER.SET_SUBMIT_STATE,
    submitState
  }
}

export const setCustomer = (
  customerId,
  customerIdForDelivery = null,
  customerIdForInvoice = null,
  sendInvoiceBy = 'delivery'
) => {
  return {
    type: ORDER.SET_CUSTOMER,
    customerId, customerIdForDelivery, customerIdForInvoice,
    sendInvoiceBy
  }
}

export function setDeliveryAddress(id) {
  return {
    type: ORDER.SET_DELIVERY_ADDRESS,
    id
  }
}

export function setInvoiceAddress(id) {
  return {
    type: ORDER.SET_INVOICE_ADDRESS,
    id
  }
}


export const uploadLocalFiles = (ids) => ({
  type: ORDER.UPLOAD_LOCAL_FILES,
  ids
})

export const localFilesHasBeenUploaded = () => ({
  type: ORDER.LOCAL_FILES_HAS_BEEN_UPLOADED
})




export function fromSyncableInputs(inputs) {
  return inputs.reduce((accum, x) => ({
    ...accum,
    [x.inputId]: x.value
  }), {})
}

export function toSyncableComments(comment) {
  const comments = []

  if (comment) {
    comments.push({ text: comment })
  }

  return comments
}

export function validateOrderState(orderState) {
  return orderState.rows.length > 0
}



export const toSyncableOrderRows = (simplifiedRows) => {
  return simplifiedRows.map(
    (simplifiedRow) => {
      const { articleId, quantity, unitPrice, inputValues, optionSelectionPicks, productFieldInputs } = simplifiedRow;

      const syncableRow = ({
        articleId,
        quantity,
        unitPrice,
        inputs: inputValues.map(({ inputId, value }) => ({
          inputId,
          value
        })),
        selections: optionSelectionPicks.map(({ optionId, optionScopeId, selectionId, inputs }) => ({
          optionId,
          optionScopeId,
          selectionId,
          inputs
        })),
        productFieldInputs
      })

      return syncableRow;
    })
}

export const fromSyncableOrderRows = (syncedRows, buildSummary) => syncedRows.map(({
  selections: optionSelectionPicks = [],
  inputs = [],
  ...commonProps
}) => ({
  optionSelectionPicks: optionSelectionPicks
    .map(({ inputs, ...optionSelectionPick }) => ({
      ...optionSelectionPick,
      inputValues: inputs,
    }))
    .map((optionSelectionPick) => ({
      ...optionSelectionPick,
      summary: buildSummary(optionSelectionPick),
    })),
  inputValues: inputs,
  ...commonProps,
})
);

export function fromOrderStateToSyncableOrder(orderState) {
  const {
    useDate,
    name,
    comment,
    discountCode,
    deliveryMethodId,
    paymentMethodId,
    rows,
    // customerId,
    customerIdForInvoice,
    customerIdForDelivery,
    sendInvoiceBy
  } = orderState

  return cleanObject({
    customerIdForInvoice,
    customerIdForDelivery,
    sendInvoiceBy,
    useDate,
    deliveryMethodId,
    paymentMethodId,
    discountCode,
    name,
    rows: toSyncableOrderRows(rows),
    comments: toSyncableComments(comment),
  })
}
import times from 'lodash/times'
import find from 'lodash/find'

import { caseSelector } from 'utils/helpers'
import { CONTEST_TYPES, PRICE_TYPES, DEFAULT_CONTEST_PRICE_TYPES_ROWS } from './constants'


export function createEmptyContest(contestType, id) {
  return {
    type: contestType,
    name: "",
    id,
    prices: caseSelector({
      [CONTEST_TYPES.TEAM]: [
        {
          type: PRICE_TYPES.ROWS,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.TEAM][PRICE_TYPES.ROWS]
        },
        {
          type: PRICE_TYPES.ROWS_AND_COPIES,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.TEAM][PRICE_TYPES.ROWS_AND_COPIES]
        },
        {
          type: PRICE_TYPES.COPIES,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.TEAM][PRICE_TYPES.COPIES]
        },
      ],
      [CONTEST_TYPES.INDIVIDUAL]: [
        {
          type: PRICE_TYPES.ROWS,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.INDIVIDUAL][PRICE_TYPES.ROWS]
        },
        {
          type: PRICE_TYPES.COPIES,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.INDIVIDUAL][PRICE_TYPES.COPIES]
        },
      ]
    })(contestType)
  }
}

export function createEmptyEvent() {
  return {
    id: null,
    name: '',
    date: null,
    activityId: null,
    contests: [],
  }
}

export function duplicateContest(contest, defaultProps) {

  const { name, type, prices } = contest

  return {
    ...defaultProps,
    name, type, prices
  }
}

export function contestPriceRowIsValid(row) {
  return row.numOfCopies !== null
}

export function contestPriceRowsIsValid(rows) {
  const numOfRows = rows.length
  const numOfValidRows = rows.filter(contestPriceRowIsValid).length
  return numOfRows > 0 && numOfRows === numOfValidRows
}


export function exportEvent(event) {
  const {
    name, date, activityId,
    contests
  } = event

  const contestsWithOnlySelectedPrices = contests.map((contest) => ({
    ...contest,
    prices: contest.prices.filter((price) => price.isSelected)
  }))

  return {
    name, date, activityId,
    contests: contestsWithOnlySelectedPrices
  }
}

export function importEvent(event) {
  const {
    id,
    name, date, activityId,
    contests: contestsWithOnlySelectedPrices
  } = event

  const allContests = contestsWithOnlySelectedPrices.map((contestWithOnlySelectedPrices) => ({
    ...contestWithOnlySelectedPrices,
    prices: createEmptyContest(contestWithOnlySelectedPrices.type, contestWithOnlySelectedPrices.id).prices.map(
      (emptyPrice) => {
        const selectedPrice = find(
          contestWithOnlySelectedPrices.prices,
          (x) => x.type === emptyPrice.type
        )

        return (
          selectedPrice !== undefined ?
            {
              ...selectedPrice,
              isSelected: true
            } :
            emptyPrice
        )
      }
    )
  }))

  const importedEvent = {
    id,
    name, date, activityId,
    contests: allContests
  }

  return importedEvent
}


export function createContestWithNameAndSelectedPriceTypes(contestType, id, name, priceTypeAndNumOfCopiesBundles) {
  const emptyContest = createEmptyContest(contestType, id)

  const contest = {
    ...emptyContest,
    name,
    prices: emptyContest.prices.map(
      (price) => (
        priceTypeAndNumOfCopiesBundles[price.type] ?
          ({
            ...price,
            isSelected: true,
            rows: times(priceTypeAndNumOfCopiesBundles[price.type][0], () => ({
              numOfCopies: priceTypeAndNumOfCopiesBundles[price.type][1]
            }))
          }) :
          price
      )
    )
  }
  return contest
}

export function createDummyEvent({
  name = "Testevent",
  activityId = 149,
  date = new Date()
} = {}) {
  return {
    ...createEmptyEvent(),
    name, date, activityId,
    contests: [
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.TEAM, 1, "P10", {
        [PRICE_TYPES.COPIES]: [1, 500],
      }),
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.TEAM, 2, "F10", {
        [PRICE_TYPES.COPIES]: [1, 200],
      }),
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.TEAM, 3, "P12", {
        [PRICE_TYPES.ROWS]: [3, 1],
        [PRICE_TYPES.ROWS_AND_COPIES]: [3, 20],
      }),
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.TEAM, 4, "F12", {
        [PRICE_TYPES.ROWS]: [3, 1],
        [PRICE_TYPES.ROWS_AND_COPIES]: [3, 20],
      }),
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.INDIVIDUAL, 5, "Trixtävling", {
        [PRICE_TYPES.ROWS]: [2, 1],
      })
    ],
  }
}


import React from 'react'
import PropTypes from 'prop-types'

function DeliveryMethodNameAndDescription({ deliveryMethod, isSelected }) {
  return (
    <React.Fragment>
      <h3 className={isSelected ? "text-primary" : ""}>
        {deliveryMethod.name}
      </h3>
      {deliveryMethod.description && (
        <p className="mb-0">{deliveryMethod.description}</p>
      )}
    </React.Fragment>
  )
}

DeliveryMethodNameAndDescription.propTypes = {
  deliveryMethod: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default DeliveryMethodNameAndDescription
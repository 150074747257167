import times from 'lodash/times'

export const CONTEST_TYPES = {
  TEAM: 'team',
  INDIVIDUAL: 'individual'
}

export const PRICE_TYPES = {
  ROWS: 'rows',
  COPIES: 'copies',
  ROWS_AND_COPIES: 'rowsAndCopies',
  CUSTOM: 'custom',
}

const numOfCopiesSingleCopyValues = 1
const numOfCopiesNotSetupValue = null

const defaultNumOfRows = 3

export function createRows(numOfRows, numOfCopies) {
  return times(numOfRows, () => ({
    numOfCopies
  }))
}

export const DEFAULT_CONTEST_PRICE_TYPES_ROWS = {
  [CONTEST_TYPES.TEAM]: {
    [PRICE_TYPES.ROWS]: [], // createRows(defaultNumOfRows, numOfCopiesSingleCopyValues),
    [PRICE_TYPES.ROWS_AND_COPIES]: [], // createRows(defaultNumOfRows, numOfCopiesNotSetupValue),
    [PRICE_TYPES.COPIES]: createRows(1, numOfCopiesNotSetupValue),
  },
  [CONTEST_TYPES.INDIVIDUAL]: {
    [PRICE_TYPES.ROWS]: [], // createRows(defaultNumOfRows, numOfCopiesSingleCopyValues),
    [PRICE_TYPES.COPIES]: createRows(1, numOfCopiesNotSetupValue)
  }
}
import React from 'react'
import PropTypes from 'prop-types'

import TextWidget from './TextWidget'

export default class SimpleTextField extends React.Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired
  }
  static defaultProps = {
    input: {}
  }

  handleChange = (value) => {
    if (!!value) {
      this.props.onChange({ value })
    }
    else {
      this.props.onReset()
    }
  }

  render() {
    const {
      onChange,
      input,
      ...widgetProps
    } = this.props

    return (
      <TextWidget
        {...widgetProps}
        value={input.value}
        onChange={this.handleChange}
      />
    )
  }
}
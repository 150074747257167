import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import FilterDropdown from './FilterDropdown';

const defaultOrderingOptions = [
  { label: "sequence", value: "SEQUENCE" },
  { label: "price", value: "PRICE_ASC" },
  { label: "name", value: "NAME" },
  { label: "articleNumber", value: "ARTICLE_NUMBER_ASC" },
];

export const defaultOrdering = "SEQUENCE";

const ProductArchiveOrderingDropdown = translate('productArchive')(({
  orderingOptions = defaultOrderingOptions,
  selectedOrdering = defaultOrdering,
  onSelect,
  t
}) => {

  const fetchOptions = React.useCallback(() => Promise.resolve(orderingOptions.map(({ label, value }) => ({
    label: t('ordering.option.label', { context: label }),
    value,
  }))), [])

  const selectedValues = React.useMemo(() => [selectedOrdering], [selectedOrdering])

  return (
    <FilterDropdown
      label={(selectedOptions) => <div style={{ display: "inline-block", width: 240, textAlign: "left" }}>{selectedOptions.length > 0 ? `${t('ordering.label')}: ${selectedOptions[0].label}` : t('ordering.label')}</div>}
      fetchOptions={fetchOptions}
      immediateFetch
      toggleValueSelection={onSelect}
      selectedValues={selectedValues}
    />
  );
});

export default ProductArchiveOrderingDropdown
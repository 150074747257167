import React from 'react'
import PropTypes from 'prop-types'

import { Translate } from 'containers/translates';
import ContentHeader from 'components/ContentHeader'

import AssistantComment from './AssistantComment'

export default function WizardStepWrapper({ ns, headerContext, children, withAssistantComment, assistantCommentStartOpen }) {
  return (
    <Translate
      key={ns}
      ns={ns}
    >
      {({ t }) => (
        <div>
          <ContentHeader
            context={headerContext}
            {...{
              withPre: true,
              withPost: false
            }}
          />
          {
            withAssistantComment && (
              <div className="mt-3 mb-4">
                <AssistantComment
                  key={ns}
                  startOpen={assistantCommentStartOpen}
                >
                  <h2>{t('assistantComment.header')}</h2>
                  <p className="mb-0">{t('assistantComment.text')}</p>
                </AssistantComment>
              </div>
            )
          }
          {children(t)}
        </div>
      )}
    </Translate>
  )
}

WizardStepWrapper.propTypes = {
  ns: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  headerContext: PropTypes.object,
  withAssistantComment: PropTypes.bool,
  assistantCommentStartOpen: PropTypes.bool,
}


WizardStepWrapper.defaultProps = {
  headerContext: {},
  withAssistantComment: false,
  assistantCommentStartOpen: false
}
import find from 'lodash/find'

import { getProductFromHistoryByArticleId } from './selectedProduct'
import { getProductCategoryFlatTree } from './productCategories'
import { sumProductFieldInputPrices } from './productFields'

import { fetchArticlePriceBundle } from 'api'


export function getOrderRow(state, rowId) {
  return find(state.order.rows, r => r.id === rowId)
}

export function getOrderRowBundle(state, rowId) {
  const row = getOrderRow(state, rowId)
  const product = getProductFromHistoryByArticleId(state, row.articleId)

  if (product) {
    const article = find(product.articles, a => a.id === row.articleId)

    return {
      row,
      product: {
        ...product,
        selectedArticleIdentifier: article.identifier,
        selectedArticleId: article.id,
      },
      categoryTree: getProductCategoryFlatTree(state, product.mainCategoryId),
    }
  }
  else {
    return undefined
  }
}

export function getOrderRowBundles(state) {
  return state.order.rows
    .map(row => row.id).map(getOrderRowBundle.bind(null, state))
    .filter(n => n) // Removes rows where product is not in selectedProductHistory
}

export function buildOptionSelectionSummary({ selectionId, inputValues = [], inputs = [] }, optionScope, option) {
  const optionSelection = option && find(option.selections, s => s.id === selectionId)
  const optionGroup = optionSelection && find(option.groups, ({ id }) => id === optionSelection.groupId)

  const optionScopeName = optionScope ? optionScope.name : optionGroup.name
  const optionGroupName = optionGroup.name
  const optionScopeNameDiffersFromOptionGroupName = optionScopeName != optionGroupName

  // TODO: This method now supports two different prop names for the same values ..
  const uglyMergeInputValues = [
    ...inputValues,
    ...inputs,
  ]

  const summary = {
    name: optionScopeNameDiffersFromOptionGroupName ? `${optionScopeName} (${optionGroupName})` : optionScopeName,
    value: (
      uglyMergeInputValues.length > 0 ?
        uglyMergeInputValues.map(({ inputId, name, value }) => value).join(", ") :
        optionSelection.attributeValue
    )
  }

  return summary
}


export const numOfOrderRows = (state) => state.order.rows.length


export function createOrderRow({
  id,
  articleId,
  quantity,
  productFieldInputs
}) {
  return new Promise((resolve) => {
    fetchArticlePriceBundle(
      articleId,
      {
        productFieldInputs,
        quantity
      }
    )
      .then(({ item }) => item)
      .then((priceBundle) => {
        const unitPrice = (
          priceBundle.price +
          sumProductFieldInputPrices(priceBundle.productFieldInputPrices)
        )

        resolve({
          id,
          articleId,
          quantity,
          unitPrice: unitPrice,
          productFieldInputs
        })
      })
  })
}
import { ACTIVITIES } from '../actions';

const activities = (state = [], action) => {
  switch(action.type) {
    case ACTIVITIES.FETCH.RECEIVE:
      return [...action.items];
    default:
      return state;
  }
}

export default activities;
import React from 'react';
import { connect } from 'react-redux';

import ModalFactory from '../components/modals/ModalFactory';

export default connect(state => ({
  modals: state.modals
}))(({modals}) => {

  return (
    <div className="Modals">
      {modals.map(({modalComponentId, modalComponentProps}) => {
        return (
          <ModalFactory
            key={modalComponentId}
            modalComponentId={modalComponentId}
            modalComponentProps={modalComponentProps}
            isOpen
          />
        );
      })}
    </div>
  )
});
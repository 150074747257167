import React from 'react';
import { connect } from 'react-redux';
import { waitUntilSatisfyingState } from '../../containers/hoc';

import { getProductOptions } from '../../actions';
import { getProductOptionById } from '../../selectors';

import ProductOption, { ProductOptionLoading } from './ProductOption';

export default waitUntilSatisfyingState(
  (state, ownProps) => {
    const {optionId} = ownProps;
    const option = getProductOptionById(state, optionId);
    return option !== null && option.state === "fetched";
  },
  (state, ownProps) => {
    const {optionId} = ownProps;
    const option = getProductOptionById(state, optionId);
    return {
      option
    }
  },
  {
    getProductOptions
  },
  (props) => {
    const {getProductOptions, optionId, imageVariants} = props;
    getProductOptions([optionId], imageVariants);
  }
)(ProductOption, ProductOptionLoading);
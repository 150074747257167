import { useState, useCallback } from 'react'

export default function useBoolState(initialState = false) {
  const [state, setState] = useState(initialState)

  const toTrue = useCallback(() => setState(true), [setState])
  const toFalse = useCallback(() => setState(false), [setState])
  const toggle = useCallback(() => setState(!setState), [setState])

  return [
    state,
    toTrue,
    toFalse,
    toggle
  ]
}
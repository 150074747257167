
import { cleanArray } from 'utils/array';


export const toggleClass = (element, className, force = undefined) => {

  const addClassNameToList = force !== undefined ? force : !element.classList.contains(className);
  element.classList[addClassNameToList ? 'add' : 'remove'](className);

}

export const matches = (element, selector) => {
  const matchesFunc = (
    Element.prototype.matches ||
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    function (s) {
      var matches = (this.document || this.ownerDocument).querySelectorAll(s),
        i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) { }
      return i > -1;
    }
  );

  return matchesFunc.call(element, selector);
}

export function windowScrollTo(scrollY = 0, animationDuration = false) {
  if (!window) {
    return;
  }

  if (animationDuration === false) {
    window.scrollTo(0, scrollY);
    return;
  }

  var cosParameter = (window.scrollY - scrollY) / 2,
    scrollCount = 0,
    oldTimestamp = performance.now();

  function step(newTimestamp) {
    scrollCount += Math.PI / (animationDuration / (newTimestamp - oldTimestamp));

    if (scrollCount >= Math.PI) {
      window.scrollTo(0, scrollY);
    }

    if (window.scrollY === scrollY) {
      return
    }

    window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
    oldTimestamp = newTimestamp;

    window.requestAnimationFrame(step);

  }

  window.requestAnimationFrame(step);
}

export function windowScrollToTop(animationDuration = false) {
  windowScrollTo(0, animationDuration);
}

export function arrayToClassName(a) {
  return cleanArray(a, [false]).join(' ')
}

export function arrayToMaybeClassNameAsProps(a) {
  const className = arrayToClassName(a)
  return (className.length > 0 ? { className } : {});
}
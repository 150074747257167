export const ACTIVITIES = {
  FETCH: {
    REQUEST: 'ACTIVITIES.FETCH.REQUEST',
    RECEIVE: 'ACTIVITIES.FETCH.RECEIVE',
    FAILURE: 'ACTIVITIES.FETCH.FAILURE'
  },
  GET: 'ACTIVITIES.GET'
};

export const requestActivities = () => {
  return {
    type: ACTIVITIES.FETCH.REQUEST,
  }
}

export const receiveActivities = (items) => {
  return {
    type: ACTIVITIES.FETCH.RECEIVE,
    items
  }
}


export const failedActivities = (error) => {
  return {
    type: ACTIVITIES.FETCH.FAILURE,
    error
  }
}
import React from 'react';


export default function LinkedData({type, data}) {
  return (
    <script type="application/ld+json">{
      JSON.stringify({
        "@context": "http://schema.org",
        "@type": type,
        ...data
      })
    }</script>
    );
}
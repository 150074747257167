import React from 'react';
import moment from 'moment';

import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';


import './DatePicker.scss';

export default class DatePicker extends React.Component {
  render() {
    const {
      inputName = null,
      placeholder,
      value,
      onChange,
      extras = [],
      focusOnMount,
      validationErrors = [],
      hasTriedSubmit = false,
      ...props
    } = this.props;

    const today = moment();

    let
      selectedMoment = null,
      initialMoment = null

    if (!!value) {
      initialMoment = selectedMoment = moment(value)
    }

    const showValidationErrors = hasTriedSubmit && validationErrors.length > 0

    return (
      <div style={showValidationErrors ? { padding: "0px 10px", border: "1px solid red" } : { padding: "0px 10px" }}>
        <DayPicker
          key={value.toString()}
          selectedDays={selectedMoment !== null ? selectedMoment.toDate() : undefined}
          initialMonth={initialMoment !== null ? initialMoment.toDate() : undefined}
          onDayClick={(day, { disabled }) => {
            if (disabled) {
              return
            }
            onChange(moment(day).format())
          }}
          disabledDays={[{
            before: today.toDate()
          }]}
          localeUtils={MomentLocaleUtils}
          locale={'sv-se'}
        />
        {
          showValidationErrors && validationErrors.map((validationError, validationErrorIndex) => (
            <p key={validationErrorIndex}>{validationError.error}</p>
          ))
        }
      </div>
    )

  }
}
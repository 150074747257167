import React from 'react'

import { PromiseWrapper } from '../../utils/promises'
import Autosuggest from 'react-autosuggest'

import './Autosuggest.scss'
import { cleanArray } from '../../utils/array'

const getSuggestionValue = ({ name }) => name
const renderSuggestion = (suggestion) => {

  return (
    <div className="d-flex align-items-center">
      <div style={{
        flex: '0 0 40px'
      }}>
        <img alt='' src={suggestion.imageUrl} style={{
          width: 'auto',
          height: '40px',
        }} />
      </div>

      <div style={{
        marginLeft: '20px'
      }}>
        <small>
          <span>{suggestion.type}</span>
          <span className="d-block">{suggestion.display || suggestion.text || suggestion.name}</span>
        </small>
      </div>
    </div>
  )
}

class _Autosuggest extends React.Component {
  constructor(props) {
    super(props)

    const { value } = this.props

    this.state = {
      value,
      suggestions: this.props.dataToSuggestions(null, value)
    }

    this.wrappedFetchPromise = null
    this.getSuggestionsTimeoutId = null
  }

  setValue = (value) => {
    this.setState({ value })
    if (this.props.onSetValue) {
      this.props.onSetValue(value)
    }
  }

  resetValue = () => {
    this.setValue("")
  }

  maybeResetFetchPromise = () => {

    if (this.wrappedFetchPromise) {
      this.wrappedFetchPromise.cancel()
      this.wrappedFetchPromise = null
    }
  }

  getSuggestions = (value) => {

    this.maybeResetFetchPromise()

    const fetchPromise = this.props.createPromise(value)

    this.wrappedFetchPromise = new PromiseWrapper(fetchPromise)
    this.wrappedFetchPromise.promise
      .then(data => {

        this.setState({
          suggestions: this.props.dataToSuggestions(data, value)
        })

        this.wrappedFetchPromise = null

      })
      .catch((msg) => {
        this.wrappedFetchPromise = null
        this.setState({
          suggestions: this.props.dataToSuggestions(null, value)
        })
      })
  }

  componentWillUnmount() {
    this.maybeResetFetchPromise()
    this.maybeResetGetSuggestionsTimeout()
  }

  maybeResetGetSuggestionsTimeout = () => {
    if (this.getSuggestionsTimeoutId !== null) {
      clearTimeout(this.getSuggestionsTimeoutId)
      this.getSuggestionsTimeoutId = null
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.maybeResetGetSuggestionsTimeout()
    if (value.length > 1) {
      this.getSuggestionsTimeoutId = setTimeout(this.getSuggestions.bind(this, value), 200)
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.dataToSuggestions(null, this.state.value)
    })
  }

  onSuggestionSelected = (e, { suggestion, method }) => {
    e.preventDefault()

    const shouldTrigger = ['click', 'enter'].indexOf(method) !== -1

    if (shouldTrigger) {

      if (this.props.triggerResetsValue) {
        this.resetValue()
      }

      if (this.props.onSuggestionSelected) {
        this.props.onSuggestionSelected(suggestion)
      }
    }
  }

  render() {
    const { value, suggestions } = this.state
    const { placeholder, autoFocus = false, className, renderSuggestion: customRenderSuggestion, t } = this.props

    const inputProps = {
      placeholder: placeholder,
      value: value || '',
      autoFocus: autoFocus,
      onChange: ((e, { newValue }) => this.setValue(newValue)).bind(this),
      className: cleanArray([
        'react-autosuggest__input',
        className,
      ]).join(' ')
    }

    return (
      <div className="AutoSuggest">
        <Autosuggest
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestion={customRenderSuggestion || renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    )
  }
}

export default _Autosuggest
import find from 'lodash/find';

export const getActivityById = (activities, id) => {
  return find(activities, a => a.id === id);
}

export const getActivityBySlug = (activities, slug) => {
  return find(activities, a => a.slug === slug);
}

export const getFeaturedActivities = (state) => {
  const featuredActivities = state.activities.filter(({isFeatured}) => isFeatured);
  return featuredActivities;
}
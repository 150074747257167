export const USER_SETTINGS = {
  SET_ACTIVITY: 'USER_SETTINGS.SET_ACTIVITY',
  SET_ACCEPT_COOKIE_USAGE: 'USER_SETTINGS.SET_ACCEPT_COOKIE_USAGE',
  OPTION_SELECTION_PICK: 'USER_SETTINGS.OPTION_SELECTION_PICK',
  RESET_OPTION_SELECTION_PICK: 'USER_SETTINGS.RESET_OPTION_SELECTION_PICK',
  FIELD_INPUT: 'USER_SETTINGS.FIELD_INPUT',
  RESET_FIELD_INPUT: 'USER_SETTINGS.RESET_FIELD_INPUT',

  FIELD_TEMPLATE_SELECTION: 'USER_SETTINGS.FIELD_TEMPLATE_SELECTION',
  FIELD_VARIABLE_INPUT: 'USER_SETTINGS.FIELD_VARIABLE_INPUT',

  RESET: 'USER_SETTINGS.RESET',


  LOCAL_FILES: {
    ADD: 'USER_SETTINGS.LOCAL_FILES.ADD',
    EDIT: 'USER_SETTINGS.LOCAL_FILES.EDIT',
    REMOVE: 'USER_SETTINGS.LOCAL_FILES.REMOVE',
  }
}

export const setUserActivity = (activitySlug) => {
  return {
    type: USER_SETTINGS.SET_ACTIVITY,
    activitySlug
  }
}

export const setAcceptCookieUsage = (hasAcceptedCookieUsage) => ({
  type: USER_SETTINGS.SET_ACCEPT_COOKIE_USAGE,
  hasAcceptedCookieUsage
})

export const pickedOptionScopeSelection = (optionScopeIdentifier, optionSelectionIdentifier, inputValues) => ({
  type: USER_SETTINGS.OPTION_SELECTION_PICK,
  optionScopeIdentifier, optionSelectionIdentifier, inputValues
})

export const resetPickedOptionScopeSelection = (optionScopeIdentifier) => ({
  type: USER_SETTINGS.RESET_OPTION_SELECTION_PICK,
  optionScopeIdentifier
})

export const fieldInput = (fieldId, input) => ({
  type: USER_SETTINGS.FIELD_INPUT,
  fieldId, input
})

export const resetFieldInput = (fieldId) => ({
  type: USER_SETTINGS.RESET_FIELD_INPUT,
  fieldId
})

export const selectedFieldTemplate = (templateId) => ({
  type: USER_SETTINGS.FIELD_TEMPLATE_SELECTION,
  templateId
})

export const fieldVariableInput = (variableInputData) => ({
  type: USER_SETTINGS.FIELD_VARIABLE_INPUT,
  data: variableInputData
})

export const resetUserSettings = () => ({
  type: USER_SETTINGS.RESET
})

export const addLocalFile = ({
  id,
  name,
  dataUrl,
  selectionTemplateId,
}) => ({
  type: USER_SETTINGS.LOCAL_FILES.ADD,
  id, name, dataUrl,
  selectionTemplateId,
})

export const editLocalFile = (
  id, {
    name,
  }
) => ({
  type: USER_SETTINGS.LOCAL_FILES.EDIT,
  id,
  name,
})

export const removeLocalFile = (id) => ({
  type: USER_SETTINGS.LOCAL_FILES.REMOVE,
  id,
})
import { combineReducers } from 'redux';
import filters from './filters';
import ordering from './ordering';
import items from './items';
import itemsKey from './itemsKey';
import totalNumOfItems from './totalNumOfItems';

import isInvalidated from './isInvalidated';

export default combineReducers({
  items,
  // itemsKey,
  totalNumOfItems,
  filters,
  // ordering,
  isInvalidated
});


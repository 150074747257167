import React from 'react';

import isNumber from 'lodash/isNumber';

import { formatPrice } from '../../utils/content';
import { classNamesMaybeToClassNameProp } from '../../utils/props';

import './ProductFormSectionPrice.scss';

export default function ({ value, placeholder = '', currency = 'kr', alwaysShowPlusMinus = false, valueState = '', className, ...elementProps }) {

  return (
    <div
      className={classNamesMaybeToClassNameProp([
        'ProductFormSectionPrice',
        `--${valueState}`,
        className,
      ])}
      {...elementProps}
    >
      {
        isNumber(value) ?
          formatPrice(value, currency, alwaysShowPlusMinus) :
          placeholder
      }
    </div>
  )
}
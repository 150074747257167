export const localSelectionsCreationForm = {
  header: {
    pre: "",
    heading: "Ladda upp bildfil",
    post: ""
  },
  inputs: {
    file: "Fil",
    name: "Namn",
    selectionTemplate: "Alternativ",
  },
  submit: {
    label: "Spara",
  },
  modalButton: {
    label: "Ladda upp"
  }
}

const textField = {
  templateSelection: {
    header: "Mallar",
    freeTextButton: {
      label: {
        header: "Fritext",
        description: "Skriv vad du vill!"
      }
    }
  },
  switchTemplateButton: {
    label: "Byt mall"
  },
  template: {
    submitButton: {
      label: "Spara"
    },
    cancelButton: {
      label: "Avbryt"
    }
  },
  freeText: {
    header: "Fritext",
    submitButton: {
      label: "Spara"
    },
    cancelButton: {
      label: "Avbryt"
    }
  },
  submitButton: {
    label: "Spara"
  },
  cancelButton: {
    label: "Avbryt"
  }
}

export default {

  description: {
    facts: {
      header: "Intressanta fakta"
    },
    options: {
      numOfSelections: "1 alternativ",
      numOfSelections_plural: "{{count}} alternativ"
    },
    requiredOptions: {
      header: "Ingår"
    },
    optionalOptions: {
      header: "Tillval"
    },
    header: "Information",
    price: {
      header: "Pris"
    },
    minimumOrderAmount: {
      description_plural: "Minsta beställning: {{count}} st"
    },
    stockStatus: {
      header: "Lagerstatus",
      inStock: "(i lager)",
      stockItem: {
        backInStock: "Beräknas inkomma {{date}}",
      },
      orderItem: {
        estimatedDeliveryTime: "Beställningsvara, {{text}}",
      },
      noRepurchase: {
        left: "ca {{count}} kvar"
      }
    }
  },
  form: {
    header: "Beställ {{productName}}",
    alternatives: {
      header: "Alternativ"
    },
    options: {
      default: {
        header: "Tillval"
      },
      secondary: {
        header: "Fler Tillval"
      }
    },
    quantity: {
      label: "Antal",
    },
    unitPrice: {
      label: "Pris/st"
    }
  },
  addToCart: "Lägg till i beställning",
  articleNumber: "Artikelnummer",
  weight: "Vikt",
  validationError: {
    option: {
      isRequired: "{{name}} är obligatorisk."
    }
  },
  options: {
    selections: {
      none: "Inget"
    },
    selectionFilter: {
      noSelected: "Välj",
      noSelected_optionGroup: "Välj typ",
      noSelected_activity: "Välj sport",
      selectedValue: {
        label: "Val",
        label_optionGroup: "Typ",
        label_activity: "Sport",
      }
    }
  },
  productFields: {
    filters: {
      header: "Filter",
      resetButton: {
        label: "Nollställ"
      }
    },
    localSelections: {
      creationForm: localSelectionsCreationForm,
    },
    nav: {
      standard: "Standard",
      customer: "Egna",
      upload: "Ladda upp"
    },
    textField
  },
  attributePackageWizard: {

    prevButton: {
      label: "Föregående"
    },

    nextButton: {
      label: "Nästa"
    },

    submitButton: {
      label: "Lägg till"
    },

    modalButton: {
      label: "Gör paketbeställning"
    },

    preselectNumOfRows: {
      header: {
        pre: "Paketbeställning",
        heading: "Varianter",
        post: "Här bestämmer du hur många varianter"
      },
      input: {
        label: "Antal"
      }
    },

    attributeValueQuantities: {
      header: {
        pre: "Paketbeställning",
        heading: "Attribut",
        post: ""
      },
      bundle: {
        positions: {
          1: "1:a",
          2: "2:a",
          3: "3:a",
          4: "4:a",
          5: "5:a",
          6: "6:a",
          7: "7:a",
          8: "8:a",
          9: "9:a",
          10: "10:a",
          11: "11:a",
          12: "12:a",
        }
      }
    },

    attributeValueQuantitiesSimple: {
      header: {
        pre: "Paketbeställning",
        heading: "Vårt förslag",
        post: ""
      },
      everyQuantity: {
        input: {
          label: "Antal paket"
        }
      },
      numOfRows: {
        input: {
          label: "Antal varianter"
        }
      },
      sum: {
        label: "Summa exkl tillval"
      },
      changeToTableViewButton: {
        label: "Ändra"
      }
    },

    productFields: {
      header: {
        pre: "Paketbeställning",
        heading: "Inställningar",
        post: ""
      },
      product: {
        productFields: {
          textField
        }
      }
    },
    summary: {
      header: {
        pre: "Paketbeställning",
        heading: "Sammanställning",
        post: ""
      },
      sum: {
        label: "Summa"
      }
    }
  },

  fromPrice: "Från {{ formattedPriceAndCurrency }}",
}
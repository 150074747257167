import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Trans } from 'react-i18next'

import {
  signinWithToken,
  signinFailed,
  createUserBump
} from 'actions'
import {
  userIsSignedInAndLoaded
} from 'selectors/user'

import {
  createUser,
  sendCode,
  getToken
} from 'api'

import { caseSelector } from 'utils/helpers'
import { patterns } from 'utils/values'

import { Link } from 'react-router-dom'
import routes from 'routes'

import { PrimaryButton, ButtonContainer } from 'components/buttons'

import { SingleInputSimplifiedForm } from 'components/forms/SimplifiedForm'

import TestEmailForm from 'containers/steps/TestEmailForm'
import TestCustomerCodeForm from 'containers/steps/TestCustomerCodeForm'

import GetToken from 'containers/steps/GetToken'
import ForgotPassword from 'containers/steps/ForgotPassword'

import stepStateComposer, { STATES } from 'containers/hoc/stepStateComposer'

import { Translate } from 'containers/translates'

const STEPS = {
  START: 'start',
  SIGNIN: {
    PASSWORD: 'signinPassword',
    COMPLETE: 'signinComplete',
  },
  FORGOT_PASSWORD: 'forgotPassword',
  SIGNUP: {
    VALIDATE_CUSTOMER_ACCESS_CODE: 'signupValidateCustomerAccessCode',
    NAME: 'signupName',
    PASSWORD: 'signupPassword',
    RETYPE_PASSWORD: 'signupRetypePassword',
    COMPLETE: 'signupComplete',
  }
}

const SigninOrRegisterWrapper = connect(state => ({
  user: state.user,
  signinFailed: state.auth.error !== null,
  userIsSignedInAndLoaded: userIsSignedInAndLoaded(state)
}), {
    signinWithToken,
    signinFailed,
    createUserBump
  })(stepStateComposer(class _SigninOrRegisterWrapper extends React.Component {
    static propTypes = {
      setStep: PropTypes.func.isRequired,
      setStepState: PropTypes.func.isRequired,
      signinWithToken: PropTypes.func.isRequired,
      signinFailed: PropTypes.func.isRequired,
      createUserBump: PropTypes.func.isRequired,
      step: PropTypes.any,
      stepState: PropTypes.any,
      userIsSignedInAndLoaded: PropTypes.bool.isRequired,
      includeCompleteStep: PropTypes.bool,
      user: PropTypes.object,
      onDone: PropTypes.func,
      customerIds: PropTypes.array,
    }

    constructor(props) {
      super(props)

      this.state = {
        email: null,
        name: null,
        nameSuggestion: null,
        password: null,
        userId: null,
        pendingCustomerIds: [],
        approvedCustomerIds: []
      }

      this.onExistingUser = this.onExistingUser.bind(this)
      this.onExistingCustomerForNewUser = this.onExistingCustomerForNewUser.bind(this)
      this.onNewUser = this.onNewUser.bind(this)

      this.onSentResetPassword = this.onSentResetPassword.bind(this)

      this.onSignupSubmitName = this.onSignupSubmitName.bind(this)
      this.onSignupSubmitPassword = this.onSignupSubmitPassword.bind(this)
      this.onSignupSubmitRetypePassword = this.onSignupSubmitRetypePassword.bind(this)

      this.onCustomerCodeSuccess = this.onCustomerCodeSuccess.bind(this)
    }

    onExistingUser({ email, userId }) {
      this.setState(
        () => ({ email, userId }),
        () => this.props.setStep(STEPS.SIGNIN.PASSWORD, null)
      )
    }

    onExistingCustomerForNewUser({ email, customerIds, nameSuggestion }) {
      sendCode(email, customerIds)
        .then(() => {
          this.setState(
            () => ({
              email,
              pendingCustomerIds: customerIds,
              nameSuggestion, // TODO: A better idea would be to fetch this separatly
            }),
            () => {
              this.props.setStep(STEPS.SIGNUP.VALIDATE_CUSTOMER_ACCESS_CODE, null)
            }
          )
        })
    }

    onNewUser({ email }) {
      this.setState(
        () => ({ email }),
        () => this.props.setStep(STEPS.SIGNUP.NAME, null)
      )
    }

    onCustomerCodeSuccess(customerIds) {
      this.setState(
        () => ({ approvedCustomerIds: customerIds }),
        () => this.props.setStep(STEPS.SIGNUP.NAME, null)
      )
    }

    onSentResetPassword() {
      this.props.setStep(STEPS.FORGOT_PASSWORD, STATES.SYNCED)
    }

    onSignupSubmitName({ name }) {
      this.setState(
        () => ({ name }),
        () => this.props.setStep(STEPS.SIGNUP.PASSWORD, null)
      )
    }

    onSignupSubmitPassword({ password }) {
      this.setState(
        () => ({ password }),
        () => this.props.setStep(STEPS.SIGNUP.RETYPE_PASSWORD, null)
      )
    }

    onSignupSubmitRetypePassword() {
      const { name, email, password, approvedCustomerIds } = this.state
      const { customerIds: customerIdsFromProps = [] } = this.props

      const [
        mainCustomerId = false,
        ...otherCustomerIds
      ] = approvedCustomerIds

      const allCustomerIds = [
        mainCustomerId,
        ...otherCustomerIds,
        ...customerIdsFromProps
      ]

      this.props.setStepState(STATES.SYNCING)
        .then(() => {
          createUser({
            name, email, password,
            customerIds: allCustomerIds,
          })
            .then(() => {
              this.props.createUserBump()
            })
            .then(() => getToken(email, password))
            .then(({ token }) => this.props.signinWithToken(token))
            .catch(resp => {
              console.error("error creating user", resp)
            })
        })
    }

    componentDidMount() {
      this.props.setStep(STEPS.START, null)
    }

    componentDidUpdate() {

      const {
        step, stepState,
        userIsSignedInAndLoaded,
        includeCompleteStep = false
      } = this.props

      if (userIsSignedInAndLoaded) {
        if (includeCompleteStep) {
          switch (step) {
            case STEPS.SIGNUP.RETYPE_PASSWORD:
              this.props.setStep(STEPS.SIGNUP.COMPLETE)
              break
            case STEPS.SIGNIN.PASSWORD:
              this.props.setStep(STEPS.SIGNIN.COMPLETE)
              break
          }
        }
        else {
          this.props.onDone()
        }
      }

      if (stepState !== STATES.FAILED) {
        const signinFailed = this.props.signinFailed

        if (signinFailed) {
          switch (step) {
            case STEPS.SIGNIN.PASSWORD:
              this.props.setStepState(STATES.FAILED)
              break
          }
        }
      }

    }

    render() {
      const { step, stepState, user } = this.props
      const { nameSuggestion } = this.state

      const primaryCase = `${step}.${stepState}`
      const fallbackCase = step

      // const stepHasFailed = stepState === STATES.FAILED
      const stepIsSyncing = stepState === STATES.SYNCING

      return (
        <Translate ns="signinOrRegister">
          {({ t, composeI18Key }) => (
            caseSelector({
              [STEPS.START]: () => (
                <React.Fragment>
                  <header>
                    <small>{t('steps.start.header.pre')}</small>
                    <h2>{t('steps.start.header.heading')}</h2>
                    <p>
                      {t('steps.start.header.post')} <br />
                      <Trans i18nKey={composeI18Key('steps.start.header.byApprovingUserConditions')}>
                        <Link to={routes.BLOCK_PAGE.get('user-conditions')} onClick={this.props.onDone}>
                          {"User conditions"}
                        </Link>
                      </Trans>
                    </p>
                  </header>
                  <TestEmailForm
                    t={t}
                    onFoundUser={this.onExistingUser}
                    onFoundCustomer={this.onExistingCustomerForNewUser}
                    onNotFound={this.onNewUser}
                  />
                </React.Fragment>
              ),
              [STEPS.SIGNIN.PASSWORD]: () => (
                <React.Fragment>
                  <header>
                    {/* <small>{t('steps.signin.header.pre')}</small> */}
                    <h2>{t('steps.signin.header.heading')}</h2>
                    <p>{t('steps.signin.header.post')}</p>
                  </header>
                  <hr />
                  <GetToken
                    t={t}
                    email={this.state.email}
                    onSuccess={this.props.signinWithToken}
                    onFailure={this.props.signinFailed}
                  />
                  <hr />
                  <ForgotPassword
                    t={t}
                    email={this.state.email}
                    onSuccess={stuff => {
                      this.onSentResetPassword(stuff)
                    }}
                  />
                </React.Fragment>
              ),
              [STEPS.SIGNUP.VALIDATE_CUSTOMER_ACCESS_CODE]: () => (
                <React.Fragment>
                  <header>
                    {/* <small>{t('steps.start.header.pre')}</small> */}
                    <h2>{t('steps.validateCustomerAccessCode.header.heading')}</h2>
                    <p>{t('steps.validateCustomerAccessCode.header.post', { email: this.state.email })}</p>
                  </header>
                  <TestCustomerCodeForm
                    t={t}
                    customerIds={this.state.pendingCustomerIds}
                    onSuccess={this.onCustomerCodeSuccess}
                  />
                </React.Fragment>
              ),
              [STEPS.SIGNUP.NAME]: () => (
                <React.Fragment>
                  <header>
                    {/* <small>{t('steps.start.header.pre')}</small> */}
                    <h2>{t('steps.signup.name.header.heading')}</h2>
                    <p>{t('steps.signup.name.header.post')}</p>
                  </header>
                  <SingleInputSimplifiedForm
                    t={t}
                    key={STEPS.SIGNUP.NAME} inputName={"name"} onSubmit={this.onSignupSubmitName}
                    prefilledInputValues={nameSuggestion ? {
                      name: nameSuggestion
                    } : {}}
                    valueRules={[{ key: "name", required: true, ...patterns.name }]}
                  />
                </React.Fragment>

              ),
              [STEPS.SIGNUP.PASSWORD]: () => (
                <React.Fragment>
                  <header>
                    {/* <small>{t('steps.start.header.pre')}</small> */}
                    <h2>{t('steps.signup.password.header.heading')}</h2>
                    <p>{t('steps.signup.password.header.post')}</p>
                  </header>
                  <SingleInputSimplifiedForm
                    t={t}
                    key={STEPS.SIGNUP.PASSWORD}
                    inputType="password" inputName="password"
                    valueRules={[{ key: "password", required: true, ...patterns.password }]}
                    onSubmit={this.onSignupSubmitPassword}
                  />
                </React.Fragment>

              ),
              [STEPS.SIGNUP.RETYPE_PASSWORD]: () => (
                <React.Fragment>
                  <header>
                    {/* <small>{t('steps.start.header.pre')}</small> */}
                    <h2>{t('steps.signup.retypePassword.header.heading')}</h2>
                    <p>{t('steps.signup.retypePassword.header.post')}</p>
                  </header>
                  <SingleInputSimplifiedForm
                    t={t}
                    key={STEPS.SIGNUP.RETYPE_PASSWORD}
                    inputType="password" inputName="retypePassword"
                    onSubmit={this.onSignupSubmitRetypePassword}
                    showLoadingIndicator={stepIsSyncing}
                    valueRules={[{
                      key: "retypePassword",
                      required: true,
                      ...patterns.retypePassword,
                      method: (value) => {
                        const retypeMatches = value === this.state.password
                        return [
                          retypeMatches,
                          "noMatch"
                        ]
                      }
                    }]}
                    inputProps={{
                      disabled: stepIsSyncing
                    }}
                  />
                </React.Fragment>
              ),
              [STEPS.FORGOT_PASSWORD]: () => (
                (
                  <React.Fragment>
                    <header>
                      {/* <small>{t('steps.start.header.pre')}</small> */}
                      <h2>{t('steps.signin.forgotPassword.header.heading')}</h2>
                      <p>{t('steps.signin.forgotPassword.header.post')}</p>
                    </header>
                    <hr />
                    <div>
                      <button className="btn btn-dark" onClick={this.props.onDone}>
                        {t('steps.signin.forgotPassword.closeButton.label')}
                      </button>
                    </div>
                  </React.Fragment>
                )
              ),
              [STEPS.SIGNUP.COMPLETE]: () => (
                <React.Fragment>
                  <header>
                    {/* <small>{t('steps.start.header.pre')}</small> */}
                    <h2>{t('steps.signup.complete.header.heading', { userName: user.name })}</h2>
                    <p>{t('steps.signup.complete.header.post')}</p>
                  </header>
                  <hr />
                  <ButtonContainer>
                    <PrimaryButton onClick={this.props.onDone}>
                      {t('steps.signup.complete.goShopping.button.label')}
                    </PrimaryButton>
                    <Link to={routes.USER.ACCOUNT.get()} onClick={this.props.onDone} className="btn btn-dark">
                      {t('steps.signup.complete.userPage.button.label')}
                    </Link>
                  </ButtonContainer>
                </React.Fragment>
              ),
              [STEPS.SIGNIN.COMPLETE]: () => (
                <React.Fragment>
                  <header>
                    {/* <small>{t('steps.start.header.pre')}</small> */}
                    <h2>{t('steps.signin.complete.header.heading', { userName: user.name })}</h2>
                    <p>{t('steps.signin.complete.header.post')}</p>
                  </header>
                  <hr />
                  <ButtonContainer>
                    <PrimaryButton onClick={this.props.onDone}>
                      {t('steps.signin.complete.goShopping.button.label')}
                    </PrimaryButton>


                    <Link to={routes.USER.CUSTOMER_ORDERS.get()} onClick={this.props.onDone} className="btn btn-dark">
                      {t('steps.signin.complete.customerOrdersPage.button.label')}
                    </Link>

                    <Link to={routes.USER.ACCOUNT.get()} onClick={this.props.onDone} className="btn btn-dark">
                      {t('steps.signin.complete.userPage.button.label')}
                    </Link>
                  </ButtonContainer>
                </React.Fragment>
              ),
            }, () => <p>Not implemented: {step}(.{stepState})</p>)(
              primaryCase,
              fallbackCase
            )()
          )}

        </Translate>
      )
    }
  }))

export default SigninOrRegisterWrapper
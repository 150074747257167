import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import find from 'lodash/find';
import {
  setSubmitState
} from 'actions/order'

import { formatPrice } from '../../utils/content';

import BlockArea from '../../containers/BlockArea';
import { Translate } from 'containers/translates'

import LoadingIndicator from '../spinners';
import { BareModal, ModalBody, SMALL } from 'components/modals/Modal'
import { PrimaryButton } from 'components/buttons';

import swishSvgSrc from './media/swish_logo_primary_RGB.svg'
import { CheckIcon } from 'components/Icon';

function SummaryItem({ label, price, displayPlus = false }) {
  return (
    <tr>
      <td>{label}</td>
      <td className="text-right">{displayPlus && price > 0 && "+"}{formatPrice(price, 'kr')}</td>
    </tr>
  )
}

SummaryItem.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  displayPlus: PropTypes.bool,
}

const InnerSummarySection = connect(state => ({
  order: state.order,
  orderSubmitState: state.orderSubmitState,
}), { setSubmitState })(class InnerSummarySection extends React.Component {

  static propTypes = {
    order: PropTypes.object.isRequired,
    orderSubmitState: PropTypes.string,
    setSubmitState: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    sectionState: PropTypes.string,
  }

  getRowsSum() {
    return this.props.order.rows.reduce((accum, { quantity, unitPrice }) => accum + (quantity * unitPrice), 0);
  }

  getDeliveryMethodSum() {
    const deliveryMethod = find(this.props.order.availableDeliveryMethods, dm => dm.id === this.props.order.deliveryMethodId);
    return deliveryMethod && deliveryMethod.price || 0;
  }

  getPaymentMethodSum() {
    const paymentMethod = find(this.props.order.availablePaymentMethods, (paymentMethod) => paymentMethod.id === this.props.order.paymentMethodId)
    return paymentMethod && paymentMethod.price || 0;
  }

  getSum() {
    const { order } = this.props;
    const productAndFeeRowsSum = order.amount.total;
    const deliveryMethodCost = this.getDeliveryMethodSum();
    const paymentMethodCost = this.getPaymentMethodSum();

    return productAndFeeRowsSum + deliveryMethodCost + paymentMethodCost;
  }

  closeStateModal = () => {
    this.props.setSubmitState(null)
  }

  renderNoSubmitState = () => (
    <React.Fragment>
      <LoadingIndicator />
    </React.Fragment>
  )

  renderStartedState = () => (
    <React.Fragment>
      <LoadingIndicator />
    </React.Fragment>
  )

  renderStartedLocalFilesUpload = () => (
    <React.Fragment>
      <LoadingIndicator />
      <h2 className="text-center">{this.props.t('startedLocalFilesUpload.header')}</h2>
    </React.Fragment>
  )

  renderStartedOrderCreate = () => (
    <React.Fragment>
      <LoadingIndicator />
      <h2 className="text-center">{this.props.t('startedOrderCreate.header')}</h2>
    </React.Fragment>
  )

  renderStartedPaymentState = () => {
    if (this.props.order.paymentMethodId === 'SWISH') {
      return (
        <React.Fragment>
          <div className="text-center mb-4">
            <img
              src={swishSvgSrc}
              style={{
                width: 120,
                height: "auto"
              }}
            />
          </div>
          <h2>{this.props.t('startedSwishPayment.header')}</h2>
          <ol style={{
            paddingLeft: 0,
            listStylePosition: "inside"
          }}>
            <li>{this.props.t('startedSwishPayment.descriptionRowOne')}</li>
            <li>{this.props.t('startedSwishPayment.descriptionRowTwo')}</li>
            <li>{this.props.t('startedSwishPayment.descriptionRowThree')}</li>
          </ol>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          <LoadingIndicator />
        </React.Fragment>
      )
    }
  }

  renderPaymentErrorState = () => (
    <React.Fragment>
      <h2>{this.props.t('paymentErrorState.header')}</h2>
      <p>{this.props.t('paymentErrorState.description')}</p>
      <PrimaryButton onClick={this.closeStateModal}>{this.props.t('paymentErrorState.closeButtonLabel')}</PrimaryButton>
    </React.Fragment>
  )

  render() {

    const { order, orderSubmitState, sectionState, t } = this.props;

    const orderIsSubmitting = orderSubmitState !== null
    const orderHasBeenSubmitted = orderSubmitState === 'SUBMITTED';

    const buttonCssClasses = (
      sectionState === 'disabled' ?
        "btn-xl btn-default" :
        `btn-xl mt-3 ${orderHasBeenSubmitted ? "btn-success" : "btn-secondary"}`
    );

    const orderIsSyncing = (
      order.isSyncing ||
      order.localFilesIsBeeingUploaded
    )

    return (
      <React.Fragment>

        <div style={{
          position: 'relative'
        }}>
          <div style={{
            opacity: orderIsSyncing ? 0.3 : 1
          }}>
            <div className="mb-4 px-3 text-center">
              {
                sectionState !== 'disabled' &&
                <div style={{
                  maxWidth: '400px',
                  margin: '0 auto',
                }}>

                  <table className="table --border-color-lth-grey text-left">
                    <thead>
                      <tr>
                        <th>Beskrivning</th>
                        <th className="text-right">Kostnad</th>
                      </tr>
                    </thead>
                    <tbody>
                      <SummaryItem key="products" label={t('items.products.label')} price={this.getRowsSum()} />
                      {[
                        ...order.discountRows,
                        ...order.feeRows
                      ].map(row => (
                        <SummaryItem key={`special-row-${row.id}`} label={row.productName} price={row.totalPrice} displayPlus />
                      ))}
                      <SummaryItem key="deliveryMethod" label={t('items.deliveryMethod.label')} price={this.getDeliveryMethodSum()} displayPlus />
                      <SummaryItem key="paymentMethod" label={t('items.paymentMethod.label')} price={this.getPaymentMethodSum()} displayPlus />
                    </tbody>
                  </table>

                  <hr />
                  <div className="h2">
                    {t('sum.label')}: {formatPrice(this.getSum(), 'kr')}
                  </div>
                  <BlockArea areaSlug="checkout-send" conditions={[]} />
                </div>
              }
              <button
                className={`btn ${buttonCssClasses}`}
                disabled={sectionState === 'disabled' || orderIsSubmitting}
                onClick={this.props.onSubmit}
              >
                <span>
                  {
                    orderIsSubmitting &&
                    <i className="fa fa-circle-o-notch fa-spin fa-fw" />
                  } {t('button.label', { context: order.sendState })}
                </span>
              </button>
            </div>
          </div>
          {
            orderIsSyncing &&
            <div className="d-flex align-items-center" style={{
              position: 'absolute',
              left: '0px',
              top: '0px',
              width: '100%',
              height: '100%'
            }}>
              <LoadingIndicator />
            </div>
          }
        </div>
        <BareModal
          isOpen={orderSubmitState !== null}
        >
          <ModalBody size={SMALL}>
            {({
              'STARTED': this.renderStartedState,
              'STARTED_LOCAL_FILES_UPLOAD': this.renderStartedLocalFilesUpload,
              'STARTED_ORDER_CREATE': this.renderStartedOrderCreate,
              'STARTED_PAYMENT': this.renderStartedPaymentState,
              'PAYMENT_DECLINED': this.renderPaymentErrorState,
              'PAYMENT_ERROR': this.renderPaymentErrorState,
            }[orderSubmitState] || this.renderNoSubmitState)()}
          </ModalBody>
        </BareModal>
      </React.Fragment>
    );
  }

});

export default function SummarySection(props) {
  return (
    <Translate ns="summary">
      {({ t }) => <InnerSummarySection t={t} {...props} />}
    </Translate>
  )
}
import React from 'react';
import { fetchProductPropertyValueProductCounts } from 'api'
import FilterDropdown from './FilterDropdown';

const ProductPropertyFilterDropdown = ({
  productProperty,
  selectedValueIds,
  toggleProductPropertyValueIdIsSelected,
  productPropertyProductFiltersFactory,
}) => {

  const fetchOptions = React.useCallback(() => {
    return fetchProductPropertyValueProductCounts([productProperty.id], productPropertyProductFiltersFactory(productProperty))
      .then((res) => res.items.map((propertyValue) => ({
        value: propertyValue.id,
        label: propertyValue.value,
        count: propertyValue.productCount,
      })))
  }, [productProperty, productPropertyProductFiltersFactory])

  return (
    <FilterDropdown
      label={productProperty.name}
      fetchOptions={fetchOptions}
      toggleValueSelection={toggleProductPropertyValueIdIsSelected}
      selectedValues={selectedValueIds}
    />
  );
}

export default ProductPropertyFilterDropdown
import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';

import { 
  getProductCategoryFlatTree
} from '../selectors'; 

import { Breadcrumb, BreadcrumbItem } from './Breadcrumb';
import routes from '../routes';

export const ProductCategoryBreadcrumb = translate()(({categoryTree, children, t}) => (
  <Breadcrumb>
    <BreadcrumbItem to={routes.HOME.get()}>{t('home:name')}</BreadcrumbItem>
    {categoryTree.map((category, i) => (
      <BreadcrumbItem key={category.id} to={routes.PRODUCT_CATEGORY.get(categoryTree.slice(0, i + 1))}>{category.name}</BreadcrumbItem>
    ))}
    {children}
  </Breadcrumb>
));

export const ProductDetailBreadcrumb = connect((state, {categoryId}) => ({
  categoryTree: getProductCategoryFlatTree(state, categoryId)
}))(({productName, categoryTree = []}) => (
  <ProductCategoryBreadcrumb categoryTree={categoryTree}/>
));
import find from 'lodash/find'
import { dataURItoBlob } from 'utils/file'

import { getLocalFile } from './userSettings'

export function getUserFieldSelections(state) {
  return [
    ...state.fieldSelections.filter(
      (fieldSelection) => (
        fieldSelection.permissions.filter(({type}) => type === 'customers').length > 0
      )
    )
  ]
}

export function getFieldSelection(state, selectionId) {
  return find(
    state.fieldSelections,
    (x) => x.id === selectionId
  )
}

export function getFieldSelectionImageUrl(state, { selectionId, value }) {
  const selection = getFieldSelection(state, selectionId)

  if (selection) {
    if (selection.isTemplate && value) {
      const localFile = getLocalFile(state, value)

      if (localFile) {
        const fileBlob = dataURItoBlob(localFile.dataUrl)
        const imageFileTypes = [
          'image/jpeg',
          'image/png',
        ]

        if (imageFileTypes.indexOf(fileBlob.type) !== -1) {
          return localFile.dataUrl
        }
      }

    }

    if (selection.image) {
      return selection.image.processedUrls.default
    }
  }


  return null
}

export function getFieldSelectionDisplay(state, { selectionId, value }) {
  const selection = getFieldSelection(state, selectionId)

  if (selection) {
    if (selection.isTemplate && value) {
      const localFile = getLocalFile(state, value)
      return localFile && localFile.name
    }
    else if (selection.identifier) {
      return `${selection.identifier} - ${selection.name}`
    }
    else {
      return selection.name
    }
  }

  return null
}
import React from 'react'
import PropTypes from 'prop-types'

import Modal, { ModalBody, LARGE } from 'components/modals/Modal'

import { Event } from './props'
import EventForm from './EventForm'

export default class EventModal extends React.Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
    event: PropTypes.shape(
      Event.propTypes
    )
  }

  handleComplete = () => {
    this.props.onComplete()
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <ModalBody size={LARGE}>
          <React.Fragment>
            <EventForm
              customerId={this.props.customerId}
              event={this.props.event}
              onComplete={this.handleComplete}
            />
          </React.Fragment>
        </ModalBody>
      </Modal>
    )
  }
}

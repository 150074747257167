import { BLOCKS } from '../actions';
import { combineReducers } from 'redux';

const requestsInitState = [];

const requests = (state = requestsInitState, action) => {
  switch(action.type) {
    // case BLOCKS.FETCH.RECEIVE:
    //   return [...action.items];
    default:
      return state;
  }
}

const items = (state = [], action) => {
  switch(action.type) {
    case BLOCKS.FETCH.RECEIVE:
      return [...action.items];
    default:
      return state;
  }
}

export default combineReducers({
  requests,
  items
});
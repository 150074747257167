import React from 'react';
import { connect } from 'react-redux';

import {
  sendPasswordReset
} from 'api';

import { DarkButton } from 'components/buttons';

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    sendPasswordReset(this.props.email)
      .then((...args) => {
        this.props.onSuccess(args);
      })
      .catch(stuff => {
        this.props.onFailure(args);
      })
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <DarkButton onClick={this.onSubmit}>
          {t('forgotPassword.button.label')}
        </DarkButton>
      </div>
    )
  }
}



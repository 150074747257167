import templateCompiler from 'lodash/template'
import toPairs from 'lodash/toPairs'
import times from 'lodash/times'
import find from 'lodash/find'

import { toDate } from 'utils/date'
import { caseSelector } from 'utils/helpers'
import { makeCoolSelection } from 'utils/misc'

import { createOrderRow } from './order'
import { createProductFieldInput, productFieldInputIsEmpty } from './productFields'

export const CONTEST_TYPES = {
  TEAM: 'team',
  INDIVIDUAL: 'individual'
}

export const PRICE_TYPES = {
  ROWS: 'rows',
  COPIES: 'copies',
  ROWS_AND_COPIES: 'rowsAndCopies',
  CUSTOM: 'custom',
}

export const PRICE_TYPES_IN_ORDER = [
  PRICE_TYPES.ROWS,
  PRICE_TYPES.ROWS_AND_COPIES,
  PRICE_TYPES.COPIES,
]

const numOfCopiesNotSetupValue = null

export function createRows(numOfRows, numOfCopies) {
  return times(numOfRows, () => ({
    numOfCopies
  }))
}

export const DEFAULT_CONTEST_PRICE_TYPES_ROWS = {
  [CONTEST_TYPES.TEAM]: {
    [PRICE_TYPES.ROWS]: [], // createRows(defaultNumOfRows, numOfCopiesSingleCopyValues),
    [PRICE_TYPES.ROWS_AND_COPIES]: [], // createRows(defaultNumOfRows, numOfCopiesNotSetupValue),
    [PRICE_TYPES.COPIES]: createRows(1, numOfCopiesNotSetupValue),
  },
  [CONTEST_TYPES.INDIVIDUAL]: {
    [PRICE_TYPES.ROWS]: [], // createRows(defaultNumOfRows, numOfCopiesSingleCopyValues),
    [PRICE_TYPES.COPIES]: createRows(1, numOfCopiesNotSetupValue)
  }
}

export function createEmptyContest(contestType, id) {
  return {
    type: contestType,
    name: "",
    id,
    prices: caseSelector({
      [CONTEST_TYPES.TEAM]: [
        {
          type: PRICE_TYPES.ROWS,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.TEAM][PRICE_TYPES.ROWS]
        },
        {
          type: PRICE_TYPES.ROWS_AND_COPIES,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.TEAM][PRICE_TYPES.ROWS_AND_COPIES]
        },
        {
          type: PRICE_TYPES.COPIES,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.TEAM][PRICE_TYPES.COPIES]
        },
      ],
      [CONTEST_TYPES.INDIVIDUAL]: [
        {
          type: PRICE_TYPES.ROWS,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.INDIVIDUAL][PRICE_TYPES.ROWS]
        },
        {
          type: PRICE_TYPES.COPIES,
          isSelected: false,
          rows: DEFAULT_CONTEST_PRICE_TYPES_ROWS[CONTEST_TYPES.INDIVIDUAL][PRICE_TYPES.COPIES]
        },
      ]
    })(contestType)
  }
}

export function createEmptyEvent() {
  return {
    id: null,
    name: '',
    date: null,
    activityId: null,
    contests: [],
  }
}

export function duplicateContest(contest, defaultProps) {

  const { name, type, prices } = contest

  return {
    ...defaultProps,
    name, type, prices
  }
}

export function contestPriceRowIsValid(row) {
  return row.numOfCopies !== null
}

export function contestPriceRowsIsValid(rows) {
  const numOfRows = rows.length
  const numOfValidRows = rows.filter(contestPriceRowIsValid).length
  return numOfRows > 0 && numOfRows === numOfValidRows
}


export function exportEvent(event) {
  const {
    name, date, activityId,
    contests
  } = event

  const contestsWithOnlySelectedPrices = contests.map((contest) => ({
    ...contest,
    prices: contest.prices.filter((price) => price.isSelected)
  }))

  return {
    name, date, activityId,
    contests: contestsWithOnlySelectedPrices
  }
}

export function importEvent(event) {
  const {
    id,
    name, date, activityId,
    contests: contestsWithOnlySelectedPrices
  } = event

  const allContests = contestsWithOnlySelectedPrices.map((contestWithOnlySelectedPrices) => ({
    ...contestWithOnlySelectedPrices,
    prices: createEmptyContest(contestWithOnlySelectedPrices.type, contestWithOnlySelectedPrices.id).prices.map(
      (emptyPrice) => {
        const selectedPrice = find(
          contestWithOnlySelectedPrices.prices,
          (x) => x.type === emptyPrice.type
        )

        return (
          selectedPrice !== undefined ?
            {
              ...selectedPrice,
              isSelected: true
            } :
            emptyPrice
        )
      }
    )
  }))

  const importedEvent = {
    id,
    name, date, activityId,
    contests: allContests
  }

  return importedEvent
}


export function createContestWithNameAndSelectedPriceTypes(contestType, id, name, priceTypeAndNumOfCopiesBundles) {
  const emptyContest = createEmptyContest(contestType, id)

  const contest = {
    ...emptyContest,
    name,
    prices: emptyContest.prices.map(
      (price) => (
        priceTypeAndNumOfCopiesBundles[price.type] ?
          ({
            ...price,
            isSelected: true,
            rows: times(priceTypeAndNumOfCopiesBundles[price.type][0], () => ({
              numOfCopies: priceTypeAndNumOfCopiesBundles[price.type][1]
            }))
          }) :
          price
      )
    )
  }
  return contest
}

export function createDummyEvent({
  name = "Testevent",
  activityId = 149,
  date = new Date()
} = {}) {
  return {
    ...createEmptyEvent(),
    name, date, activityId,
    contests: [
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.TEAM, 1, "P10", {
        [PRICE_TYPES.COPIES]: [1, 500],
      }),
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.TEAM, 2, "F10", {
        [PRICE_TYPES.COPIES]: [1, 200],
      }),
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.TEAM, 3, "P12", {
        [PRICE_TYPES.ROWS]: [3, 1],
        [PRICE_TYPES.ROWS_AND_COPIES]: [3, 20],
      }),
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.TEAM, 4, "F12", {
        [PRICE_TYPES.ROWS]: [3, 1],
        [PRICE_TYPES.ROWS_AND_COPIES]: [3, 20],
      }),
      createContestWithNameAndSelectedPriceTypes(CONTEST_TYPES.INDIVIDUAL, 5, "Trixtävling", {
        [PRICE_TYPES.ROWS]: [2, 1],
      })
    ],
  }
}


export function constructEventContestPriceBundles(contests) {
  return (
    contests.reduce((accum, contest) => ([
      ...accum,
      ...(
        () =>
          contest.prices
            .filter((x) => x.isSelected)
            .map((price) => ({
              ...price,
              priceType: price.type,
              contestType: contest.type,
              contestId: contest.id,
              contestName: contest.name
            }))
      )()
    ]), [])
  )
}


function eventContestPositionToTextTemplateContext(event, eventContestName, eventContestPositionIndex) {
  const eventName = event.name
  const eventYear = toDate(event.date).getFullYear()

  const eventContestPosition = [
    "1:a",
    "2:a",
    "3:a",
    "4:a",
    "5:a",
    "6:a",
    "7:a",
    "8:a",
    "9:a",
    "10:a",
    "11:a",
    "12:a",
    "13:a",
    "14:a",
    "15:a",
  ][eventContestPositionIndex]

  return {
    eventName,
    eventYear,
    eventContestName,
    eventContestPosition
  }
}

function constructRenderedTextTemplateProductFieldInput(textTemplate, variableInputs) {

  const value = templateCompiler(textTemplate.template)(variableInputs)

  return {
    value,
    meta: {
      templateId: textTemplate.id,
      variableInputs
    }
  }
}

export function createOrderRowsOfEvent(event, priceTypeSelections, nextOrderRowId) {
  let localId_ = nextOrderRowId
  function nextId() {
    return ++localId_
  }

  const rowBundles = event.contests.reduce((rowsAccum, contest) => [
    ...rowsAccum,
    ...contest.prices
      .filter((price) => price.isSelected)
      .reduce((subRowsAccum, price) => [
        ...subRowsAccum,
        ...price.rows.map((row, rowIndex) => {
          const tempOrderRowId = nextId()

          const { productFieldInputs, productArticleIds } = priceTypeSelections[price.type]
          const articleIndex = makeCoolSelection(
            productArticleIds.length,
            price.rows.length,
            rowIndex
          )
          const articleId = productArticleIds[articleIndex]
          const quantity = row.numOfCopies || 1

          const filteredRenderedProductFieldInputs = toPairs(productFieldInputs)
            .map(
              ([productFieldId, productFieldInputValues]) => {
                return createProductFieldInput(
                  productFieldId,
                  productFieldInputValues.textTemplate ?
                    constructRenderedTextTemplateProductFieldInput(
                      productFieldInputValues.textTemplate,
                      eventContestPositionToTextTemplateContext(
                        event,
                        contest.name,
                        rowIndex
                      )
                    ) : productFieldInputValues
                )
              })
            .filter((productFieldInput) => !productFieldInputIsEmpty(productFieldInput))


          return {
            id: tempOrderRowId,
            articleId,
            quantity,
            productFieldInputs: filteredRenderedProductFieldInputs
          }
        })
      ], [])
  ], [])

  console.log("-rowBundles-", rowBundles)

  // const rowBundles = productBundles.reduce(
  //   (rowsAccum, { eventContestPriceTypeBundles, productFieldInputs, productArticleIds }) => ([
  //     ...rowsAccum,
  //     ...(
  //       eventContestPriceTypeBundles.reduce(
  //         (subRowsAccum, eventContestPriceBundle) => ([
  //           ...subRowsAccum,
  //           ...(
  //             eventContestPriceBundle.rows.map((row, rowIndex) => {

  //               const tempOrderRowId = nextId()

  //               const articleId = productArticleIds[rowIndex]
  //               const quantity = row.numOfCopies

  //               return {
  //                 id: tempOrderRowId,
  //                 articleId,
  //                 quantity,
  //                 productFieldInputs: toPairs(productFieldInputs)
  //                   .map(
  //                     ([productFieldId, productFieldInputValues]) => {
  //                       return createProductFieldInput(
  //                         productFieldId,
  //                         productFieldInputValues.textTemplate ?
  //                           constructRenderedTextTemplateProductFieldInput(
  //                             productFieldInputValues.textTemplate,
  //                             eventContestPositionToTextTemplateContext(
  //                               event,
  //                               eventContestPriceBundle.contestName,
  //                               rowIndex
  //                             )
  //                           ) : productFieldInputValues
  //                       )
  //                     })
  //                   .filter(productFieldInputIsEmpty)
  //               }
  //             })
  //           )
  //         ]),
  //         []
  //       )
  //     )
  //   ]),
  //   []
  // )

  return Promise.all(rowBundles.map(createOrderRow))
    .then((orderRows) => {
      console.log("-orderRows-", orderRows)
      return orderRows
    })
}


export function getProductsOfPriceTypeAndActivity(priceTypeProductBundles, priceType, activityId) {
  return priceTypeProductBundles
    .filter((priceTypeProductBundle) =>
      priceTypeProductBundle.haveAllActivities ||
      priceTypeProductBundle.activityIds.indexOf(activityId) !== -1
    )
    .filter((priceTypeProductBundle) => priceTypeProductBundle.type === priceType)
    .map((priceTypeProductBundle) => priceTypeProductBundle.product)

}
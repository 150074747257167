import React from 'react';
import PropTypes from 'prop-types'

export default class ModalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: (this.props.modalStartsOpen !== undefined ? this.props.modalStartsOpen : false)
    }

    this.toggle = this.toggle.bind(this);
  }

  toggle(force = undefined) {
    this.setState({ modalIsOpen: force !== undefined ? force : !this.state.modalIsOpen })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.modalIsOpen !== this.state.modalIsOpen) {
      return true
    }
    return false
  }

  render() {

    const {
      ModalComponent,
      ButtonComponent,
      prerenderModalComponent,
      ...modalProps
    } = this.props;

    const { modalIsOpen } = this.state;

    return (
      <React.Fragment>
        <ButtonComponent
          key="button"
          onClick={(force) => this.toggle(typeof (force) === "boolean" ? force : undefined)}
          open={() => this.toggle(true)}
          toggle={() => this.toggle()}
        />
        {
          (modalIsOpen || prerenderModalComponent) &&
          <ModalComponent
            key="modal"
            isOpen={modalIsOpen}
            onClose={() => this.toggle(false)}
            {...modalProps}
          />
        }
      </React.Fragment>
    );
  }
}

ModalButton.propTypes = {
  ModalComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  ButtonComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  prerenderModalComponent: PropTypes.bool,
}

ModalButton.defaultProps = {
  prerenderModalComponent: false
}
import { toQueryUrl } from '../utils/url';
import i18n from '../i18n';

const maybeLocalize = (fragment, localize) => localize ? i18n.t(`routes:${fragment}`) : fragment;

function appendQuerystring(url, queryStr) {
  return `${url}${queryStr ? '?' : ''}${queryStr || ''}`
}

const HOME = {
  get: (localize = true) => '/',
  route: (localize = true) => '/',
}

const BLOCK_PAGE = {
  get: (pageName, localize = true) => `/${maybeLocalize('pages', localize)}/${maybeLocalize(pageName, localize)}`,
  route: (localize = true) => `/${maybeLocalize('pages', localize)}/:pageName`
}

const PRODUCT_ARCHIVE = {
  get: (localize = true) => `/${maybeLocalize('products', localize)}`,
  route: (localize = true) => `/${maybeLocalize('products', localize)}`
}

const PRODUCT_CATEGORY = {
  get: (categoryTree = [], query = {}, localize = true) => {
    const categoryTreeStr = (categoryTree.length > 0 ? "/" : "") + categoryTree.map(c => c.slug).join("/");
    return appendQuerystring(`${PRODUCT_ARCHIVE.get()}/${maybeLocalize('categories', localize)}${categoryTreeStr}`, toQueryUrl(query));
  },
  route: (localize = true) => `${PRODUCT_ARCHIVE.route(localize)}/${maybeLocalize('categories', localize)}/:category1Slug/:category2Slug?`,
}

const ACTIVITY_ARCHIVE = {
  get: (localize = true) => `${PRODUCT_ARCHIVE.get()}/${maybeLocalize('activities', localize)}`,
  route: (localize = true) => `${PRODUCT_ARCHIVE.route(localize)}/${maybeLocalize('activities', localize)}`,
}

const ACTIVITY = {
  get: (activitySlug, query = {}, localize = true) => {
    return appendQuerystring(`${ACTIVITY_ARCHIVE.get()}/${activitySlug}`, toQueryUrl(query));
  },
  route: (localize = true) => `${ACTIVITY_ARCHIVE.route(localize)}/:activitySlug`,
}

const PRODUCT_DETAIL = {
  get: (productIdentifier, articleIdentifier = null, localize = true) => `${PRODUCT_ARCHIVE.get()}/${productIdentifier}${articleIdentifier !== null ? `/${articleIdentifier}` : ""}`,
  route: (localize = true) => `${PRODUCT_ARCHIVE.route(localize)}/:productIdentifier/:articleIdentifier?`
}

const ORDER = {
  get: (localize = true) => `/${maybeLocalize('order', localize)}`,
  route: (localize = true) => `/${maybeLocalize('order', localize)}`,
}

const USER = {
  get: (localize = true) => `/${maybeLocalize('user.root', localize)}`,
  route: (localize = true) => `/${maybeLocalize('user.root', localize)}`
}

const USER_ADDRESSES = {
  get: (localize = true) => `${USER.get(localize)}/${maybeLocalize('user.addresses', localize)}`,
  route: (localize = true) => `${USER.route(localize)}/${maybeLocalize('user.addresses', localize)}`
}

const USER_ACCOUNT = {
  get: (localize = true) => `${USER.get(localize)}/${maybeLocalize('user.account', localize)}`,
  route: (localize = true) => `${USER.route(localize)}/${maybeLocalize('user.account', localize)}`
}

const USER_STAFF = {
  get: (localize = true) => `${USER.get(localize)}/${maybeLocalize('user.staff', localize)}`,
  route: (localize = true) => `${USER.route(localize)}/${maybeLocalize('user.staff', localize)}`
}

const USER_CONTACT = {
  get: (localize = true) => `${USER.get(localize)}/${maybeLocalize('user.contact', localize)}`,
  route: (localize = true) => `${USER.route(localize)}/${maybeLocalize('user.contact', localize)}`
}

const CUSTOMER_ORDERS = {
  get: (localize = true) => `${USER.get(localize)}/${maybeLocalize('user.customerOrders', localize)}`,
  route: (localize = true) => `${USER.route(localize)}/${maybeLocalize('user.customerOrders', localize)}`
}

const CUSTOMER_EVENTS = {
  get: (localize = true) => `${USER.get(localize)}/${maybeLocalize('user.customerEvents', localize)}`,
  route: (localize = true) => `${USER.route(localize)}/${maybeLocalize('user.customerEvents', localize)}`
}

const CUSTOMER_IMAGES = {
  get: (localize = true) => `${USER.get(localize)}/${maybeLocalize('user.customerImages', localize)}`,
  route: (localize = true) => `${USER.route(localize)}/${maybeLocalize('user.customerImages', localize)}`
}

const CUSTOMER = {
  get: (localize = true) => `/${maybeLocalize('customer', localize)}`,
  route: (localize = true) => `/${maybeLocalize('customer', localize)}`
}

const RESET_PASSWORD = {
  get: (localize = true) => `/${maybeLocalize('resetPassword', localize)}`,
  route: (localize = true) => `/${maybeLocalize('resetPassword', localize)}`
}

const SEARCH = {
  get: (q, localize = true) => `/${maybeLocalize('search', localize)}/${q}`,
  route: (localize = true) => `/${maybeLocalize('search', localize)}/:q`
}

export default {
  HOME,
  BLOCK_PAGE,
  PRODUCT_ARCHIVE,
  PRODUCT_CATEGORY,
  PRODUCT_DETAIL,
  ACTIVITY_ARCHIVE,
  ACTIVITY,
  CUSTOMER,
  ORDER,
  SEARCH,
  USER: {
    ROOT: USER,
    CUSTOMER_ORDERS,
    CUSTOMER_EVENTS,
    CUSTOMER_IMAGES,
    ADDRESSES: USER_ADDRESSES,
    ACCOUNT: USER_ACCOUNT,
    STAFF: USER_STAFF,
    CONTACT: USER_CONTACT,
  },
  RESET_PASSWORD
};


export const getProductCategorySlugFromParams = (params) => params.category3Slug || params.category2Slug || params.category1Slug || null;
import React from 'react';

import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';

import {
  fromSyncableOrderRows,
  getProductOptions
} from '../../actions';

import {
  getOrder,
  fetchProducts
} from '../../api';

import { buildOptionSelectionSummary } from '../../selectors/order';

import { toFormattedDateStr } from '../../utils/content';
import { waitUntilPromiseIsResolved, waitUntilSatisfyingState } from '../../containers/hoc';

import OrderRows from '../OrderRows';

function _extractOptionScopesFromOrderRows(orderRows) {
  return uniqBy(orderRows.reduce((accum, row) => {
    return [
      ...accum,
      ...(row.product ? row.product.optionScopes : [])
    ]
  }, []), os => os.id);
}

const OrderModalContent = waitUntilPromiseIsResolved(function ({ customerId, orderId }) {
  return new Promise((resolve, reject) => {
    let order = null;

    getOrder(customerId, orderId)
      .then(({ item }) => {
        order = item;
        const orderRowProductIds = order.rows.map(r => r.productId);

        return fetchProducts({
          productIds: orderRowProductIds,
          extended: true,
        });
      })
      .then(({ items: products }) => {
        const orderRows = order.rows.map((row) => ({
          ...row,
          product: find(products, p => p.id === row.productId),
        }));

        const optionScopes = _extractOptionScopesFromOrderRows(orderRows);

        resolve({
          order,
          orderRows,
          optionScopes,
          optionIds: optionScopes.map(os => os.optionId)
        })
      })
  })
}, ({ order, orderRows, optionScopes, optionIds }) => ({
  order,
  orderRows,
  optionScopes,
  optionIds
}))(waitUntilSatisfyingState(
  ({ productOptions: allProductOptions }, { orderRows, optionScopes, optionIds }) => {
    const productOptions = allProductOptions.filter(x => x.state === 'fetched' && optionIds.indexOf(x.id) !== -1);
    return productOptions.length === optionIds.length;
  },
  ({ productOptions: allProductOptions }, { optionIds }) => {
    return {
      productOptions: allProductOptions.filter(x => optionIds.indexOf(x.id) !== -1)
    }
  },
  {
    getProductOptions
  },
  (props) => {
    const { optionIds, productOptions, getProductOptions } = props;
    const existingProductOptionIds = productOptions.map(x => x.id);
    getProductOptions(optionIds.filter(id => existingProductOptionIds.indexOf(id) === -1), []);
  }
)(function _OrderModalContent({ order, orderRows, optionScopes, productOptions, onClose, ...props }) {
  return (
    <div>
      <h2>{toFormattedDateStr(order.orderDate)}</h2>
      <hr />
      <OrderRows orderRows={fromSyncableOrderRows(
        order.rows,
        function _buildSummary(selectedOption) {
          return buildOptionSelectionSummary(
            selectedOption,
            find(optionScopes, x => x.id === selectedOption.optionScopeId),
            find(productOptions, x => x.id === selectedOption.optionId)
          )
        }
      )} isCopyable />
    </div>
  )
}));

export default OrderModalContent;
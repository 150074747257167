import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  setPaymentMethod,
  setInvoiceAddress,
} from 'actions'

import Table from 'components/Table'
import PaymentMethodPriceColumn from './PaymentMethodPriceColumn'
import PaymentMethodMainColumn from './PaymentMethodMainColumn'


function OrderPaymentMethodSelectionForIndividual({
  paymentMethods,
  selectedPaymentMethodId,
  setPaymentMethod,
  setInvoiceAddress,
  customer,
}) {

  const handleSelectPaymentMethod = React.useCallback((dataItem) => {
    setPaymentMethod(dataItem.id)
    setInvoiceAddress(customer.id)
  }, [setPaymentMethod, setInvoiceAddress, customer])

  const paymentMethodColumns = React.useMemo(() => [
    {
      key: 'main',
      render(dataItem) {
        return (
          <PaymentMethodMainColumn
            paymentMethod={dataItem}
            isSelected={dataItem.isSelected}
            showInvoiceAddress={false}
          />
        )
      }
    },
    {
      key: 'price',
      className: "text-right",
      render(dataItem) {
        return (
          <PaymentMethodPriceColumn
            paymentMethod={dataItem}
            isSelected={dataItem.isSelected}
          />
        )
      }
    },
  ], [])

  const paymentMethodData = React.useMemo(() => paymentMethods.map((paymentMethod) => ({
    ...paymentMethod,
    isSelected: paymentMethod.id === selectedPaymentMethodId,
    key: paymentMethod.id,
  })), [paymentMethods, selectedPaymentMethodId])


  return (
    <React.Fragment>
      <Table
        columns={paymentMethodColumns}
        data={paymentMethodData}
        showSelectable
        selectedKey={selectedPaymentMethodId}
        onSelect={handleSelectPaymentMethod}
      />
    </React.Fragment>
  )
}

OrderPaymentMethodSelectionForIndividual.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  selectedPaymentMethodId: PropTypes.string,
  customer: PropTypes.object.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  setInvoiceAddress: PropTypes.func.isRequired,
}

export default connect(state => ({
  paymentMethods: state.order.availablePaymentMethods,
  selectedPaymentMethodId: state.order.paymentMethodId,
  customer: state.customer,
}), {
    setPaymentMethod,
    setInvoiceAddress,
  }
)(OrderPaymentMethodSelectionForIndividual)
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import find from 'lodash/find'

import config from '../../config'

import {
  setDeliveryMethod,
  setPaymentMethod,
  setDeliveryAddress,
  setInvoiceAddress,
  refreshCustomer,
  refreshUser
} from 'actions'

import {
  orderApi
} from 'api'

import { hasValidAddress, hasValidContactInformation } from 'utils/content'
import { callbackWithoutPropagationComposer } from 'utils/event'

import { DarkButton } from 'components/buttons'
import useBoolState from 'hooks/useBoolState'
import ModalWithClose, { ModalBody, SIZES } from 'components/modals/Modal'
import ContentHeader from 'components/ContentHeader'
import Table from 'components/Table'
import Address from 'components/Address'
import CreateCustomerFormWrapper from 'components/forms/CreateCustomerFormWrapper'
import { Translate } from 'containers/translates'
import DeliveryMethodPriceColumn from './DeliveryMethodPriceColumn'
import DeliveryMethodMainColumn from './DeliveryMethodMainColumn'
import EditCustomerFormWrapper from 'components/forms/EditCustomerFormWrapper';

function OrderDeliveryMethodSelectionForOtherThanIndividualAndSignedInUser({
  deliveryMethods,
  selectedDeliveryMethodId,
  user,
  customer,
  order,
  setDeliveryMethod,
  setPaymentMethod,
  setDeliveryAddress,
  setInvoiceAddress,
  refreshUser,
  refreshCustomer,
}) {

  const [selectDeliveryAddressDialogIsOpen, openSelectDeliveryAddressDialog, closeSelectDeliveryAddressDialog] = useBoolState(false)
  const [createDeliveryAddressFormDialogIsOpen, openCreateDeliveryAddressFormDialog, closeCreateDeliveryAddressFormDialog] = useBoolState(false)
  const [editDeliveryAddressFormDialogIsOpen, openEditDeliveryAddressFormDialog, closeEditDeliveryAddressFormDialog] = useBoolState(false)

  const [lastClickedDeliveryMethodId, setLastClickedDeliveryMethodId] = React.useState(selectedDeliveryMethodId)

  const temporarySelectedDeliveryMethodId = createDeliveryAddressFormDialogIsOpen ? lastClickedDeliveryMethodId : null
  const preOrJustSelectedDeliveryMethodId = selectedDeliveryMethodId || temporarySelectedDeliveryMethodId

  const selectedDeliveryAddressId = order.customerIdForDelivery
  const deliveryAddresses = React.useMemo(
    () => (
      uniqBy(
        [
          ...(user.customer ? [user.customer] : []),
          ...(
            user.customerAccess
              .filter((customerAccess) => customerAccess.state === 'approved')
              .filter((customerAccess) => ['delivery', 'other'].indexOf(customerAccess.customer.usage) !== -1)
          ),
          ...(
            get(customer, 'data.addresses', [])
              .filter((address) => ['delivery', 'other'].indexOf(address.usage) !== -1)
          )
        ],
        (deliveryAddress) => deliveryAddress.id
      )
        .filter((deliveryAddress) => hasValidAddress(deliveryAddress) && hasValidContactInformation(deliveryAddress))
    ),
    [user, customer]
  )

  const deliveryAddress = find(deliveryAddresses, (deliveryAddress) => deliveryAddress.id === selectedDeliveryAddressId)

  const refresh = React.useMemo(() => () => {
    refreshUser()
    refreshCustomer()
  }, [refreshUser, refreshCustomer])

  const handleSelectDeliveryMethod = (dataItem) => {
    if (selectedDeliveryAddressId) {
      setDeliveryMethod(dataItem.id)
    }
    else if (deliveryAddresses.length > 0) {
      setLastClickedDeliveryMethodId(dataItem.id)
      openSelectDeliveryAddressDialog()
    }
    else {
      setLastClickedDeliveryMethodId(dataItem.id)
      openCreateDeliveryAddressFormDialog()
    }
  }

  const handleOnCreatedDeliveryAddress = (createdDeliveryAddressId) => {
    setDeliveryMethod(lastClickedDeliveryMethodId)
    setDeliveryAddress(createdDeliveryAddressId)
    refresh()
    closeCreateDeliveryAddressFormDialog()
  }

  const handleOnEditedDeliveryAddress = () => {
    refresh()
    closeEditDeliveryAddressFormDialog()
  }

  const lastClickedDeliveryMethod = React.useMemo(() => (
    find(deliveryMethods, (deliveryMethod) => deliveryMethod.id === lastClickedDeliveryMethodId)
  ), [deliveryMethods, lastClickedDeliveryMethodId])

  const deliveryMethodColumns = React.useMemo(() => [
    {
      key: 'main',
      render(dataItem) {
        return (
          <DeliveryMethodMainColumn
            deliveryMethod={dataItem}
            isSelected={dataItem.isSelected}
            showDeliveryAddress
            deliveryAddress={deliveryAddress ? deliveryAddress : undefined}
            onChangeDeliveryAddress={openSelectDeliveryAddressDialog}
          />
        )
      }
    },
    {
      key: 'price',
      className: "text-right",
      render(dataItem) {
        return (
          <DeliveryMethodPriceColumn
            deliveryMethod={dataItem}
            isSelected={dataItem.isSelected}
          />
        )
      }
    },
  ], [preOrJustSelectedDeliveryMethodId, deliveryMethods, deliveryAddress])

  const deliveryMethodData = React.useMemo(() => deliveryMethods.map((deliveryMethod) => ({
    ...deliveryMethod,
    isSelected: deliveryMethod.id === preOrJustSelectedDeliveryMethodId,
    key: deliveryMethod.id,
  })))

  const handleReset = () => {
    setDeliveryMethod(null)
    setDeliveryAddress(null)
    setPaymentMethod(null)
    setInvoiceAddress(null)
  }

  const handleSelectDeliveryAddress = React.useCallback((deliveryAddress) => {
    setDeliveryMethod(lastClickedDeliveryMethodId)
    setDeliveryAddress(deliveryAddress.id)
    closeSelectDeliveryAddressDialog()
  }, [setDeliveryMethod, lastClickedDeliveryMethodId, setDeliveryAddress, closeSelectDeliveryAddressDialog])

  const deliveryAddressColumns = React.useMemo(() => [
    {
      key: "main",
      render(deliveryAddressBundle) {
        return (
          <React.Fragment>
            <h3 className={deliveryAddressBundle.isSelected ? "text-primary" : ""}>{deliveryAddressBundle.name}</h3>
            <Address
              showName={false}
              showContactInformation
              {...deliveryAddressBundle}
            />
          </React.Fragment>
        )
      }
    },
    {
      key: "actions",
      render(deliveryAddressBundle) {
        return (
          <React.Fragment>
            {deliveryAddressBundle.isSelected && (
              <DarkButton
                stopOnClickPropagation
                onClick={openEditDeliveryAddressFormDialog}
              >{"Edit"}</DarkButton>
            )}
          </React.Fragment>
        )
      }
    }
  ], [preOrJustSelectedDeliveryMethodId, openEditDeliveryAddressFormDialog, selectedDeliveryAddressId])

  const deliveryAddressBundleData = React.useMemo(() => (
    preOrJustSelectedDeliveryMethodId !== undefined ?
      deliveryAddresses.map((deliveryAddress) => ({
        key: deliveryAddress.id,
        isSelected: deliveryAddress.id === selectedDeliveryAddressId,
        ...deliveryAddress
      }))
      : []),
    [deliveryAddresses, preOrJustSelectedDeliveryMethodId, selectedDeliveryAddressId]
  )

  return (
    <React.Fragment>
      <Table
        columns={deliveryMethodColumns}
        data={deliveryMethodData}
        showSelectable
        selectedKey={preOrJustSelectedDeliveryMethodId}
        onSelect={handleSelectDeliveryMethod}
      />
      <ModalWithClose
        isOpen={selectDeliveryAddressDialogIsOpen}
        onClose={closeSelectDeliveryAddressDialog}
      >
        <ModalBody size={SIZES.LARGE}>
          <Translate ns="deliveryAddressList">
            {({ t }) => (
              <React.Fragment>
                <ContentHeader />
                <Table
                  showTableHead={false}
                  showSelectable
                  columns={deliveryAddressColumns}
                  data={deliveryAddressBundleData}
                  onSelect={handleSelectDeliveryAddress}
                  {...{
                    selectedKey: selectedDeliveryAddressId
                  }}
                />
                <DarkButton onClick={callbackWithoutPropagationComposer(openCreateDeliveryAddressFormDialog)}>
                  {t("createDeliveryAddressButtonLabel")}
                </DarkButton>
              </React.Fragment>
            )}
          </Translate>
        </ModalBody>
      </ModalWithClose>
      <ModalWithClose
        isOpen={createDeliveryAddressFormDialogIsOpen}
        onClose={closeCreateDeliveryAddressFormDialog}
      >
        <Translate ns="deliveryAddressForm">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader />
              {lastClickedDeliveryMethod && (
                <CreateCustomerFormWrapper
                  fields={['name', 'mobile', 'email', 'address']}
                  onSubmit={handleOnCreatedDeliveryAddress}
                  initialValues={{
                    infoType: 'address',
                    usage: 'delivery',
                    customerId: customer.id,
                  }}
                  t={t}
                />
              )}
            </React.Fragment>
          )}
        </Translate>
      </ModalWithClose>
      <ModalWithClose
        isOpen={editDeliveryAddressFormDialogIsOpen}
        onClose={closeEditDeliveryAddressFormDialog}
      >
        <Translate ns="deliveryAddressForm">
          {({ t }) => (
            <React.Fragment>
              <ContentHeader />
              {lastClickedDeliveryMethod && (
                <EditCustomerFormWrapper
                  fields={['name', 'mobile', 'email', 'address']}
                  onSubmit={handleOnEditedDeliveryAddress}
                  customer={deliveryAddress}
                  t={t}
                />
              )}
            </React.Fragment>
          )}
        </Translate>
      </ModalWithClose>
      {config.DEBUG && <div className="mt-4">
        <DarkButton onClick={handleReset}>{"Reset"}</DarkButton>
      </div>}
    </React.Fragment>
  )
}

OrderDeliveryMethodSelectionForOtherThanIndividualAndSignedInUser.propTypes = {
  // Redux props
  deliveryMethods: PropTypes.array.isRequired,
  selectedDeliveryMethodId: PropTypes.number,
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  setDeliveryMethod: PropTypes.func.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
  setDeliveryAddress: PropTypes.func.isRequired,
  setInvoiceAddress: PropTypes.func.isRequired,
  refreshCustomer: PropTypes.func.isRequired,
  refreshUser: PropTypes.func.isRequired,
}

export default connect(state => ({
  deliveryMethods: state.order.availableDeliveryMethods,
  selectedDeliveryMethodId: state.order.deliveryMethodId,
  customer: state.customer,
  order: state.order,
  user: state.user,
}), {
    setDeliveryMethod,
    setPaymentMethod,
    setDeliveryAddress,
    setInvoiceAddress,
    refreshUser,
    refreshCustomer
  }
)(OrderDeliveryMethodSelectionForOtherThanIndividualAndSignedInUser)
import { MESSAGES } from '../actions';

const initState = [];

const message = (state = null, action) => {
  switch(action.type) {
    case MESSAGES.ADD:
      return {
        id: action.id,
        title: action.title,
        body: action.body,
        created: (new Date()).getTime(),
        autoRemoveIn: action.autoRemoveIn
      }
    default:
      return state;
  }
}

const messages = (state = initState, action) => {
  switch(action.type) {
    case MESSAGES.ADD:
      return [
        ...state,
        message(undefined, action)
      ];
    case MESSAGES.REMOVE:
      return state.filter(message => message.id !== action.messageId);
    default:
      return state;
  }
}

export default messages;
import React from 'react'
import PropTypes from 'prop-types'

import {
  createCustomerEvent,
  editCustomerEvent
} from 'api'
import { caseSelector } from 'utils/helpers'

import { } from './constants'
import {
  exportEvent,
  createEmptyEvent,
} from 'selectors/event'

import { Event } from './props'

import { Translate } from 'containers/translates'

import EventDetailsForm from './EventDetailsForm'
import EventContestsForm from './EventContestsForm'

import { PrimaryButton } from 'components/buttons'

import ContentHeader from 'components/ContentHeader'



const STEPS = {
  DETAILS: 'details',
  CONTESTS: 'contests',
  END: 'end',
}

class EventFormSteps extends React.Component {
  state = {
    step: STEPS.DETAILS,
  }

  static propTypes = {
    event: PropTypes.shape(Event.propTypes),
    onSubmit: PropTypes.func.isRequired,
    onChangeContests: PropTypes.func.isRequired,
  }

  handleSubmit = (incomingData) => {
    this.props.onSubmit(incomingData)
      .then(() => {
        switch (this.state.step) {
          case STEPS.DETAILS:
            this.setState({
              step: STEPS.CONTESTS
            })
            break
          case STEPS.CONTESTS:
            this.props.onComplete()
            break
          case STEPS.END:
            this.props.onComplete()
            break
        }
      })

  }

  render() {
    const contextMode = this.props.event.id !== null ? "edit" : "create"

    const eventNameAndYear = this.props.event.id !== null ? `${this.props.event.name} ${this.props.event.date.getFullYear ? this.props.event.date.getFullYear() : ''}` : ''

    return (
      <Translate ns="customer.event">
        {({ t }) => (
          <React.Fragment>
            {
              caseSelector({
                [STEPS.DETAILS]: () => (
                  <React.Fragment>
                    <Translate ns={`details.${contextMode}`}>
                      {({ t }) => (
                        <ContentHeader context={{ eventName: this.props.event.name }} />
                      )}
                    </Translate>

                    <EventDetailsForm
                      onSubmit={this.handleSubmit}
                      event={this.props.event}
                    />
                  </React.Fragment>
                ),
                [STEPS.CONTESTS]: () => (
                  <React.Fragment>
                    <Translate ns={`contests.${contextMode}`}>
                      {({ t }) => (
                        <ContentHeader context={{ context: contextMode, eventNameAndYear }} />
                      )}
                    </Translate>
                    <EventContestsForm
                      contests={this.props.event.contests}
                      onChange={this.props.onChangeContests}
                    />
                    <hr />
                    <div>
                      <PrimaryButton onClick={() => this.handleSubmit(this.props.event)} disabled={this.props.event.contests.length === 0}>
                        {t(`contests.saveButton.label`)}
                      </PrimaryButton>
                    </div>
                  </React.Fragment>

                ),
                [STEPS.END]: () => (
                  <div>
                    <PrimaryButton onClick={() => this.handleSubmit(this.props.event)}>
                      {"End"}
                    </PrimaryButton>
                  </div>
                )
              })(this.state.step)()
            }
          </React.Fragment >
        )
        }
      </Translate>
    )
  }
}

export default class EventForm extends React.Component {

  state = {
    ...createEmptyEvent(),
    isSyncing: false,
    hasBeenSetup: false
  }

  static propTypes = {
    onComplete: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
    event: PropTypes.shape(
      Event.propTypes
    ),
  }

  componentDidMount() {

    if (this.props.event) {
      this.setState({
        ...this.props.event
      })
    }
    else {
      this.setState({
        // ...createDummyEvent()
      })
    }
  }

  handleSubmit = (formValues) => {
    return new Promise((resolve) => {
      this.setState({
        ...formValues,
        isSyncing: true
      }, () => {

        const exportedEvent = exportEvent(this.state)

        if (this.state.id === null) {
          createCustomerEvent(
            this.props.customerId,
            exportedEvent
          )
            .then(({ createdId }) =>
              this.setState({
                id: createdId,
                isSyncing: false
              }, resolve)
            )
        }
        else {
          editCustomerEvent(
            this.props.customerId,
            this.state.id,
            exportedEvent
          )
            .then(() =>
              this.setState({ isSyncing: false }, resolve)
            )
        }
      })
    })
  }

  handleComplete = () => {
    this.props.onComplete(this.state)
  }

  render() {
    return (
      <EventFormSteps
        event={this.state}
        onSubmit={this.handleSubmit}
        onChangeContests={this.handleChangeContests}
        onComplete={this.handleComplete}
      />
    )
  }
}
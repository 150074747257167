export const PromiseWrapper = (promise, promiseId) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => {
      hasCanceled_ ? reject({isCanceled: true}) : resolve(val);
    });
    promise.catch((error) => {
      hasCanceled_ ? reject({isCanceled: true}) : reject(error);
    });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};


export function RejectedPromiseCreator() {
  return new Promise((resolve, reject) => reject('Always rejected.'));
}
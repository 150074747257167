import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import OrderDeliveryMethodSelectionForIndividual from './OrderDeliveryMethodSelectionForIndividual'
import OrderDeliveryMethodSelectionForOtherThanIndividual from './OrderDeliveryMethodSelectionForOtherThanIndividual'
import OrderDeliveryMethodSelectionForOtherThanIndividualAndSignedInUser from './OrderDeliveryMethodSelectionForOtherThanIndividualAndSignedInUser'

function InnerDeliverySection({
  isSignedIn,
  customer,
}) {

  if (customer.customerType === 'individual') {
    return <OrderDeliveryMethodSelectionForIndividual />
  }
  else if (isSignedIn) {
    return <OrderDeliveryMethodSelectionForOtherThanIndividualAndSignedInUser />
  }
  else {
    return <OrderDeliveryMethodSelectionForOtherThanIndividual />
  }
}

InnerDeliverySection.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
}


export default connect(state => ({
  customer: state.customer,
  isSignedIn: state.auth.isSignedIn,
}))(InnerDeliverySection)
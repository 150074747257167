import React from 'react';
import { connect } from 'react-redux';

import { removeMessage } from '../actions';

import { Button } from '../components/buttons';

import './Messages.css';


function CloseButton(props) {
  return (
    <Button {...props}>X</Button>
  )
};

const Message = ({ id, title, body, onClick }) => {
  return (
    <div className="Messages_Item">
      <p>
        <CloseButton style={{ float: 'right' }} onClick={() => onClick(id)} />
        <strong>{title}</strong>
        {body}
      </p>
    </div>
  );
}

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.removeMessage = this.removeMessage.bind(this);
  }

  removeMessage(messageId) {
    const { removeMessage } = this.props;
    removeMessage(messageId);
  }

  render() {
    const { messages } = this.props;
    return (
      <div className="Messages">
        {messages.map(message => (
          <Message key={message.id} {...message} onClick={this.removeMessage} />
        ))}
      </div>
    );
  }
}

export default connect((state) => {
  const { messages } = state;

  return {
    messages
  }
}, {
    removeMessage
  })(Messages);
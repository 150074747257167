import React from 'react'
import PropTypes from 'prop-types'


function PaymentMethodNameAndDescription({ paymentMethod, isSelected }) {
  return (
    <React.Fragment>
      <h3 className={isSelected ? "text-primary" : ""}>
        {paymentMethod.name}
      </h3>
      {paymentMethod.description && (
        <p className="mb-0">{paymentMethod.description}</p>
      )}
    </React.Fragment>
  )
}

PaymentMethodNameAndDescription.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default PaymentMethodNameAndDescription
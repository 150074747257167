import find from 'lodash/find';

import {
  ACTIVITIES,
  BLOCKS,
  PRODUCT_CATEGORIES,
  PRODUCT_OPTIONS,
  PRODUCTS,
  SELECTED_PRODUCT,
  ORDER,
  MESSAGES,
  addMessage,
  removeMessage
} from '../actions';

import config from '../config';

const messageCreator = store => next => action => {
  const result = next(action);

  const _addErrorMessage = (msg) => {
    store.dispatch(addMessage("Error", msg));

    if (config.DEBUG) {
      console.warn("-FAILURE-", action.type, action);
    }
  }

  switch (action.type) {
    case ORDER.ROWS.ADD:
      // store.dispatch(addMessage("Order row was added", "", 4000));
      break;
    case ORDER.ROWS.REMOVE:
      // store.dispatch(addMessage("Order row was removed", "", 4000));
      break;

    case ACTIVITIES.FETCH.FAILURE:
      _addErrorMessage("There was a problem loading activities. Try again in a few minutes.");
      break;

    case BLOCKS.FETCH.FAILURE:
      // _addErrorMessage("There was a problem loading blocks. Try again in a few minutes.");
      break;

    case PRODUCT_CATEGORIES.FETCH.FAILURE:
      _addErrorMessage("There was a problem loading product categories. Try again in a few minutes.");
      break;

    case PRODUCT_OPTIONS.FETCH.FAILURE:
      _addErrorMessage("There was a problem loading product options. Try again in a few minutes.");
      break;

    case PRODUCTS.FETCH.FAILURE:
      _addErrorMessage("There was a problem loading products. Try again in a few minutes.");
      break;

    case SELECTED_PRODUCT.FETCH.FAILURE:
      _addErrorMessage("There was a problem loading selected product. Try again in a few minutes.");
      break;

    default:
      break;
  }

  switch (action.type) {
    case MESSAGES.ADD:
      const message = find(store.getState().messages, m => m.id === action.id);

      if (message.autoRemoveIn !== false) {
        const timeElapsed = (new Date()).getTime() - message.created;
        const timeLeft = message.autoRemoveIn - timeElapsed;

        setTimeout(() => store.dispatch(removeMessage(message.id)), timeLeft);
      }
      break;
    default:
      break;
  }

  return result;
}

export default messageCreator;
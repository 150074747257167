import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import { formatPrice } from '../utils/content';
import { makeRows, cleanArray, sortBy } from '../utils/array';
import { caseSelector } from '../utils/helpers';

import firstOfArray from 'lodash/head';
import lastOfArray from 'lodash/last';

import { Link, withRouter } from 'react-router-dom';
import routes from '../routes';

import {
  getProductCategoryFlatTree
} from '../selectors';

import VisibilitySensor from 'react-visibility-sensor';
import { Block, connectToFetchingBlocks } from '../containers/BlockArea';
import { mountWhenVisible } from '../containers/hoc';
import EmptyWrapper from '../containers/EmptyWrapper';

import LoadingIndicator from './spinners';

import './ProductArchive.scss';

// const ProductArchiveItemImage = ({name = "no name", url}) => (
//   <div className="BackgroundImage--contained" style={{backgroundImage: `url(${url})`}} />
// );

const ProductArchiveItemImage = ({ name = "no name", url }) => (
  <img alt={name} src={url} />
);


class ProductArchiveItemImageWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };

    this.imageEl = null;
    this.onPreloadDone = this.onPreloadDone.bind(this);

    this.preloadImage();
  }

  onPreloadDone() {
    this.setState({ isLoaded: true });
  }

  preloadImage() {
    const { isLoaded } = this.state;

    if (isLoaded) {
      return;
    }

    const { url } = this.props;

    this.imageEl = new Image();
    this.imageEl.src = url;
    this.imageEl.addEventListener("load", this.onPreloadDone);
  }

  componentWillUnmount() {
    if (this.imageEl) {
      this.imageEl.removeEventListener("load", this.onPreloadDone);
    }
  }

  render() {
    const { name = "no image name", url } = this.props;
    const { isLoaded } = this.state;

    return (
      isLoaded ?
        <ProductArchiveItemImage name={name} url={url} /> :
        <LoadingIndicator />
    );
  }
}

const VisibilitySensoredProductArchiveItemImageWrapper = mountWhenVisible({})(ProductArchiveItemImageWrapper);
const VisibilitySensoredProductArchiveItemImage = mountWhenVisible({})(ProductArchiveItemImage);


const ProductDescription = translate("productArchive")(({ t, numOfArticles, descriptionType, attributesAndValues, extended }) => {

  return (
    <div className="mb-1">
      {attributesAndValues.map(({ attribute, values }, attributeIndex) => (
        <span key={attribute.id}>
          {
            attributeIndex !== 0 &&
            <span>, </span>
          }
          {t(
            `item.attribute.description${extended ? '_extended' : ''}`,
            {
              count: values.length,
              context: attribute.valueType,
              value: caseSelector({
                size: () => {
                  if (values.length === 1) {
                    return firstOfArray(values).value;
                  }

                  const sortedValues = sortBy(values, v => Number(v.value));
                  const smallestValue = firstOfArray(sortedValues);
                  const largestValue = lastOfArray(sortedValues);

                  return `${smallestValue ? smallestValue.value : '<smallest>'} - ${largestValue ? largestValue.value : '<largest>'}`;
                }
              }, () => values.map(v => v.value).join(", "))(attribute.valueType)()
            })}
        </span>
      ))}
    </div>
  )
});

const ProductItemBadges = translate("productArchive")(({ isNews, onSale, t }) => {

  return (
    <div className="Archive_Item_Badges">
      {isNews && <span key="badge-isNews" className="badge badge-dark">{t('item.states.isNews')}</span>}
      {onSale && <span key="badge-onSale" className="badge badge-secondary">{t('item.states.onSale')}</span>}
    </div>
  );
});

class _ProductArhiveItemLink extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();

    (this.props.onClickHandler || (() => null))();
    this.props.history.push(this.props.to);

    return false;
  }

  render() {
    const {
      to,
      children,
      onClickHandler,
      className,
    } = this.props;

    return (
      <a href={to} onClick={this.onClick} className={className}>
        {children}
      </a>
    );
  }
}

const ProductArhiveItemLink = withRouter(_ProductArhiveItemLink);

const ProductArchiveItemInner = connect((state, props) => {
  const attributeIds = Object.keys(props.attributeValueIds).map(idStr => Number(idStr));

  return {
    attributesAndValues: state.productAttributes
      .filter(a => attributeIds.indexOf(a.id) !== -1)
      .map(a => ({
        attribute: a,
        values: a.values.filter(v => props.attributeValueIds[a.id].indexOf(v.id) !== -1)
      }))
      .filter(av => av.values.length > 0),
    extended: state.client.greaterThan.medium
  }

})(translate("productArchive")(({ identifier, defaultArticleIdentifier, name, price, isNews, onSale, numOfArticles, attributesAndValues, extended, attributes: [defaultAttribute] = [], imageUrl, className, onClickHandler, size = 'default', t }) => {
  return (
    <ProductArhiveItemLink to={routes.PRODUCT_DETAIL.get(identifier, numOfArticles > 1 ? defaultArticleIdentifier : null)} className={`block-link Archive_Item ${className || ''} --${size}`} onClickHandler={onClickHandler}>
      <div className="Archive_Item_Image">
        {
          imageUrl &&
          <div className="Archive_Item_image_Wrapper">
            <ProductArchiveItemImageWrapper url={imageUrl} name={name} />
          </div>
        }
      </div>

      <div className="Archive_Item_Body">
        {
          size == 'default' &&
          <div>
            <h3 className="text-primary mb-0">
              {name}
            </h3>
            {<ProductDescription numOfArticles={numOfArticles} attributesAndValues={attributesAndValues} extended={extended} descriptionType={defaultAttribute ? defaultAttribute.slug : "default"} />}
          </div>
        }
        <div>
          <div className="h5">{t('item.price', { value: formatPrice(price.from, 'kr') })}</div>
        </div>
      </div>

      <ProductItemBadges {...{
        isNews, onSale
      }} />
    </ProductArhiveItemLink>
  );
}));

class _ProductArchiveItemVisibilityWrapper extends React.Component {
  constructor() {
    super();

    this.state = {
      isVisible: false,
      visibleCount: 0,
      hasBeenTriggered: false,
    }

    this.onChange = this.onChange.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onChange(isVisible) {
    this.setState(state => ({
      ...state,
      isVisible,
      visibleCount: state.visibleCount + Number(isVisible)
    }), () => {
      if (this.state.visibleCount == 1 && !this.state.hasBeenTriggered) {
        this.setState(state => ({
          ...state,
          hasBeenTriggered: true
        }));
      }
    })
  }

  onClickHandler() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const { product, ...props } = this.props;

    return (
      <VisibilitySensor onChange={this.onChange}>
        <ProductArchiveItemInner
          {...product}
          {...props}
          onClickHandler={this.onClickHandler}
        />
      </VisibilitySensor>
    )
  }
}

export const ProductArchiveItem = connect((state, { product }) => {
  return {
    categoryTree: getProductCategoryFlatTree(state, product.mainCategoryId),
  }

})(translate("productArchive")(_ProductArchiveItemVisibilityWrapper));


function _ProductArchiveListWithBlocks({ blocks, products, totalNumOfProducts, numOfCols, listType }) {
  const rows = makeRows(products, numOfCols).map(products => ({
    type: 'products-row',
    data: products
  }));

  if (blocks) {
    blocks.forEach(block => {
      rows.splice(block.sequence, 0, {
        type: 'block-row',
        data: block
      })
    })
  }

  const _productsRow = (products, index, productIndexOffset) => (
    <div key={`products-row-${index}`} className="row no-gutters">
      {products.map((product, index) => (
        <ProductArchiveItem
          key={product.id}
          product={product}
          index={productIndexOffset + index}
          imageUrl={product.image && product.image.processedUrls.archive}
          className={`col col-${Math.round(12 / numOfCols)}`}
          listType={listType}
        />
      ))}
    </div>
  );

  const _blockRow = ({ content }, index) => (
    <div key={`block-row-${index}`} className="my-3">
      <div>
        <Block content={content} />
      </div>
    </div>
  )

  let productIndexOffset = 0;

  return (
    <div className="Archive">
      {rows.map(({ type, data }, index) => {
        return {
          'products-row': () => {
            const currentProductIndexOffset = productIndexOffset;
            productIndexOffset += data.length;
            return _productsRow(data, index, currentProductIndexOffset);
          },
          'block-row': () => _blockRow(data, index),
        }[type]()
      })}
    </div>
  );
}

const ProductArchiveListWithBlocks = connectToFetchingBlocks(connect(state => {
  const { client } = state;

  const numOfCols = caseSelector({
    medium: 3,
    large: 4,
    extraLarge: 4,
  }, 2)(client.mediaType);

  return {
    numOfCols: numOfCols
  }
})(_ProductArchiveListWithBlocks));

const ProductArchive = translate("productArchive")(({ products, t, blockConditions, ...props }) => {
  return (
    <div>
      {
        products.length ?
          <ProductArchiveListWithBlocks
            products={products}
            areaSlug="product-archive-row"
            conditions={blockConditions}
            {...props}
          /> :
          <div>
            <p>{t('noProductWithCurrentFilters')}</p>
          </div>
      }
    </div>
  );
});

export default ProductArchive;
import { AUTH } from '../actions';
import { REHYDRATE } from 'redux-persist';
import { init } from 'i18next/dist/commonjs';

const initState = {
  userName: null,
  token: null,
  error: null,
  isSignedIn: false
}

const auth = (state = initState, action) => {
  switch(action.type) {
    case AUTH.SIGNINGIN.SUCCESS:
      return {
        ...state,
        error: initState.error,
        token: action.token,
        userName: action.userName,
        isSignedIn: true
      }

    case AUTH.SIGNINGIN.FAILED:
      return {
        ...initState,
        error: action.error,
        isSignedIn: false
      }

    case AUTH.SIGNOUT:
      return {
        ...initState
      };

    case AUTH.RESET_ERROR:
      return {
        ...state,
        error: initState.error,
      }
    case REHYDRATE:
      const {auth} = action.payload || {};
      return {
        ...state,
        ...(auth || {}),
        error: initState.error
      }

    default:
      return state;
  }
}

export default auth;
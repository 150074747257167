import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import { Link } from 'react-router-dom';
import routes from '../routes';

import NavbarSearch from './NavbarSearch';
import { makeSingleToggable } from '../containers/hoc';

import './Navbar.scss';

const NavbarLink = ({to, children, className}) => (
  <Link to={to} className={className}>
    {children}
  </Link>
);

const CategorySubMenu = ({id, slug, name, children}) => (
  <div className="SubMenu">
    <div className="SubMenu_Inner">
      {children.map(childCategory => (
        <div key={childCategory.id} className="SubMenu_Item">
          <Link to={routes.PRODUCT_CATEGORY.get([{slug}, {slug: childCategory.slug}])}>
            {childCategory.name}
          </Link>
        </div>
      ))}
    </div>
  </div>
)

export const RootCategoryMenu = ({className, ...props}) => (
  <div className={`RootItem ${className}`}>
    <CategorySubMenu {...props} className="RootItem"/>
    
    <NavbarLink className="RootItem_Link" to={routes.PRODUCT_CATEGORY.get([{slug: props.slug}])}>
      {props.name}
    </NavbarLink>
  </div>
)

export const ActivityMenu = translate("activities")(({className, activities, t}) => {

  return (
    <div className={`RootItem ${className}`}>
      <NavbarLink className="RootItem_Link" to={routes.ACTIVITY_ARCHIVE.get()}>
        {t("activities")}
      </NavbarLink>
    </div>
  )
})

const CategoryDropDown = translate('category')(({id, name, slug, children, expandedDropdownId, onToggleDropdown, t}) => {
  const dropdownId = `category-${id}`;
  const isExpanded = expandedDropdownId === dropdownId;

  return (
    
    <div className={`dropdown d-inline-block`}>
      <button type="button" className={`btn btn-no-focus dropdown-toggle ${isExpanded ? "btn-no-background-white-text" : "btn-no-background-white-text"}`} onClick={() => onToggleDropdown(dropdownId)}>{name}</button>

      <div className={`dropdown-menu dropdown-menu-right ${isExpanded ? "show" : ""}`}>
        {children.map(childCategory => (
          <Link onClick={() => onToggleDropdown(dropdownId, false)} key={childCategory.id} className="dropdown-item" to={routes.PRODUCT_CATEGORY.get([{slug}, {slug: childCategory.slug}])}>{childCategory.name}</Link>
        ))}

        <div className="dropdown-divider"></div>
        <Link onClick={() => onToggleDropdown(dropdownId, false)} className="dropdown-item" to={routes.PRODUCT_CATEGORY.get([{slug}])}>{t('menu.toParent.label', {categoryName: name})}</Link>
      </div>
    </div>
  );
});

const ActivitiesDropDown = translate('activities')(({activities, expandedDropdownId, onToggleDropdown, t}) => {
  const dropdownId = 'activities';
  const isExpanded = expandedDropdownId === dropdownId;

  return (
    
    <div className={`dropdown d-inline-block`}>
      <button type="button" className={`btn btn-no-focus dropdown-toggle ${isExpanded ? "btn-no-background-white-text" : "btn-no-background-white-text"}`} onClick={() => onToggleDropdown(dropdownId)}>
        {t('activities')}
      </button>

      <div className={`dropdown-menu dropdown-menu-right ${isExpanded ? "show" : ""}`}>

        <div className="h6 dropdown-header">{t('menu.featuredActivities.header')}</div>
        {activities.map(activity => (
          <Link onClick={() => onToggleDropdown(dropdownId, false)} key={activity.id} className="dropdown-item" to={routes.ACTIVITY.get(activity.slug)}>{activity.name}</Link>
        ))}

        <div className="dropdown-divider"></div>
        <Link onClick={() => onToggleDropdown(dropdownId, false)} className="dropdown-item" to={routes.ACTIVITY_ARCHIVE.get()}>
          {t('menu.toActivityArchive.label')}
        </Link>
      </div>
    </div>
  );
});

const NavbarMenu = translate("common")(class _NavbarMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedDropdownId: null
    }

    this.onToggleDropdown = this.onToggleDropdown.bind(this);
  }

  onToggleDropdown(dropdownId, force = undefined) {
    this.setState({
      expandedDropdownId: (
        force !== undefined ? 
          (force === true ? dropdownId : null) : 
          (this.state.expandedDropdownId !== dropdownId ? dropdownId : null)
      )
    })
  }

  render() {
    const {productCategories, activities, t} = this.props;
    const {expandedDropdownId} = this.state;

    return (
      <nav id="Navbar_Menu">
        <ActivitiesDropDown key="activities" activities={activities.filter(a => a.isFeatured)} expandedDropdownId={expandedDropdownId} onToggleDropdown={this.onToggleDropdown} />

        {productCategories.map(category => (
          <CategoryDropDown key={category.id} {...category} expandedDropdownId={expandedDropdownId} onToggleDropdown={this.onToggleDropdown}/>
        ))}
        
      </nav>
    )
  }
});

const Navbar = ({productCategories, activities}) => {
  return (
    <div id="Navbar">
      <NavbarSearch />
      <NavbarMenu productCategories={productCategories} activities={activities}/>
    </div>
  );
}

export default connect((state, ownProps) => {
  const {productCategories, activities} = state;

  return {
    productCategories,
    activities,
    ...ownProps
  }
}, {

})(Navbar);
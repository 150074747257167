export const PRODUCT_CATEGORIES = {
  FETCH: {
    REQUEST: 'PRODUCT_CATEGORIES.FETCH.REQUEST',
    RECEIVE: 'PRODUCT_CATEGORIES.FETCH.RECEIVE',
    FAILURE: 'PRODUCT_CATEGORIES.FETCH.FAILURE'
  },
  GET: 'PRODUCT_CATEGORIES.GET'
};

export const requestProductCategories = () => {
  return {
    type: PRODUCT_CATEGORIES.FETCH.REQUEST,
  }
}

export const receiveProductCategories = (items) => {
  return {
    type: PRODUCT_CATEGORIES.FETCH.RECEIVE,
    items
  }
}

export const getProductCategories = () => {
  return {
    type: PRODUCT_CATEGORIES.GET
  }
}

export const failedProductCategories = (error) => {
  return {
    type: PRODUCT_CATEGORIES.FETCH.FAILURE,
    error
  }
}
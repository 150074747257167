import { PRODUCT_OPTIONS } from '../actions';

const initState = [];

const productOptions = (state = initState, action) => {
  switch(action.type) {
    case PRODUCT_OPTIONS.FETCH.REQUEST:
      return [
        ...state.filter(o => action.productOptionIds.indexOf(o.id) === -1),
        ...action.productOptionIds.map(id => ({
          id,
          state: "requested",
          selections: [],
          
        }))
      ]
    case PRODUCT_OPTIONS.FETCH.RECEIVE:
      return [
        ...state.filter(o => action.items.map(i => i.id).indexOf(o.id) === -1),
        ...action.items.map(item => ({
          ...item,
          state: "fetched"
        })),
      ];
      
    default: 
      return state;
  }
};


export default productOptions;
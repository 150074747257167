export const ROUTE = {
  PARAMS: {
    SET: 'ROUTE.PARAMS.SET'
  },
};

export const setParams = (params) => {
  return {
    type: ROUTE.PARAMS.SET,
    params
  }
}
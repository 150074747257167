export const USER = {
  FETCH: {
    REQUEST: 'USER.FETCH.REQUEST',
    RECEIVE: 'USER.FETCH.RECEIVE',
    FAILURE: 'USER.FETCH.FAILURE'
  },

  CREATE: {
    REQUEST: 'USER.CREATE.REQUEST',
    RECEIVE: 'USER.CREATE.RECEIVE',
    FAILURE: 'USER.CREATE.FAILURE'
  },

  CUSTOMER_ACCESS: {
    ADD: {
      INIT: 'USER.CUSTOMER_ACCESS.ADD.INIT',
      REQUEST: 'USER.CUSTOMER_ACCESS.ADD.REQUEST',
      SUCCESS: 'USER.CUSTOMER_ACCESS.ADD.SUCCESS',
      FAILURE: 'USER.CUSTOMER_ACCESS.ADD.FAILURE',
    },
    REMOVE: 'USER.CUSTOMER_ACCESS.REMOVE'
  },

  SELECT_CUSTOMER: 'SELECT_CUSTOMER',
  RESET: 'USER.RESET',
  GET: 'USER.GET',
  REFRESH: 'USER.REFRESH',

  CREATE_BUMP: 'USER.CREATE_BUMP'
};

export const requestUser = (data) => {
  return {
    type: USER.FETCH.REQUEST,
    data
  }
}

export const receiveUser = (data) => {
  return {
    type: USER.FETCH.RECEIVE,
    data
  }
}

export const failedUser = (data) => {
  return {
    type: USER.FETCH.FAILURE,
    data
  }
}

export const resetUser = () => ({
  type: USER.RESET
})

export const getUser = () => ({
  type: USER.GET,
})

export const refreshUser = () => ({
  type: USER.REFRESH,
})


export const createUserBump = () => ({
  type: USER.CREATE_BUMP
})

export const addCustomerAccess = (customerId, accessLevel) => {
  return {
    type: USER.CUSTOMER_ACCESS.ADD.INIT,
    customerId,
    accessLevel
  }
}

export const requestAddCustomerAccess = () => {
  return {
    type: USER.CUSTOMER_ACCESS.ADD.REQUEST,
  }
}

export const successAddCustomerAccess = () => {
  return {
    type: USER.CUSTOMER_ACCESS.ADD.SUCCESS,
  }
}

export const failedAddCustomerAccess = () => {
  return {
    type: USER.CUSTOMER_ACCESS.ADD.FAILURE,
  }
}

export function removeCustomerAccess(id) {
  return {
    type: USER.CUSTOMER_ACCESS.REMOVE,
    id
  }
}

export function selectCustomer(id) {
  return {
    type: USER.SELECT_CUSTOMER,
    id
  }
}
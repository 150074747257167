import React from 'react';
import { Link } from 'react-router-dom';

export class LinkAndScrollToTop extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    window.scrollTo(0, 0);

    if(this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const {onClick, children, ...linkProps} = this.props;

    return (
      <Link {...linkProps} onClick={this.onClick}>
        {children}
      </Link>
    )
  }
}
export const TAGS = {
  FETCH: {
    REQUEST: 'TAGS.FETCH.REQUEST',
    RECEIVE: 'TAGS.FETCH.RECEIVE',
    FAILURE: 'TAGS.FETCH.FAILURE'
  },
  GET: 'TAGS.GET'
};

export const requestTags = () => {
  return {
    type: TAGS.FETCH.REQUEST,
  }
}

export const receiveTags = (items) => {
  return {
    type: TAGS.FETCH.RECEIVE,
    items
  }
}

export const getTags = () => {
  return {
    type: TAGS.GET
  }
}

export const failedTags = (error) => {
  return {
    type: TAGS.FETCH.FAILURE,
    error
  }
}

export const TAG_CATEGORIES = {
  FETCH: {
    REQUEST: 'TAG_CATEGORIES.FETCH.REQUEST',
    RECEIVE: 'TAG_CATEGORIES.FETCH.RECEIVE',
    FAILURE: 'TAG_CATEGORIES.FETCH.FAILURE'
  },
  GET: 'TAG_CATEGORIES.GET'
};

export const requestTagCategories = () => {
  return {
    type: TAG_CATEGORIES.FETCH.REQUEST,
  }
}

export const receiveTagCategories = (items) => {
  return {
    type: TAG_CATEGORIES.FETCH.RECEIVE,
    items
  }
}

export const getTagCategories = () => {
  return {
    type: TAG_CATEGORIES.GET
  }
}

export const failedTagCategories = (error) => {
  return {
    type: TAG_CATEGORIES.FETCH.FAILURE,
    error
  }
}
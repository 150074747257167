import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import DayPicker from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'

import './UseDateInput.scss'

export default class UseDateInput extends React.Component {

  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  }


  render() {
    const { value, onChange, t, ...props } = this.props

    const momentToday = moment()
    const dateToday = momentToday.toDate()

    const momentValue = moment(value)
    const dateValue = momentValue.toDate()

    return (
      <div className="bg-white d-inline-block">
        <DayPicker
          selectedDays={dateValue}
          onDayClick={(day, { disabled }) => {
            if (disabled) {
              return
            }
            this.props.onChange(moment(day).format())
          }}
          initialMonth={momentValue.isValid() ? dateValue : dateToday}
          fromMonth={dateToday}
          disabledDays={[{
            before: dateToday
          }]}
          localeUtils={MomentLocaleUtils}
          locale={'sv-se'}
        />
      </div>
    )
  }
}
import { PRODUCT_OPTIONS } from '../actions';

const initState = [];

const productOptionIds = (state = initState, action) => {
  switch(action.type) {
    case PRODUCT_OPTIONS.FETCH.REQUEST:
      return [
        ...state,
        ...action.productOptionIds
      ];
    // case PRODUCT_OPTIONS.FETCH.RECEIVE:
    //   return [
    //     ...state,
    //     ...action.items.map(({id}) => id),
    //   ];
      
    default: 
      return state;
  }
};


export default productOptionIds;
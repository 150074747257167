import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Translate } from 'containers/translates'
import OrderSectionSheet, { OrderSectionSheetHeader, OrderSectionSheetBody } from './OrderSectionSheet'
import { SectionState } from './propTypes';
import LoadingIndicator from 'components/spinners'

import InnerDeliverySection from './InnerDeliverySection'

function DeliverySection({
  deliveryMethods,
  sectionState,
}) {
  return (
    <Translate ns="delivery">
      {({ t }) => (
        <OrderSectionSheet state={sectionState}>
          <OrderSectionSheetHeader no={4}>
            {t('heading')}
          </OrderSectionSheetHeader>
          <OrderSectionSheetBody>
            {sectionState !== 'disabled' && (
              deliveryMethods.length > 0 ?
                <InnerDeliverySection /> :
                <LoadingIndicator />
            )}
          </OrderSectionSheetBody>
        </OrderSectionSheet>
      )}
    </Translate>
  )
}

DeliverySection.propTypes = {
  // Own props
  sectionState: SectionState,

  // Redux props
  deliveryMethods: PropTypes.array.isRequired
}


export default connect(state => ({
  deliveryMethods: state.order.availableDeliveryMethods,
}))(DeliverySection)
import React from 'react';
import { translateFactory } from 'containers/translates';

import Modal, { OVERFLOWS } from './Modal';
import GetStarted from 'containers/steps/GetStarted';

const UserGetStartedTranslate = translateFactory('user.getStarted');

export default function GetStartedModal({ onClose, ...props }) {
  return (
    <Modal overflow={OVERFLOWS.VISIBLE} onClose={onClose} {...props}>
      <div style={{ width: '70vw', maxWidth: 800 }}>
        <UserGetStartedTranslate>
          {({ t }) => (
            <div>
              <h2>{t('header')}</h2>
              <hr />
              <div className="d-flex justify-content-center" >
                <div style={{ width: 500, maxWidth: "100%" }}>
                  <GetStarted onDone={onClose} t={t} />
                </div>
              </div>
            </div>
          )}
        </UserGetStartedTranslate>
      </div>
    </Modal>
  );
}
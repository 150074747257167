import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';
import BlockArea from '../BlockArea';

import find from 'lodash/find';

import { OneColumnLayout } from '../../components/layouts';
import DocumentMeta from '../../components/documentMeta';

import ActivitySlider from '../../components/ActivitySlider';

import { scrollToTop } from '../hoc';

import {
  getFeaturedRootProductCategories,
  getFeaturedActivities
} from '../../selectors';
import FeaturedProductCategory from '../../components/FeaturedProductCategory';

import routes from '../../routes';

import GridArea from 'components/Grid/GridArea';

export default scrollToTop()(connect(state => ({
  featuredRootProductCategories: getFeaturedRootProductCategories(state),
  featuredActivities: getFeaturedActivities(state),
  blockPage: find(state.blockPages, p => p.slug === 'home')
}))(translate('home')(({ featuredRootProductCategories, featuredActivities, blockPage, t }) => {
  const blockConditions = ['page:home'];

  return (
    <div>
      <DocumentMeta
        title={blockPage ? blockPage.title || blockPage.name : t('title')}
        description={blockPage ? blockPage.description : t('description')}
        url={routes.HOME.get()}
      />
      <GridArea
        areaSlug={"page-hero"}
        conditions={[blockConditions]}
      />

      <div className="mt-4">
        <OneColumnLayout>
          <BlockArea areaSlug="main-top" conditions={blockConditions} />

          <div>
            {featuredRootProductCategories.map(category => (
              <FeaturedProductCategory
                key={category.id}
                {...category}
              />
            ))}
          </div>

          <div className="mt-4">
            <h2>{t('activitySlider.header')}</h2>
            <ActivitySlider activities={featuredActivities} />
          </div>

          <div>
            <GridArea
              areaSlug={"main"}
              conditions={[blockConditions]}
            />
          </div>

          <div className="mt-4">
            <BlockArea
              areaSlug="main"
              conditions={blockConditions}
            />
          </div>
        </OneColumnLayout>
      </div>
    </div>
  );
})));
export default {
  signIn: {
    header: "Personalinloggning",
    email: {
      placeholder: "E-post",
    },
    password: {
      placeholder: "Lösenord"
    },
    submit: "Logga in",
    button: {
      label: "Personalinloggning"
    }
  },
  signOut: {
    button: {
      label: "Logga ut"
    }
  }

}
export const CUSTOMER = {
  FETCH: {
    REQUEST: 'CUSTOMER.FETCH.REQUEST',
    RECEIVE: 'CUSTOMER.FETCH.RECEIVE',
    FAILURE: 'CUSTOMER.FETCH.FAILURE'
  },

  CREATE: {
    REQUEST: 'CUSTOMER.CREATE.REQUEST',
    RECEIVE: 'CUSTOMER.CREATE.RECEIVE',
    FAILURE: 'CUSTOMER.CREATE.FAILURE'
  },

  CREATE_AND_FETCH: 'CUSTOMER.CREATE_AND_FETCH',
  EDIT_AND_FETCH: 'CUSTOMER.EDIT_AND_FETCH',
  RESET: 'CUSTOMER.RESET',

  SET_AND_FETCH: 'CUSTOMER.SET_AND_FETCH',
  SET_TYPE: 'CUSTOMER.SET_TYPE',
  REFRESH: 'CUSTOMER.REFRESH',
};


export const createAndFetchCustomer = (data) => {
  return {
    type: CUSTOMER.CREATE_AND_FETCH,
    data
  }
}

export const editAndFetchCustomer = (id, data) => {
  return {
    type: CUSTOMER.EDIT_AND_FETCH,
    id, data
  }
}

export const refreshCustomer = () => {
  return {
    type: CUSTOMER.REFRESH
  }
}

export const requestCustomer = (data) => {
  return {
    type: CUSTOMER.FETCH.REQUEST,
    data
  }
}

export const receiveCustomer = (data) => {
  return {
    type: CUSTOMER.FETCH.RECEIVE,
    data
  }
}

export const failedCustomer = (data) => {
  return {
    type: CUSTOMER.FETCH.FAILURE,
    data
  }
}

export const resetCustomer = () => ({
  type: CUSTOMER.RESET
})

export const setAndFetchCustomer = (id) => ({
  type: CUSTOMER.SET_AND_FETCH,
  id
})

export const setCustomerType = (customerType) => ({
  type: CUSTOMER.SET_TYPE,
  customerType
})
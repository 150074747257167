import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Grid.scss'

export const
  LAYOUT_1 = 'layout1',
  LAYOUT_2 = 'layout2',
  LAYOUT_3 = 'layout3',
  LAYOUT_4 = 'layout4',
  LAYOUTS = [
    LAYOUT_1,
    LAYOUT_2,
    LAYOUT_3,
    LAYOUT_4,
  ]

export default class Grid extends Component {
  static propTypes = {
    children: PropTypes.node,
    layout: PropTypes.oneOf(LAYOUTS)
  }

  render() {
    return (
      <div className={classNames(
        'grid',
        `--layout-${this.props.layout}`
      )}>
        {this.props.children}
      </div>
    )
  }
}
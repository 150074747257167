import React from 'react';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';

import { CUSTOMER_TYPES } from 'selectors/customer';
import { orderApi } from 'api';

import CustomerForm from 'components/forms/CustomerForm';
import { translateFactory } from 'containers/translates';

const Translate = translateFactory('customer.company.create');

export default class CreateCompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCreated = this.onCreated.bind(this);
  }

  onSubmit(inputValues) {

    return this.props.onSubmit({
      ...inputValues,
      type: CUSTOMER_TYPES.COMPANY
    })
    // return orderApi.createCustomer({
    //   ...inputValues,
    //   type: CUSTOMER_TYPES.COMPANY
    // })
    //   .then(resp => head(resp.createdIds))
    //   .then(this.onCreated);
  }

  onCreated(customerId) {
    this.props.onCreated(customerId)
  }

  render() {
    const { prefilledInputValues = {}, t } = this.props;

    return (

      <CustomerForm
        prefilledInputValues={prefilledInputValues}
        fields={['name', 'registrationNumber']}
        onSubmit={this.onSubmit}
        t={t}
      />
    )
  }
}
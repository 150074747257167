import React from 'react';
import { LoadingLayerContainer } from 'components/LoadingLayer';

const FilterDropdown = ({
  label,
  fetchOptions,
  toggleValueSelection,
  selectedValues,
  immediateFetch = false,
  t
}) => {

  const [isOpen, toggle] = React.useState(false)
  const close = React.useCallback(() => toggle(false), [toggle])

  const [options, setOptions] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (immediateFetch || isOpen) {
      setIsLoading(true)
      fetchOptions()
        .then((nextOptions) => {
          setOptions(nextOptions)
          setIsLoading(false)
        })
    }
  }, [immediateFetch, isOpen, fetchOptions, setIsLoading, setOptions])

  const outerElementRef = React.useRef(null);

  const handleMaybeOutsideClick = React.useCallback((e) => {
    if (isOpen && outerElementRef.current && !outerElementRef.current.contains(e.target)) {
      close()
    }
  }, [isOpen, outerElementRef, toggle])

  React.useEffect(() => {
    document.addEventListener("mousedown", handleMaybeOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleMaybeOutsideClick);
    };
  }, [handleMaybeOutsideClick]);

  const _label = React.useMemo(() => {
    if (typeof label === 'function') {
      return label(options && selectedValues ? options.filter((option) => selectedValues.includes(option.value)) : [])
    }
    return label
  }, [label, options])

  return (
    <div className={`dropdown`} ref={outerElementRef}>
      <button type="button" className={`btn btn-no-focus btn-white-background btn-rounded dropdown-toggle`} onClick={toggle}>{_label}</button>

      <div className={`dropdown-menu dropdown-menu-left ${isOpen ? "show" : ""}`}>
        <LoadingLayerContainer isLoading={isLoading}>
          {options !== null ? (
            <React.Fragment>
              {options.map((option) => {
                const isSelected = selectedValues.includes(option.value)

                return (
                  <div
                    key={option.value}
                    className={`dropdown-item ${isSelected ? "selected" : ""} ${option.count !== undefined && option.count === 0 ? "not-available" : ""}`}
                    onClick={() => toggleValueSelection(option.value)}
                  >
                    <div style={{
                      display: "flex"
                    }}>
                      <span>
                        {option.label}{option.count !== undefined && <React.Fragment> ({option.count})</React.Fragment>}
                      </span>
                      {isSelected && <span style={{ marginLeft: "auto" }}>
                        <i className="fa fa-check" />
                      </span>}
                    </div>
                  </div>
                )
              })}
            </React.Fragment>
          ) : <div style={{ height: 100 }} />}
        </LoadingLayerContainer>
      </div>
    </div>
  );
};

export default FilterDropdown
import auth from './auth'
import activities from './activities'
import blocks from './blocks'
import blockPages from './blockPages'
import customer from './customer'
import productCategories from './productCategories'
import productAttributes from './productAttributes'
import productOptions from './productOptions'
import productOptionIds from './productOptionIds'
import products from './products'
import selectedProduct from './selectedProduct'
import selectedProductHistory from './selectedProductHistory'
import { fieldSelections, productFields } from './fields'

import order from './order'
import orderSubmitState from './orderSubmitState'
import messages from './messages'
import modals from './modals'

import route from './route'
import userSettings from './userSettings'
import user from './user'

import { tags, tagCategories } from './tags'

export default {
  auth,
  activities,
  blocks,
  blockPages,
  customer,
  productCategories,
  productAttributes,
  productOptions,
  productOptionIds,
  products,
  selectedProduct,
  selectedProductHistory,
  order,
  orderSubmitState,
  messages,
  modals,
  route,
  userSettings,
  user,
  fieldSelections, productFields,
  tags,
  tagCategories,
}
import { PRODUCT_ATTRIBUTES } from '../actions';

const productAttributes = (state = [], action) => {
  switch(action.type) {
    case PRODUCT_ATTRIBUTES.FETCH.RECEIVE:
      return [
        ...action.items
      ];
    default:
      return state;
  }
}

export default productAttributes;
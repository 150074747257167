import React from 'react';

import { cleanArray } from 'utils/array';
import './layouts.scss';

const LayoutWrapper = ({children}) => (
  <div className="px-2 px-sm-4 py-4">
    {children}
  </div>
)

export const TwoColumnLayout = ({columnOne, columnTwo}) => (
  <LayoutWrapper>
    <div className="row">
      <div className="col-xs-12 col-md-8 MainColumn">
        <main>
          {columnOne}
        </main>
      </div>
      <div className="col-xs-12 col-md-4 SideColumn">
        <hr className="d-md-none mt-4"/>
        <aside>
          {columnTwo}
        </aside>
      </div>
    </div>
  </LayoutWrapper>
)

export const OneColumnLayout = ({children}) => (
  <LayoutWrapper>
    <main>
      {children}
    </main>
  </LayoutWrapper>
)

export const Sheet = ({children, className, state = 'init', ...props}) => {
  return (
    <div className={cleanArray(['Sheet', className, state && `--${state}`]).join(' ')} {...props}>
      {children}
    </div>
  );
}

export const SheetHeader = ({children, className, ...props}) => {
  return (
    <div className={cleanArray(['Sheet_Header', className]).join(' ')} {...props}>
      {children}
    </div>
  );
}

export const SheetBody = ({children, className, ...props}) => {
  return (
    <div className={cleanArray(['Sheet_Body', className]).join(' ')} {...props}>
      {children}
    </div>
  );
}
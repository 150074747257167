export const cleanObject = (obj, moreRemovableValues = []) => {

  let objCopy = {
    ...obj
  };

  const removableValues = [
    ...moreRemovableValues,
    null,
    undefined
  ];

  for (var propName in obj) {
    if (removableValues.indexOf(objCopy[propName]) !== -1) {
      delete objCopy[propName];
    }
  }

  return objCopy;
}

export const isObject = (obj) => typeof obj === 'object' && obj.constructor === Object;
export const isEmpty = (obj) => isObject(obj) && Object.keys(obj).length === 0;


export const extractNamespacedKeys = (rootObj, namespace) => {
  let newObj = {};
  let restObj = {};

  const regexp = new RegExp(`${namespace}(\\[(\\d+)\\])?.([\\w]+)`);

  for (const key in rootObj) {
    const regexResult = key.match(regexp) || [null, null];
    const [_1, _2, arrayIndex, subKey] = regexResult;

    if(subKey) {
      newObj[subKey] = rootObj[key];
    }
    else {
      restObj[key] = rootObj[key];
    }
  }

  return [
    newObj,
    restObj
  ];
}

export const createNamespacedObject = (obj, namespace, arrayIndex = false) => {
  return Object.keys(obj).reduce((accum, key) => ({
    ...accum,
    [`${namespace}${arrayIndex !== false ? `[${arrayIndex}]` : ""}.${key}`]: obj[key]
  }), {})
}
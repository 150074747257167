import React from 'react';
import isEqual from 'lodash/isEqual';
import { withRouter } from 'react-router-dom'
import { getLocation } from '../utils/location';
import { isEmpty } from '../utils/object';
import { matches } from '../utils/elements';


import { fetchBlocks } from '../api';
import { waitUntilPromiseIsResolved, makeSingleToggable } from './hoc';

import Markdown from '../components/Markdown';

import './BlockArea.scss';
import { getFromStorage } from 'utils/storage';

const debugBlocks = getFromStorage('configs', '').indexOf('debugBlocks') !== -1

class _Block extends React.Component {
  handleClick = (e) => {
    const clickedElem = e.target;

    if (matches(clickedElem, 'a[href]')) {
      const clickedElemHref = clickedElem.getAttribute('href');
      const hasSameHost = clickedElem.host === getLocation().host;
      const hasInternalUrl = !clickedElemHref.match(/https?:\/\//);

      if (hasSameHost || hasInternalUrl) {
        this.props.history.push(clickedElemHref);
        e.preventDefault();
        return false;
      }
    }
  }


  render() {
    const {
      content,
      history, location, match, staticContext,
      ...props
    } = this.props;

    return (
      <Markdown
        content={content}
        onClick={this.handleClick}
        {...props}
      />
      // <div className="d-inline" ref={(div) => this.elem = div}>
      // </div>
    );
  }
}

export const Block = withRouter(_Block);

const DefaultBlockWrapper = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);


export const connectToFetchingBlocks = waitUntilPromiseIsResolved(
  ({ areaSlug, conditions, context }) => fetchBlocks([areaSlug], conditions, context),
  data => ({ blocks: data }),
  (prevProps, currentProps) => !isEqual(currentProps.conditions, prevProps.conditions),
  { id: "fetching-blocks" }
)


const BlockAreaInfo = makeSingleToggable(({ areaSlug, conditions, context, blocks, defaultContent, isActive, toggle }) => (
  <div className="BlockAreaInfo">
    <button style={{}} onClick={() => toggle()}>
      <i className="fa fa-info" />
    </button>
    {
      isActive &&
      <div className="BlockAreaInfoContent">
        <h2>Area slug: {areaSlug}</h2>
        <div>
          Blocks: {blocks.length}
        </div>
        {
          conditions && conditions.length > 0 &&
          <div className="mt-3">
            <h3>Conditions</h3>
            <div>
              {conditions.map(c => (
                <div key={c}>{c}</div>
              ))}
            </div>
          </div>
        }

        {
          !isEmpty(context) &&
          <div className="mt-3">
            <h3>Context</h3>
            <div>
              {Object.keys(context).map(contextKey => (
                <div key={contextKey}>
                  <strong>{contextKey}</strong>: {context[contextKey]}
                </div>
              ))}
            </div>
          </div>
        }

        {
          defaultContent &&
          <div className="mt-3">
            <h3>Default</h3>
            {defaultContent}
          </div>
        }
      </div>
    }
  </div>
));

const BlockArea = ({ blocks, wrapperClassName, blockClassName, blockStyle = {}, areaSlug, conditions = [], context = {}, WrapperComponent = DefaultBlockWrapper, defaultContent = null, markdownOptions = {}, ...wrapperComponentProps }) => {

  return (
    <div className={`BlockArea ${debugBlocks && '--debug'} ${wrapperClassName || ''}`}>
      {
        blocks.length > 0 ?
          <WrapperComponent {...wrapperComponentProps}>
            {blocks.map(({ id, content }) => (
              <Block key={id} className={blockClassName} style={blockStyle} content={content} markdownOptions={markdownOptions} />
            ))}
          </WrapperComponent> :
          <div>
            {defaultContent}
          </div>
      }
      {
        debugBlocks &&
        <BlockAreaInfo areaSlug={areaSlug} conditions={conditions} context={context} blocks={blocks} defaultContent={defaultContent} />
      }
    </div>
  );
}

export default connectToFetchingBlocks(BlockArea);
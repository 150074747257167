import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { fetchGrids } from 'api'
import { waitUntilPromiseIsResolved } from 'containers/hoc';

import GridFromData from './GridFromData'

const connectToFetchingGrids = waitUntilPromiseIsResolved(
  ({ areaSlug, conditions }) => {
    return fetchGrids([areaSlug], conditions)
  },
  (data) => ({ grids: data.items }),
)

class GridArea extends Component {
  static propTypes = {
    areaSlug: PropTypes.string,
    grids: PropTypes.array,
  }

  static defaultProps = {
    grids: []
  }

  render() {
    return (
      <React.Fragment>
        {this.props.grids.map((grid) => (
          <GridFromData
            key={grid.id}
            {...grid}
          />
        ))}
      </React.Fragment>
    )
  }
}

export default connectToFetchingGrids(GridArea)

import React from 'react';

import { waitUntilPromiseIsResolved } from '../containers/hoc';
import { fetchProducts } from '../api';
import { sortBy } from '../utils/array';

import { ProductArchiveItem } from './ProductArchive';
import SlidesBox from './SlidesBox';


function ProductSlidesBox({ header, products, slidesToShow, listType, onClickProduct, ...props }) {
  return (
    <SlidesBox
      header={header}
      itemRenderer={(product) => (
        <ProductArchiveItem
          product={product}
          size="small"
          imageUrl={product.image && product.image.processedUrls.archiveSmall}
          listType={listType}
          onClick={onClickProduct}
        />
      )}
      items={products}
      slidesToShow={slidesToShow}
      {...props}
    />
  );
}

export default waitUntilPromiseIsResolved(
  ({ productIds }) => {
    return fetchProducts({
      productIds: productIds,
      imageVariants: {
        archiveSmall: {
          h: 120
        },
      }
    });
  },
  (data, { productIds }) => {
    return {
      products: sortBy(data !== null ? data.items || [] : [], p => productIds.indexOf(p.id))
    }
  },
  null,
  { id: "Product-slides-box" }
)(ProductSlidesBox);
import React from 'react';
import PropTypes from 'prop-types'

export default class Textarea extends React.Component {
  componentDidMount() {
    if (this.props.focusOnMount) {
      this.el.focus();
    }
  }

  render() {
    const {
      inputName = null,
      placeholder,
      value,
      onChange,
      extras,
      focusOnMount,
      className,
      ...rest
    } = this.props

    const classNames = [
      className,
      'form-control',
      ...extras.map(x => `form-control-${x}`),
    ]

    return (
      <textarea
        {...rest}
        rows="3" cols="20"
        name={inputName}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={classNames.join(" ")}
        ref={(el) => { this.el = el }}
      />
    );
  }
}

Textarea.defaultProps = {
  className: '',
  extras: []
}

Textarea.propTypes = {

}
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ImageFromProcessedUrls } from './Image'

import './Card.css'

export default function Card({ children, size, onClick }) {
  const className = classNames(
    'TheCard',
    {
      [`--${size}`]: size !== undefined,
      [`--clickable`]: onClick !== undefined
    }
  )

  return (
    <div
      className={className}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  onClick: PropTypes.func
}

export function CardImage({ image }) {
  const className = classNames(
    'TheCard__Image'
  )

  return (
    <div className={className}>
      <ImageFromProcessedUrls {...image} />
    </div>
  )
}

CardImage.propTypes = {
  image: PropTypes.object.isRequired
}

export function CardIcon({ children }) {
  const className = classNames(
    'TheCard__Icon'
  )

  return (
    <div className={className}>
      {children}
    </div>
  )
}

CardIcon.propTypes = {
  children: PropTypes.node
}


export function CardBody({ children }) {
  const className = classNames(
    'TheCard__Body'
  )

  return (
    <div className={className}>
      {children}
    </div>
  )
}

CardBody.propTypes = {
  children: PropTypes.object.isRequired
}

export function CardBodyHeader({ text, Component }) {
  const className = classNames(
    'TheCard__Body__Header',
    'text-primary',
  )

  return (
    <Component className={className}>
      {text}
    </Component>
  )
}

CardBodyHeader.propTypes = {
  text: PropTypes.string.isRequired,
  Component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object
  ]).isRequired
}

CardBodyHeader.defaultProps = {
  Component: "h3"
}


export function CardList({ children, className: extraClassName }) {
  const className = classNames(
    'CardList',
    extraClassName
  )

  return (
    <div className={className}>
      {children}
    </div>
  )
}

CardList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

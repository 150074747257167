import React from 'react';
import Transition from 'react-transition-group/Transition';

import { ToggleState } from '../containers/hoc';

import isEqual from 'lodash/isEqual';

import './transitions.scss';

export const VerticalSlide = ({ children, ...props }) => (
  children
);

export function ToggledTransition({ children, ...props }) {
  return (
    <ToggleState>
      {({ toggle, isCurrent }) => (
        <Transition in={isCurrent(true)}  {...props}>
          {(state) => (
            children({
              toggle,
              state
            })
          )}
        </Transition>
      )}
    </ToggleState>
  )
}

export class StateChange extends React.Component {

  maybeRunOnChange(state1, state2) {
    if (!isEqual(state1, state2)) {
      this.props.onChange();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.maybeRunOnChange(this.props.state, nextProps.state);
  }

  render() {
    return this.props.children;
  }
}

export function TransitionWhenStateChanges({ children, state, ...transitionProps }) {
  return (
    <ToggleState>
      {({ toggle, isCurrent }) => {
        return (
          <Transition in={isCurrent(true)}  {...transitionProps}>
            {(transitionState) => {
              return (
                <StateChange onChange={toggle} state={state}>
                  {children({transitionState})}
                </StateChange>
              )
            }}
          </Transition>
        )
      }}
    </ToggleState>
  )
}
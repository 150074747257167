import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { toDate } from 'utils/date'

import find from 'lodash/find'
import get from 'lodash/get'
import templateCompiler from 'lodash/template'
import intersection from 'lodash/intersection'
import keys from 'lodash/keys'

import { caseSelector } from 'utils/helpers'

import Input from 'components/forms/Input'

import { PRICE_TYPES } from './constants'
import { Event } from './props'

import ProductField from 'components/Product/ProductField'
import FieldInputsContext from 'components/fields/FieldInputsContext'


class PriceTypeProductField extends React.Component {
  static propTypes = {
    productField: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    eventContestPriceTypeBundles: PropTypes.array.isRequired,
    onInput: PropTypes.func.isRequired,
    input: PropTypes.object,
  }

  static defaultProps = {
    input: {}
  }

  handleInput = (input) => {
    this.props.onInput(input)
  }

  renderDefaultProductField = () => {
    return (
      <ProductField
        productFieldId={this.props.productField.id}
        fieldId={this.props.productField.fieldId}
        input={this.props.input}
        onChange={this.handleInput}
        onReset={() => { }}
      />
    )
  }

  renderLongTextProductField = () => {

    const eventName = this.props.event.name
    const eventYear = toDate(this.props.event.date).getFullYear()

    const eventContestName = `<${this.props.eventContestPriceTypeBundles.slice(0, 3).map((eventContestPriceTypeBundle) => eventContestPriceTypeBundle.contestName).join(", ")}${this.props.eventContestPriceTypeBundles.length > 3 ? " .." : ""}>`
    const eventContestPosition = "<1:a, 2:a, ..>"

    const priceType = this.props.eventContestPriceTypeBundles[0].type

    const sharedContext = {
      eventName,
      eventYear,
      eventContestName,
    }

    const priceTypeContext = caseSelector({
      [PRICE_TYPES.ROWS]: {
        ...sharedContext,
        eventContestPosition
      },
      [PRICE_TYPES.COPIES]: {
        ...sharedContext
      },
      [PRICE_TYPES.ROWS_AND_COPIES]: {
        ...sharedContext,
        eventContestPosition
      },
    })(priceType)

    const priceTypeContextKeys = keys(priceTypeContext)

    const textTemplates = (this.props.productField.textTemplates || [])
      .filter(
        (textTemplate) => {
          const variableNames = textTemplate.variables.map(({ name }) => name)
          const contextKeysIntersection = intersection(variableNames, priceTypeContextKeys)
          return contextKeysIntersection.length >= variableNames.length
        }
      )

    if (textTemplates.length === 0) {
      return this.renderDefaultProductField()
    }

    return (
      <React.Fragment>
        <h3>{this.props.productField.label || this.props.productField.name}</h3>
        <hr />
        {
          textTemplates.map(textTemplate => {
            // const renderedTextTemplatePlaceholder = templateCompiler(textTemplate.template)(priceTypeContext)
            const isSelected = get(this.props.input, 'textTemplateId') === textTemplate.id

            const exampleContext = {
              eventName,
              eventYear,
              eventContestName: this.props.eventContestPriceTypeBundles[0].contestName,
              eventContestPosition: "1:a"
            }

            const placeholderContext = textTemplate.variables.reduce((contextAccum, variable) => ({
              ...contextAccum,
              [variable.name]: `<${variable.label}>`
            }), {})

            const renderedTextTemplateWithPlaceholders = templateCompiler(textTemplate.template)(placeholderContext)
            const renderedTextTemplateExample = templateCompiler(textTemplate.template)(exampleContext)


            return (
              <div className="form-check my-2" key={textTemplate.id}>
                <Input
                  type="radio"
                  id={`text-template-${textTemplate.id}`}
                  className="form-check-input"
                  addFormControlClassName={false}
                  onChange={() => this.handleInput({
                    textTemplate,
                    textTemplateId: textTemplate.id,
                    value: renderedTextTemplateExample,
                  })}
                  checked={isSelected}
                />
                <label
                  htmlFor={`text-template-${textTemplate.id}`}
                  className="form-check-label d-flex"
                >
                  <div>
                    <p style={{ whiteSpace: "pre-wrap" }}>{renderedTextTemplateWithPlaceholders}</p>
                  </div>
                  <div className="ml-3" style={{ opacity: 0.6 }}>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      <i>{renderedTextTemplateExample}</i>
                    </p>
                  </div>
                </label>
              </div>
            )
          })
        }
      </React.Fragment>
    )
  }

  render() {
    return (
      caseSelector(
        {
          long_text: this.renderLongTextProductField,
        },
        this.renderDefaultProductField
      )(this.props.productField.type)()
    )
  }
}


class PriceTypeProductFields extends React.Component {
  static propTypes = {
    onFieldInput: PropTypes.func.isRequired,
    savedFieldInputs: PropTypes.array.isRequired,
    event: PropTypes.shape(Event.propTypes).isRequired,
    productFields: PropTypes.arrayOf(PropTypes.object).isRequired,
    eventContestPriceTypeBundles: PropTypes.array.isRequired,
    children: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = props.productFields.reduce((inputsAccum, productField) => {
      const savedFieldInput = find(props.savedFieldInputs, ({ fieldId }) => productField.fieldId === fieldId)

      if (savedFieldInput) {
        return {
          ...inputsAccum,
          [productField.id]: savedFieldInput.input
        }
      }

      return inputsAccum
    }, {})
  }

  handleProductFieldInput = (productFieldId, productFieldInput) => {
    this.setState({
      [productFieldId]: productFieldInput
    }, () => {
      if (this.props.onFieldInput) {
        const productField = find(this.props.productFields, (pf) => pf.id === productFieldId)
        this.props.onFieldInput(productField.fieldId, productFieldInput)
      }
    })
  }

  renderProductField = (productField) => {
    return (
      <PriceTypeProductField
        key={productField.id}
        event={this.props.event}
        eventContestPriceTypeBundles={this.props.eventContestPriceTypeBundles}
        productField={productField}
        onInput={(incomingInput) => this.handleProductFieldInput(productField.id, incomingInput)}
        input={get(this.state, productField.id)}
      />
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.props.productFields.map(this.renderProductField)}
        {this.props.children(this.state)}
      </React.Fragment>
    )
  }
}


export default connect((state, { productFieldIds }) => {
  return {
    productFields: state.productFields.filter((pf) => productFieldIds.indexOf(pf.id) !== -1)
  }
})(({ ...props }) => (
  <FieldInputsContext.Consumer>
    {(fieldInputsContext) => (
      <PriceTypeProductFields
        {...props}
        onFieldInput={fieldInputsContext.onFieldInput}
        savedFieldInputs={fieldInputsContext.fieldInputs}
      />
    )}
  </FieldInputsContext.Consumer>
))
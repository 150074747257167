import React from 'react'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'

import { caseSelector } from 'utils/helpers'
import Input from 'components/forms/Input'

export default class TextWidget extends React.Component {

  state = {
    value: '',
    hasMadeChanges: false
  }

  constructor(props) {
    super(props)
    this.debouncedExternalOnChange = debounce(this.externalOnChange, 200)
  }

  externalOnChange() {
    this.props.onChange(this.state.value)
  }

  handleChange = (value) => {
    this.setState(() => ({
      value,
      hasMadeChanges: true
    }), this.debouncedExternalOnChange)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasMadeChanges) {
      return {
        value: nextProps.value
      }
    }

    return null
  }

  render() {
    const { type, placeholder } = this.props

    return (
      <Input
        name="input"
        type={caseSelector({
          'long_text': 'textarea',
          'number': 'number'
        }, 'text')(type)}
        value={this.state.value}
        onChange={this.handleChange}
        placeholder={placeholder}
      />
    )
  }
}


TextWidget.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired
}

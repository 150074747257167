import React from 'react';
import Slider from '../containers/Slider';

import './SlidesBox.scss';


class SlidesBox extends React.Component {
  render() {
    const {
      header,
      items,
      itemRenderer,
      slidesToShow,
      boxed = false
    } = this.props;
    
    return (
      <div className={`SlidesBox ${boxed ? "--boxed" : ""}`}>
        <header className="text-center text-md-left">
          <h3 className="m-0">
            {header}
          </h3>
        </header>

        <Slider slidesToShow={slidesToShow} className="SliderArchive">
          {items.map(item => (
            <div key={item.id} className="SliderArchive_Item">
              {itemRenderer(item)}
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default SlidesBox;
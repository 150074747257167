import React from 'react';
import { withTranslation as translate } from 'react-i18next';

import { sortBy } from '../../utils/array';
import { formatPrice } from '../../utils/content';

const ProductPriceAmountDiscountTable = translate('product')(({prices, minimumOrderAmount, t}) => {
  const sortedPrices = sortBy(
    prices,
    x => x.min_qty
  );

  return (
    <table className="table table-compact">
      <thead>
        <tr>
          {sortedPrices.map(({min_qty}, priceIndex) => (
            <th key={priceIndex}>
              {Math.max(min_qty, minimumOrderAmount)}-{priceIndex + 1 < sortedPrices.length ? (sortedPrices[priceIndex + 1].min_qty - 1) : ''}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {sortedPrices.map(({price}, priceIndex) => (
            <td key={priceIndex}>
              {formatPrice(price, 'kr')}
            </td>
          ))}
        </tr>
      </tbody>
      
    </table>
  )
});

export default ({price, minimumOrderAmount}) => {
  return (
    price.data.length > 1 ? 
      <ProductPriceAmountDiscountTable prices={price.data} minimumOrderAmount={minimumOrderAmount}/> : 
      <div className="h5">
        {formatPrice(price.data[0].price, 'kr')}
      </div>
  )
}
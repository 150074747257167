import { SELECTED_PRODUCT } from '../actions';

const initState = [];

export default function selectedProductHistory(state = initState, action) {
  switch(action.type) {
    case SELECTED_PRODUCT.FETCH.RECEIVE:
      return [
        ...state.filter(obj => obj.product.id !== action.selectedProduct.id),
        {
          received: +(new Date()),
          product: action.selectedProduct,
        }
      ];
    default:
      return state;
  }
}
export const BLOCKS = {
  FETCH: {
    REQUEST: 'BLOCKS.FETCH.REQUEST',
    RECEIVE: 'BLOCKS.FETCH.RECEIVE',
    FAILURE: 'BLOCKS.FETCH.FAILURE'
  },
  GET: 'BLOCKS.GET'
};

export const requestBlocks = () => {
  return {
    type: BLOCKS.FETCH.REQUEST,
  }
}

export const receiveBlocks = (items) => {
  return {
    type: BLOCKS.FETCH.RECEIVE,
    items
  }
}

export const getBlocks = (areaSlugs = [], conditions=[]) => {
  return {
    type: BLOCKS.GET,
    areaSlugs, conditions
  }
}

export const failedBlocks = (error) => {
  return {
    type: BLOCKS.FETCH.FAILURE,
    error
  }
}
import React from 'react';

import { getToken } from 'api';
import { PromiseWrapper } from 'utils/promises';
import promiseHandler from 'containers/hoc/promiseHandler';

import SimplifiedForm from 'components/forms/SimplifiedForm';

export default promiseHandler(class GetToken extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit({ email, password }) {

    return new Promise((resolve, reject) => {
      this.setState(
        () => ({ errorMessage: null }),
        () => {

          if (this.props.onSubmit) {
            this.props.onSubmit();
          }

          this.props.makePromise(getToken(email, password))
            .then(({ token }) => {
              this.props.onSuccess(token);
              resolve({});
            })
            .catch((data) => {
              this.setState(
                () => ({ errorMessage: this.props.t('token.failedAccessing') }),
                () => {
                  if (this.props.onFailure) {
                    this.props.onFailure(data);
                  }
                  reject({});
                }
              )
            });
        }
      )
    })
  }

  componentWillUnmount() {
    if (this._promiseWrapper) {
      this._promiseWrapper.cancel();
    }
  }

  render() {
    const {
      t,
      email = null
    } = this.props;

    return (
      <SimplifiedForm
        t={t}
        inputs={[{
          name: "email",
          type: "email",
          required: true,
        }, {
          name: "password",
          type: "password",
          required: true,
          focusOnMount: true
        }]}
        prefilledInputValues={{ email }}
        showLoadingIndicator={false}
        onSubmit={this.onSubmit}
        errorMessage={this.state.errorMessage}
      />
    )
  }
})
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import find from 'lodash/find'

import { connect } from 'react-redux'


const ActivityIdContext = React.createContext(null)


function mapStateToProps(state) {

  const activitySlugInUserSettings = state.userSettings.activitySlug

  if (activitySlugInUserSettings !== null) {
    const activity = find(state.activities, (a) => a.slug === activitySlugInUserSettings)

    return {
      activityIdInUserSettings: activity !== undefined ? activity.id : null
    }
  }

  return {
    activityIdInUserSettings: null
  }
}

export const ActivityIdInUserSettingsProvider = connect(mapStateToProps)(function _ActivityIdInUserSettingsProvider({ activityIdInUserSettings, children }) {
  return (
    <ActivityIdContext.Provider value={activityIdInUserSettings}>
      {children}
    </ActivityIdContext.Provider>
  )
})

export default ActivityIdContext
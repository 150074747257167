import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes, { getProductCategorySlugFromParams } from 'routes';
import { getProductCategoryBySlug, getProductCategoryFlatTree, getFeaturedProductCategoryChildren, getProductCategoryFilterableGroupedProductPropertyValues } from 'selectors';
import { setUserActivity } from 'actions'
import { constructFetchProductsFilters } from 'api'
import { getQueryParamsFromLocation, toQueryUrl } from 'utils/url';
import { cleanObject } from '../../utils/object';

import { waitUntilSatisfyingState, scrollToTop } from '../hoc';
import ProductArchive from '../ProductArchive';

import { OneColumnLayout } from 'components/layouts';
import PageHeader from 'components/PageHeader';
import DocumentMeta from 'components/documentMeta';
import FeaturedProductCategory from 'components/FeaturedProductCategory';
import { ProductCategoryBreadcrumb } from 'components/ProductBreadcrumbs';
import GridArea from 'components/Grid/GridArea';
import ProductPropertyFilterDropdown from 'components/ProductPropertyFilterDropdown'
import ActivitiesFilterDropdown from 'components/ActivitiesFilterDropdown';
import ProductArchiveOrderingDropdown from 'components/ProductArchiveOrderingDropdown';

const ChildCategoryList = translate('category')(({ categories, getRoute, t }) => (
  <div>
    <p>
      <strong>{t('childCategories.label')}: </strong>

      {categories.map(childProductCategory => (
        <Link
          className="d-inline-block mr-1"
          key={childProductCategory.id}
          to={getRoute(childProductCategory)}
        >
          {childProductCategory.name}
        </Link>
      ))}
    </p>
  </div>
));

function ProductCategoryPage({
  history,
  location,
  productCategory,
  productCategoryTree,
  productCategoryFeaturedChildren,
  activities,
  userActivitySlug,
  setUserActivity,
  isSignedInAndStaff,
}) {
  const [openFilterId, setOpenProductPropertyFilterId] = React.useState(null)

  const toggleFilter = React.useCallback((productPropertyId, force) => {
    setOpenProductPropertyFilterId((current) => (
      force !== undefined ?
        (force === true ? productPropertyId : null) :
        (current !== productPropertyId ? productPropertyId : null)
    ))
  }, [setOpenProductPropertyFilterId])

  const closeAnyFilters = React.useCallback(() => {
    setOpenProductPropertyFilterId(null)
  }, [setOpenProductPropertyFilterId])

  const queryParams = React.useMemo(() => (
    getQueryParamsFromLocation(location)
  ), [location])

  const selectedProductPropertyValueIds = React.useMemo(() => (
    queryParams.productPropertyValueIds || []).map((idStr) => parseInt(idStr)
    ), [queryParams])

  const selectedActivitySlug = React.useMemo(() => (
    queryParams.activity
  ), [queryParams])

  const selectedOrdering = React.useMemo(() => (
    queryParams.ordering
  ), [queryParams])

  const selectedActivity = React.useMemo(() => activities && selectedActivitySlug ? activities.find((activity) => activity.slug === selectedActivitySlug) : undefined, [activities, selectedActivitySlug])

  const selectedProductPropertyValueBundles = React.useMemo(() => (
    productCategory.filterableProductProperties.reduce((acc, property) => {
      const selectedPropertyValues = property.values.filter((value) => selectedProductPropertyValueIds.includes(value.id))

      return [
        ...acc,
        ...selectedPropertyValues.map((propertyValue) => [property, propertyValue])
      ]
    }, [])
  ), [productCategory, selectedProductPropertyValueIds])

  const productFilters = React.useMemo(() => constructFetchProductsFilters({
    productCategory,
    groupedProductPropertyValueIds: getProductCategoryFilterableGroupedProductPropertyValues(
      productCategory,
      selectedProductPropertyValueIds
    ),
    activitySlug: selectedActivitySlug,
    excludeItemsWithoutCategory: isSignedInAndStaff ? false : true
  }), [productCategory, selectedProductPropertyValueIds, selectedActivitySlug])

  const productPropertyProductFiltersFactory = React.useMemo(() => (productProperty) => constructFetchProductsFilters({
    productCategory,
    groupedProductPropertyValueIds: getProductCategoryFilterableGroupedProductPropertyValues(
      productCategory,
      selectedProductPropertyValueIds.filter((id) => !productProperty.values.map(({ id }) => id).includes(id))
    ),
    activitySlug: selectedActivitySlug,
    excludeItemsWithoutCategory: isSignedInAndStaff ? false : true
  }), [productCategory, selectedProductPropertyValueIds, selectedActivitySlug])

  const changeUrlQueryParams = React.useCallback((nextPartialQueryParams, method = 'push') => {
    history[method]({
      search: `?${toQueryUrl(
        cleanObject({
          ...queryParams,
          ...nextPartialQueryParams,
        })
      )}`
    });
  }, [history, productCategoryTree])

  const changeSelectedActivity = React.useCallback((activitySlug) => {
    changeUrlQueryParams({
      activity: activitySlug
    })
  }, [changeUrlQueryParams])

  const changeSelectedOrdering = React.useCallback((ordering) => {
    changeUrlQueryParams({
      ordering
    })
  }, [changeUrlQueryParams])

  React.useEffect(() => {
    if (productCategory.usesActivities) {
      if (selectedActivitySlug) {
        if (selectedActivitySlug !== userActivitySlug) {
          setUserActivity(selectedActivitySlug)
        }
      }
      else if (userActivitySlug) {
        changeSelectedActivity(userActivitySlug)
      }
    }
  }, [productCategory, userActivitySlug, selectedActivitySlug, setUserActivity, changeSelectedActivity])


  const resetSelectedActivity = React.useCallback(() => {
    setUserActivity(null)
    changeSelectedActivity(undefined)
  }, [setUserActivity, changeSelectedActivity])

  const toggleProductPropertyValueIdIsSelected = React.useCallback((productPropertyValueId) => {
    const nextProductPropertyValueIds = selectedProductPropertyValueIds.includes(productPropertyValueId) ? selectedProductPropertyValueIds.filter((id) => id !== productPropertyValueId) : [...selectedProductPropertyValueIds, productPropertyValueId]
    changeUrlQueryParams({
      productPropertyValueIds: nextProductPropertyValueIds
    })
  }, [changeUrlQueryParams, selectedProductPropertyValueIds])


  const resetProductPropertyValueSelection = React.useCallback(() => {
    changeUrlQueryParams({
      productPropertyValueIds: undefined
    })
  }, [changeUrlQueryParams])

  const resetAllFilterSelections = React.useCallback(() => {
    if (productCategory.usesActivities) {
      setUserActivity(null)
    }
    changeUrlQueryParams({
      productPropertyValueIds: undefined,
      activity: undefined
    })
  }, [productCategory, resetSelectedActivity, resetProductPropertyValueSelection])

  const blockConditions = React.useMemo(() => ([
    'page:product-category',
    `product-category:${productCategory.id}`,
    `product-category:${productCategory.slug}`,
  ]), [productCategory])

  return (
    <div>
      <DocumentMeta
        title={productCategory.altTitle || productCategory.name}
        description={productCategory.altDescription || productCategory.description || ''}
        url={routes.PRODUCT_CATEGORY.get(productCategoryTree, queryParams)}
        imageUrl={productCategory.image ? productCategory.image.processedUrls.default : null}
      />
      <GridArea
        areaSlug={"page-hero"}
        conditions={[blockConditions]}
      />

      <OneColumnLayout>
        <div>
          <ProductCategoryBreadcrumb categoryTree={productCategoryTree.slice(0, -1)} />
          <PageHeader title={productCategory.name} description={productCategory.description || ""} />

          <div className="text-center text-md-left px-2 px-md-0">
            {
              productCategory.children.length > 0 &&
              <ChildCategoryList
                categories={productCategory.children}
                getRoute={(childProductCategory) => {
                  return routes.PRODUCT_CATEGORY.get([productCategory, childProductCategory], queryParams)
                }}
              />
            }
          </div>

          <div>
            {productCategoryFeaturedChildren.map(category => (
              <FeaturedProductCategory key={category.id} {...category} />
            ))}
          </div>

          <div>
            <hr />
            <div>
              {productCategory.usesActivities && <div className="d-inline-block mr-2 mb-2">
                <ActivitiesFilterDropdown
                  activities={activities}
                  selectedActivitySlug={userActivitySlug}
                  selectActivitySlug={changeSelectedActivity}
                  isOpen={openFilterId === "activities"}
                  toggle={() => toggleFilter("activities")}
                  close={closeAnyFilters}
                /></div>}
              {productCategory.filterableProductProperties.map((productProperty) => (
                <div className="d-inline-block mr-2 mb-2" key={productProperty.id}>
                  <ProductPropertyFilterDropdown
                    productProperty={productProperty}
                    selectedValueIds={selectedProductPropertyValueIds}
                    isOpen={openFilterId === productProperty.id}
                    toggle={() => toggleFilter(productProperty.id)}
                    close={closeAnyFilters}
                    productPropertyProductFiltersFactory={productPropertyProductFiltersFactory}
                    toggleProductPropertyValueIdIsSelected={toggleProductPropertyValueIdIsSelected}
                  />
                </div>
              ))}
            </div>
            <hr />
            <div className="mb-3" style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="mb-2" style={{ marginRight: "auto" }}>
                {productCategory.usesActivities && selectedActivity && (
                  <button
                    key={`activity-${selectedActivity.id}`}
                    className="btn btn-no-focus btn-light-grey-background btn-rounded mr-2"
                    onClick={resetSelectedActivity}
                  >
                    {selectedActivity.name} <span style={{ marginLeft: 10 }}><i className="fa fa-close" /></span>
                  </button>
                )}
                {selectedProductPropertyValueBundles.map(([, propertyValue]) => (
                  <button
                    key={propertyValue.id}
                    className="btn btn-no-focus btn-light-grey-background btn-rounded mr-2"
                    onClick={() => {
                      const nextProductPropertyValueIds = selectedProductPropertyValueIds.filter((id) => id !== propertyValue.id)
                      changeUrlQueryParams({
                        productPropertyValueIds: nextProductPropertyValueIds
                      })
                    }}
                  >
                    {propertyValue.value} <span style={{ marginLeft: 10 }}><i className="fa fa-close" /></span>
                  </button>
                ))}

                {(selectedActivitySlug || selectedProductPropertyValueBundles.length > 0) && <button className="btn btn-link" onClick={resetAllFilterSelections}>Rensa filtrering</button>}
              </div>
              <div>
                <ProductArchiveOrderingDropdown
                  selectedOrdering={selectedOrdering}
                  onSelect={changeSelectedOrdering}
                />
              </div>
            </div>
          </div>
          <div>
            <ProductArchive
              filters={productFilters}
              ordering={queryParams.ordering}
              blockConditions={blockConditions}
              listType={`product-category--${productCategoryTree.map(c => c.slug).join('/')}`}
            />
          </div>
        </div>
      </OneColumnLayout>
    </div>
  );
}

const mapWrapperStateToProps = (state, ownProps) => {
  const { activities, userSettings: { activitySlug: userActivitySlug }, auth, user } = state
  const { match: { params } } = ownProps;

  const productCategorySlug = getProductCategorySlugFromParams(params);
  const productCategory = getProductCategoryBySlug(state, productCategorySlug);

  const productCategoryTree = getProductCategoryFlatTree(state, productCategory.id);
  const productCategoryFeaturedChildren = getFeaturedProductCategoryChildren(state, productCategory.id);

  return {
    productCategory,
    productCategoryTree,
    productCategoryFeaturedChildren,
    activities,
    userActivitySlug,
    isSignedInAndStaff: auth.isSignedIn && user && user.type === 'staff',
  }
}

export default waitUntilSatisfyingState(
  state => (state.productCategories.length > 0),
  mapWrapperStateToProps,
  {
    setUserActivity
  },
)(scrollToTop()(ProductCategoryPage));
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import find from 'lodash/find';

export function caseSelector(cases, defaultCase) {
  return (caseKey, secondaryCaseKey = null) => {
    if (isArray(cases)) {
      try {
        const [caseKeys, caseValue] = find(cases, ([caseKeys]) => caseKeys.indexOf(caseKey) !== -1);
        return caseValue;
      } catch (error) {
        return defaultCase;
      }
    }
    else if (isObject(cases)) {
      return cases[caseKey] || (secondaryCaseKey && cases[secondaryCaseKey]) || defaultCase;
    }
    else {
      return defaultCase;
    }
  }
}
import auth from './auth'
import blockPage from './blockPage'
import category from './category'
import common from './common'
import customer from './customer'
import documentMeta from './documentMeta'
import form from './form'
import product from './product'
import search from './search'
import activities from './activities'
import productArchive from './productArchive'
import home from './home'
import routes from './routes'
import order from './order'
import signinOrRegister from './signinOrRegister'
import userSettings from './userSettings'
import user from './user'

export default {
  auth,
  blockPage,
  category,
  common,
  customer,
  documentMeta,
  form,
  product,
  search,
  activities,
  productArchive,
  home,
  routes,
  order,
  signinOrRegister,
  userSettings,
  user
}
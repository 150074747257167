import { createStore, applyMiddleware, compose } from 'redux'

import createSagaMiddleware from 'redux-saga';

import { persistStore, persistCombineReducers, createMigrate } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import { createResponsiveStateReducer, responsiveStoreEnhancer } from 'redux-responsive'

import reducers from '../reducers';
import messageCreator from '../middlewares/messageCreator';
import triggers from '../middlewares/triggers';
import orderSync from '../middlewares/orderSync';

import config from '../config'

const migrations = {
  0: (state) => {
    return {
      ...state,
      auth: undefined,
      customer: undefined,
      order: undefined,
      selectedProductHistory: undefined,
      userSettings: undefined,
      user: undefined,
    }
  },
}

const rootReducer = persistCombineReducers({
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'customer',
    'order',
    'selectedProductHistory',
    'userSettings',
    'user',
  ],
  version: 0,
  migrate: createMigrate(migrations, {
    debug: config.DEBUG
  })
}, {
    ...reducers,
    client: createResponsiveStateReducer({
      extraSmall: 576,
      small: 768,
      medium: 992,
      large: 1200,
      extraLarge: 5000,
    }),
  });

export default function configureStore() {

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    applyMiddleware(sagaMiddleware),
    applyMiddleware(messageCreator),
    responsiveStoreEnhancer,
  ];

  const haveReduxDevTools = !!window.__REDUX_DEVTOOLS_EXTENSION__;

  if (haveReduxDevTools) {
    middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }


  const store = createStore(
    rootReducer,
    undefined,
    compose(...middlewares)
  );

  let persistor = persistStore(store);

  return {
    store,
    persistor,
    runSaga: sagaMiddleware.run
  };
}

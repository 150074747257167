import '@babel/polyfill'
// import 'es5-shim'
// import 'es6-shim'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
// import { I18nextProvider } from 'react-i18next'
import * as Sentry from '@sentry/browser'

import configureStore from './store/configureStore'
import { rootSaga } from './sagas'

import './i18n'

import App from './containers/App'
import config from './config'

import './styles/index.scss'

if (config.ENABLE_SENTRY) {
  Sentry.init({
    dsn: "https://c948db067ffe4517a892e095f1e67050@sentry.io/1760868"
  })
}

function renderApp() {
  const { store, runSaga, persistor } = configureStore()
  runSaga(rootSaga)

  const rootElement = document.getElementById('root')

  render(
    <React.Fragment>
      {/* <I18nextProvider i18n={i18n}> */}
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <App />
        </Provider>
      </PersistGate>
      {/* </I18nextProvider> */}
    </React.Fragment>,
    rootElement
  )
}

renderApp()
import { BLOCK_PAGES } from '../actions';

const blockPages = (state = [], action) => {
  switch(action.type) {
    case BLOCK_PAGES.FETCH.RECEIVE:
      return [
        ...action.items
      ];
    default:
      return state;
  }
}

export default blockPages;
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';

import {
  fetchCustomers
} from 'api';

import {
  setAndFetchCustomer,
  resetCustomer
} from 'actions';

import Autosuggest from '../forms/Autosuggest';

import Modal, { OVERFLOWS } from './Modal';
import ContentHeader from 'components/ContentHeader';


class SearchForCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.dataToSuggestions = this.dataToSuggestions.bind(this);
    this.createPromise = this.createPromise.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  dataToSuggestions(data) {
    const { items = [] } = data || {}

    return items.map(({ id, name }) => {
      return {
        id,
        name: `${id}: ${name}`
      };
    });
  }

  createPromise(value) {
    return fetchCustomers({
      q: value,
      limit: 5,
    });
  }

  onSuggestionSelected({ id }) {
    this.props.onSelect(id);
  }

  render() {
    const { t } = this.props;

    return (
      <Autosuggest
        dataToSuggestions={this.dataToSuggestions}
        createPromise={this.createPromise}
        onSetValue={(value) => this.setState({ q: value })}
        onSuggestionSelected={this.onSuggestionSelected}
        placeholder={t('search.placeholder')}
        triggerResetsValue
        className="form-control form-control-lg"
      />
    );
  }
};

export default connect(state => ({
  customer: state.customer
}), {
    setAndFetchCustomer
  })(({ customer, setAndFetchCustomer, onClose, t, ...props }) => (
    <Modal overflow={OVERFLOWS.VISIBLE} onClose={onClose} {...props}>
      <div>
        <ContentHeader />
        <hr />
        <SearchForCustomer
          t={t}
          onSelect={(customerId) => {
            setAndFetchCustomer(customerId);
            onClose();
          }}
        />
      </div>
    </Modal>
  ));
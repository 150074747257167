import { USER_SETTINGS } from '../actions';
import { combineReducers } from 'redux';


import entries from 'lodash/entries'
import castArray from 'lodash/castArray'

import { cleanObject } from '../utils/object';

const initState = {
  activitySlug: null,
  hasAcceptedCookieUsage: false,
  optionScopeSelectionPicks: {},
  fieldInputs: [],
  fieldTemplateIdSelection: null,
  fieldVariableInputs: {},
  localFiles: []
};

const activitySlug = (state = initState.activitySlug, action) => {
  switch (action.type) {
    case USER_SETTINGS.SET_ACTIVITY:
      return action.activitySlug;
    case USER_SETTINGS.REMOVE_ACTIVITY:
    case USER_SETTINGS.RESET:
      return initState.activitySlug;
    default:
      return state;
  }
}

const hasAcceptedCookieUsage = (state = initState.hasAcceptedCookieUsage, action) => {
  switch (action.type) {
    case USER_SETTINGS.SET_ACCEPT_COOKIE_USAGE:
      return action.hasAcceptedCookieUsage;
    default:
      return state;
  }
}

const optionScopeSelectionPicks = (state = initState.optionScopeSelectionPicks, action) => {
  switch (action.type) {
    case USER_SETTINGS.OPTION_SELECTION_PICK:
      return {
        ...state,
        [action.optionScopeIdentifier]: {
          optionSelectionIdentifier: action.optionSelectionIdentifier,
          inputValues: cleanObject({
            ...(state[action.optionScopeIdentifier] ? state[action.optionScopeIdentifier].inputValues : {}),
            ...(action.inputValues || {})
          })
        }
      }
    case USER_SETTINGS.RESET_OPTION_SELECTION_PICK:
      return cleanObject({
        ...state,
        [action.optionScopeIdentifier]: null
      })
    case USER_SETTINGS.RESET:
      return initState.optionScopeSelectionPicks;
    default:
      return state;
  }
}

function fieldInputs(state = initState.fieldInputs, action) {
  switch (action.type) {
    case USER_SETTINGS.FIELD_INPUT:
      return [
        ...state.filter(x => x.fieldId !== action.fieldId),
        {
          fieldId: action.fieldId,
          ...action.input
        }
      ]
    case USER_SETTINGS.RESET_FIELD_INPUT:
      return state.filter(x => x.fieldId !== action.fieldId);
    case USER_SETTINGS.RESET:
      return initState.fieldInputs;
    default:
      return state;
  }
}

function fieldTemplateIdSelection(state = initState.fieldTemplateIdSelection, action) {
  switch (action.type) {
    case USER_SETTINGS.FIELD_TEMPLATE_SELECTION:
      return action.templateId
    case USER_SETTINGS.RESET:
      return initState.fieldTemplateIdSelection
    default:
      return state
  }
}

function fieldVariableInputs(state = initState.fieldVariableInputs, action) {
  switch (action.type) {
    case USER_SETTINGS.FIELD_VARIABLE_INPUT:
      return {
        ...state,
        ...entries(action.data).reduce((fieldVariableInputs, [key, value]) => ({
          ...fieldVariableInputs,
          [key]: [
            ...(state[key] || []),
            ...castArray(value)
          ]
        }), {}),
      }
    case USER_SETTINGS.RESET:
      return initState.fieldVariableInputs
    default:
      return state
  }
}

function localFile(state = {}, action) {
  switch (action.type) {
    case USER_SETTINGS.LOCAL_FILES.ADD:
      return {
        id: action.id,
        name: action.name,
        dataUrl: action.dataUrl,
        selectionTemplateId: action.selectionTemplateId,
      }
    case USER_SETTINGS.LOCAL_FILES.EDIT:
      return {
        name: action.name || state.name,
      }

    default:
      return state
  }
}

function localFiles(state = initState.localFiles, action) {
  switch (action.type) {
    case USER_SETTINGS.LOCAL_FILES.ADD:
      return [
        ...state,
        localFile({}, action)
      ]
    case USER_SETTINGS.LOCAL_FILES.EDIT:
      return state.map((selection) => (
        selection.id === action.id ? localFile(selection, action) : selection
      ))
    case USER_SETTINGS.LOCAL_FILES.REMOVE:
      return state.filter((selection) => selection.id !== action.id)
    default:
      return state
  }
}

export default combineReducers({
  activitySlug,
  hasAcceptedCookieUsage,
  optionScopeSelectionPicks,
  fieldInputs,
  fieldTemplateIdSelection,
  fieldVariableInputs,
  localFiles
});
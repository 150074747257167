import { ROUTE } from '../actions';

const initStateParams = {};
const initStateRoute = {
  params: initStateParams
}

const params = (state = initStateParams, action) => {
  switch(action.type) {
    case ROUTE.PARAMS.SET:
      return {...action.params};
    default:
      return state;
  }
}

const route = (state = initStateRoute, action) => {
  switch(action.type) {
    case ROUTE.PARAMS.SET:
      return {
        ...state,
        params: params(state.params, action)
      };
    default:
      return state;
  }
}

export default route;
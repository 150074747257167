import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import classNames from 'classnames'
import { formatPrice } from 'utils/content';
import routes from 'routes'

import { Translate } from 'containers/translates'
import Markdown from 'components/Markdown';

import './GridCell.scss'

import grassTexture from './textures/grass.jpg'

export const
  LINK = 'link',
  CONTENT = 'content',
  PRODUCT = 'product',
  ARTICLE = 'article',
  TYPES = [LINK, CONTENT, PRODUCT, ARTICLE],
  HEIGHT_THEME = 'theme',
  HEIGHT_SMALL = 'small',
  HEIGHT_MEDIUM = 'medium',
  HEIGHT_LARGE = 'large',
  HEIGHTS = [
    HEIGHT_THEME,
    HEIGHT_SMALL,
    HEIGHT_MEDIUM,
    HEIGHT_LARGE
  ],
  PADDING_NORMAL = 'normal',
  PADDING_SPACIOUS = 'spacious',
  PADDINGS = [PADDING_NORMAL, PADDING_SPACIOUS],
  ALIGNMENT_TOP = 'top',
  ALIGNMENT_CENTER = 'center',
  ALIGNMENT_BOTTOM = 'bottom',
  ALIGNMENTS = [ALIGNMENT_TOP, ALIGNMENT_CENTER, ALIGNMENT_BOTTOM],
  JUSTIFICATION_LEFT = 'left',
  JUSTIFICATION_CENTER = 'center',
  JUSTIFICATION_RIGHT = 'right',
  JUSTIFICATIONS = [JUSTIFICATION_LEFT, JUSTIFICATION_CENTER, JUSTIFICATION_RIGHT],
  THEME_DARK = 'theme_dark',
  THEME_DARK_ARTICULATED = 'theme_dark_articulated',
  THEME_WHITE = 'theme_white',
  THEME_WHITE_ARTICULATED = 'theme_white_articulated',
  THEME_PRIMARY = 'theme_primary',
  THEME_SECONDARY = 'theme_secondary',
  THEME_THIRD = 'theme_third',
  THEME_FOURTH = 'theme_fourth',
  THEME_FIFTH = 'theme_fifth',
  THEMES = [
    false,
    THEME_DARK,
    THEME_DARK_ARTICULATED,
    THEME_WHITE,
    THEME_WHITE_ARTICULATED,
    THEME_PRIMARY,
    THEME_SECONDARY,
    THEME_THIRD,
    THEME_FOURTH,
    THEME_FIFTH,
  ],
  COLOR_BRIGHT = 'bright',
  COLOR_DARK = 'dark',
  COLORS = [COLOR_BRIGHT, COLOR_DARK],
  BACKGROUND_COLOR_WHITE = 'white',
  BACKGROUND_COLOR_DARK = 'dark',
  BACKGROUND_COLOR_PRIMARY = 'primary',
  BACKGROUND_COLOR_SECONDARY = 'secondary',
  BACKGROUND_COLOR_SUCCESS = 'success',
  BACKGROUND_COLORS = [
    BACKGROUND_COLOR_WHITE,
    BACKGROUND_COLOR_DARK,
    BACKGROUND_COLOR_PRIMARY,
    BACKGROUND_COLOR_SECONDARY,
    BACKGROUND_COLOR_SUCCESS,
  ],
  GRASS = 'grass',
  BACKGROUND_TEXTURES = [GRASS],
  BACKGROUND_POSITION_TOP_LEFT = 'top_left',
  BACKGROUND_POSITION_TOP_CENTER = 'top_center',
  BACKGROUND_POSITION_TOP_RIGHT = 'top_right',
  BACKGROUND_POSITION_CENTER_LEFT = 'center_left',
  BACKGROUND_POSITION_CENTER_CENTER = 'center_center',
  BACKGROUND_POSITION_CENTER_RIGHT = 'center_right',
  BACKGROUND_POSITION_BOTTOM_LEFT = 'bottom_left',
  BACKGROUND_POSITION_BOTTOM_CENTER = 'bottom_center',
  BACKGROUND_POSITION_BOTTOM_RIGHT = 'bottom_right',
  BACKGROUND_POSITIONS = [
    BACKGROUND_POSITION_TOP_LEFT,
    BACKGROUND_POSITION_TOP_CENTER,
    BACKGROUND_POSITION_TOP_RIGHT,
    BACKGROUND_POSITION_CENTER_LEFT,
    BACKGROUND_POSITION_CENTER_CENTER,
    BACKGROUND_POSITION_CENTER_RIGHT,
    BACKGROUND_POSITION_BOTTOM_LEFT,
    BACKGROUND_POSITION_BOTTOM_CENTER,
    BACKGROUND_POSITION_BOTTOM_RIGHT,
  ]


function GridCellButton({ children }) {
  return (
    <span className="grid-item-button">
      {children}
    </span>
  )
}

GridCellButton.propTypes = {
  children: PropTypes.node
}


function GridCellMarkdown(MarkdownProps) {
  return (
    <Markdown
      {...MarkdownProps}
      options={{
        overrides: {
          Button: {
            component: GridCellButton
          }
        }
      }}
    />
  )
}

export default class GridCell extends Component {
  static propTypes = {
    type: PropTypes.oneOf(TYPES).isRequired,
    theme: PropTypes.oneOf(THEMES),
    customSize: PropTypes.oneOf(HEIGHTS),
    padding: PropTypes.oneOf(PADDINGS),
    align: PropTypes.oneOf(ALIGNMENTS),
    justify: PropTypes.oneOf(JUSTIFICATIONS),
    color: PropTypes.oneOf(COLORS),
    backgroundColor: PropTypes.oneOf(BACKGROUND_COLORS),
    backgroundImage: PropTypes.shape({
      url: PropTypes.string
    }),
    backgroundImagePosition: PropTypes.oneOf(BACKGROUND_POSITIONS),
    backgroundTexture: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object
    ]),
    componentProps: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
  }

  static defaultProps = {
    component: "div",
    componentProps: {},
    style: {}
  }

  render() {
    const RootComponent = this.props.component

    return (
      <RootComponent
        className={classNames(
          this.props.className,
          'grid-item',
          `--type-${this.props.type}`,
          {
            [`--theme-${this.props.theme}`]: this.props.theme,
            [`--customSize-${this.props.customSize}`]: this.props.customSize,
            [`--padding-${this.props.padding}`]: this.props.padding,
            [`--align-${this.props.align}`]: this.props.align,
            [`--justify-${this.props.justify}`]: this.props.justify,
            [`--color-${this.props.color}`]: this.props.color,
            [`--background-color-${this.props.backgroundColor}`]: this.props.backgroundColor,
            [`--background-position-${this.props.backgroundImagePosition}`]: this.props.backgroundImagePosition,
            [`--background-texture`]: this.props.backgroundTexture,
            [`--background-image`]: this.props.backgroundImage,
          }
        )}
        {...this.props.componentProps}

        style={{
          ...(this.props.backgroundImage ?
            {
              backgroundImage: `url(${this.props.backgroundImage.url})`
            } :
            {}),
          ...(this.props.backgroundTexture ?
            {
              backgroundImage: `url(${{
                [GRASS]: grassTexture
              }[this.props.backgroundTexture]})`
            } :
            {}),
          ...this.props.style,
        }
        }
      >
        <div className="grid-item-content">
          {this.props.children}
        </div>
      </RootComponent>
    )
  }
}



export class LinkGridCell extends Component {
  static propTypes = {
    content: PropTypes.string,
    buttonLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    url: PropTypes.string,
  }

  render() {

    const { content, buttonLabel, url, ...sharedProps } = this.props

    return (
      <GridCell
        component={Link}
        componentProps={{
          to: url
        }}
        {...sharedProps}
      >
        <GridCellMarkdown
          content={content}
          markdownOptions={{ headerLevelStart: 2 }}
        />
        {
          buttonLabel &&
          <GridCellButton>{buttonLabel}</GridCellButton>
        }
      </GridCell>
    )
  }
}

export class ContentGridCell extends Component {
  static propTypes = {
    content: PropTypes.string,
  }

  render() {
    return (
      <GridCell
        {...this.props}
      >
        <GridCellMarkdown
          content={this.props.content}
        />
      </GridCell>
    )
  }
}

export class ProductGridCell extends Component {
  static propTypes = {
    product: PropTypes.object,
    productHeader: PropTypes.string,
  }

  render() {
    const url = routes.PRODUCT_DETAIL.get(this.props.product.identifier)

    const {
      ...sharedProps
    } = this.props

    const formattedPriceAndCurrency = formatPrice(this.props.product.price.from, 'kr')

    return (
      <GridCell
        {...sharedProps}
        component={Link}
        componentProps={{
          to: url
        }}
      >
        <Translate ns="product">
          {({ t }) => (
            <div>
              <div>
                <h4 className="text-secondary">{this.props.productHeader}</h4>
                <h2>{this.props.product.name}</h2>
                <p>{t('fromPrice', { formattedPriceAndCurrency })}</p>
              </div>
              <img
                src={this.props.product.image.processedUrls.default}
              />
            </div>
          )}
        </Translate>

      </GridCell>
    )
  }
}

export class ArticleGridCell extends Component {
  static propTypes = {
    content: PropTypes.string,
  }

  render() {
    return (
      <GridCell
        {...this.props}
      >
        <GridCellMarkdown content={this.props.content} />
      </GridCell>
    )
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';


import {
  signout,
  removeAndResetOrder,
  resetUserSettings,
} from '../actions';

import ModalButton from './modals/ModalButton';
import StaffSigninModal from './modals/StaffSigninModal';
import SetCustomerModal from './modals/SetCustomerModal';
import CustomerTypeModal from './modals/CustomerTypeModal';
import SigninOrRegisterModal from './modals/SigninOrRegisterModal';

import BlockArea from '../containers/BlockArea';

// const SigninOrRegisterModalButton = translate('user')(function _SigninOrRegisterModalButton({ isSignedIn, t }) {
//   return (
//     <ModalButton
//       ModalComponent={SigninOrRegisterModal}
//       ButtonComponent={({ onClick }) => (
//         isSignedIn ?
//           <div /> :
//           <button className="btn btn-dark" onClick={onClick}>
//             <i className="fa fa-asterisk" /> {t('signinOrRegister.button.label')}
//           </button>
//       )}
//     />
//   )
// });

// const CustomerTypeModalButton = connect(state => ({
//   selectedCustomerType: state.customer.customerType,
//   isSignedIn: state.auth.isSignedIn
// }))(translate('customer')(({ selectedCustomerType, isSignedIn, t }) => (
//   <ModalButton
//     modalStartsOpen={
//       false
//       // selectedCustomerType === null && !isSignedIn
//     }
//     ButtonComponent={({ onClick }) => (
//       <button className="mb-1 btn btn-outline-dark" onClick={onClick}>
//         {
//           selectedCustomerType !== null ?
//             <span>{t('type.selected.label', { context: selectedCustomerType })}</span> :
//             <span>{t('type.select.label')}</span>
//         }
//       </button>
//     )}
//     ModalComponent={CustomerTypeModal}
//   />
// )))


// const SetCustomerModalButton = connect(state => ({
//   customer: state.customer
// }))(translate('customer')(({ customerIsSet, customer, t }) => (
//   <ModalButton
//     ButtonComponent={({ onClick }) => (
//       <button className="mb-1 btn btn-outline-dark" onClick={onClick}>
//         <i className="fa fa-search" /> {
//           customer.id !== null ?
//             <span>{t('selected.button.label')}: {customer.data.name} ({customer.id})</span> :
//             <span>{t('select.button.label')}</span>
//         }
//       </button>
//     )}
//     ModalComponent={SetCustomerModal}
//   />
// )));

// const StaffSignInButton = translate('auth')(({ t }) => (
//   <ModalButton
//     ButtonComponent={({ onClick }) => (
//       <button className="mb-1 btn btn-outline-dark" onClick={onClick}>
//         <i className="fa fa-sign-in" /> {t('signIn.button.label')}
//       </button>
//     )}
//     ModalComponent={StaffSigninModal}
//   />
// ));

// const StaffSignOutButton = connect(state => ({
//   userName: state.auth.userName
// }), {
//     signout
//   })(translate('auth')(({ userName, signout, t }) => (
//     <button className="mb-1 btn btn-danger" onClick={() => signout()}>
//       <i className="fa fa-sign-in" /> {t('signOut.button.label')} {userName}
//     </button>
//   )));


// const RemoveAndResetOrderButton = connect(state => ({
//   orderId: state.order.id,
//   customerId: state.order.data.customerId || null,
// }), {
//     removeAndResetOrder
//   })(translate('order')(({ customerId, orderId, removeAndResetOrder, t }) => (
//     customerId !== null && orderId !== null &&
//     <div className="d-inline-block mx-1">
//       <button className="mb-1 btn btn-outline-dark" onClick={() => removeAndResetOrder(customerId, orderId)}>
//         <i className="fa fa-close" /> {t('buttons.reset')}
//       </button>
//     </div>
//   )));

// const ResetUserSettingsButton = connect(null, {
//   resetUserSettings
// })(translate('order')(({ resetUserSettings, t }) => (
//   <div className="d-inline-block">
//     <button className="mb-1 btn btn-warning" onClick={() => resetUserSettings()}>
//       <i className="fa fa-eraser" /> {t('buttons.resetUserSettings')}
//     </button>
//   </div>
// )))


export default connect(state => ({
  isSignedIn: state.auth.isSignedIn,
  customer: state.customer,
}))(({ }) => (
  <div className="row">

    <div className="col-md-8">
      <BlockArea areaSlug="footer-main" />
    </div>
    <div className="col-md-3 col-offset-1 pl-md-4" style={{ position: "relative" }}>
      <div className="d-sm-none d-md-block" style={{ position: "absolute", left: "0px", top: "0px", height: "100%", borderLeft: "1px solid rgba(0, 0, 0, .25)" }} />
      <hr className="d-md-none" />

      <div>
        <BlockArea areaSlug="footer-aside" />
      </div>

      <hr />

      <div>
        {/* {
          customer.id === null &&
          <div>
            <CustomerTypeModalButton />
          </div>
        } */}
        {/* {
          isSignedIn ?
            <div>
              <SetCustomerModalButton />
              <RemoveAndResetOrderButton />
              <hr />
              <ResetUserSettingsButton />
              <StaffSignOutButton />
            </div> :

            <div>
              <StaffSignInButton />
            </div>
        } */}

        {/* <div className="mt-1">
          {
            isSignedIn &&
            <LinkAndScrollToTop to={routes.USER.ROOT.get()}>User</LinkAndScrollToTop>
          }

          <SigninOrRegisterModalButton isSignedIn={isSignedIn} />
        </div> */}

      </div>
    </div>


  </div>
));
import { combineReducers } from 'redux';
import { CUSTOMER } from '../actions';

const initState = {
  id: null,
  data: {},
  customerType: null,
  isSyncing: false,
}

const id = (state = initState.id, action) => {
  switch(action.type) {
    case CUSTOMER.FETCH.RECEIVE:
      return action.data.id;
    case CUSTOMER.RESET:
      return initState.id;
    default:
      return state;
  }
}

const data = (state = initState.data, action) => {
  switch(action.type) {
    case CUSTOMER.FETCH.RECEIVE:
      return {
        ...action.data
      };
    case CUSTOMER.RESET:
      return initState.data;
    default:
      return state;
  }
}

const customerType = (state = initState.customerType, action) => {
  switch(action.type) {
    case CUSTOMER.SET_TYPE:
      return action.customerType;
    case CUSTOMER.FETCH.RECEIVE:
      return action.data.type;
    case CUSTOMER.RESET:
      return initState.customerType;
    default:
      return state;
  }
}

const isSyncing = (state = initState.isSyncing, action) => {
  switch(action.type) {
    case CUSTOMER.CREATE_AND_FETCH:
    case CUSTOMER.EDIT_AND_FETCH:
    case CUSTOMER.FETCH.REQUEST:
      return true;
    case CUSTOMER.RESET:
    case CUSTOMER.FETCH.RECEIVE:
    case CUSTOMER.FETCH.FAILURE:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  customerType,
  id,
  data,
  isSyncing
});
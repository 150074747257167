import React from 'react';
import { connect } from 'react-redux';

import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import isInteger from 'lodash/isInteger';
import every from 'lodash/every';
import matches from 'lodash/matches';

import { withRouter } from 'react-router-dom';
import routes from '../../routes';

import { waitUntilSatisfyingState, scrollToTop } from '../hoc';

import { changeSelectedArticle, getSelectedProduct } from '../../actions';
import { 
  getProductCategoryFlatTree
} from '../../selectors';

import DocumentMeta from '../../components/documentMeta';
import LinkedData from '../../components/LinkedData';

import { OneColumnLayout } from '../../components/layouts';

import LoadingIndicator from '../../components/spinners';
import ProductDetail from '../../components/Product/ProductDetail';

class ProductDetailPage extends React.Component {

  constructor(props) {
    super(props);
    this.changeProductAttribute = this.changeProductAttribute.bind(this);
  }

  getProduct() {
    const {getSelectedProduct, match: {params: {productIdentifier, articleIdentifier = null}}} = this.props;
    const productArgs = [productIdentifier];

    productArgs.push(articleIdentifier !== null ? articleIdentifier : null);

    productArgs.push({
      slide: {
        h: 691,
      },
      full: {
        h: 1200,
        dpi: 2
      }
    });

    getSelectedProduct(
      ...productArgs
    );
  }

  changeProductAttribute(attributeId, attributeValueId) {
    const {product: {articles, identifier, selectedArticleIdentifier}} = this.props;

    const currentArticle = find(articles, a => a.identifier === selectedArticleIdentifier);
    const nextAttributeValueIds = {
      ...currentArticle.attributeValueIds,
      [attributeId]: attributeValueId
    };

    const nextArticle = find(articles, matches({attributeValueIds: nextAttributeValueIds}));

    this.props.history.replace(routes.PRODUCT_DETAIL.get(identifier, nextArticle.identifier));
  }

  componentDidMount() {
    this.getProduct();
  }

  componentDidUpdate(prevProps) {
    const {product, match: {params}} = this.props;
    const {product: prevProduct, match: {params: prevParams}} = prevProps;

    if(params.productIdentifier !== prevParams.productIdentifier) {
      this.getProduct();
    }

    if(params.articleIdentifier !== prevParams.articleIdentifier) {
      this.props.changeSelectedArticle(params.articleIdentifier);
    }
  }

  render() {
    const {product, categoryTree, match: {params: {productIdentifier, articleIdentifier = null}}} = this.props;
    const productIsValid = product !== null &&
      (
        product.identifier === productIdentifier ||
        (isInteger(product.identifier) && product.identifier === Number(productIdentifier))
      );

    if(!productIsValid) {
      return (<LoadingIndicator />);
    }

    return (
      <div>
        <DocumentMeta
          title={product.name}
          description={product.description || ''}
          url={routes.PRODUCT_DETAIL.get(productIdentifier, articleIdentifier)}
          imageUrl={product.image ? product.image.processedUrls.default : null}
        />

        <LinkedData 
          type='product'
          data={{
            name: product.name,
            category: categoryTree.map(c => c.name).join("/"),
            image: product.image ? product.image.processedUrls.default : null,
            description: product.description,
          }}
        />
        <OneColumnLayout>
          <ProductDetail
            product={product}
            changeProductAttribute={this.changeProductAttribute}
          />
        </OneColumnLayout>
      </div>
    );
  }
}

export default waitUntilSatisfyingState(
  (state) => {
    const categoriesIsLoaded = state.productCategories.length > 0;
    return categoriesIsLoaded;
  },
  (state) => ({
    product: state.selectedProduct,
    categoryTree: state.selectedProduct !== null ? getProductCategoryFlatTree(state, state.selectedProduct.mainCategoryId) : [],
  }),
  {
    changeSelectedArticle,
    getSelectedProduct
  }
)(scrollToTop()(ProductDetailPage));
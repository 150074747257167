import React from 'react';
import { connect } from 'react-redux';

import ProductOptionScope from './ProductOptionScope';

class ProductDetailOptions extends React.Component {
  render() {
    const {optionScopes, optionSelectionPicks, imageVariants, onChange, onReset} = this.props;
    
    return (
      <div>
        {optionScopes.map((optionScope) => {
          
          const optionScopeIdentifier = optionScope.identifier;
          const optionSelectionPick = (optionSelectionPicks[optionScopeIdentifier] || null);

          return (
            <ProductOptionScope 
              key={optionScope.id}
              optionScopeIdentifier={optionScopeIdentifier}
              {...optionScope}
              imageVariants={imageVariants}
              onChange={onChange.bind(null, optionScopeIdentifier)}
              onReset={onReset.bind(null, optionScopeIdentifier)}
              selectedSelection={optionSelectionPick}
            />
          );Z
        })}
      </div>
    );
  }
}

export default ProductDetailOptions;
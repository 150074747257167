export default {
  modal: {
    header: "Mitt konto",
    header_signupValidateCustomerAccessCode: "Kontrollering av e-post",
    header_signupName: "Användarregistrering",
    header_signupPassword: "Användarregistrering",
    header_signupRetypePassword: "Användarregistrering",
    header_signupComplete: "Välkommen till oss!",

    header_signinPassword: "Logga in",
    header_signinComplete: "Välkommen tillbaka!",

    header_forgotPassword: "Glömt lösenord",
  },
  button: {
    label: "Mitt konto"
  },
  form: {
    email: {
      label: "E-post",
      placeholder: "Din e-post, tack!"
    },
    code: {
      placeholder: "Fyll i femsifrig kod"
    },
    name: {
      placeholder: "Ditt namn",
    },
    password: {
      placeholder: "Lösenord",
    },
    retypePassword: {
      placeholder: "Repetera lösenord",
    }
  },

  steps: {
    start: {
      header: {
        pre: "",
        heading: "Registrera / Logga in",
        post: "Här kan du logga in på befintligt konto, eller registrera ett nytt konto.",
        byApprovingUserConditions: "Genom att registrera ett konto godkänner du <0>våra användarvillkor</0>."
      }
    },
    signin: {
      header: {
        pre: "",
        heading: "Logga in",
        post: "Du har skapat ett konto tidigare så det är bara för dig att logga in."
      },

      forgotPassword: {
        header: {
          pre: "",
          heading: "Ingen fara!",
          post: "Vi har e-postat dig instruktioner om hur du ändrar ditt lösenord.",
        },
        closeButton: {
          label: "Stäng"
        }
      },

      complete: {
        header: {
          pre: "",
          heading: "Välkommen tillbaka {{ userName }}!",
          post: "",
        },
        userPage: {
          button: {
            label: "Konto"
          }
        },
        customerOrdersPage: {
          button: {
            label: "Orderhistorik"
          }
        },
        goShopping: {
          button: {
            label: "Börja handla"
          }
        },
      }
    },
    validateCustomerAccessCode: {
      header: {
        pre: "",
        heading: "Validering av e-post",
        post: "Angiven e-post finns registrerad hos oss. Mata in den femsiffriga kod vi skickat till {{ email }} för att gå vidare."
      },
      validationError: {
        wrongCode: "Koden överensstämmer inte med den vi skickat till din e-post."
      }
    },
    signup: {
      name: {
        header: {
          pre: "",
          heading: "Vad heter du?",
          post: "Ditt namn behövs för att vi skall kunna leverera varor till just dig."
        }
      },
      password: {
        header: {
          pre: "",
          heading: "Ange önskat lösenord",
          post: ""
        }
      },
      retypePassword: {
        header: {
          pre: "",
          heading: "Repetera angivet lösenord",
          post: ""
        }
      },
      complete: {
        header: {
          pre: "",
          heading: "Välkommen {{ userName }}!",
          post: "Vad trevligt att du har skapat ett konto hos oss.",
        },
        userPage: {
          button: {
            label: "Konto"
          }
        },
        goShopping: {
          button: {
            label: "Börja handla"
          }
        },
      }
    }
  },

  forgotPassword: {
    button: {
      label: "Glömt lösenordet?"
    }
  },
  token: {
    failedAccessing: "Misslyckades med inloggning"
  },
}